import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import * as IntermediationContract from "./IntermediationContractApi";
import * as dict from "../../config/Dictionary";

const IntermediationContractMonitor = (props) => {
  const id = props.id;
  const [status, setStatus] = useState(0);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (props.mode === dict.FormMode.add) {
      return;
    } else if (props.mode === dict.FormMode.preview) {
      detectStatus();
      return;
    } else if (props.mode === dict.FormMode.edit) {
      detectStatus();
      const intervalId = setInterval(() => {
        detectStatus(intervalId);
      }, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line
  }, [props.mode]);

  const detectStatus = async (intervalId) => {
    const intermediationContract = await IntermediationContract.GetMonitorById(id);
    setStatus(intermediationContract.status);
    setEmail(intermediationContract.clientEmail);

    if (intermediationContract.status === 200 && intervalId) {
      clearInterval(intervalId);
    }
  };

  const bgColors = {
    waitingForSending: "#e1f5fe",
    sended: "#fff59d",
    accepted: "#a5d6a7",
  };

  const setBgColor = () => {
    if (status === dict.DocStatus.new) {
      return bgColors.waitingForSending;
    } else if (status === dict.DocStatus.sended) {
      return bgColors.sended;
    } else if (status === dict.DocStatus.accepted) {
      return bgColors.accepted;
    } else {
      return "none";
    }
  };

  const setMonitorText = () => {
    if (status === dict.DocStatus.new) {
      return <div style={{ fontSize: "13px" }}>{"Trwa przygotowywanie dokumentu."}</div>;
    } else if (status === dict.DocStatus.sended) {
      return <div style={{ fontSize: "13px" }}>{"Wysłano powiadomienie do klienta. Dokument oczekuje na akceptację."}</div>;
    } else if (status === dict.DocStatus.accepted) {
      return (
        <div style={{ fontSize: "13px" }}>
          {"Klient zaakceptował. Potwierdzenie wysłane do: "}
          <b>{email}</b>
          {"."}
        </div>
      );
    }
    return <div style={{ fontSize: "13px" }}>{""}</div>;
  };

  return (
    <FormGroup
      sx={{
        bgcolor: setBgColor(),
        boxShadow: 1,
        borderRadius: 2,
        p: 2,
        maxWidth: 1200,
        mb: 2,
      }}
    >
      <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 0, pb: 2 }}>
        {"Status"}
      </Stack>
      <Stack>{setMonitorText(status)}</Stack>
    </FormGroup>
  );
};

export default IntermediationContractMonitor;
