import { Text, View, Image } from "@react-pdf/renderer";

const DocumentForm = (props) => {
  const styles = {
    page: {
      fontFamily: "Roboto",
      padding: "0%",
      marginLeft: "15px",
      fontSize: "9px",
      marginRight: "40px",
      marginTop: "-60px",
    },
    title: {
      marginTop: "20px",
      marginBottom: "20px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "12px",
      marginLeft: "30px",
    },
  };

  return (
    <View break style={styles.page}>
      <View style={styles.title}>
        <Text>Załącznik Nr 4 - Kopia dokumentu aktualnego ubezpieczenia od odpowiedzialności</Text>
        <Text>cywilnej za szkody wyrządzone w związku z wykonywaniem czynności pośrednictwa</Text>
      </View>
      <View>
        <Image
          style={{
            width: "464px",
            height: "656px",
          }}
          src={"/assets/Graphics/homeestate/insurance/polisaOC-1-2024.png"}
        />
      </View>
      <View>
        <Image
          style={{
            width: "464px",
            height: "656px",
          }}
          src={"/assets/Graphics/homeestate/insurance/polisaOC-2-2024.png"}
        />
      </View>
    </View>
  );
};

export default DocumentForm;
