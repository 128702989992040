import * as React from "react";
import { formatAddressLine1, phoneNumberFormat, formatNumberByCurrency, netToGrossRecalculate, formatAddressLine2 } from "./../../../utils/validator";
import * as dict from "./../../../config/Dictionary";
import { useEffect, useRef, useState } from "react";
import DocumentHTMLAtt1 from "./DocumentHTMLAtt1";
import DocumentHTMLAtt2 from "./DocumentHTMLAtt2";
import DocumentHTMLAtt3 from "./DocumentHTMLAtt3";
import DocumentHTMLAtt4 from "./DocumentHTMLAtt4";
import DocumentHTMLAtt5 from "./DocumentHTMLAtt5";
import * as ContractPurchaseCoownerApi from "./../../../business/ContractPurchase/ContractPurchaseCoowner/ContractPurchaseCoownerApi";
import * as ContractPurchaseBinaryApi from "./../../../business/ContractPurchase/ContractPurchaseBinary/ContractPurchaseBinaryApi";
import ImageViewer from "./../../../business/ContractPurchase/ContractPurchaseBinary/ImageViewer";

const DocumentForm = (props) => {
  const id = props.id;
  const mode = props.mode;
  const sessionKey = props.sessionKey;

  const withPresentation = props.withPresentation;
  const contractDate = props.contractDate;
  const contractHour = props.contractHour;
  const clientName = props.clientName;
  const clientSurname = props.clientSurname;
  const propertyAddrStreet = props.propertyAddrStreet;
  const propertyAddrStreetNumber = props.propertyAddrStreetNumber;
  const propertyAddrLocaleNumber = props.propertyAddrLocaleNumber;
  const propertyAddrPostcode = props.propertyAddrPostcode;
  const propertyAddrCity = props.propertyAddrCity;
  //const propertyAddrCountry = props.propertyAddrCountry;
  const agentName = props.agentName;
  const agentSurname = props.agentSurname;

  const hiddenInputRef = useRef(null);

  const [coownersData, setCoownersData] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);

  const getCoownersData = async () => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await ContractPurchaseCoownerApi.GetByDocIdSessionKey(id, sessionKey);
      setCoownersData(object);
    } else {
      const object = await ContractPurchaseCoownerApi.GetByDocId(id, sessionKey);
      setCoownersData(object);
    }
  };

  const getAttachmentsData = async () => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await ContractPurchaseBinaryApi.GetIdsByDocIdSessionKey(id, null, sessionKey);
      setAttachmentsData(object);
    } else {
      const object = await ContractPurchaseBinaryApi.GetIdsByDocId(id, null);
      setAttachmentsData(object);
    }
  };

  useEffect(() => {
    hiddenInputRef.current.focus();
    getCoownersData();
    getAttachmentsData();

    return () => {};
    // eslint-disable-next-line
  }, []);

  const styles = {
    page: {
      fontFamily: "Roboto",
      padding: "3%",
      paddingTop: "5px",
      marginLeft: "15px",
      fontSize: "12px",
      marginRight: "20px",
    },
    parameter: {
      fontWeight: "bold",
      color: "#163058",
    },
    title: {
      paddingBottom: "40px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
    attachmentTitle: {
      paddingBottom: "5px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
    descr: {
      paddingBottom: "7px",
      marginRight: "20px",
    },
    descrBold: {
      fontWeight: "bold",
    },
    descrBoldAndCenter: {
      fontWeight: "bold",
      textAlign: "center",
    },
    square: {
      width: 10, // Adjust width and height as needed
      height: 10,
      borderWidth: 0, // Set border width to 0 for no border
      border: "1px solid black",
    },
    parameterLack: {
      fontWeight: "bold",
      //color: "red",
      color: "#163058",
    },
    paragrapf: {
      textAlign: "center",
      fontSize: "12px",
      fontWeight: "bold",
    },
  };

  const loadData = (value) => {
    if (!value) {
      return <span style={styles.parameterLack}>{" - "}</span>;
    }
    return <span style={styles.parameter}>{value}</span>;
  };

  const renderRepresentative = (
    xClientName,
    xClientSurname,
    xClientAddrCity,
    xClientAddrPostcode,
    xClientAddrStreet,
    xClientAddrStreetNumber,
    xClientAddrLocaleNumber,
    xClientPesel,
    xClientIdType,
    xClientIdNumber,
    xClientEmail,
    xClientPhone
  ) => {
    return (
      <div>
        <p>
          {loadData(xClientName)} {loadData(xClientSurname)} zamieszkałym w {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},
          {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, PESEL: {loadData(xClientPesel)},
          legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)} {loadData(xClientIdNumber)}
        </p>
        <p>
          Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
        </p>
      </div>
    );
  };

  const renderStakeholder = (
    isFirst,
    type,
    xClientName,
    xClientSurname,
    xClientAddrCity,
    xClientAddrPostcode,
    xClientAddrStreet,
    xClientAddrStreetNumber,
    xClientAddrLocaleNumber,
    xClientPesel,
    xClientIdType,
    xClientIdNumber,
    xClientEmail,
    xClientPhone,
    xPersonName,
    xPersonSurname,
    xPersonAddrCity,
    xPersonAddrPostcode,
    xPersonAddrStreet,
    xPersonAddrStreetNumber,
    xPersonAddrLocaleNumber,
    xPersonPesel,
    xPersonIdType,
    xPersonIdNumber,
    xPersonEmail,
    xPersonPhone,
    xCompanyName,
    xCompanyNIP,
    xCompanyKRS
  ) => {
    if (type === dict.ClientType.person) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xClientName)} {loadData(xClientSurname)} zamieszkałym w {loadData(xClientAddrCity)}, kod pocztowy{" "}
            {loadData(xClientAddrPostcode)}, {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))},
            PESEL: {loadData(xClientPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)}{" "}
            {loadData(xClientIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.personWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xPersonName)} {loadData(xPersonSurname)} zamieszkałym w {loadData(xPersonAddrCity)}, kod pocztowy{" "}
            {loadData(xPersonAddrPostcode)}, {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))},
            PESEL: {loadData(xPersonPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xPersonIdType)[0]?.label)}{" "}
            {loadData(xPersonIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xPersonEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xPersonPhone))}
          </p>
          <p>reprezentowanym przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    } else if (type === dict.ClientType.businessIndividual) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xClientName)} {loadData(xClientSurname)} prowadzącym jednoosobową działalność gospodarczą pod nazwą {loadData(xCompanyName)},
            adres: {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, PESEL:{" "}
            {loadData(xClientPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)}{" "}
            {loadData(xClientIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.businessIndividualWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xPersonName)} {loadData(xPersonSurname)} prowadzącym jednoosobową działalność gospodarczą pod nazwą {loadData(xCompanyName)},
            adres: {loadData(xPersonAddrCity)}, kod pocztowy {loadData(xPersonAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, PESEL:{" "}
            {loadData(xPersonPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xPersonIdType)[0]?.label)}{" "}
            {loadData(xPersonIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xPersonEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xPersonPhone))}
          </p>
          <p>reprezentowanym przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    } else if (type === dict.ClientType.company) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xCompanyName)}, adres: {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, KRS:{" "}
            {loadData(xCompanyKRS)}
          </p>
          <p>
            reprezentowaną przez {loadData(xClientName)} {loadData(xClientSurname)} na podstawie KRS
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.companyWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xCompanyName)}, adres: {loadData(xPersonAddrCity)}, kod pocztowy {loadData(xPersonAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, KRS:{" "}
            {loadData(xCompanyKRS)}
          </p>
          <p>reprezentowaną przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    }
  };

  const renderOwner = () => {
    return renderStakeholder(
      true,
      props.clientType,
      props.clientName,
      props.clientSurname,
      props.clientAddrCity,
      props.clientAddrPostcode,
      props.clientAddrStreet,
      props.clientAddrStreetNumber,
      props.clientAddrLocaleNumber,
      props.clientPesel,
      props.clientIdType,
      props.clientIdNumber,
      props.clientEmail,
      props.clientPhone,
      props.principalName,
      props.principalSurname,
      props.principalAddrCity,
      props.principalAddrPostcode,
      props.principalAddrStreet,
      props.principalAddrStreetNumber,
      props.principalAddrLocaleNumber,
      props.principalPesel,
      props.principalIdType,
      props.principalIdNumber,
      props.principalEmail,
      props.principalPhone,
      props.companyName,
      props.companyNIP,
      props.companyKRS
    );
  };

  const renderCoowner = (obj) => {
    return renderStakeholder(
      false,
      obj.clientType,
      obj.clientName,
      obj.clientSurname,
      obj.clientAddrCity,
      obj.clientAddrPostcode,
      obj.clientAddrStreet,
      obj.clientAddrStreetNumber,
      obj.clientAddrLocaleNumber,
      obj.clientPesel,
      obj.clientIdType,
      obj.clientIdNumber,
      obj.clientEmail,
      obj.clientPhone,
      obj.principalName,
      obj.principalSurname,
      obj.principalAddrCity,
      obj.principalAddrPostcode,
      obj.principalAddrStreet,
      obj.principalAddrStreetNumber,
      obj.principalAddrLocaleNumber,
      obj.principalPesel,
      obj.principalIdType,
      obj.principalIdNumber,
      obj.principalEmail,
      obj.principalPhone,
      obj.companyName,
      obj.companyNIP,
      obj.companyKRS
    );
  };

  const renderCoowners = () => {
    return coownersData?.map((item) => (
      <div key={item.id}>
        <span>{"i "}</span>
        {renderCoowner(item)}
      </div>
    ));
  };

  const renderAttachments = () => {
    return attachmentsData?.map((item) => (
      <div key={item.id} style={{ textAlign: "center", marginTop: "10px", marginBottom: "0px" }}>
        <div style={styles.attachmentTitle}>{item.label}</div>
        <ImageViewer id={item.id} sessionKey={sessionKey} mode={mode} mimeType={item.mimeType} docId={id} />
      </div>
    ));
  };

  const renderAgentProvision = () => {
    if (props.agentProvisionType === dict.AgentProvisionType.amount) {
      return (
        <span>
          1. Klient zobowiązuje się do zapłaty Pośrednikowi wynagrodzenia w wysokości{" "}
          {loadData(formatNumberByCurrency(netToGrossRecalculate(props.agentProvisionAmountNet), props.offerPriceCurrency))} PLN brutto ({""}
          {loadData(formatNumberByCurrency(props.agentProvisionAmountNet, props.offerPriceCurrency))} PLN netto + 23% podatku od towarów i usług VAT)
          niezależnie od ceny transakcyjnej nieruchomości ("Wynagrodzenie")
        </span>
      );
    }

    return (
      <span>
        1. Klient zobowiązuje się do zapłaty Pośrednikowi wynagrodzenia w wysokości{" "}
        {loadData(formatNumberByCurrency(netToGrossRecalculate(props.agentProvisionPercentNet), props.offerPriceCurrency))}% brutto ({""}
        {loadData(formatNumberByCurrency(props.agentProvisionPercentNet, props.offerPriceCurrency))}% netto + 23% podatku od towarów i usług VAT) ceny
        transakcyjnej Nieruchomości brutto ("Wynagrodzenie")
      </span>
    );
  };

  return (
    <div>
      <div style={styles.page}>
        <input type="checkbox" ref={hiddenInputRef} style={{ width: 0, height: 0 }} />
        <p style={styles.title}>
          {"UMOWA POŚREDNICTWA NABYCIA NIERUCHOMOŚCI"} <br />
          {"zawarta poza lokalem przedsiębiorstwa v1.01"}
        </p>
        <p>
          zawarta w dniu {loadData(props.contractDate)} r. w miejscowości {loadData(props.contractCity)} pomiędzy:
        </p>
        <p>
          (1) Home Spółką z ograniczoną odpowiedzialnością z siedzibą w Szczecin, pod adresem: ul. Zielonogórska 31, lok. 1.1, 71-084 Szczecin,
          wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Szczecin-Centrum w Szczecinie, XIII Wydział
          Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000854950, o numerze NIP 8522665760, o kapitale zakładowym w wysokości 5 000,00
          złotych, zwaną dalej „HOME”, reprezentowaną przez: {loadData(props.agentName)} {loadData(props.agentSurname)} - pełnomocnika, tel.:{" "}
          {loadData(phoneNumberFormat(props.agentPhone))} email: {loadData(props.agentEmail)}
        </p>
        <p>a</p>
        {renderOwner()}
        {renderCoowners()}
        <p style={styles.paragrapf}>§1</p>
        <p>
          1. Klient powierza, a Pośrednik zobowiązuje się do pośredniczenia w zawarciu przez Zlecającego umowy nabycia nieruchomości ("Nieruchomość").
          Na poczet niniejszej umowy jako Nieruchomość należy rozumieć jako:
        </p>
        <p>
          <input type="checkbox" id="propertyIsSeparateOwnership" name="propertyIsSeparateOwnership" value="" checked={"checked"} disabled />
          <label>odrębny lokal mieszkalny/ użytkowy (prawo własności)</label>
          <br />
          <input type="checkbox" id="propertyIsLand" name="propertyIsLand" value="" checked={"checked"} disabled />
          <label>nieruchomość gruntową </label>
          <br />
          <input type="checkbox" id="propertyIsCooperativeOwnership" name="propertyIsCooperativeOwnership" value="" checked={"checked"} disabled />
          <label>spółdzielcze własnościowe prawo do lokalu </label>
          <br />
          <input type="checkbox" id="propertyIsHome" name="propertyIsHome" value="" checked={"checked"} disabled />
          <label>lokal lub dom jednorodzinny (rynek wtórny).</label>
          <br />
        </p>
        <p style={styles.paragrapf}>§2</p>
        <p>
          1. Pośrednik zobowiązuje się do podejmowania dla Klienta czynności pośrednictwa zmierzających do nabycia Nieruchomości przez Klienta,
          zgodnie z wiedzą i doświadczeniem Pośrednika. W szczególności Pośrednik zobowiązuje się do:
        </p>
        <p>
          <input type="checkbox" id="obligation1" name="obligation1" value="" checked={"checked"} disabled />
          <label>przedstawiania Klientowi ofert zbycia Nieruchomości</label>
          <br />
          <input type="checkbox" id="obligation2" name="obligation2" value="" checked={"checked"} disabled />
          <label>dokonywania w uzgodnionych terminach prezentacji Nieruchomości Klientowi</label>
          <br />
          <input type="checkbox" id="obligation3" name="obligation3" value="" checked={"checked"} disabled />
          <label>dokonania wstępnej weryfikacji i przedstawienia Klientowi stanu prawnego Nieruchomości</label>
          <br />
          <input type="checkbox" id="obligation4" name="obligation4" value="" checked={"checked"} disabled />
          <label>zapewnienia obsługi związanej z przygotowaniem transakcji. </label>
          <br />
        </p>
        <p>2. Pośrednik może wykonywać czynności pośrednictwa na rzecz obu stron transakcji.</p>
        <p style={styles.paragrapf}>§3</p>
        <p>{renderAgentProvision()}</p>
        <p>
          2. Wynagrodzenie dla Pośrednika będzie wymagalne i płatne w dwóch częściach: (i) pierwsza część w wysokości 50% Wynagrodzenia zostanie
          zapłacona po zawarciu przez Klienta umowy przedwstępnej sprzedaży Nieruchomości, na podstawie wystawionej przez Pośrednika faktury VAT z 7-
          dniowym terminem płatności; (ii) druga część w wysokości 50% Wynagrodzenia zostanie zapłacona po zawarciu przez Klienta umowy przyrzeczonej
          z nabywcą wskazanym przez Pośrednika, na podstawie wystawionej przez Pośrednika faktury VAT z 7- dniowym terminem płatności.{" "}
        </p>
        <p>
          3. Pośrednikowi przysługuje prawo zatrzymania pierwszej części Wynagrodzenia, jeżeli pomimo zawartej umowy przedwstępnej nie dojdzie do
          zawarcia umowy przenoszącej własność Nieruchomości, tytułem ryczałtowego wynagrodzenia Pośrednika za czynności podjęte w wykonaniu Umowy.
        </p>
        <p>
          4. W przypadku, gdy zawarcie umowy przenoszącej własność Nieruchomości nie zostanie poprzedzone umową przedwstępną, całość Wynagrodzenia
          zostanie zapłacona Pośrednikowi po zawarciu umowy przenoszącej własność Nieruchomości, na podstawie wystawionej przez Pośrednika faktury VAT
          z 7- dniowym terminem płatności.
        </p>
        <p>
          5. Wynagrodzenie przysługuje Pośrednikowi również w sytuacji, gdy przeniesienie własności Nieruchomości nastąpi na podstawie innej czynności
          prawnej niż umowa sprzedaży.
        </p>
        <p>
          6. Jeżeli nie dojdzie do przeniesienia własności Nieruchomości na rzecz Klienta z powodu skorzystania przez uprawnionego z prawa pierwokupu
          Nieruchomości, Pośrednik zwróci Klientowi Wynagrodzenie otrzymane w związku z zwarciem umowy przedwstępnej lub warunkowej umowy sprzedaży
          Nieruchomości, w terminie 14 dni od dnia przekazania Pośrednikowi kopii oświadczenia o wykonaniu prawa pierwokupu Nieruchomości, złożonego w
          formie aktu notarialnego przez uprawnionego do pierwokupu oraz okazania Pośrednikowi oryginału wypisu aktu notarialnego tego oświadczenia.
          Zwrot nastąpi na podstawie faktury korygującej.
        </p>
        <p>
          7. Wynagrodzenie Pośrednika należne jest również przypadku, gdy w okresie 12 miesięcy po rozwiązaniu lub wygaśnięciu niniejszej Umowy
          dojdzie do zawarcia z Klientem lub jego osobą bliską, umowy sprzedaży albo umowy przedwstępnej sprzedaży albo umowy najmu Nieruchomości
          przedstawionej Klientowi przez Pośrednika. Przez osobę bliską należy rozumieć: zstępnych, wstępnych, rodzeństwo, dzieci rodzeństwa,
          małżonka, konkubenta, osoby przysposabiające i przysposobione.{" "}
        </p>
        <p>
          8. Klient zobowiązuje się do przekazania Pośrednikowi kopii przedwstępnej umowy sprzedaży Nieruchomości i umowy przenoszącej własność
          Nieruchomości oraz okazania Pośrednikowi oryginałów tych umów w celu prawidłowego wyliczenia wynagrodzenia należnego Pośrednikowi. W
          przypadku niewykonania tego zobowiązania przez Klienta, Pośrednik wyliczy wynagrodzenie według ceny przekazanej mu przez zbywcę.
        </p>
        <p style={styles.paragrapf}>§4</p>
        <p>1. Klient oświadcza, że wyraża zgodę na przekazanie przez Pośrednika faktury VAT drogą elektroniczną na adres: ___________________</p>
        <p>
          2. Klient i Pośrednik zobowiązują się do każdorazowego sporządzania protokołu prezentacji Nieruchomości zgodnie ze wzorem który stanowi
          Załącznik nr 5 do Umowy.{" "}
        </p>
        <p>3. Klient zobowiązuje się do zachowania poufności danych potencjalnych zbywców Nieruchomości. </p>
        <p style={styles.paragrapf}>§5</p>
        <p>
          1. Pośrednik oświadcza, że zgodnie z obowiązkiem określonym w art. 181 ustawy o gospodarce nieruchomościami jest ubezpieczony od
          odpowiedzialności cywilnej za szkody wyrządzone w związku z wykonywaniem czynności pośrednictwa, na dowód czego przekazuje Klientowi kopię
          dokumentu ubezpieczenia.{" "}
        </p>
        <p style={styles.paragrapf}>§6</p>
        <p>1. Niniejsza Umowa zostaje zawarta na czas nieoznaczony. Każda Strona może Umowę z zachowaniem 14-dniowego okresu wypowiedzenia. </p>
        <p>2. Wszelkie zmiany do niniejszej Umowy wymagają formy pisemnej pod rygorem nieważności.</p>
        <p>
          3. Klient oświadcza, że został poinformowany przez Pośrednika o: (i) prawie do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa w
          terminie 14 (czternastu) dni od dnia jej zawarcia oraz sposobie odstąpienia (zgodnie z art. 27 ustawy z dnia 30 maja 2014r. o prawach
          konsumenta); (ii) obowiązku zapłaty Pośrednikowi za świadczenia spełnione do chwili odstąpienia od umowy, w przypadku odstąpienia od umowy
          zawartej poza lokalem przedsiębiorstwa, jeśli zgłosił żądanie wykonywania usługi przed terminem do odstąpienia od umowy (zgodnie z art. 35
          ustawy z dnia 30 maja 2014r. o prawach konsumenta), (iii) braku prawa do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa jeśli
          zgłosił żądanie wykonywania usługi przed terminem do odstąpienia od umowy (zgodnie z art. 12 ust. 1 pkt.12) ustawy z dnia 30 maja 2014r. o
          prawach konsumenta) w przypadku, gdy dojdzie do transakcji określonej w Umowie w wyniku podjętych w tym okresie przez Pośrednika czynności
          pośrednictwa.
        </p>
        <p>
          4. Klient oświadcza, że otrzymał następujące Załączniki do umowy: <br />
          Załącznik Nr 1 - Pouczenie o odstąpieniu od umowy zawartej poza lokalem przedsiębiorstwa <br />
          Załącznik Nr 2 - Wzór formularza o odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa <br />
          Załącznik Nr 3 - informacja o ochronie danych osobowych <br />
          Załącznik Nr 4 - kopia dokumentu aktualnego ubezpieczenia od odpowiedzialności cywilnej za szkody wyrządzone w związku z wykonywaniem
          czynności pośrednictwa. <br />
          Załącznik Nr 5 - Wzór protokołu prezentacji Nieruchomości <br />
        </p>
        <p>5. Reklamacje należy kierować na adres email: reklamacje@home-estate.pl</p>
        <p>6. Niniejsza umowa została sporządzona w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze Stron.</p>
      </div>
      <div>{renderAttachments()}</div>
      <DocumentHTMLAtt1 />
      <DocumentHTMLAtt2 />
      <DocumentHTMLAtt3 />
      <DocumentHTMLAtt4 />
      <DocumentHTMLAtt5
        withPresentation={withPresentation}
        clientName={clientName}
        clientSurname={clientSurname}
        agentName={agentName}
        agentSurname={agentSurname}
        contractDate={contractDate}
        contractHour={contractHour}
        visitAddress={"".concat(
          formatAddressLine1(propertyAddrStreet, propertyAddrStreetNumber, propertyAddrLocaleNumber),
          propertyAddrCity ? ", " : "",
          formatAddressLine2(propertyAddrPostcode, propertyAddrCity)
        )}
      />
    </div>
  );
};

export default DocumentForm;
