import * as React from "react";
import { useState, useEffect } from "react";
import * as PresentationProtocolApi from "./../../business/PresentationProtocol/PresentationProtocolApi";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";

import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MessageOK from "./MessageOK";
import Printing from "../../printing/PresentationProtocol";
import { pdf } from "@react-pdf/renderer";
import * as dict from "../../config/Dictionary";
import * as sessionClient from "./../../components/SessionStorage/SessionStorageClient";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationHelper";
import { phoneNumberFormat, validateEmail } from "./../../utils/validator";

const ClientForm = (props) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  const presentationProtocolId = props.id;

  const [visitDate, setVisitDate] = useState(dayjs(new Date().toUTCString()));

  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [clientPesel, setClientPesel] = useState("");
  const [clientIdType, setClientIdType] = useState("");
  const [clientIdNumber, setClientIdNumber] = useState("");

  //const [clientPhone, setClientPhone] = useState("");

  const [coviewerName, setCoviewerName] = useState("");
  const [coviewerSurname, setCoviewerSurname] = useState("");
  const [coviewerPesel, setCoviewerPesel] = useState("");
  const [coviewerIdType, setCoviewerIdType] = useState("");
  const [coviewerIdNumber, setCoviewerIdNumber] = useState("");

  const [addrStreet, setAddrStreet] = useState("");
  const [addrStreetNumber, setAddrStreetNumber] = useState("");
  const [addrLocaleNumber, setAddrLocaleNumber] = useState("");
  const [addrPostcode, setAddrPostcode] = useState("");
  const [addrCity, setAddrCity] = useState("");

  const [clientEmail, setClientEmail] = useState("");
  const [clientEmailRepeat, setClientEmailRepeat] = useState("");

  const consentPrivacyText = t(formatTranslation("presentationProtocol", "consentPrivacyText"));
  const consentMediationText = t(formatTranslation("presentationProtocol", "consentMediationText"));
  const consentTradeText = t(formatTranslation("presentationProtocol", "consentTradeText"));
  const consentMarketingText = t(formatTranslation("presentationProtocol", "consentMarketingText"));

  const [consentPrivacy, setConsentPrivacy] = useState(false);
  const [consentMediation, setConsentMediation] = useState(false);
  const [consentTrade, setConsentTrade] = useState(false);
  const [consentMarketing, setConsentMarketing] = useState(false);

  const [openMessage, setOpenMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [userName, setUserName] = useState("");
  const [userSurname, setUserSurname] = useState("");

  const [branchName, setBranchName] = useState("");
  const [branchAddrStreet, setBranchAddrStreet] = useState("");
  const [branchAddrStreetNumber, setBranchAddrStreetNumber] = useState("");
  const [branchAddrLocaleNumber, setBranchAddrLocaleNumber] = useState("");
  const [branchAddrPostcode, setBranchAddrPostcode] = useState("");
  const [branchAddrCity, setBranchAddrCity] = useState("");
  // eslint-disable-next-line
  const [branchAddrCountry, setBranchAddrCountry] = useState("");
  const [branchEmail, setBranchEmail] = useState("");
  const [branchPhone, setBranchPhone] = useState("");

  const getFormData = async () => {
    try {
      let presentationProtocolData = await PresentationProtocolApi.GetByIdUnauthorized(presentationProtocolId, sessionClient.getSessionKey());

      setVisitDate(presentationProtocolData.visitDate);
      setClientName(presentationProtocolData.clientName);
      setClientSurname(presentationProtocolData.clientSurname);
      setClientPesel(presentationProtocolData.clientPesel);
      setClientIdType(presentationProtocolData.clientIdType);
      setClientIdNumber(presentationProtocolData.clientIdNumber);
      setCoviewerName(presentationProtocolData.coviewerName);
      setCoviewerSurname(presentationProtocolData.coviewerSurname);
      setCoviewerPesel(presentationProtocolData.coviewerPesel);
      setCoviewerIdType(presentationProtocolData.coviewerIdType);
      setCoviewerIdNumber(presentationProtocolData.coviewerIdNumber);
      setAddrStreet(presentationProtocolData.addrStreet);
      setAddrStreetNumber(presentationProtocolData.addrStreetNumber);
      setAddrLocaleNumber(presentationProtocolData.addrLocaleNumber);
      setAddrPostcode(presentationProtocolData.addrPostcode);
      setAddrCity(presentationProtocolData.addrCity);

      // Agent
      setUserName(presentationProtocolData.UserAgent.name);
      setUserSurname(presentationProtocolData.UserAgent.surname);

      // Branch
      setBranchName(presentationProtocolData.Branch.name);
      setBranchAddrStreet(presentationProtocolData.Branch.addrStreet);
      setBranchAddrStreetNumber(presentationProtocolData.Branch.addrStreetNumber);
      setBranchAddrLocaleNumber(presentationProtocolData.Branch.addrLocaleNumber);
      setBranchAddrPostcode(presentationProtocolData.Branch.addrPostcode);
      setBranchAddrCity(presentationProtocolData.Branch.addrCity);
      setBranchAddrCountry(presentationProtocolData.Branch.addrCountry);
      setBranchEmail(presentationProtocolData.Branch.email);
      setBranchPhone(presentationProtocolData.Branch.phone);
    } catch (error) {
      console.error("Wystąpił błąd PDF: ", error); // TODO: wysłać na serwer do loga, trzeba reagować
    }
  };

  useEffect(() => {
    getFormData();

    return () => {};
    // eslint-disable-next-line
  }, []);

  const printingToBlob = async (clientAcceptedDate) => {
    return await pdf(
      <Printing
        clientName={clientName}
        clientSurname={clientSurname}
        clientPesel={clientPesel}
        clientIdType={clientIdType}
        clientIdNumber={clientIdNumber}
        coviewerName={coviewerName}
        coviewerSurname={coviewerSurname}
        coviewerPesel={coviewerPesel}
        coviewerIdType={coviewerIdType}
        coviewerIdNumber={coviewerIdNumber}
        agentName={userName}
        agentSurname={userSurname}
        consentPrivacy={consentPrivacy}
        consentMediation={consentMediation}
        consentTrade={consentTrade}
        consentMarketing={consentMarketing}
        presentationDate={
          clientAcceptedDate
            ? dayjs(clientAcceptedDate).format("YYYY-MM-DD HH:mm").toString()
            : dayjs(new Date()).format("YYYY-MM-DD HH:mm").toString()
        }
        clientSignature={clientName + " " + clientSurname}
        visitDate={dayjs(visitDate).format("YYYY-MM-DD")}
        visitHour={dayjs(visitDate).format("HH:mm")}
        visitAddress={"".concat(
          addrStreet ? "ul. ".concat(addrStreet) : "",
          " ",
          addrStreetNumber,
          addrLocaleNumber ? "/".concat(addrLocaleNumber) : "",
          "\n",
          addrPostcode,
          " ",
          addrCity
        )}
        branchName={branchName}
        branchAddressLine1={"".concat(
          branchAddrStreet ? "ul. ".concat(branchAddrStreet) : "",
          " ",
          branchAddrStreetNumber,
          branchAddrLocaleNumber ? "/".concat(branchAddrLocaleNumber) : ""
        )}
        branchAddressLine2={"".concat(branchAddrPostcode, " ", branchAddrCity)}
        branchEmail={branchEmail}
        branchPhone={phoneNumberFormat(branchPhone)}
      />
    ).toBlob();
  };

  const confirmData = async () => {
    try {
      if (!clientEmail) {
        setErrorMessage("Wprowadź adres e-mail.");
        setOpenMessage(true);
        return -1;
      }

      if (!validateEmail(clientEmail)) {
        setErrorMessage("Podany adres e-mail jest niepoprawny. Zweryfikuj i popraw.");
        setOpenMessage(true);
        return -1;
      }

      if (!clientEmailRepeat) {
        setErrorMessage("Wprowadź adres e-mail ponownie w pole potwierdzenia.");
        setOpenMessage(true);
        return -1;
      }

      if (!validateEmail(clientEmailRepeat)) {
        setErrorMessage("Podany ponownie adres e-mail jest niepoprawny. Zweryfikuj i popraw.");
        setOpenMessage(true);
        return -1;
      }

      if (clientEmail.toLocaleLowerCase() !== clientEmailRepeat.toLocaleLowerCase()) {
        setErrorMessage("Adres e-mail w obu polach musi być jednakowy, występują różnice. Zweryfikuj i popraw.");
        setOpenMessage(true);
        return -1;
      }

      if (!consentPrivacy) {
        setErrorMessage("Wymagane jest zaznaczenie zgody dotyczącej przetwarzania danych osobowych na potrzeby prezentacji.");
        setOpenMessage(true);
        return -1;
      }

      let clientAcceptedDate = dayjs(new Date().toUTCString());
      let printingAsBlob = await printingToBlob(clientAcceptedDate);

      await PresentationProtocolApi.UpdateUnauthorized(presentationProtocolId, sessionClient.getSessionKey(), {
        clientEmail: clientEmail,
        consentPrivacy: consentPrivacy,
        consentMediation: consentMediation,
        consentTrade: consentTrade,
        consentMarketing: consentMarketing,
        pdfDocument: printingAsBlob,
        status: dict.DocStatus.accepted,
        clientAcceptedAt: clientAcceptedDate,
      });

      // await MailApi.Send({
      //   to: clientEmail,
      //   subject: "Protokół prezentacji z oględzin mieszkania",
      //   text: "W załączniku przesyłamy zatwierdzony dokument. Dziękujemy.",
      //   attachments: [presentationProtocolId],
      // });

      props.onAccept();
    } catch (error) {
      console.error("Wystąpił błąd: ", error); // TODO: wysłać na serwer do loga, trzeba reagować
    }
  };

  const renderCoviewerSection = () => {
    if (coviewerName) {
      return (
        <Typography variant="body1" display="inline">
          wraz z <strong>{coviewerName + " " + coviewerSurname + " "}</strong>
          {renderCoviewerIdentificationData()}
        </Typography>
      );
    }
    return "";
  };

  const handleDialogClose = () => {
    setOpenMessage(false);
  };

  const renderClientIdentificationData = () => {
    if (clientPesel !== "") {
      return (
        <>
          (Pesel: <strong> {clientPesel}</strong>)
        </>
      );
    }

    return (
      <>
        ({dict.IdentityDocumentType.filter((f) => f.code === clientIdType)[0].label}:<strong> {clientIdNumber}</strong>)
      </>
    );
  };

  const renderCoviewerIdentificationData = () => {
    if (coviewerPesel !== "") {
      return (
        <>
          (Pesel: <strong> {coviewerPesel}</strong>)
        </>
      );
    }

    return (
      <>
        ({dict.IdentityDocumentType.filter((f) => f.code === coviewerIdType)[0].label}:<strong> {coviewerIdNumber}</strong>)
      </>
    );
  };

  return (
    <Stack direction="column" spacing={1} sx={{ p: 2, textAlign: "left", m: 1 }}>
      <MessageOK open={openMessage} onClose={handleDialogClose} title={"Błąd"} text={errorMessage} />
      <img
        style={{
          top: -5,
          left: -1,
          width: "25px",
          position: "absolute",
        }}
        src={"/assets/Graphics/client/docLogoTop.png"}
        alt={"logo"}
      />
      <Typography variant="h4" component="h1" sx={{ pl: 2, pb: 2 }}>
        Potwierdzenie protokołu prezentacji
      </Typography>
      <Typography variant="body1" component="span">
        Ja <strong> {clientName + " " + clientSurname + " "}</strong>
        {renderClientIdentificationData()} potwierdzam {renderCoviewerSection()} uczestnictwo w oględzinach nieruchomości znajdującej się pod adresem
        <strong>
          {" ul. ".concat(addrStreet, " ", addrStreetNumber, "/", addrLocaleNumber)}
          {" ".concat(addrPostcode, " ", addrCity)}
        </strong>{" "}
        dnia <strong>{dayjs(visitDate).format("YYYY-MM-DD HH:mm")}</strong>.
      </Typography>

      {/* {"TODO: Agent Tomek"} */}

      <Stack sx={{ fontSize: "13px" }}>{"* Dokument zostanie przekazany drogą e-mail."}</Stack>
      <Stack direction="column" spacing={2}>
        <TextField
          size="small"
          label={"Wprowadź e-mail"}
          id={"clientEmail"}
          value={clientEmail}
          onChange={(e) => {
            setClientEmail(e.target.value);
          }}
          disabled={false}
          sx={{ pb: 2, width: "100%" }}
          required={false}
          type="email"
          error={clientEmail.length === 0 || !validateEmail(clientEmail) ? true : false}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          label={"Potwierdź e-mail"}
          id={"clientEmailRepeat"}
          value={clientEmailRepeat}
          onChange={(e) => {
            setClientEmailRepeat(e.target.value);
          }}
          disabled={false}
          sx={{ pb: 2, width: "100%" }}
          required={false}
          type="email"
          error={clientEmailRepeat.length === 0 || !validateEmail(clientEmailRepeat) ? true : false}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <Typography variant="body1" sx={{ fontSize: "11px" }}>
              {consentPrivacyText}
            </Typography>
          }
          sx={{
            mr: 0,
          }}
          checked={consentPrivacy}
          onChange={(e) => setConsentPrivacy(e.target.checked)}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <Typography variant="body1" sx={{ fontSize: "11px" }}>
              {consentMediationText}
            </Typography>
          }
          sx={{
            mr: 0,
          }}
          checked={consentMediation}
          onChange={(e) => setConsentMediation(e.target.checked)}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <Typography variant="body1" sx={{ fontSize: "11px" }}>
              {consentTradeText}
            </Typography>
          }
          sx={{ mr: 0 }}
          checked={consentTrade}
          onChange={(e) => setConsentTrade(e.target.checked)}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <Typography variant="body1" sx={{ fontSize: "11px" }}>
              {consentMarketingText}
            </Typography>
          }
          sx={{ mr: 0 }}
          checked={consentMarketing}
          onChange={(e) => setConsentMarketing(e.target.checked)}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
        <Button variant="outlined" size="medium" startIcon={<RefreshOutlinedIcon />} onClick={getFormData}>
          {"Odśwież dane"}
        </Button>
        <Button variant="contained" size="medium" startIcon={<ThumbUpAltOutlinedIcon />} onClick={confirmData}>
          {"Potwierdzam"}
        </Button>
      </Stack>
      <br />
    </Stack>
  );
};

export default ClientForm;
