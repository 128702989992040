import * as Dict from "../../config/Dictionary";

export const setToken = (value) => {
  sessionStorage.setItem(Dict.SessionStorageAuth.token, JSON.stringify(value));
};

export const setLogin = (value) => {
  sessionStorage.setItem(Dict.SessionStorageAuth.login, JSON.stringify(value));
};

export const setRefreshToken = (value) => {
  sessionStorage.setItem(Dict.SessionStorageAuth.refreshToken, JSON.stringify(value));
};

export const setUserId = (value) => {
  sessionStorage.setItem(Dict.SessionStorageAuth.userId, JSON.stringify(value));
};

export const getToken = () => {
  return JSON.parse(sessionStorage.getItem(Dict.SessionStorageAuth.token));
};

export const getLogin = () => {
  return JSON.parse(sessionStorage.getItem(Dict.SessionStorageAuth.login));
};

export const getRefreshToken = () => {
  return JSON.parse(sessionStorage.getItem(Dict.SessionStorageAuth.refreshToken));
};
export const getUserId = () => {
  return JSON.parse(sessionStorage.getItem(Dict.SessionStorageAuth.userId));
};
