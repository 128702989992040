import axios from "axios";
import * as api from "../../config/ApiConfig";
import * as session from "../../components/SessionStorage/SessionStorage";

// export const GetById = async (objectId) => {
//   try {
//     const response = await axios.get(api.url_ActiveLink_GetById, {
//       headers: {
//         login: session.getLogin(),
//         token: session.getToken(),
//       },
//       params: { id: objectId },
//     });

//     let activeLink = {};

//     activeLink.id = response.data.id;
//     activeLink.guid = response.data.guid;
//     activeLink.pin = response.data.pin;
//     activeLink.objCategory = response.data.objCategory;
//     activeLink.objId = response.data.objId;
//     activeLink.status = response.data.status;

//     return activeLink;
//   } catch (error) {
//     console.error("API method error: ", api.url_ActiveLink_GetById, error);
//   }
// };

export const Create = async (activeLink) => {
  try {
    const response = await axios({
      method: "post",
      url: api.url_ActiveLink_Create,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        createdBy: session.getUserId(),
        pin: activeLink.pin,
        objCategory: activeLink.objCategory,
        objId: activeLink.objId,
        objStage: activeLink.objStage,
        isActive: activeLink.isActive,
      },
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_ActiveLink_Create, error);
  }
};

export const CheckCredentials = async (activeLink) => {
  try {
    const response = await axios({
      method: "get",
      url: api.url_ActiveLink_CheckCredentials,
      params: {
        guid: activeLink.guid,
        pin: activeLink.pin,
      },
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_ActiveLink_CheckCredentials, error);
  }
};
