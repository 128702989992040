import React, { useState, useEffect } from "react";
import * as TemplateDocumentBinaryApi from "./TemplateDocumentBinaryApi";
import { Stack } from "@mui/material";
import File from "./File";

const FileList = (props) => {
  const docId = props.docId;

  const [list, setList] = useState([]);

  const loadList = async () => {
    if (!docId) {
      return 0;
    }

    const files = await TemplateDocumentBinaryApi.GetIdsByDocId(docId);

    let myList = [];
    files.forEach((e) => {
      myList.push({ id: e.id });
    });

    setList(myList);
  };

  useEffect(
    () => {
      loadList();
    },
    // eslint-disable-next-line
    [props.reload]
  );

  return (
    <Stack sx={{ pt: 2 }}>
      {list.map((e) => (
        <File key={"file".concat(e.id)} id={e.id} />
      ))}
    </Stack>
  );
};

export default FileList;
