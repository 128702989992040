import { useState, useEffect, useRef, createRef } from "react";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "../../config/NavigationRoute/RoutePath";
import * as session from "../../components/SessionStorage/SessionStorage";
import {
  Stack,
  FormGroup,
  TextField,
  //Autocomplete,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import {
  SaveOutlined as SaveOutlinedIcon,
  CancelOutlined as CancelOutlinedIcon,
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  SendToMobileOutlined as SendToMobileOutlinedIcon,
  BorderColorOutlined,
  SearchOutlined as SearchOutlinedIcon,
  PersonAddAlt1Outlined as PersonAddAlt1OutlinedIcon,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import * as dict from "../../config/Dictionary";
import * as UserApi from "../User/UserApi";
import * as BranchApi from "../Branch/BranchApi";
import * as SMSApi from "../../components/SMS/SMSApi";
import * as ActiveLinkAPI from "../ActiveLink/ActiveLinkApi";
import * as ContractLeaseApi from "./ContractLeaseApi";
import * as ContractLeaseCoownerApi from "./ContractLeaseCoowner/ContractLeaseCoownerApi";
import { PDFViewer } from "@react-pdf/renderer";
import ContractLeasePDF from "../../pages/ContractLease/DocumentPDF/DocumentPDF";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { phoneValidator, emailValidator, phoneAsPin, setFileName, convertBlobToBase64, returnFormattedValue } from "../../utils/validator";
import DrawingCanvas from "../../components/DrawingCanvas/DrawingCanvas";
import ContractLeaseMonitor from "./ContractLeaseMonitor";
import Coowner from "./ContractLeaseCoowner/ContractLeaseCoowner";
import Person from "../../components/LegalEntity/Person";
import BusinessIndividual from "../../components/LegalEntity/BusinessIndividual";
import Company from "../../components/LegalEntity/Company";
import ContractLeaseBinary from "./ContractLeaseBinary/ContractLeaseBinary";
import * as ContractLeaseBinaryApi from "../ContractLease/ContractLeaseBinary/ContractLeaseBinaryApi";

const ContractLeaseForm = (props) => {
  const debug = false;
  const navigate = useNavigate();
  const isSm = useMediaQuery("(min-width:600px)");

  const isAfterFirstRender = useRef(false);
  const autoSaveLastDate = useRef(0);

  const objectId = props.id;
  const mode = props.mode;
  const isModePreview = props.mode === dict.FormMode.preview ? true : false;

  // Part I
  // Form fields
  const [sessionKey, setSessionKey] = useState("");
  const [status, setStatus] = useState(0);
  const [agentSentAt, setAgentSentAt] = useState(dayjs(dict.DefaultDate.toUTCString()));
  const [clientAcceptedAt, setClientAcceptedAt] = useState(dayjs(dict.DefaultDate.toUTCString()));

  const [propertyAddrStreet, setPropertyAddrStreet] = useState("");
  const [propertyAddrStreetNumber, setPropertyAddrStreetNumber] = useState("");
  const [propertyAddrLocaleNumber, setPropertyAddrLocaleNumber] = useState("");
  const [propertyAddrPostcode, setPropertyAddrPostcode] = useState("");
  const [propertyAddrCity, setPropertyAddrCity] = useState("");
  const [propertyAddrCountry, setPropertyAddrCountry] = useState("Polska");

  const [contractVariant, setContractVariant] = useState(dict.IntermediationContractVariantCombo[0].code);
  const [contractDate, setContractDate] = useState(dayjs(new Date().toUTCString()));
  const [contractCity, setContractCity] = useState("");
  const [contractDurationMonth, setContractDurationMonth] = useState(12);

  const [clientType, setClientType] = useState(dict.ClientType.person);
  const [companyName, setCompanyName] = useState("");
  const [companyNIP, setCompanyNIP] = useState("");
  const [companyKRS, setCompanyKRS] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [clientAddrStreet, setClientAddrStreet] = useState("");
  const [clientAddrStreetNumber, setClientAddrStreetNumber] = useState("");
  const [clientAddrLocaleNumber, setClientAddrLocaleNumber] = useState("");
  const [clientAddrPostcode, setClientAddrPostcode] = useState("");
  const [clientAddrCity, setClientAddrCity] = useState("");
  const [clientAddrCountry, setClientAddrCountry] = useState("Polska");
  const [clientPesel, setClientPesel] = useState("");
  const [clientIdType, setClientIdType] = useState("");
  const [clientIdNumber, setClientIdNumber] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [principalName, setPrincipalName] = useState("");
  const [principalSurname, setPrincipalSurname] = useState("");
  const [principalAddrStreet, setPrincipalAddrStreet] = useState("");
  const [principalAddrStreetNumber, setPrincipalAddrStreetNumber] = useState("");
  const [principalAddrLocaleNumber, setPrincipalAddrLocaleNumber] = useState("");
  const [principalAddrPostcode, setPrincipalAddrPostcode] = useState("");
  const [principalAddrCity, setPrincipalAddrCity] = useState("");
  const [principalAddrCountry, setPrincipalAddrCountry] = useState("Polska");
  const [principalPesel, setPrincipalPesel] = useState("");
  const [principalIdType, setPrincipalIdType] = useState("");
  const [principalIdNumber, setPrincipalIdNumber] = useState("");
  const [principalPhone, setPrincipalPhone] = useState("");
  const [principalEmail, setPrincipalEmail] = useState("");
  const [agentProvisionType, setAgentProvisionType] = useState(dict.AgentProvisionType.percent);
  const [agentProvisionPercentNet, setAgentProvisionPercentNet] = useState(100);
  const [agentProvisionAmountNet, setAgentProvisionAmountNet] = useState(dict.DefaultNumber);
  const [withPresentation, setWithPresentation] = useState(true);

  // Combo fields
  const [clientIdTypeCombo, setClientIdTypeCombo] = useState(dict.IdentityDocumentType[0]);
  // eslint-disable-next-line
  const [contractVariantCombo, setContractVariantCombo] = useState(dict.IntermediationContractVariantCombo[0]);
  const [principalIdTypeCombo, setPrincipalIdTypeCombo] = useState(dict.IdentityDocumentType[0]);

  // Error fields
  const [hasErrorContractDate, setHasErrorContractDate] = useState(false);
  const [hasErrorContractCity, setHasErrorContractCity] = useState(false);
  // eslint-disable-next-line
  const [hasErrorContractVariant, setHasErrorContractVariant] = useState(false);
  // eslint-disable-next-line
  const [hasErrorContractDurationMonth, setHasErrorContractDurationMonth] = useState(false);

  const [hasErrorPropertyAddrStreet, setHasErrorPropertyAddrStreet] = useState(false);
  const [hasErrorPropertyAddrStreetNumber, setHasErrorPropertyAddrStreetNumber] = useState(false);
  const [hasErrorPropertyAddrCity, setHasErrorPropertyAddrCity] = useState(false);

  const [hasErrorSectionClient, setHasErrorSectionClient] = useState(false);
  const [hasErrorCompanyName, setHasErrorCompanyName] = useState(false);
  const [hasErrorCompanyNIP, setHasErrorCompanyNIP] = useState(false);
  const [hasErrorCompanyKRS, setHasErrorCompanyKRS] = useState(false);
  const [hasErrorClientName, setHasErrorClientName] = useState(false);
  const [hasErrorClientSurname, setHasErrorClientSurname] = useState(false);
  const [hasErrorClientPhone, setHasErrorClientPhone] = useState(false);
  const [hasErrorClientEmail, setHasErrorClientEmail] = useState(false);
  const [hasErrorClientAddrCountry, setHasErrorClientAddrCountry] = useState(false);
  const [hasErrorClientPesel, setHasErrorClientPesel] = useState(false);
  const [hasErrorClientIdType, setHasErrorClientIdType] = useState(false);
  const [hasErrorClientIdNumber, setHasErrorClientIdNumber] = useState(false);
  const [hasErrorClientAddrStreet, setHasErrorClientAddrStreet] = useState(false);
  const [hasErrorClientAddrStreetNumber, setHasErrorClientAddrStreetNumber] = useState(false);
  const [hasErrorClientAddrCity, setHasErrorClientAddrCity] = useState(false);

  const [hasErrorSectionRepresentative, setHasErrorSectionRepresentative] = useState(false);
  const [hasErrorRepresentativeName, setHasErrorRepresentativeName] = useState(false);
  const [hasErrorRepresentativeSurname, setHasErrorRepresentativeSurname] = useState(false);
  const [hasErrorRepresentativeAddrStreet, setHasErrorRepresentativeAddrStreet] = useState(false);
  const [hasErrorRepresentativeAddrStreetNumber, setHasErrorRepresentativeAddrStreetNumber] = useState(false);
  const [hasErrorRepresentativeAddrCity, setHasErrorRepresentativeAddrCity] = useState(false);
  const [hasErrorRepresentativeAddrCountry, setHasErrorRepresentativeAddrCountry] = useState(false);
  const [hasErrorRepresentativePesel, setHasErrorRepresentativePesel] = useState(false);
  const [hasErrorRepresentativeIdType, setHasErrorRepresentativeIdType] = useState(false);
  const [hasErrorRepresentativeIdNumber, setHasErrorRepresentativeIdNumber] = useState(false);
  const [hasErrorRepresentativePhone, setHasErrorRepresentativePhone] = useState(false);
  const [hasErrorRepresentativeEmail, setHasErrorRepresentativeEmail] = useState(false);
  const [hasErrorAgentProvisionPercentNet, setHasErrorAgentProvisionPercentNet] = useState(false);
  const [hasErrorAgentProvisionAmountNet, setHasErrorAgentProvisionAmountNet] = useState(false);
  const [hasErrorSectionPresentation, setHasErrorSectionPresentation] = useState(false);

  // View state
  const [hideSendToClientButton, setHideSendToClientButton] = useState(false);

  // Coowners data
  const [coowners, setCoowners] = useState([]);

  // Signature
  const [isSignatureAgentVisible, setIsSignatureAgentVisible] = useState(false);
  const signatureAgent = useRef(-1);
  const [checkCounterAgent, setCheckCounterAgent] = useState(0);

  // Part II
  const setFieldsDefault = async () => {
    let agent = await UserApi.GetById(session.getUserId());
    let branch = await BranchApi.GetById(agent.branchId);

    if (branch && branch.addrCity) {
      setContractCity(branch.addrCity);
    }
  };

  const refreshCoowners = async () => {
    const object = await ContractLeaseCoownerApi.GetByDocId(objectId);

    let data = [];
    object.forEach((e) => {
      data.push({ id: e.id, myRef: createRef() });
    });
    setCoowners(data);
  };

  const setFields = async (objId) => {
    try {
      const object = await ContractLeaseApi.GetById(objId);

      setSessionKey(object.sessionKey);
      setStatus(object.status);

      setContractVariant(object.contractVariant);
      if (object.contractVariant) {
        setContractVariantCombo(dict.IntermediationContractVariantCombo.filter((f) => f.code === object.contractVariant)[0]);
      }
      setContractDate(dayjs(object.contractDate));
      setContractCity(object.contractCity);
      setContractDurationMonth(object.contractDurationMonth);

      setPropertyAddrStreet(object.propertyAddrStreet);
      setPropertyAddrStreetNumber(object.propertyAddrStreetNumber);
      setPropertyAddrLocaleNumber(object.propertyAddrLocaleNumber);
      setPropertyAddrPostcode(object.propertyAddrPostcode);
      setPropertyAddrCity(object.propertyAddrCity);
      setPropertyAddrCountry(object.propertyAddrCountry);

      setClientType(object.clientType);
      setCompanyName(object.companyName);
      setCompanyNIP(object.companyNIP);
      setCompanyKRS(object.companyKRS);
      setClientName(object.clientName);
      setClientSurname(object.clientSurname);
      setClientAddrStreet(object.clientAddrStreet);
      setClientAddrStreetNumber(object.clientAddrStreetNumber);
      setClientAddrLocaleNumber(object.clientAddrLocaleNumber);
      setClientAddrPostcode(object.clientAddrPostcode);
      setClientAddrCity(object.clientAddrCity);
      setClientAddrCountry(object.clientAddrCountry);
      setClientPesel(object.clientPesel);
      setClientIdType(object.clientIdType);
      if (object.clientIdType) {
        setClientIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === object.clientIdType)[0]);
      }
      setClientIdNumber(object.clientIdNumber);
      setClientPhone(object.clientPhone);
      setClientEmail(object.clientEmail);

      setPrincipalName(object.principalName);
      setPrincipalSurname(object.principalSurname);
      setPrincipalAddrStreet(object.principalAddrStreet);
      setPrincipalAddrStreetNumber(object.principalAddrStreetNumber);
      setPrincipalAddrLocaleNumber(object.principalAddrLocaleNumber);
      setPrincipalAddrPostcode(object.principalAddrPostcode);
      setPrincipalAddrCity(object.principalAddrCity);
      setPrincipalAddrCountry(object.principalAddrCountry);
      setPrincipalPesel(object.principalPesel);
      setPrincipalIdType(object.principalIdType);
      setPrincipalIdNumber(object.principalIdNumber);
      setPrincipalPhone(object.principalPhone);
      setPrincipalEmail(object.principalEmail);
      setAgentProvisionType(object.agentProvisionType ? object.agentProvisionType : dict.AgentProvisionType.percent);
      setAgentProvisionPercentNet(object.agentProvisionPercentNet);
      setAgentProvisionAmountNet(object.agentProvisionAmountNet);
      setWithPresentation(object.withPresentation);

      const signatureAgentBlob = await ContractLeaseApi.GetAgentSignatureById(objId);

      if (!(signatureAgentBlob || signatureAgentBlob.size)) {
        //let blob = new Blob([signatureAgentBlob], { type: "image/png" });
        signatureAgent.current = await convertBlobToBase64(signatureAgentBlob);
        setIsSignatureAgentVisible(true);
      }

      // let response = await IntermediationContractApi.GetAgentSignatureById(
      //   objId
      // );
      // let blob = new Blob([response], { type: "image/png" });
      // let link = document.createElement("a");

      // link.download = "testttt.jpg";
      // link.href = URL.createObjectURL(blob);
      // link.click();

      // URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error: ", error);
    }

    try {
      await refreshCoowners();
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const setFieldsModePreview = async (objId) => {
    try {
      const object = await ContractLeaseApi.GetById(objId);

      // dedykowana logika
      setClientEmail(object.clientEmail);
      if (object.clientAcceptedAt) {
        setClientAcceptedAt(dayjs(object.clientAcceptedAt));
      }
      if (object.agentSentAt) {
        setAgentSentAt(dayjs(object.agentSentAt));
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (mode === dict.FormMode.edit) {
      setFields(objectId);
      isAfterFirstRender.current = true;
    } else if (mode === dict.FormMode.preview) {
      setFields(objectId);
      setFieldsModePreview(objectId);
    } else if (mode === dict.FormMode.add) {
      setFieldsDefault();
      isAfterFirstRender.current = true;
    }

    // eslint-disable-next-line
  }, []);

  // Part III
  useEffect(() => {
    let runAutosave = false;

    if (mode === dict.FormMode.edit) {
      runAutosave = true;
    } else if (mode === dict.FormMode.preview) {
    } else if (mode === dict.FormMode.add) {
      runAutosave = true;
    }

    if (runAutosave && isAfterFirstRender.current) {
      autosave();
    }

    if (mode !== dict.FormMode.preview) {
      setHasErrorContractVariant(false);
      setHasErrorContractDurationMonth(false);
      setHasErrorContractDate(false);
      setHasErrorContractCity(false);
      setHasErrorClientName(false);
      setHasErrorClientSurname(false);
      //setHasErrorClientPhone(false);
      setHasErrorClientAddrCountry(false);
      setHasErrorClientPesel(false);
      setHasErrorClientIdType(false);
      setHasErrorClientIdNumber(false);
      setHasErrorClientAddrStreet(false);
      setHasErrorClientAddrStreetNumber(false);
      setHasErrorClientAddrCity(false);
      setHasErrorPropertyAddrStreet(false);
      setHasErrorPropertyAddrStreetNumber(false);
      setHasErrorPropertyAddrCity(false);
      setHasErrorSectionClient(false);
      setHasErrorAgentProvisionPercentNet(false);
      setHasErrorAgentProvisionAmountNet(false);

      setHasErrorCompanyName(false);
      setHasErrorCompanyNIP(false);
      setHasErrorCompanyKRS(false);
      setHasErrorRepresentativeName(false);
      setHasErrorRepresentativeSurname(false);
      setHasErrorRepresentativeAddrStreet(false);
      setHasErrorRepresentativeAddrStreetNumber(false);
      setHasErrorRepresentativeAddrCity(false);
      setHasErrorRepresentativeAddrCountry(false);
      setHasErrorRepresentativePesel(false);
      setHasErrorRepresentativeIdType(false);
      setHasErrorRepresentativeIdNumber(false);
      //setHasErrorRepresentativePhone(false);
      //setHasErrorRepresentativeEmail(false);
      setHasErrorSectionRepresentative(false);
      setHasErrorSectionPresentation(false);
    }
    // eslint-disable-next-line
  }, [
    contractVariant,
    contractDurationMonth,
    contractDate,
    contractCity,
    clientName,
    clientSurname,
    clientAddrStreet,
    clientAddrStreetNumber,
    clientAddrLocaleNumber,
    clientAddrPostcode,
    clientAddrCity,
    clientAddrCountry,
    clientPhone,
    clientPesel,
    clientIdType,
    clientIdNumber,
    clientEmail,

    propertyAddrStreet,
    propertyAddrStreetNumber,
    propertyAddrLocaleNumber,
    propertyAddrPostcode,
    propertyAddrCity,
    propertyAddrCountry,

    //status,
    clientType,
    companyName,
    companyNIP,
    companyKRS,
    principalName,
    principalSurname,
    principalAddrStreet,
    principalAddrStreetNumber,
    principalAddrLocaleNumber,
    principalAddrPostcode,
    principalAddrCity,
    principalAddrCountry,
    principalPesel,
    principalIdType,
    principalIdNumber,
    principalPhone,
    principalEmail,
    agentProvisionType,
    agentProvisionPercentNet,
    agentProvisionPercentNet,
    withPresentation,
  ]);

  const autosave = async () => {
    if (autoSaveLastDate.current === 0) {
      autoSaveLastDate.current = Date.now();
    }

    let currentTimestamp = Date.now();
    let lastSaveTimestamp = autoSaveLastDate.current;

    let interval = currentTimestamp - lastSaveTimestamp;
    //console.log("bbbbbbbbbbb", currentTimestamp, lastSaveTimestamp, interval);
    if (interval <= 30000) {
      //console.log("Mniej niż 15 sekund", interval);
      //console.log("kuku");
      return 0;
    }

    if (objectId) {
      // tryb edycji
      // const intermediationContract =
      //   await IntermediationContract.GetMonitorById(objectId);

      if (status === dict.DocStatus.accepted) {
        return 0;
      } else {
        //console.log("autosave RUN - editMode ", new Date().toUTCString());
        autoSaveLastDate.current = Date.now();
        handleSaveClick();
      }
    } else {
      // tryb dodawania
      //console.log("autosave RUN - addMode", new Date().toUTCString());
      autoSaveLastDate.current = Date.now();
      handleSaveClick();
    }
  };

  // Part IV
  const validateFormButtonSave = () => {
    if (clientType === dict.ClientType.person && !clientName && !clientSurname && !clientPhone && !clientIdNumber) {
      setHasErrorSectionClient(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (
      clientType === dict.ClientType.personWithRepresentative &&
      !principalName &&
      !principalSurname &&
      !principalPhone &&
      !principalIdNumber
    ) {
      setHasErrorSectionRepresentative(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (clientType === dict.ClientType.businessIndividual && !companyName && !companyNIP && !clientName && !clientSurname && !clientPhone) {
      setHasErrorSectionClient(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (
      clientType === dict.ClientType.businessIndividualWithRepresentative &&
      !companyName &&
      !companyNIP &&
      !principalName &&
      !principalSurname &&
      !principalPhone
    ) {
      setHasErrorSectionRepresentative(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (
      clientType === dict.ClientType.company &&
      !companyName &&
      !companyNIP &&
      !companyKRS &&
      !clientName &&
      !clientSurname &&
      !clientPhone
    ) {
      setHasErrorSectionClient(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (
      clientType === dict.ClientType.companyWithRepresentative &&
      !companyName &&
      !companyNIP &&
      !companyKRS &&
      !principalName &&
      !principalSurname &&
      !principalPhone
    ) {
      setHasErrorSectionRepresentative(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (
      clientType === dict.ClientType.person ||
      clientType === dict.ClientType.businessIndividual ||
      clientType === dict.ClientType.businessIndividual
    ) {
      if (!phoneValidator(clientPhone)) {
        props.onNotificationShow("Numer telefonu klienta posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
        return -1;
      }
    }

    if (
      clientType === dict.ClientType.personWithRepresentative ||
      clientType === dict.ClientType.businessIndividualWithRepresentative ||
      clientType === dict.ClientType.businessIndividualWithRepresentative
    ) {
      if (!phoneValidator(principalPhone)) {
        props.onNotificationShow("Numer telefonu klienta posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
        return -1;
      }

      if (!phoneValidator(clientPhone)) {
        props.onNotificationShow("Numer telefonu pełnomocnika posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
        return -1;
      }
    }
  };

  const validateClientPrincipal = (
    clientName,
    clientSurname,
    clientPhone,
    clientAddrCountry,
    clientPesel,
    clientIdType,
    clientIdNumber,
    clientAddrStreet,
    clientAddrStreetNumber,
    clientAddrCity
  ) => {
    let stakeholder = "klienta";

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrCountry) {
      setHasErrorClientAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
      setHasErrorClientPesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(clientIdType && clientIdNumber)) {
      if (!clientIdType) {
        setHasErrorClientIdType(true);
      }

      if (!clientIdNumber) {
        setHasErrorClientIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateRepresentative = () => {
    let stakeholder = "pełnomocnika";

    if (!clientName) {
      setHasErrorRepresentativeName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorRepresentativeSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorRepresentativePhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrCountry) {
      setHasErrorRepresentativeAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
      setHasErrorRepresentativePesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(clientIdType && clientIdNumber)) {
      if (!clientIdType) {
        setHasErrorRepresentativeIdType(true);
      }
      if (!clientIdNumber) {
        setHasErrorRepresentativeIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorRepresentativeAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorRepresentativeAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorRepresentativeAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateClientBusinessIndividual = (
    companyName,
    companyNIP,
    clientName,
    clientSurname,
    clientPhone,
    clientAddrStreet,
    clientAddrStreetNumber,
    clientAddrCity
  ) => {
    let stakeholder = "klienta";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP) {
      setHasErrorCompanyNIP(true);
      props.onNotificationShow("NIP firmy jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateCompanyFormFieldsClientErrorClient = () => {
    let stakeholder = "reprezentanta";
    let company = "firmy";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP && !companyKRS) {
      setHasErrorCompanyNIP(true);
      setHasErrorCompanyKRS(true);
      props.onNotificationShow("NIP firmy lub KRS jest wymagany. Uzupełnij minimum jedno pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }
  };

  const validateCompanyFormFieldsPrincipalErrorClient = () => {
    let company = "firmy";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP && !companyKRS) {
      setHasErrorCompanyNIP(true);
      setHasErrorCompanyKRS(true);
      props.onNotificationShow("NIP firmy lub KRS jest wymagany. Uzupełnij minimum jedno pole.", dict.NotificationType.error);
      return -1;
    }

    if (!principalAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!principalAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!principalAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateForm = async () => {
    if (!contractDate) {
      setHasErrorContractDate(true);
      props.onNotificationShow("Data zawarcia umowy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!contractCity) {
      setHasErrorContractCity(true);
      props.onNotificationShow("Miejscowość zawarcia umowy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    // if (!contractDurationMonth) {
    //   setHasErrorContractDurationMonth(true);
    //   props.onNotificationShow("Czas trwania umowy jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
    //   return -1;
    // }

    if (clientType === dict.ClientType.person) {
      // prettier-ignore
      let res = validateClientPrincipal(
        clientName,clientSurname,clientPhone,clientAddrCountry,clientPesel,clientIdType,clientIdNumber,clientAddrStreet,clientAddrStreetNumber,clientAddrCity
      );
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.personWithRepresentative) {
      // prettier-ignore
      let res = validateClientPrincipal(
        principalName,principalSurname,principalPhone,principalAddrCountry,principalPesel,principalIdType,principalIdNumber,principalAddrStreet,principalAddrStreetNumber,principalAddrCity
      );
      if (res === -1) return -1;
      res = validateRepresentative();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.businessIndividual) {
      // prettier-ignore
      let res = validateClientBusinessIndividual(
        companyName,companyNIP,clientName,clientSurname,clientPhone,clientAddrStreet,clientAddrStreetNumber,clientAddrCity
      );
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.businessIndividualWithRepresentative) {
      // prettier-ignore
      let res = validateClientBusinessIndividual(
        companyName,companyNIP,principalName,principalSurname,principalPhone,principalAddrStreet,principalAddrStreetNumber,principalAddrCity
      );
      if (res === -1) return -1;
      res = validateRepresentative();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.company) {
      let res = validateCompanyFormFieldsClientErrorClient();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.companyWithRepresentative) {
      let res = validateCompanyFormFieldsPrincipalErrorClient();
      if (res === -1) return -1;
      res = validateRepresentative();
      if (res === -1) return -1;
    }

    for (let i = 0; i < coowners.length; i++) {
      if (coowners[i].myRef) {
        let result = 0;
        result = await coowners[i].myRef.current.validateCoowner();
        if (result === -1) {
          return -1;
        }
      }
    }

    if (
      agentProvisionType === dict.AgentProvisionType.percent &&
      (!agentProvisionPercentNet ||
        agentProvisionPercentNet === "" ||
        // eslint-disable-next-line
        agentProvisionPercentNet == dict.DefaultNumber)
    ) {
      setHasErrorAgentProvisionPercentNet(true);
      props.onNotificationShow("Wynagrodzenie procentowe Agenta jest wymagane. Uzupełnij dane nieruchomości.", dict.NotificationType.error);
      return -1;
    }

    if (
      agentProvisionType === dict.AgentProvisionType.amount &&
      (!agentProvisionAmountNet ||
        agentProvisionAmountNet === "" ||
        // eslint-disable-next-line
        agentProvisionAmountNet == dict.DefaultNumber)
    ) {
      setHasErrorAgentProvisionAmountNet(true);
      props.onNotificationShow("Wynagrodzenie kwotowe Agenta jest wymagane. Uzupełnij dane nieruchomości.", dict.NotificationType.error);
      return -1;
    }

    if (withPresentation) {
      if (!propertyAddrStreet) {
        setHasErrorPropertyAddrStreet(true);
        props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
        return -1;
      }
      if (!propertyAddrStreetNumber) {
        setHasErrorPropertyAddrStreetNumber(true);
        props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
        return -1;
      }
      if (!propertyAddrCity) {
        setHasErrorPropertyAddrCity(true);
        props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
        return -1;
      }
    }
  };

  const validateFormSend = () => {
    if (signatureAgent.current === -1) {
      //setHasErrorContractDate(true);
      props.onNotificationShow("Podpis Agenta jest wymagany.", dict.NotificationType.error);
      return -1;
    }

    if (checkCounterAgent < 800) {
      //setHasErrorContractDate(true);
      props.onNotificationShow("Podpis Agenta jest uzupełniony niepoprawnie i wymaga poprawy.", dict.NotificationType.error);
      return -1;
    }
  };

  // Part V
  const getFields = (formMode) => {
    let data = {
      contractVariant: contractVariant,
      contractDurationMonth: parseInt(contractDurationMonth),
      contractDate: contractDate,
      contractCity: contractCity,
      clientName: clientName,
      clientSurname: clientSurname,
      clientAddrStreet: clientAddrStreet,
      clientAddrStreetNumber: clientAddrStreetNumber,
      clientAddrLocaleNumber: clientAddrLocaleNumber,
      clientAddrPostcode: clientAddrPostcode,
      clientAddrCity: clientAddrCity,
      clientAddrCountry: clientAddrCountry,
      clientPesel: clientPesel,
      clientIdType: clientIdType,
      clientIdNumber: clientIdNumber,
      clientPhone: clientPhone,
      clientEmail: clientEmail,

      propertyAddrStreet: propertyAddrStreet,
      propertyAddrStreetNumber: propertyAddrStreetNumber,
      propertyAddrLocaleNumber: propertyAddrLocaleNumber,
      propertyAddrPostcode: propertyAddrPostcode,
      propertyAddrCity: propertyAddrCity,
      propertyAddrCountry: propertyAddrCountry,

      status: status,
      sessionKey: sessionKey,
      clientType: clientType,
      companyName: companyName,
      companyNIP: companyNIP,
      companyKRS: companyKRS,
      principalName: principalName,
      principalSurname: principalSurname,
      principalAddrStreet: principalAddrStreet,
      principalAddrStreetNumber: principalAddrStreetNumber,
      principalAddrLocaleNumber: principalAddrLocaleNumber,
      principalAddrPostcode: principalAddrPostcode,
      principalAddrCity: principalAddrCity,
      principalAddrCountry: principalAddrCountry,
      principalPesel: principalPesel,
      principalIdType: principalIdType,
      principalIdNumber: principalIdNumber,
      principalPhone: principalPhone,
      principalEmail: principalEmail,
      agentProvisionType: agentProvisionType,
      agentProvisionPercentNet: agentProvisionPercentNet,
      agentProvisionAmountNet: agentProvisionAmountNet,
      withPresentation: withPresentation,
    };

    if (formMode === dict.FormMode.add) {
      data = {
        ...data,
        status: dict.DocStatus.new,
      };
    } else if (formMode === dict.FormMode.edit) {
      data = {
        ...data,
        agentSignature: signatureAgent.current,
      };
    }

    return data;
  };

  const saveModeAdd = async () => {
    let objId = await ContractLeaseApi.Create(getFields(dict.FormMode.add));
    return objId;
  };

  const saveModeEdit = async () => {
    await ContractLeaseApi.Update(objectId, getFields(dict.FormMode.edit));

    for (let i = 0; i < coowners.length; i++) {
      if (coowners[i].myRef) {
        let result = 0;
        result = await coowners[i].myRef.current.saveCoowner();
        if (result === -1) {
          return -1;
        }
      }
    }

    return objectId;
  };

  // Part VI
  const handleSaveClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormButtonSave();
      if (result === -1) {
        return -1;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        let p = await ContractLeaseApi.GetStatusById(objectId);
        if (p.status === dict.DocStatus.accepted) {
          throw Error("Nie można aktualizować - dane zostały zatwierdzone przez klienta.");
        }

        objId = await saveModeEdit();
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd: ".concat(error.message), dict.NotificationType.error);
    }
  };

  const handleCancelClick = () => {
    if (props.onAfterCancel) {
      props.onAfterCancel(mode);
    }
  };

  const handleBackClick = () => {
    if (props.onAfterBack) {
      props.onAfterBack(mode);
    }
  };

  const handleSendClick = async () => {
    let objId = null;
    try {
      setIsSignatureAgentVisible(true);
      setHideSendToClientButton(true);
      setStatus(dict.DocStatus.sended);

      let result = 0;
      result = await validateForm();
      if (result === -1) {
        setHideSendToClientButton(false);
        setIsSignatureAgentVisible(false);
        setStatus(dict.DocStatus.new);
        return;
      }
      result = validateFormSend();
      if (result === -1) {
        setHideSendToClientButton(false);
        setStatus(dict.DocStatus.new);
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.preview) {
        objId = objectId;
      }

      await ContractLeaseApi.Update(objId, {
        agentId: session.getUserId(),
        agentSentAt: dayjs(new Date().toUTCString()),
        status: dict.DocStatus.sended,
      });

      let activeLink = await ActiveLinkAPI.Create({
        pin: phoneAsPin(clientPhone),
        objCategory: dict.ActiveLinkCategory.contractLease, // todo
        objId: objId,
        objStage: 0,
        isActive: true,
      });

      let link = "".concat(process.env.REACT_APP_SYSTEM_URL, dict.ActiveLinkCategoryShortcut.contractLease, "/", activeLink.guid);

      let agent = await UserApi.GetById(session.getUserId());
      //throw new Error("Test");
      let sms = {
        to: clientPhone,
        msg: "Potwierdź umowę pośrednictwa. Kliknij: ".concat(link, " ", agent.name, " ", agent.surname, ", tel. ", agent.phone),
      };

      //console.log(sms);
      let smsValidator = await SMSApi.Send(sms);

      if (smsValidator?.errorMsg) {
        throw new Error({
          message: "SMS - (" + smsValidator?.errorCode + ") " + smsValidator?.errorMsg,
        });
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }

      props.onNotificationShow("Dokument został wysłany na urządzenie klienta.", dict.NotificationType.success);
    } catch (error) {
      props.onNotificationShow("Błąd wysyłki: ".concat(error.message), dict.NotificationType.error);
      setHideSendToClientButton(false);
      setStatus(dict.DocStatus.new);
      return;
    }
  };

  const handleShareClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = await validateForm();
      if (result === -1) {
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        let doc = await ContractLeaseApi.GetById(objectId);

        if (doc.status === dict.DocStatus.accepted) {
          throw Error("Dokument został już podpisany");
        }
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.preview) {
        objId = objectId;
      }

      await ContractLeaseApi.Update(objId, {
        agentId: session.getUserId(),
      });

      navigate(RoutePath.ContractLeaseClientDecision, {
        state: {
          id: objId,
          mode: dict.DocFormViewMode.agent,
        },
      });

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd wysyłki: ".concat(error.message), dict.NotificationType.error);
      return;
    }
  };

  const handleInspectionClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormButtonSave();
      if (result === -1) {
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        let doc = await ContractLeaseApi.GetById(objectId);

        if (doc.status === dict.DocStatus.accepted) {
          throw Error("Dokument został już podpisany");
        }
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.preview) {
        objId = objectId;
      }

      await ContractLeaseApi.Update(objId, {
        agentId: session.getUserId(),
      });

      navigate(RoutePath.ContractLeaseClientDecision, {
        state: {
          id: objId,
          mode: dict.DocFormViewMode.agent,
          isInspection: true,
        },
      });

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd wysyłki: ".concat(error.message), dict.NotificationType.error);
      return;
    }
  };

  const handleDownloadClick = async () => {
    let link = document.createElement("a");

    link.download = setFileName(
      dict.DocTypes.intermediationContract,
      "pdf",
      dayjs(contractDate).format("YYYY-MM-DDTHH:mm"),
      propertyAddrCity,
      propertyAddrStreet,
      propertyAddrStreetNumber,
      propertyAddrLocaleNumber
    );

    let response = await ContractLeaseApi.GetDocumentPDFById(props.id);
    let blob = new Blob([response], { type: "application/pdf" });

    link.href = URL.createObjectURL(blob);
    link.click();

    URL.revokeObjectURL(link.href);
  };

  // Part VII
  const renderPreviewInfo = () => {
    if (mode !== dict.FormMode.preview) return;

    return (
      <Stack>
        <Stack sx={{ pt: 2, fontSize: 13 }}>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Status: "}</b> {dict.DocStatusGetName(status)}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Data wysłania: "}</b>{" "}
            {!agentSentAt || agentSentAt.valueOf() === dict.DefaultDate.valueOf() ? null : dayjs(agentSentAt).format("YYYY-MM-DD HH:mm")}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Data akceptacji: "}</b>{" "}
            {!clientAcceptedAt || clientAcceptedAt.valueOf() === dict.DefaultDate.valueOf()
              ? null
              : dayjs(clientAcceptedAt).format("YYYY-MM-DD HH:mm")}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Email klienta: "}</b> {clientEmail}
          </Typography>
        </Stack>
        <Stack width={"150px"}>
          {clientEmail && (
            <Button variant="text" onClick={handleDownloadClick}>
              Pobierz dokument
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<SaveOutlinedIcon />} onClick={handleSaveClick}>
            {(mode === dict.FormMode.add && !hideSendToClientButton) || (mode === dict.FormMode.edit && !hideSendToClientButton)
              ? "Zapisz"
              : "Aktualizuj"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<CancelOutlinedIcon />} onClick={handleCancelClick}>
            {"Wyjdź"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? true : false) && (
          <Button variant="contained" size="small" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackClick}>
            {"Wstecz"}
          </Button>
        )}
        {status !== dict.DocStatus.accepted && status === dict.DocStatus.new && !isModePreview && !hideSendToClientButton && (
          <Button variant="contained" size="small" startIcon={<SendToMobileOutlinedIcon />} onClick={handleSendClick}>
            {"Wyślij"}
          </Button>
        )}
        {status !== dict.DocStatus.accepted && (status === dict.DocStatus.new || status === dict.DocStatus.sended) && !isModePreview && (
          <Button variant="contained" size="small" startIcon={<BorderColorOutlined />} onClick={handleShareClick}>
            {"Podpisz"}
          </Button>
        )}
        {status !== dict.DocStatus.accepted && (status === dict.DocStatus.new || status === dict.DocStatus.sended) && !isModePreview && (
          <Button variant="contained" size="small" startIcon={<SearchOutlinedIcon />} onClick={handleInspectionClick}>
            {"Podgląd"}
          </Button>
        )}
        {/* <Stack>
    <PDFDownloadLink
      document={
        <Printing
          clientName={clientName}
          clientSurname={clientSurname}
          clientPesel={clientPesel}
          clientIdType={clientIdType}
          clientIdNumber={clientIdNumber}
          coviewerName={coviewerName}
          coviewerSurname={coviewerSurname}
          coviewerPesel={coviewerPesel}
          coviewerIdType={coviewerIdType}
          coviewerIdNumber={coviewerIdNumber}
          agentName={"Imię Agenta"}
          agentSurname={"Nazwisko Agenta"}
          consentPrivacy={consentPrivacy}
          consentMediation={consentMediation}
          consentTrade={consentTrade}
          consentMarketing={consentMarketing}
          presentationDate={dayjs(new Date())
            .format("YYYY-MM-DD HH:mm")
            .toString()}
          clientSignature={clientName + " " + clientSurname}
          visitDate={dayjs(visitDate).format("YYYY-MM-DD")}
          visitHour={dayjs(visitDate).format("HH:mm")}
          visitAddress={"".concat(
            addrStreet ? "ul. ".concat(addrStreet) : "",
            " ",
            addrStreetNumber,
            addrLocaleNumber ? "/".concat(addrLocaleNumber) : "",
            "\n",
            addrPostcode,
            " ",
            addrCity
          )}
        />
      }
      fileName="somename.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now!"
      }
    </PDFDownloadLink>
  </Stack> */}
      </Stack>
    );
  };

  const [coownersData, setCoownersData] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);

  const getCoownersData = async () => {
    if (!objectId) return;

    if (mode === dict.DocFormViewMode.agent) {
      const object = await ContractLeaseCoownerApi.GetByDocId(objectId, sessionKey);
      setCoownersData(object);
    } else {
      const object = await ContractLeaseCoownerApi.GetByDocIdSessionKey(objectId, sessionKey);
      setCoownersData(object);
    }
  };

  const getAttachmentsData = async () => {
    if (!objectId) return;
    if (mode === dict.DocFormViewMode.client) {
      const object = await ContractLeaseBinaryApi.GetIdsByDocIdSessionKey(objectId, null, sessionKey);
      setAttachmentsData(object);
    } else {
      const object = await ContractLeaseBinaryApi.GetIdsByDocId(objectId, null);
      // setAttachmentsData([
      //   { id: 19, label: "Kolorki", mimeType: "image/png" },
      //   { id: 20, label: "Lewek", mimeType: "image/png" },
      // ]);
      setAttachmentsData(object);
    }
  };

  const prepareExternalPrintingData = () => {
    getCoownersData();
    getAttachmentsData();
  };

  const renderPrintingPreview = () => {
    if (!debug) return;

    prepareExternalPrintingData();

    return (
      <PDFViewer width={"100%"} height={1000} showToolbar={true}>
        <ContractLeasePDF
          id={objectId}
          mode={dict.DocFormViewMode.agent}
          sessionKey={sessionKey}
          contractDate={dayjs(contractDate).format("YYYY-MM-DD")}
          contractHour={dayjs(contractDate).format("HH:mm")}
          contractCity={contractCity}
          clientName={clientName}
          clientSurname={clientSurname}
          clientAddrStreet={clientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          clientAddrPostcode={clientAddrPostcode}
          clientAddrCity={clientAddrCity}
          clientAddrCountry={clientAddrCountry}
          clientEmail={clientEmail}
          clientPhone={clientPhone}
          clientPesel={clientPesel}
          clientIdType={clientIdType}
          clientIdNumber={clientIdNumber}
          propertyAddrStreet={propertyAddrStreet}
          propertyAddrStreetNumber={propertyAddrStreetNumber}
          propertyAddrLocaleNumber={propertyAddrLocaleNumber}
          propertyAddrPostcode={propertyAddrPostcode}
          propertyAddrCity={propertyAddrCity}
          propertyAddrCountry={propertyAddrCountry}
          agentPostTerminationWork={true}
          agentProvisionType={agentProvisionType}
          agentProvisionPercentNet={agentProvisionPercentNet}
          agentProvisionAmountNet={agentProvisionAmountNet}
          withPresentation={withPresentation}
          agentName={"AImię"}
          agentSurname={"ANazwisko"}
          agentPhone={"123456789"}
          agentEmail={"example@example.com"}
          branchName={"Oddział Warszawa"}
          // branchAddressLine1={"".concat(
          //   branchAddrStreet ? "ul. ".concat(branchAddrStreet) : "",
          //   " ",
          //   branchAddrStreetNumber,
          //   branchAddrLocaleNumber ? "/".concat(branchAddrLocaleNumber) : ""
          // )}
          // branchAddressLine2={"".concat(
          //   branchAddrPostcode,
          //   " ",
          //   branchAddrCity
          // )}
          // branchEmail={branchEmail}
          // branchPhone={branchPhone}
          // signature={-1}
          contractVariant={contractVariant}
          contractDurationMonth={contractDurationMonth}
          clientType={clientType}
          principalName={principalName}
          principalSurname={principalSurname}
          principalAddrStreet={principalAddrStreet}
          principalAddrStreetNumber={principalAddrStreetNumber}
          principalAddrLocaleNumber={principalAddrLocaleNumber}
          principalAddrPostcode={principalAddrPostcode}
          principalAddrCity={principalAddrCity}
          principalAddrCountry={principalAddrCountry}
          principalEmail={principalEmail}
          principalPhone={principalPhone}
          principalPesel={principalPesel}
          principalIdType={principalIdType}
          principalIdNumber={principalIdNumber}
          companyName={companyName}
          companyNIP={companyNIP}
          companyKRS={companyKRS}
          coownersData={coownersData}
          attachmentsData={attachmentsData}
        />
      </PDFViewer>
    );
  };

  const handleOnTouchEndAgent = (counter, value) => {
    setCheckCounterAgent(counter);
    signatureAgent.current = value;
  };

  const renderAgentSignature = () => {
    if (!isSignatureAgentVisible) return;

    if (status !== dict.DocStatus.sended) {
      return (
        <FormGroup
          sx={{
            bgcolor: "background.paper",
            boxShadow: 1,
            borderRadius: 2,
            p: 2,
            maxWidth: 1200,
            mb: 2,
          }}
        >
          <Grid container spacing={1} style={{ marginTop: "-25px" }}>
            <Grid item xs={12} sm={12} md={12}>
              <DrawingCanvas
                id={"signatureAgentBeforeSend"}
                title="Podpis Agenta"
                onTouchEnd={(a, b, c) => handleOnTouchEndAgent(a, b, c)}
                marginTop={"0px"}
                scale={isSm ? 3 : 2}
              />
            </Grid>
          </Grid>
        </FormGroup>
      );
    }

    return (
      <FormGroup
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          maxWidth: 1200,
          mb: 2,
        }}
      >
        {renderSignature(signatureAgent.current)}
      </FormGroup>
    );
  };

  const renderSignature = (value) => {
    if (value && value !== -1) {
      return (
        // <Image
        //   source={value}
        //   type={"image/png"}
        //   style={{
        //     padding: "0",
        //     margin: "0",
        //     width: "180",
        //     height: "70",
        //   }}
        //   // width="360px"
        //   // height="140px"
        // />
        <div style={{ marginTop: "-15px" }}>
          <h4>Podgląd podpisu Agenta</h4>
          <img src={value} type={"image/png"} alt="Podpis Agenta" width="180px" height="70px" />
        </div>
      );
    }
    return <div></div>;
  };

  const handleAddCoowner = async () => {
    if (mode !== dict.FormMode.edit) {
      let result = await handleSaveClick();

      if (result === -1) {
        return -1;
      }

      props.onNotificationShow("Dodawanie Współposzukujących wymaga wcześniejszego zapisu umowy.", dict.NotificationType.error);
      return -1;
    }

    // eslint-disable-next-line
    let objId = await ContractLeaseCoownerApi.Create({
      docId: objectId,
    });

    refreshCoowners();
  };

  const handleDeleteCoowner = async (id) => {
    refreshCoowners();
  };

  const handleClientTypeChoosen = (value) => {
    setClientType(value);
  };

  const renderRepresentative = () => {
    return (
      <Person
        isRepresentative={true}
        isModePreview={isModePreview}
        hasErrorSectionClient={hasErrorSectionClient}
        clientType={clientType}
        onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
        clientName={clientName}
        onChangeClientName={(value) => setClientName(value)}
        hasErrorClientName={hasErrorRepresentativeName}
        clientSurname={clientSurname}
        onChangeClientSurname={(value) => setClientSurname(value)}
        hasErrorClientSurname={hasErrorRepresentativeSurname}
        clientPhone={clientPhone}
        onChangeClientPhone={(value) => {
          setClientPhone(value);
          phoneValidator(value) ? setHasErrorRepresentativePhone(false) : setHasErrorRepresentativePhone(true);
        }}
        hasErrorClientPhone={hasErrorRepresentativePhone}
        clientEmail={clientEmail}
        onChangeClientEmail={(value) => {
          setClientEmail(value);
          emailValidator(value) ? setHasErrorRepresentativeEmail(false) : setHasErrorRepresentativeEmail(true);
        }}
        hasErrorClientEmail={hasErrorRepresentativeEmail}
        clientAddrCountry={clientAddrCountry}
        onChangeClientAddrCountry={(value) => setClientAddrCountry(value)}
        hasErrorClientAddrCountry={hasErrorRepresentativeAddrCountry}
        clientPesel={clientPesel}
        onChangeClientPesel={(value) => setClientPesel(value)}
        hasErrorClientPesel={hasErrorRepresentativePesel}
        clientIdTypeCombo={clientIdTypeCombo}
        hasErrorClientIdType={hasErrorRepresentativeIdType}
        onChangeClientIdType={(value) => setClientIdType(value)}
        onChangeClientIdTypeCombo={(value) => setClientIdTypeCombo(value)}
        clientIdNumber={clientIdNumber}
        onChangeClientIdNumber={(value) => setClientIdNumber(value)}
        hasErrorClientIdNumber={hasErrorRepresentativeIdNumber}
        clientAddrStreet={clientAddrStreet}
        onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
        hasErrorClientAddrStreet={hasErrorRepresentativeAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
        hasErrorClientAddrStreetNumber={hasErrorRepresentativeAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
        clientAddrPostcode={clientAddrPostcode}
        onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
        clientAddrCity={clientAddrCity}
        onChangeClientAddrCity={(value) => setClientAddrCity(value)}
        hasErrorClientAddrCity={hasErrorRepresentativeAddrCity}
      />
    );
  };

  const renderClient = () => {
    if (clientType === dict.ClientType.businessIndividual) {
      return (
        <BusinessIndividual
          isModePreview={isModePreview}
          hasErrorSectionClient={hasErrorSectionClient}
          clientType={clientType}
          onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
          companyName={companyName}
          onChangeCompanyName={(value) => setCompanyName(value)}
          hasErrorCompanyName={hasErrorCompanyName}
          companyNIP={companyNIP}
          onChangeCompanyNIP={(value) => setCompanyNIP(value)}
          hasErrorCompanyNIP={hasErrorCompanyNIP}
          clientName={clientName}
          onChangeClientName={(value) => setClientName(value)}
          hasErrorClientName={hasErrorClientName}
          clientSurname={clientSurname}
          onChangeClientSurname={(value) => setClientSurname(value)}
          hasErrorClientSurname={hasErrorClientSurname}
          clientPhone={clientPhone}
          onChangeClientPhone={(value) => {
            setClientPhone(value);
            phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
          }}
          hasErrorClientPhone={hasErrorClientPhone}
          clientEmail={clientEmail}
          onChangeClientEmail={(value) => {
            setClientEmail(value);
            emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
          }}
          hasErrorClientEmail={hasErrorClientEmail}
          clientAddrStreet={clientAddrStreet}
          onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
          hasErrorClientAddrStreet={hasErrorClientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
          hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
          clientAddrPostcode={clientAddrPostcode}
          onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
          clientAddrCity={clientAddrCity}
          onChangeClientAddrCity={(value) => setClientAddrCity(value)}
          hasErrorClientAddrCity={hasErrorClientAddrCity}
        />
      );
    } else if (clientType === dict.ClientType.businessIndividualWithRepresentative) {
      return (
        <Stack>
          <BusinessIndividual
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionRepresentative}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            companyName={companyName}
            onChangeCompanyName={(value) => setCompanyName(value)}
            hasErrorCompanyName={hasErrorCompanyName}
            companyNIP={companyNIP}
            onChangeCompanyNIP={(value) => setCompanyNIP(value)}
            hasErrorCompanyNIP={hasErrorCompanyNIP}
            clientName={principalName}
            onChangeClientName={(value) => setPrincipalName(value)}
            hasErrorClientName={hasErrorClientName}
            clientSurname={principalSurname}
            onChangeClientSurname={(value) => setPrincipalSurname(value)}
            hasErrorClientSurname={hasErrorClientSurname}
            clientPhone={principalPhone}
            onChangeClientPhone={(value) => {
              setPrincipalPhone(value);
              phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
            }}
            hasErrorClientPhone={hasErrorClientPhone}
            clientEmail={principalEmail}
            onChangeClientEmail={(value) => {
              setPrincipalEmail(value);
              emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
            }}
            hasErrorClientEmail={hasErrorClientEmail}
            clientAddrStreet={principalAddrStreet}
            onChangeClientAddrStreet={(value) => setPrincipalAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={principalAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPrincipalAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={principalAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPrincipalAddrLocaleNumber(value)}
            clientAddrPostcode={principalAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPrincipalAddrPostcode(value)}
            clientAddrCity={principalAddrCity}
            onChangeClientAddrCity={(value) => setPrincipalAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } else if (clientType === dict.ClientType.company) {
      return (
        <Company
          isRepresentative={false}
          isModePreview={isModePreview}
          hasErrorSectionClient={hasErrorSectionClient}
          clientType={clientType}
          onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
          companyName={companyName}
          onChangeCompanyName={(value) => setCompanyName(value)}
          hasErrorCompanyName={hasErrorCompanyName}
          companyNIP={companyNIP}
          onChangeCompanyNIP={(value) => setCompanyNIP(value)}
          hasErrorCompanyNIP={hasErrorCompanyNIP}
          companyKRS={companyKRS}
          onChangeCompanyKRS={(value) => setCompanyKRS(value)}
          hasErrorCompanyKRS={hasErrorCompanyKRS}
          clientAddrStreet={clientAddrStreet}
          onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
          hasErrorClientAddrStreet={hasErrorClientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
          hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
          clientAddrPostcode={clientAddrPostcode}
          onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
          clientAddrCity={clientAddrCity}
          onChangeClientAddrCity={(value) => setClientAddrCity(value)}
          hasErrorClientAddrCity={hasErrorClientAddrCity}
          clientName={clientName}
          onChangeClientName={(value) => setClientName(value)}
          hasErrorClientName={hasErrorClientName}
          clientSurname={clientSurname}
          onChangeClientSurname={(value) => setClientSurname(value)}
          hasErrorClientSurname={hasErrorClientSurname}
          clientPhone={clientPhone}
          onChangeClientPhone={(value) => {
            setClientPhone(value);
            phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
          }}
          hasErrorClientPhone={hasErrorClientPhone}
          clientEmail={clientEmail}
          onChangeClientEmail={(value) => {
            setClientEmail(value);
            emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
          }}
          hasErrorClientEmail={hasErrorClientEmail}
        />
      );
    } else if (clientType === dict.ClientType.companyWithRepresentative) {
      return (
        <Stack>
          <Company
            isRepresentative={true}
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionRepresentative}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            companyName={companyName}
            onChangeCompanyName={(value) => setCompanyName(value)}
            hasErrorCompanyName={hasErrorCompanyName}
            companyNIP={companyNIP}
            onChangeCompanyNIP={(value) => setCompanyNIP(value)}
            hasErrorCompanyNIP={hasErrorCompanyNIP}
            companyKRS={companyKRS}
            onChangeCompanyKRS={(value) => setCompanyKRS(value)}
            hasErrorCompanyKRS={hasErrorCompanyKRS}
            clientAddrStreet={principalAddrStreet}
            onChangeClientAddrStreet={(value) => setPrincipalAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={principalAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPrincipalAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={principalAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPrincipalAddrLocaleNumber(value)}
            clientAddrPostcode={principalAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPrincipalAddrPostcode(value)}
            clientAddrCity={principalAddrCity}
            onChangeClientAddrCity={(value) => setPrincipalAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } else if (clientType === dict.ClientType.personWithRepresentative) {
      return (
        <Stack>
          <Person
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionRepresentative}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            clientName={principalName}
            onChangeClientName={(value) => setPrincipalName(value)}
            hasErrorClientName={hasErrorClientName}
            clientSurname={principalSurname}
            onChangeClientSurname={(value) => setPrincipalSurname(value)}
            hasErrorClientSurname={hasErrorClientSurname}
            clientPhone={principalPhone}
            onChangeClientPhone={(value) => {
              setPrincipalPhone(value);
              phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
            }}
            hasErrorClientPhone={hasErrorClientPhone}
            clientEmail={principalEmail}
            onChangeClientEmail={(value) => {
              setPrincipalEmail(value);
              emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
            }}
            hasErrorClientEmail={hasErrorClientEmail}
            clientAddrCountry={principalAddrCountry}
            onChangeClientAddrCountry={(value) => setPrincipalAddrCountry(value)}
            hasErrorClientAddrCountry={hasErrorClientAddrCountry}
            clientPesel={principalPesel}
            onChangeClientPesel={(value) => setPrincipalPesel(value)}
            hasErrorClientPesel={hasErrorClientPesel}
            clientIdTypeCombo={principalIdTypeCombo}
            hasErrorClientIdType={hasErrorClientIdType}
            onChangeClientIdType={(value) => setPrincipalIdType(value)}
            onChangeClientIdTypeCombo={(value) => setPrincipalIdTypeCombo(value)}
            clientIdNumber={principalIdNumber}
            onChangeClientIdNumber={(value) => setPrincipalIdNumber(value)}
            hasErrorClientIdNumber={hasErrorClientIdNumber}
            clientAddrStreet={principalAddrStreet}
            onChangeClientAddrStreet={(value) => setPrincipalAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={principalAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPrincipalAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={principalAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPrincipalAddrLocaleNumber(value)}
            clientAddrPostcode={principalAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPrincipalAddrPostcode(value)}
            clientAddrCity={principalAddrCity}
            onChangeClientAddrCity={(value) => setPrincipalAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } //else if (clientType === dict.ClientType.person) {

    return (
      <Person
        isModePreview={isModePreview}
        hasErrorSectionClient={hasErrorSectionClient}
        clientType={clientType}
        onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
        clientName={clientName}
        onChangeClientName={(value) => setClientName(value)}
        hasErrorClientName={hasErrorClientName}
        clientSurname={clientSurname}
        onChangeClientSurname={(value) => setClientSurname(value)}
        hasErrorClientSurname={hasErrorClientSurname}
        clientPhone={clientPhone}
        onChangeClientPhone={(value) => {
          setClientPhone(value);
          phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
        }}
        hasErrorClientPhone={hasErrorClientPhone}
        clientEmail={clientEmail}
        onChangeClientEmail={(value) => {
          setClientEmail(value);
          emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
        }}
        hasErrorClientEmail={hasErrorClientEmail}
        clientAddrCountry={clientAddrCountry}
        onChangeClientAddrCountry={(value) => setClientAddrCountry(value)}
        hasErrorClientAddrCountry={hasErrorClientAddrCountry}
        clientPesel={clientPesel}
        onChangeClientPesel={(value) => setClientPesel(value)}
        hasErrorClientPesel={hasErrorClientPesel}
        clientIdTypeCombo={clientIdTypeCombo}
        hasErrorClientIdType={hasErrorClientIdType}
        onChangeClientIdType={(value) => setClientIdType(value)}
        onChangeClientIdTypeCombo={(value) => setClientIdTypeCombo(value)}
        clientIdNumber={clientIdNumber}
        onChangeClientIdNumber={(value) => setClientIdNumber(value)}
        hasErrorClientIdNumber={hasErrorClientIdNumber}
        clientAddrStreet={clientAddrStreet}
        onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
        hasErrorClientAddrStreet={hasErrorClientAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
        hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
        clientAddrPostcode={clientAddrPostcode}
        onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
        clientAddrCity={clientAddrCity}
        onChangeClientAddrCity={(value) => setClientAddrCity(value)}
        hasErrorClientAddrCity={hasErrorClientAddrCity}
      />
    );
  };

  return (
    <div>
      <Stack>{renderPrintingPreview()}</Stack>
      <FormGroup
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          maxWidth: 1200,
          mb: 2,
        }}
      >
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 0, pb: 2 }}>
          {"Informacje"}
        </Stack>
        {/*
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            id="contractvariant"
            options={dict.IntermediationContractVariantCombo}
            sx={{ width: "100%", pb: 2 }}
            renderInput={(params) => <TextField {...params} label="Wariant umowy" size="small" required={true} error={hasErrorContractVariant} />}
            value={contractVariantCombo}
            onChange={(e, value) => {
              setContractVariant(value?.code);
              setContractVariantCombo(dict.IntermediationContractVariantCombo.filter((f) => f.code === value?.code)[0]);
            }}
            disabled={isModePreview}
            disableClearable={true}
          />
        </Stack>
        */}
        <Stack direction="row" spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={"Data zawarcia umowy *"}
              id={"contractDate"}
              value={contractDate?.isSame(dict.DefaultDate.toUTCString()) ? null : contractDate}
              format="YYYY-MM-DD HH:mm"
              onChange={(e) => setContractDate(e)}
              disabled={isModePreview}
              slotProps={{
                textField: {
                  size: "small",
                  //helperText: "Format YYYY-MM-DD HH:mm",
                },
              }}
              sx={{ pb: 2, width: "200px" }}
              error={hasErrorContractDate}
            />
          </LocalizationProvider>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Miejscowość zawarcia umowy"}
            id={"contractCity"}
            value={contractCity}
            onChange={(e) => {
              setContractCity(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={hasErrorContractCity}
          />
        </Stack>
        {/* <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Czas trwania umowy (miesiące)"}
            id={"contractDurationMonth"}
            value={returnFormattedValue(contractDurationMonth)}
            onChange={(e) => {
              setContractDurationMonth(parseInt(e.target.value));
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            type={"number"}
            error={hasErrorContractDurationMonth}
            pattern={"[0-9]*"}

            // InputProps={{
            //   inputProps: {
            //     pattern: "[0-9]",
            //   },
            // }}
          />
        </Stack> */}
        {renderPreviewInfo()}
        {renderClient()}

        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {"Współposzukiwanie"}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              size="medium"
              startIcon={<PersonAddAlt1OutlinedIcon />}
              disabled={mode !== dict.FormMode.edit ? true : false}
              onClick={handleAddCoowner}
              style={{ marginTop: "5px", marginBottom: "25px" }}
            >
              {"Dodaj współposzukującego"}
            </Button>
          </Stack>
          {coowners.map((item) => (
            <Coowner
              key={"".concat("coowner", item.id)}
              id={item.id}
              mode={mode}
              onDelete={(a) => handleDeleteCoowner(a)}
              ref={item.myRef}
              onNotificationShow={
                (a, b) => {
                  a = "Współposzukujący - " + a;
                  props.onNotificationShow(a, b);
                }
                //
              }
              docStatus={status}
            />
          ))}
        </Stack>
        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 0 }}>
            {"Wynagrodzenie Agenta"}
          </Stack>
          <FormControl>
            {/* <FormLabel id="agent-provision-group">{"Metoda wynagrodzenia Agenta"}</FormLabel> */}
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={agentProvisionType}>
              <FormControlLabel
                value={dict.AgentProvisionType.percent}
                control={<Radio />}
                label="procentowe"
                onChange={(e) => setAgentProvisionType(parseInt(e.target.value))}
              />
              <FormControlLabel
                value={dict.AgentProvisionType.amount}
                control={<Radio />}
                label="kwotowe"
                onChange={(e) => setAgentProvisionType(parseInt(e.target.value))}
              />
            </RadioGroup>
          </FormControl>
          {agentProvisionType === dict.AgentProvisionType.percent && (
            <Stack direction="row" spacing={2} sx={{ pt: 1 }}>
              <TextField
                size="small"
                label={"Procent netto wynagrodzenia"}
                id={"agentProvisionPercentNet"}
                value={returnFormattedValue(agentProvisionPercentNet)}
                onChange={(e) => {
                  setAgentProvisionPercentNet(e.target.value);
                }}
                disabled={isModePreview}
                sx={{ pb: 2, width: "100%" }}
                required={true}
                type={"number"}
                error={hasErrorAgentProvisionPercentNet}
              />
            </Stack>
          )}
          {agentProvisionType === dict.AgentProvisionType.amount && (
            <Stack direction="row" spacing={2} sx={{ pt: 1 }}>
              <TextField
                size="small"
                label={"Kwota netto wynagrodzenia"}
                id={"agentProvisionAmountNet"}
                value={returnFormattedValue(agentProvisionAmountNet)}
                onChange={(e) => {
                  setAgentProvisionAmountNet(e.target.value);
                }}
                disabled={isModePreview}
                sx={{ pb: 2, width: "100%" }}
                required={true}
                type={"number"}
                error={hasErrorAgentProvisionAmountNet}
              />
            </Stack>
          )}
        </Stack>

        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }} style={{ color: hasErrorSectionPresentation ? "red" : "" }}>
            {"Prezentacja nieruchomości"}
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"umowa zawierana podczas prezentacji"}</Typography>}
              sx={{
                mr: 0,
              }}
              checked={withPresentation}
              onChange={(e) => setWithPresentation(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          {withPresentation && (
            <Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  label={"Ulica"}
                  id={"propertyAddrStreet"}
                  value={propertyAddrStreet}
                  onChange={(e) => setPropertyAddrStreet(e.target.value)}
                  disabled={isModePreview}
                  sx={{ pb: 2, width: "100%" }}
                  required={true}
                  error={hasErrorPropertyAddrStreet}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  label={"Numer budynku"}
                  id={"propertyAddrStreetNumber"}
                  value={propertyAddrStreetNumber}
                  onChange={(e) => setPropertyAddrStreetNumber(e.target.value)}
                  disabled={isModePreview}
                  sx={{ pb: 2, width: "50%" }}
                  required={true}
                  error={hasErrorPropertyAddrStreetNumber}
                />
                <TextField
                  size="small"
                  label={"Numer lokalu"}
                  id={"propertyAddrLocaleNumber"}
                  value={propertyAddrLocaleNumber}
                  onChange={(e) => setPropertyAddrLocaleNumber(e.target.value)}
                  disabled={isModePreview}
                  sx={{ pb: 2, width: "50%" }}
                  required={false}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  label={"Kod pocztowy"}
                  id={"propertyAddrPostcode"}
                  value={propertyAddrPostcode}
                  onChange={(e) => setPropertyAddrPostcode(e.target.value)}
                  disabled={isModePreview}
                  sx={{ pb: 2, width: "100%" }}
                  required={false}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  label={"Miejscowość"}
                  id={"propertyAddrCity"}
                  value={propertyAddrCity}
                  onChange={(e) => setPropertyAddrCity(e.target.value)}
                  disabled={isModePreview}
                  sx={{ pb: 2, width: "100%" }}
                  required={true}
                  error={hasErrorPropertyAddrCity}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        {
          <ContractLeaseBinary
            docId={objectId}
            mode={mode}
            onNotificationShow={
              (a, b) => {
                a = "Załączniki - " + a;
                props.onNotificationShow(a, b);
              }
              //
            }
          />
        }
      </FormGroup>
      {renderAgentSignature()}
      <ContractLeaseMonitor id={objectId} mode={mode} />
      {renderButtons()}
    </div>
  );
};

export default ContractLeaseForm;
