import { Text, View } from "@react-pdf/renderer";

const DocumentForm = (props) => {
  const styles = {
    page: {
      fontFamily: "Roboto",
      padding: "0%",
      marginLeft: "15px",
      fontSize: "9px",
      marginRight: "20px",
      marginTop: "-60px",
    },
    title: {
      marginTop: "20px",
      marginBottom: "40px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "12px",
    },
    newParagraph: {
      paddingBottom: "4px",
    },
    bold: {
      fontWeight: "bold",
    },
  };

  return (
    <View break style={styles.page}>
      <Text style={styles.title}>Załącznik Nr 3 - Informacja o ochronie danych osobowych</Text>

      <Text style={styles.bold}>INFORMACJA O OCHRONIE DANYCH HOME</Text>

      <Text style={styles.newParagraph}>
        Administratorem Pani/Pana danych osobowych jest
        <Text style={styles.bold}> HOME Spółka z ograniczoną odpowiedzialnością </Text>z siedzibą w Szczecinie adres: ul. Zielonogórska 31, lok. 1.1,
        71-084 Szczecin (poniżej jako <Text style={styles.bold}> „Administrator”</Text>
        ).{" "}
      </Text>
      <Text style={styles.newParagraph}>
        Z Administratorem może Pani/Pan skontaktować się pisemnie, pod adresem podanym powyżej lub pod adresem email rodo@home-estate.pl
      </Text>

      <Text style={styles.newParagraph}>Administrator będzie przetwarzać Pani/Pana dane osobowe w następujących celach:</Text>

      <Text>
        a) w celu podjęcia działań przed zawarciem umowy pośrednictwa lub w przypadku jej zawarcia, w celu jej wykonania - na podstawie art. 6 ust. 1
        lit. b) RODO;
      </Text>
      <Text>
        b) w celu wypełnienia obowiązków ciążących na Administratorze, w szczególności wynikających z przepisów podatkowych i rachunkowych - na
        podstawie art. 6 ust. 1 lit. c) RODO;
      </Text>
      <Text>
        c) w celu prowadzenia postępowania windykacyjnego, ustalenia, dochodzenia i obrony przed potencjalnymi roszczeniami na drodze
        sądowej/pozasądowej - na podstawie art. 6 ust. 1 lit. f) RODO;
      </Text>
      <Text>
        d) w celu marketingu bezpośredniego produktów lub usług Administratora za pośrednictwem środków komunikacji elektronicznej i telefonicznej –
        podstawą prawną przetwarzania danych będzie Pani/Pana zgoda na przekazywanie informacji handlowej za pośrednictwem środków komunikacji
        elektronicznej i telefonicznej (art. 6 ust. 1 lit. a) RODO). Jeżeli wyrazi Pani/Pan zgodę na otrzymywanie od nas informacji marketingowych,
        informacje takie dostarczą Pani/Panu również nasi zaufani Partnerzy, z którymi stale współpracujemy. W każdej chwili mogą Państwo wycofać
        udzieloną zgodę.
      </Text>

      <Text style={styles.newParagraph}>
        Pani/Pana dane mogą zostaną przez nas udostępnione osobom, które z upoważnienia Administratora lub podmiotu przetwarzającego mogą przetwarzać
        dane osobowe (pracownicy/współpracownicy), podmiotom przetwarzającym - dostawcom usług np. pocztowych, kurierskich, marketingowych, lT, a
        także franczyzobiorcom i potencjalnym kontrahentom uczestniczącym w wykonaniu przedmiotu Umowy. W każdej chwili może Pani/Pan zwrócić się do
        nas z wnioskiem o udzielenie informacji o osobach, którym udostępniliśmy Pani/Pana dane. Jeżeli wyrazi Pani/Pan zgodę na otrzymywanie od nas
        informacji handlowych, dane kontaktowe przekażemy również naszym zaufanym Partnerom z którymi stale współpracujemy, w celu przedstawienia
        Pani/Panu informacji o ich usługach lub ofercie. Pani/Pana dane osobowe nie będą przekazywane do odbiorców znajdujących się w państwach poza
        Europejskim Obszarem Gospodarczym. W przypadku udzielenia zgody na przesyłanie informacji handlowych drogą elektroniczną lub na kontakt
        telefoniczny w celu przedstawienia oferty, Pani/Pana dane będą przez nas przetwarzane do czasu wycofania udzielonej zgody. W zakresie, w jakim
        Pani/Pana dane będą przetwarzane w celu wykonania i zapewnienia najwyższej jakości naszych usług lub w celu marketingu bezpośredniego naszych
        towarów lub usług, będziemy je przetwarzali do czasu zgłoszenia sprzeciwu wobec przetwarzania danych osobowych w powyższych celach. W
        zakresie, w jaki Pani/Pana dane będą przetwarzane w celu wykonania umowy pośrednictwa lub w celu obrony, dochodzenia, ustalenia roszczeń -
        będziemy je przetwarzali do upływu okresu przedawnienia roszczeń lub zakończenia postępowania windykacyjnego.
      </Text>

      <Text style={styles.newParagraph}>Przysługują Pani/Panu następujące prawa związane z przetwarzaniem danych osobowych:</Text>
      <Text>
        a) prawo wycofania zgody na przetwarzania danych - przy czym cofnięcie zgody nie ma wpływu na zgodność z prawem przetwarzania w okresie przed
        jej wycofaniem,
      </Text>
      <Text>b) prawo dostępu do danych osobowych – uzyskania informacji o przetwarzaniu danych osobowych oraz kopii danych, </Text>
      <Text>c) prawo żądania sprostowania danych osobowych - ich poprawienia, uzupełnienia lub aktualizacji,</Text>
      <Text>d) prawo żądania usunięcia danych osobowych, </Text>
      <Text>e) prawo żądania ograniczenia przetwarzania danych osobowych, </Text>
      <Text>
        f) prawo wyrażenia sprzeciwu wobec przetwarzania danych ze względu na Pani/Pana szczególną sytuację – w przypadkach, kiedy przetwarzamy dane w
        zakresie wynikającym z prawnie uzasadnionego interesu Administratora (w szczególności, dotyczy to sprzeciwu wobec przetwarzania Pani/Pana
        danych na potrzeby marketingu bezpośredniego oraz w celu zapewnienia najwyższej jakości usług),
      </Text>
      <Text>
        g) prawo żądania przeniesienia danych osobowych, tj. przekazania danych osobowych przez Administratora w ustrukturyzowanym, powszechnie
        używanym formacie nadającym się do odczytu maszynowego.
      </Text>

      <Text style={styles.newParagraph}>
        W celu realizacji powyższych uprawnień, prosimy o kontakt e-mailowy rodo@home-estate.pl lub pisemny na adres Administratora.
      </Text>

      <Text style={styles.newParagraph}>
        Przysługuje Pani/Panu także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych tj. Prezesa Urzędu Ochrony
        Danych Osobowych.
      </Text>
    </View>
  );
};

export default DocumentForm;
