import { useNavigate, useLocation } from "react-router-dom";
//import { useState, useEffect } from "react";
import * as RoutePath from "../../../config/NavigationRoute/RoutePath";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import * as ContractLeaseApi from "../../../business/ContractLease/ContractLeaseApi";
import { setFileName } from "../../../utils/validator";
import * as Dict from "../../../config/Dictionary";
import dayjs from "dayjs";

export default function DocumentSignedScreen(props) {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const location = useLocation();

  const docId = location.state.id;
  const email = location.state.email;

  const text1 = "Gratulacje, umowa została zawarta!";
  const text2 = "Dokument dostarczono na e-mail Klienta:";

  //   if (location.state) {
  //     setObjId(location.state.id);
  //     setTitle("Umowa pośrednictwa [".concat(location.state.id, "]"));
  //   }

  const handleDownload = async () => {
    let data = await ContractLeaseApi.GetById(docId);

    let link = document.createElement("a");
    link.download = setFileName(
      Dict.DocTypes.intermediationContract,
      "pdf",
      dayjs(data.contractDate).format("YYYY-MM-DDTHH:mm"),
      data.propertyAddrCity,
      data.propertyAddrStreet,
      data.propertyAddrStreetNumber,
      data.propertyAddrLocaleNumber
    );

    let response = await ContractLeaseApi.GetDocumentPDFById(docId);
    let blob = new Blob([response], { type: "application/pdf" });

    link.href = URL.createObjectURL(blob);
    link.click();

    URL.revokeObjectURL(link.href);
  };

  const handleBack = () => {
    navigate(RoutePath.ContractLeaseAdd, {
      state: {
        id: docId,
      },
    });
  };

  const handleFinish = () => {
    navigate(RoutePath.Main, {});
  };

  return (
    <Stack>
      <Typography variant="h5" component="h1" sx={{ pt: 10, pb: 1, fontWeight: "bold" }}>
        {text1}
      </Typography>
      <Typography variant="h6" component="h1" sx={{ pt: 1, pb: 0 }}>
        {text2}
      </Typography>
      <Typography variant="body1" component="h1" sx={{ pt: 0, pb: 1, color: "black", fontWeight: "bold" }}>
        {email}
      </Typography>
      <Stack direction="row" spacing={2} sx={{ pt: 2, justifyContent: "center" }}>
        <Button variant="text" size="medium" startIcon={<CloudDownloadOutlinedIcon />} onClick={handleDownload}>
          {"Pobierz plik"}
        </Button>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ pt: 6, justifyContent: "center" }}>
        <Button variant="outlined" size="medium" startIcon={<UndoOutlinedIcon />} onClick={handleBack}>
          {"Wróć"}
        </Button>

        <Button variant="contained" size="medium" startIcon={<ExitToAppOutlinedIcon />} onClick={handleFinish}>
          {"Zakończ"}
        </Button>
      </Stack>
    </Stack>
  );
}
