export const Main = "/";
export const Login = "/login";
export const Settings = "/settings";
export const Users = "/users";
export const Contact = "/contact";
export const Branches = "/branches";
export const DrawingCanvas = "/signature";
export const PresentationProtocolHistory = "/presentation-protocol-history";
export const PresentationProtocolAgent = "/presentation-protocol";
export const PresentationProtocolClient = "/c-prepr/:guid";
export const IntermediationContractHistory = "/intermediation-contract-history";
export const IntermediationContractAgent = "/intermediation-contract";
export const IntermediationContractClientLogin = "/c-intco/:guid";
export const IntermediationContractCientForm = "/intermediation-contract-form";
export const IntermediationContractFinish = "/intermediation-contract-finish";
export const TemplateDocumentAdd = "/template-document-add";
export const TemplateDocumentHistory = "/template-document-history";
export const TemplateDocumentClientLogin = "/c-tmp/:guid";
export const TemplateDocumentClientDecision = "/template-document-decision";
export const TemplateDocumentClientFinish = "/template-document-finish";
export const ContractPurchaseAdd = "/contract-purchase-add";
export const ContractPurchaseHistory = "/contract-purchase-history";
export const ContractPurchaseClientLogin = "/c-pur/:guid";
export const ContractPurchaseClientDecision = "/contract-purchase-decision";
export const ContractPurchaseClientFinish = "/contract-purchase-finish";
export const ContractLeaseAdd = "/contract-lease-add";
export const ContractLeaseHistory = "/contract-lease-history";
export const ContractLeaseClientLogin = "/c-lea/:guid";
export const ContractLeaseClientDecision = "/contract-lease-decision";
export const ContractLeaseClientFinish = "/contract-lease-finish";
