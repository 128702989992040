import axios from "axios";
import * as api from "../../../config/ApiConfig";
import * as session from "../../../components/SessionStorage/SessionStorage";

export const Create = async (object) => {
  try {
    const response = await axios({
      method: "post",
      url: api.url_ContractPurchaseCoowner_Create,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        createdBy: session.getUserId(),
        docId: object.docId,
        clientType: object.clientType,
        companyName: object.companyName,
        companyNIP: object.companyNIP,
        companyKRS: object.companyKRS,
        clientName: object.clientName,
        clientSurname: object.clientSurname,
        clientAddrStreet: object.clientAddrStreet,
        clientAddrStreetNumber: object.clientAddrStreetNumber,
        clientAddrLocaleNumber: object.clientAddrLocaleNumber,
        clientAddrPostcode: object.clientAddrPostcode,
        clientAddrCity: object.clientAddrCity,
        clientAddrCountry: object.clientAddrCountry,
        clientPesel: object.clientPesel,
        clientIdType: object.clientIdType,
        clientIdNumber: object.clientIdNumber,
        clientPhone: object.clientPhone,
        clientEmail: object.clientEmail,
        principalName: object.principalName,
        principalSurname: object.principalSurname,
        principalPhone: object.principalPhone,
        principalEmail: object.principalEmail,
        principalAddrStreet: object.principalAddrStreet,
        principalAddrStreetNumber: object.principalAddrStreetNumber,
        principalAddrLocaleNumber: object.principalAddrLocaleNumber,
        principalAddrPostcode: object.principalAddrPostcode,
        principalAddrCity: object.principalAddrCity,
        principalAddrCountry: object.principalAddrCountry,
        principalPesel: object.principalPesel,
        principalIdType: object.principalIdType,
        principalIdNumber: object.principalIdNumber,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchaseCoowner_Create, error);
  }
};

export const Update = async (objectId, object) => {
  try {
    let bodyData = {
      updatedBy: session.getUserId(),
      docId: object.docId,
      clientSignature: object.clientSignature,
      clientType: object.clientType,
      companyName: object.companyName,
      companyNIP: object.companyNIP,
      companyKRS: object.companyKRS,
      clientName: object.clientName,
      clientSurname: object.clientSurname,
      clientAddrStreet: object.clientAddrStreet,
      clientAddrStreetNumber: object.clientAddrStreetNumber,
      clientAddrLocaleNumber: object.clientAddrLocaleNumber,
      clientAddrPostcode: object.clientAddrPostcode,
      clientAddrCity: object.clientAddrCity,
      clientAddrCountry: object.clientAddrCountry,
      clientPesel: object.clientPesel,
      clientIdType: object.clientIdType,
      clientIdNumber: object.clientIdNumber,
      clientPhone: object.clientPhone,
      clientEmail: object.clientEmail,
      principalName: object.principalName,
      principalSurname: object.principalSurname,
      principalPhone: object.principalPhone,
      principalEmail: object.principalEmail,
      principalAddrStreet: object.principalAddrStreet,
      principalAddrStreetNumber: object.principalAddrStreetNumber,
      principalAddrLocaleNumber: object.principalAddrLocaleNumber,
      principalAddrPostcode: object.principalAddrPostcode,
      principalAddrCity: object.principalAddrCity,
      principalAddrCountry: object.principalAddrCountry,
      principalPesel: object.principalPesel,
      principalIdType: object.principalIdType,
      principalIdNumber: object.principalIdNumber,
    };

    const response = await axios({
      method: "post",
      url: api.url_ContractPurchaseCoowner_Update,
      headers: {
        "Content-Type": "multipart/form-data",
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.log(error);
    console.error("API method error: ", api.url_ContractPurchaseCoowner_Update, error);
  }
};

export const Delete = async (objectId) => {
  try {
    const response = await axios({
      method: "delete",
      url: api.url_ContractPurchaseCoowner_Delete,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: [objectId],
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchaseCoowner_Delete, error);
  }
};

export const GetById = async (objectId) => {
  try {
    const response = await axios.get(api.url_ContractPurchaseCoowner_GetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    const object = {};

    object.id = response.data.id;
    object.createdBy = response.data.createdBy;
    object.updatedBy = response.data.updatedBy;
    object.docId = response.data.docId;
    object.clientType = response.data.clientType;
    object.companyName = response.data.companyName;
    object.companyNIP = response.data.companyNIP;
    object.companyKRS = response.data.companyKRS;
    object.clientName = response.data.clientName;
    object.clientSurname = response.data.clientSurname;
    object.clientAddrStreet = response.data.clientAddrStreet;
    object.clientAddrStreetNumber = response.data.clientAddrStreetNumber;
    object.clientAddrLocaleNumber = response.data.clientAddrLocaleNumber;
    object.clientAddrPostcode = response.data.clientAddrPostcode;
    object.clientAddrCity = response.data.clientAddrCity;
    object.clientAddrCountry = response.data.clientAddrCountry;
    object.clientPesel = response.data.clientPesel;
    object.clientIdType = response.data.clientIdType;
    object.clientIdNumber = response.data.clientIdNumber;
    object.clientPhone = response.data.clientPhone;
    object.clientEmail = response.data.clientEmail;
    object.principalName = response.data.principalName;
    object.principalSurname = response.data.principalSurname;
    object.principalPhone = response.data.principalPhone;
    object.principalEmail = response.data.principalEmail;
    object.principalAddrStreet = response.data.principalAddrStreet;
    object.principalAddrStreetNumber = response.data.principalAddrStreetNumber;
    object.principalAddrLocaleNumber = response.data.principalAddrLocaleNumber;
    object.principalAddrPostcode = response.data.principalAddrPostcode;
    object.principalAddrCity = response.data.principalAddrCity;
    object.principalAddrCountry = response.data.principalAddrCountry;
    object.principalPesel = response.data.principalPesel;
    object.principalIdType = response.data.principalIdType;
    object.principalIdNumber = response.data.principalIdNumber;

    return object;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchaseCoowner_GetById, error);
  }
};

export const GetByDocId = async (docId) => {
  try {
    const response = await axios.get(api.url_ContractPurchaseCoowner_GetByDocId, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: docId },
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchaseCoowner_GetByDocId, error);
  }
};

export const GetByDocIdSessionKey = async (docId, sessionKey) => {
  try {
    const response = await axios.get(api.url_ContractPurchaseCoowner_GetByDocIdSessionKey, {
      params: { id: docId, sessionKey: sessionKey },
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchaseCoowner_GetByDocIdSessionKey, error);
  }
};
