const DocumentForm = (props) => {
  const styles = {
    page: {
      fontFamily: "Roboto",
      paddingTop: "5px",
      marginLeft: "15px",
      fontSize: "12px",
      marginRight: "20px",
      paddingBottom: "30px",
    },

    title: {
      paddingBottom: "10px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },

    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tr: {
      textAlign: "left",
      fontWeight: "normal",
      fontSize: "8px",
      lineHeight: "500%",
    },
  };

  return (
    <div style={styles.page}>
      <p style={styles.title}>
        Załącznik nr 2 - Wzór formularza odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa (zgodnie z ustawą z dnia 30 maja 2014r. o prawach
        konsumenta) <br />
        (formularz ten należy wypełnić i odesłać tylko w przypadku chęci odstąpienia od umowy)
      </p>

      <p style={{ lineHeight: "250%" }}>
        Niniejszym oświadczam, że odstępuję od Umowy pośrednictwa w obrocie nieruchomościami z dnia ___________r.
        <br />
        lmię i nazwisko konsumenta: _________________________________________
        <br />
        Adres konsumenta: __________________________________________________
        <br />
        ______________________________________________________________________
        <br />
        <br />
      </p>
      <table style={styles.table}>
        <thead>
          <tr>
            <th>Podpis konsumenta</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody style={styles.tr}>
          <tr>
            <td>________________________________________________</td>
            <td>______________________</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DocumentForm;
