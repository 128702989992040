import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

export default function MyGrid(props) {
  const handleCellEditCommit = (params, event) => {
    props.onRowValueChange(params.id, params.field, params.value);
  };

  const handleSelectionModelChange = (tab) => {
    props.onSelectionModelChange(tab);
  };

  const height = props.height ? props.height : 431;
  const width = props.width ? props.width : 980;

  return (
    <Box sx={{ height: height, width: width }}>
      <DataGrid
        rowHeight={32}
        rows={props.rows}
        columns={props.columns}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        checkboxSelection={props.checkboxSelection}
        disableSelectionOnClick={props.disableSelectionOnClick}
        experimentalFeatures={props.experimentalFeatures}
        onCellEditCommit={handleCellEditCommit}
        onRowSelectionModelChange={handleSelectionModelChange}
        initialState={props.initialState}
        sx={props.sx}
      />
    </Box>
  );
}
