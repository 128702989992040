import React, { useState, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const DrawingCanvas = (props) => {
  const scale = props.scale ? props.scale : 2;

  const canvasId = props.id ? props.id : "myCanvas";
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  // eslint-disable-next-line
  const [color, setColor] = useState("black");
  // eslint-disable-next-line
  const [lineWidth, setLineWidth] = useState(1);
  const [points, setPoints] = useState([]);
  // eslint-disable-next-line
  const [tekst, setTekst] = useState("");
  const [checkCounter, setCheckCounter] = useState(0);
  //const [force, setForce] = useState("");

  const handleMouseDown = (e) => {
    setIsDrawing(true);
    setPoints([[e.nativeEvent.offsetX, e.nativeEvent.offsetY]]);
  };

  const handleMouseUp = (e) => {
    setIsDrawing(false);
    //setTekst(tekst.concat("-", "up"));
    if (props.onTouchEnd) {
      props.onTouchEnd(checkCounter, returnSignatureAsBlob());
    }
  };

  const handleMouseMove = (e) => {
    //setTekst(tekst.concat("-", "move"));
    if (isDrawing) {
      const newPoint = [e.nativeEvent.offsetX, e.nativeEvent.offsetY];
      setPoints([...points, newPoint]);
    }
  };

  const handleTouchStart = (e) => {
    setIsDrawing(true);

    // pomiar siły nacisku, odczyt z api przeglądarki
    // const touch = e.nativeEvent.touches[0];
    // setForce("".concat(touch.clientX, " ", touch.force));

    setPoints([
      [
        e.nativeEvent.touches[0].clientX - canvasRef.current.getBoundingClientRect().left,
        e.nativeEvent.touches[0].clientY - canvasRef.current.getBoundingClientRect().top,
      ],
    ]);
  };
  const handleTouchEnd = (e) => {
    setIsDrawing(false);

    if (props.onTouchEnd) {
      props.onTouchEnd(checkCounter, returnSignatureAsBlob());
    }
  };
  const handleTouchMove = (e) => {
    if (isDrawing) {
      const newPoint = [
        e.nativeEvent.touches[0].clientX - canvasRef.current.getBoundingClientRect().left, //canvasRef.current.offsetLeft,
        e.nativeEvent.touches[0].clientY - canvasRef.current.getBoundingClientRect().top, //canvasRef.current.offsetTop,
      ];
      setPoints([...points, newPoint]);
    }
  };

  const drawLine = (ctx, x0, y0, x1, y1) => {
    ctx.beginPath();
    ctx.moveTo(x0, y0);
    ctx.lineTo(x1, y1);
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
    setCheckCounter((prevState) => prevState + 1);
  };

  const clearCanvas = () => {
    setCheckCounter(0);
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    if (props.onTouchEnd) {
      props.onTouchEnd(checkCounter, -1);
    }
  };

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");

    points.forEach((point, index) => {
      //setTekst(tekst.concat("-", index));

      if (index > 0) {
        const [x0, y0] = points[index - 1];
        const [x1, y1] = point;

        drawLine(ctx, x0, y0, x1, y1);
        // todo: zapisać całą historię z sekwencją czasową podpisu
        // todo: siła nacisku może nie być rejestrowana
      }
    });

    // eslint-disable-next-line
  }, [points]);

  const returnSignatureAsBlob = () => {
    const canvas = document.getElementById(canvasId);
    const blob = canvas.toDataURL("image/png");
    return blob;
  };

  return (
    <Stack
      style={{
        overflow: "hidden",
        // touchAction: "none", // blokowanie poruszania stroną
        margin: 0,
        padding: 0,
        paddingTop: "12px",
        //background: "green",
      }}
    >
      {/* {checkCounter} */}
      <Typography id={"title"} variant="h6" component="h1" sx={{ pt: 1, pb: 0, fontWeight: "bold" }}>
        {props.title ? props.title : "Elektroniczny podpis odręczny"}
      </Typography>
      <Typography id={"subtitle"} variant="h7" component="h4" sx={{ pt: 0, pb: 0, fontWeight: "normal" }}>
        {props.subtitle ? props.subtitle : ""}
      </Typography>
      <Stack
        style={{
          overflow: "hidden",
          touchAction: "none", // blokowanie poruszania stroną
          margin: 0,
          padding: 0,
          paddingTop: "12px",
          //background: "red",
          width: "".concat(180 * scale + 5, "px"),
          height: "".concat(70 * scale + 15, "px"),
        }}
      >
        <canvas
          id={canvasId}
          overflow="hidden"
          ref={canvasRef}
          width={"".concat(180 * scale, "px")}
          height={"".concat(70 * scale, "px")}
          style={{
            border: "1px solid #000000",
            background: "white",
            margin: 0,
            padding: 0,
            marginTop: props.marginTop ? props.marginTop : "0px",
          }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ pt: 0, justifyContent: "left" }}>
        <Button variant="text" size="medium" startIcon={<DeleteForeverOutlinedIcon />} onClick={clearCanvas}>
          {"Wyczyść"}
        </Button>
        {/*"Force: "*/}
        {/*force*/}
      </Stack>
    </Stack>
  );
};

export default DrawingCanvas;
