import { useEffect, useState } from "react";
import { Stack, TextField, IconButton, Typography } from "@mui/material";
import * as IntermediationContractBinaryApi from "./IntermediationContractBinaryApi";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const File = (props) => {
  const id = props.id;

  const [fileName, setFileName] = useState("");
  const [label, setLabel] = useState("");
  const [editDisabled, setEditDisabled] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);

  const getFileData = async () => {
    const file = await IntermediationContractBinaryApi.GetById(id);
    setFileName(file.fileName);
    setLabel(file.label);
  };

  useEffect(
    () => {
      getFileData();
    },
    // eslint-disable-next-line
    []
  );

  const handleEditClick = async () => {
    if (!editDisabled) {
      await IntermediationContractBinaryApi.Update(id, {
        label: label,
      });
    }
    setEditDisabled(!editDisabled);
  };

  const handleDeleteClick = () => {
    // todo: zadać pytanie: "Czy na pewno usunąć załącznik [name]?"
    IntermediationContractBinaryApi.Delete(id);
    setIsDeleted(true);
  };

  if (isDeleted) {
    return <Stack></Stack>;
  }

  return (
    <Stack>
      <Stack direction="row" spacing={1} sx={{ pt: 2, alignItems: "center" }}>
        <TextField
          size="small"
          label={"Nazwa załącznika"}
          id={"name"}
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          disabled={editDisabled}
          sx={{ width: "350px" }}
          required={false}
        />

        <IconButton aria-label="edit" size="medium" color={editDisabled ? "primary" : "warning"} onClick={handleEditClick}>
          {editDisabled && <ModeEditOutlinedIcon fontSize="inherit" />}
          {!editDisabled && <SaveOutlinedIcon fontSize="inherit" />}
        </IconButton>
        <IconButton aria-label="delete" size="medium" color="primary" onClick={handleDeleteClick}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Stack>
      <Typography style={{ marginLeft: "5px", fontStyle: "italic", color: "gray" }} variant="subtitle2">
        {fileName}
      </Typography>
    </Stack>
  );
};

export default File;
