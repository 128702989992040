import axios from "axios";
import * as api from "../../config/ApiConfig";
import * as session from "../../components/SessionStorage/SessionStorage";
import * as Token from "../../api/Token";
import * as UserApi from "./../../business/User/UserApi";

class PresentationProtocol {}

export const Delete = async (objectId) => {
  try {
    const response = await axios({
      method: "delete",
      url: api.url_PresentationProtocol_Delete,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: [objectId],
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_Delete, error);
  }
};

export const Create = async (presentationProtocol) => {
  try {
    let user = await UserApi.GetById(session.getUserId());

    const response = await axios({
      method: "post",
      url: api.url_PresentationProtocol_Create,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        createdBy: session.getUserId(),
        number: presentationProtocol.number,
        visitDate: presentationProtocol.visitDate,
        clientName: presentationProtocol.clientName,
        clientSurname: presentationProtocol.clientSurname,
        clientPesel: presentationProtocol.clientPesel,
        clientIdentityCard: presentationProtocol.clientIdentityCard,
        clientPhone: presentationProtocol.clientPhone,
        clientEmail: presentationProtocol.clientEmail,
        coviewerName: presentationProtocol.coviewerName,
        coviewerSurname: presentationProtocol.coviewerSurname,
        coviewerPesel: presentationProtocol.coviewerPesel,
        coviewerIdentityCard: presentationProtocol.coviewerIdentityCard,
        consentPrivacy: presentationProtocol.consentPrivacy,
        consentMarketing: presentationProtocol.consentMarketing,
        consentTrade: presentationProtocol.consentTrade,
        agentId: presentationProtocol.agentId,
        agentSentAt: presentationProtocol.agentSentAt,
        clientAcceptedAt: presentationProtocol.clientAcceptedAt,
        status: presentationProtocol.status,
        addrStreet: presentationProtocol.addrStreet,
        addrStreetNumber: presentationProtocol.addrStreetNumber,
        addrLocaleNumber: presentationProtocol.addrLocaleNumber,
        addrPostcode: presentationProtocol.addrPostcode,
        addrCity: presentationProtocol.addrCity,
        addrCountry: presentationProtocol.addrCountry,
        clientIdType: presentationProtocol.clientIdType,
        clientIdNumber: presentationProtocol.clientIdNumber,
        coviewerIdType: presentationProtocol.coviewerIdType,
        coviewerIdNumber: presentationProtocol.coviewerIdNumber,
        remark: presentationProtocol.remark,
        branchId: user.branchId,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_Create, error);
  }
};

export const Update = async (objectId, presentationProtocol) => {
  try {
    let bodyData = {
      updatedBy: session.getUserId(),
      number: presentationProtocol.number,
      visitDate: presentationProtocol.visitDate,
      clientName: presentationProtocol.clientName,
      clientSurname: presentationProtocol.clientSurname,
      clientPesel: presentationProtocol.clientPesel,
      clientIdentityCard: presentationProtocol.clientIdentityCard,
      clientPhone: presentationProtocol.clientPhone,
      clientEmail: presentationProtocol.clientEmail,
      coviewerName: presentationProtocol.coviewerName,
      coviewerSurname: presentationProtocol.coviewerSurname,
      coviewerPesel: presentationProtocol.coviewerPesel,
      coviewerIdentityCard: presentationProtocol.coviewerIdentityCard,
      consentPrivacy: presentationProtocol.consentPrivacy,
      consentMarketing: presentationProtocol.consentMarketing,
      consentTrade: presentationProtocol.consentTrade,
      agentId: presentationProtocol.agentId,
      agentSentAt: presentationProtocol.agentSentAt,
      clientResponseDt: presentationProtocol.clientResponseDt,
      clientAcceptation: presentationProtocol.clientAcceptation,
      status: presentationProtocol.status,
      addrStreet: presentationProtocol.addrStreet,
      addrStreetNumber: presentationProtocol.addrStreetNumber,
      addrLocaleNumber: presentationProtocol.addrLocaleNumber,
      addrPostcode: presentationProtocol.addrPostcode,
      addrCity: presentationProtocol.addrCity,
      addrCountry: presentationProtocol.addrCountry,
      pdfDocument: presentationProtocol.pdfDocument,
      clientIdType: presentationProtocol.clientIdType,
      clientIdNumber: presentationProtocol.clientIdNumber,
      coviewerIdType: presentationProtocol.coviewerIdType,
      coviewerIdNumber: presentationProtocol.coviewerIdNumber,
      remark: presentationProtocol.remark,
    };

    const response = await axios.post(api.url_PresentationProtocol_Update, bodyData, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_Update, error);
  }
};

export const GetById = async (objectId) => {
  try {
    const response = await axios.get(api.url_PresentationProtocol_GetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    const presentationProtocol = new PresentationProtocol();

    presentationProtocol.id = response.data.id;
    presentationProtocol.number = response.data.number;
    presentationProtocol.visitDate = response.data.visitDate;
    presentationProtocol.clientName = response.data.clientName;
    presentationProtocol.clientSurname = response.data.clientSurname;
    presentationProtocol.clientPesel = response.data.clientPesel;
    presentationProtocol.clientIdentityCard = response.data.clientIdentityCard;
    presentationProtocol.clientPhone = response.data.clientPhone;
    presentationProtocol.clientEmail = response.data.clientEmail;
    presentationProtocol.coviewerName = response.data.coviewerName;
    presentationProtocol.coviewerSurname = response.data.coviewerSurname;
    presentationProtocol.coviewerPesel = response.data.coviewerPesel;
    presentationProtocol.coviewerIdentityCard = response.data.coviewerIdentityCard;
    presentationProtocol.consentPrivacy = response.data.consentPrivacy;
    presentationProtocol.consentMarketing = response.data.consentMarketing;
    presentationProtocol.consentTrade = response.data.consentTrade;
    presentationProtocol.agentId = response.data.agentId;
    presentationProtocol.agentSentAt = response.data.agentSentAt;
    presentationProtocol.clientAcceptedAt = response.data.clientAcceptedAt;
    presentationProtocol.status = response.data.status;
    presentationProtocol.addrStreet = response.data.addrStreet;
    presentationProtocol.addrStreetNumber = response.data.addrStreetNumber;
    presentationProtocol.addrLocaleNumber = response.data.addrLocaleNumber;
    presentationProtocol.addrPostcode = response.data.addrPostcode;
    presentationProtocol.addrCity = response.data.addrCity;
    presentationProtocol.addrCountry = response.data.addrCountry;
    presentationProtocol.clientIdType = response.data.clientIdType;
    presentationProtocol.clientIdNumber = response.data.clientIdNumber;
    presentationProtocol.coviewerIdType = response.data.coviewerIdType;
    presentationProtocol.coviewerIdNumber = response.data.coviewerIdNumber;
    presentationProtocol.remark = response.data.remark;
    presentationProtocol.consentPrivacy = response.data.consentPrivacy;
    presentationProtocol.consentMarketing = response.data.consentMarketing;
    presentationProtocol.consentTrade = response.data.consentTrade;
    presentationProtocol.consentMediation = response.data.consentMediation;

    return presentationProtocol;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_GetById, error);
  }
};

export const GetAllFiltering = async (filter, retry) => {
  try {
    const response = await axios.get(api.url_PresentationProtocol_GetAllFiltering, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: !filter
        ? { qUserId: session.getUserId() }
        : {
            qUserId: session.getUserId(),
            clientName: filter.clientName,
            clientSurname: filter.clientSurname,
            visitDateFrom: filter.visitDateFrom,
            visitDateTo: filter.visitDateTo,
            clientPesel: filter.clientPesel,
            clientPhone: filter.clientPhone,
            status: filter.status,
          },
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 403 && !retry) {
      await Token.isTokenValid();
      return await GetAllFiltering(filter, true);
    } else if (error.response.status === 403) {
      throw new Error("403");
    }

    throw new Error("Error, ".concat(api.url_PresentationProtocol_GetAllFiltering, error.message));
  }
};

export const GetByIdUnauthorized = async (objectId, sessionKey) => {
  try {
    const response = await axios.get(api.url_PresentationProtocol_GetByIdUnauthorized, {
      params: { id: objectId, sessionKey: sessionKey },
    });

    const presentationProtocol = {};

    presentationProtocol.id = response.data.id;
    presentationProtocol.number = response.data.number;
    presentationProtocol.visitDate = response.data.visitDate;
    presentationProtocol.clientName = response.data.clientName;
    presentationProtocol.clientSurname = response.data.clientSurname;
    presentationProtocol.clientPesel = response.data.clientPesel;
    presentationProtocol.clientIdentityCard = response.data.clientIdentityCard;
    presentationProtocol.clientPhone = response.data.clientPhone;
    presentationProtocol.clientEmail = response.data.clientEmail;
    presentationProtocol.coviewerName = response.data.coviewerName;
    presentationProtocol.coviewerSurname = response.data.coviewerSurname;
    presentationProtocol.coviewerPesel = response.data.coviewerPesel;
    presentationProtocol.coviewerIdentityCard = response.data.coviewerIdentityCard;
    presentationProtocol.consentPrivacy = response.data.consentPrivacy;
    presentationProtocol.consentMarketing = response.data.consentMarketing;
    presentationProtocol.consentTrade = response.data.consentTrade;
    presentationProtocol.agentId = response.data.agentId;
    presentationProtocol.agentSentAt = response.data.agentSentAt;
    presentationProtocol.clientAcceptedAt = response.data.clientAcceptedAt;
    presentationProtocol.status = response.data.status;
    presentationProtocol.addrStreet = response.data.addrStreet;
    presentationProtocol.addrStreetNumber = response.data.addrStreetNumber;
    presentationProtocol.addrLocaleNumber = response.data.addrLocaleNumber;
    presentationProtocol.addrPostcode = response.data.addrPostcode;
    presentationProtocol.addrCity = response.data.addrCity;
    presentationProtocol.addrCountry = response.data.addrCountry;
    presentationProtocol.UserAgent = {};
    presentationProtocol.UserAgent.name = response.data.UserAgent.name;
    presentationProtocol.UserAgent.surname = response.data.UserAgent.surname;
    presentationProtocol.clientIdType = response.data.clientIdType;
    presentationProtocol.clientIdNumber = response.data.clientIdNumber;
    presentationProtocol.coviewerIdType = response.data.coviewerIdType;
    presentationProtocol.coviewerIdNumber = response.data.coviewerIdNumber;
    presentationProtocol.Branch = {};
    presentationProtocol.Branch.name = response.data.Branch.name;
    presentationProtocol.Branch.addrStreet = response.data.Branch.addrStreet;
    presentationProtocol.Branch.addrStreetNumber = response.data.Branch.addrStreetNumber;
    presentationProtocol.Branch.addrLocaleNumber = response.data.Branch.addrLocaleNumber;
    presentationProtocol.Branch.addrPostcode = response.data.Branch.addrPostcode;
    presentationProtocol.Branch.addrCity = response.data.Branch.addrCity;
    presentationProtocol.Branch.addrCountry = response.data.Branch.addrCountry;
    presentationProtocol.Branch.email = response.data.Branch.email;
    presentationProtocol.Branch.phone = response.data.Branch.phone;

    return presentationProtocol;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_GetByIdUnauthorized, error);
  }
};

export const UpdateUnauthorized = async (objectId, sessionKey, presentationProtocol) => {
  try {
    let bodyData = {
      clientEmail: presentationProtocol.clientEmail,
      consentPrivacy: presentationProtocol.consentPrivacy,
      consentMediation: presentationProtocol.consentMediation,
      consentMarketing: presentationProtocol.consentMarketing,
      consentTrade: presentationProtocol.consentTrade,
      pdfDocument: presentationProtocol.pdfDocument,
      status: presentationProtocol.status,
      clientAcceptedAt: presentationProtocol.clientAcceptedAt,
    };

    const response = await axios({
      method: "post",
      url: api.url_PresentationProtocol_UpdateUnauthorized,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        id: objectId,
        sessionKey: sessionKey,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_UpdateUnauthorized, error);
  }
};

export const GetBlobById = async (objectId) => {
  try {
    const response = await axios({
      method: "get",
      url: api.url_PresentationProtocol_GetBlobById,
      params: { id: objectId },
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_GetBlobById, error);
  }
};

export const GetStatusById = async (objectId) => {
  try {
    const response = await axios.get(api.url_PresentationProtocol_GetStatusById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    let presentationProtocol = {};
    presentationProtocol.status = response.data.status;

    return presentationProtocol;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_GetStatusById, error);
  }
};

export const GetMonitorById = async (objectId) => {
  try {
    const response = await axios.get(api.url_PresentationProtocol_MonitorById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    let presentationProtocol = {};
    presentationProtocol.status = response.data.status;
    presentationProtocol.clientEmail = response.data.clientEmail;

    return presentationProtocol;
  } catch (error) {
    console.error("API method error: ", api.url_PresentationProtocol_MonitorById, error);
  }
};
