import * as UserApi from "../business/User/UserApi";
import * as session from "./../components/SessionStorage/SessionStorage";

export const isTokenValid = async () => {
  const login = session.getLogin();
  const token = session.getToken();

  const result = await UserApi.VerifyToken(null, token);

  if (result.valid === true) {
    return true;
  }

  if (result.expired) {
    try {
      const refreshToken = session.getRefreshToken();
      const resRefresh = await UserApi.RefreshToken(login, refreshToken);
      if (!resRefresh.token) {
        return false;
      }

      session.setLogin(login);
      session.setToken(resRefresh.token);
      session.setRefreshToken(resRefresh.refreshToken);
      session.setUserId(resRefresh.userId);

      return true;
    } catch (error) {
      return false;
    }
  }

  return false;
};
