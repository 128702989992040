import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Token from "./../../api/Token";
import * as RoutePath from "./../../config/NavigationRoute/RoutePath";

function NoPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const checkToken = async () => {
    const isTokenValid = await Token.isTokenValid();

    if (!isTokenValid) {
      navigate(RoutePath.Login);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div
      spacing={2}
      style={{
        backgroundImage: `url('/assets/Graphics/client/background.jpg')`,
        backgroundSize: "100% 100%",
        minHeight: "100vh",
      }}
    >
      <br />
      <h1 style={{ margin: 20, padding: 0 }}>Strona o podanym adresie nie istnieje!</h1>
      <br />
      <Link to="/">Powrót do strony głównej</Link>
    </div>
  );
}

export default NoPage;
