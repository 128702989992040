import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Navigator from "./Navigator";
import Content from "./Content";
import Header from "./Header";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import { alpha } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        Michał Marcinkowski
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
    snackbarerror: {
      main: alpha("#d32f2f", 0.7),
    },
    snackbarsuccess: {
      main: alpha("#689f38", 0.7),
    },
    snackbarwarning: {
      main: alpha("#ef6c00", 0.7),
    },
    snackbarinfo: {
      main: alpha("#2196f3", 0.6),
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#9fa8da",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function Paperbase(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("lg"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (props.notification && props.notification.text && props.notification.text !== "") {
      handleNotiticationOpen(props.notification);
    } else {
      handleNotifiactionClose();
    }
  }, [props.notification]);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [notification, setNotification] = React.useState("");

  const handleNotiticationOpen = (notification) => {
    setNotification(notification);
    setSnackbarOpen(true);
  };

  const handleNotifiactionClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleNotifiactionClose}>
        {/* UNDO */}
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleNotifiactionClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />
        <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}>
          {isSmUp ? null : (
            <Navigator PaperProps={{ style: { width: drawerWidth } }} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} />
          )}

          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { lg: "block", xs: "none" } }}
            activepositioncode={props.activepositioncode}
          />
        </Box>
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header
            onDrawerToggle={handleDrawerToggle}
            title={props.title}
            subtitle={props.subtitle}
            tabActiveIndex={props.tabActiveIndex}
            onTabChange={props.onTabChange}
            tab0Label={props.tab0Label}
            tab1Label={props.tab1Label}
            tab2Label={props.tab2Label}
            tab3Label={props.tab3Label}
          />
          <Box component="main" sx={{ flex: 1, py: 3, px: 4, bgcolor: "#eaeff1" }}>
            <div>{props.operationPanel}</div>
            <Content
              tabActiveIndex={props.tabActiveIndex}
              tab0Title={props.tab0Title}
              tab0Content={props.tab0Content}
              tab1Title={props.tab1Title}
              tab1Content={props.tab1Content}
              tab2Title={props.tab2Title}
              tab2Content={props.tab2Content}
              tab3Title={props.tab3Title}
              tab3Content={props.tab3Content}
            />
          </Box>
          <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
            <Copyright />
          </Box>
          <Box>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2600}
              onClose={handleNotifiactionClose}
              //message={notification.text}
              action={action}
              sx={{ width: "60%" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Alert
                style={{ whiteSpace: "pre-wrap" }}
                color={"snackbar".concat(notification.severity)}
                onClose={handleNotifiactionClose}
                severity={notification.severity}
              >
                {notification.text}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
