import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

// Register font
Font.register({
  family: "Roboto",
  fonts: [
    { src: "/assets/Fonts/Roboto/Roboto-Regular.ttf" },
    { src: "/assets/Fonts/Roboto/Roboto-Bold.ttf" },
    {
      src: "/assets/Fonts/Roboto/Roboto-Italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "/assets/Fonts/Roboto/Roboto-BoldItalic.ttf",
      fontStyle: "italic",
      fontWeight: "bold",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "3%",
    marginLeft: "15px",
    fontSize: "11px",
  },
  parameter: {
    fontWeight: "bold",
    color: "#163058",
  },
  title: {
    marginTop: "20px",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "12px",
  },
  person: {
    flexDirection: "row",
    marginBottom: "10px",
  },
  descr: {
    marginBottom: "7px",
    fontSize: "9px",
    marginRight: "20px",
  },
  descrBold: {
    fontWeight: "bold",
  },
  square: {
    width: 10, // Adjust width and height as needed
    height: 10,
    borderWidth: 0, // Set border width to 0 for no border
    border: "1px solid black",
  },
});

const stylesTable = StyleSheet.create({
  table: {
    display: "table",
    width: "90%",
    marginTop: "30px",
  },
  rowHeader: {
    flexDirection: "row",
  },
  row: {
    flexDirection: "row",
    height: "50px",
    fontWeight: "bold",
    color: "#163058",
  },
  cell: {
    display: "table-cell",
    margin: 0,
    padding: 5,
    width: "20%",
    whiteSpace: "pre-line",
    border: "1px solid #000",
    marginLeft: "-1px",
    marginTop: "-1px",
  },
  column1: {
    width: "15%",
  },
  column2: {
    width: "11%",
  },
  column3: {
    width: "34%",
  },
  column4: {
    width: "25%",
  },
});

const Printing = (props) => {
  const withPresentation = props.withPresentation;
  const clientName = props.clientName;
  const clientSurname = props.clientSurname;
  // const clientPesel = props.clientPesel;
  // const clientIdType = props.clientIdType;
  // const clientIdNumber = props.clientIdNumber;
  // const coviewerName = props.coviewerName;
  // const coviewerSurname = props.coviewerSurname;
  // const coviewerPesel = props.coviewerPesel;
  // const coviewerIdType = props.coviewerIdType;
  // const coviewerIdNumber = props.coviewerIdNumber;
  const agentName = props.agentName;
  const agentSurname = props.agentSurname;

  const tableRows = [
    {
      date: props.visitDate,
      hour: props.visitHour,
      address: props.visitAddress,
      client: props.clientName + " " + props.clientSurname,
      signature: props.clientSignature,
    },
    // { date: "", hour: "", address: "", client: "", signature: "" },
    // { date: "", hour: "", address: "", client: "", signature: "" },
    // { date: "", hour: "", address: "", client: "", signature: "" },
  ];

  const renderTable = () => (
    <View style={stylesTable.table}>
      <View style={stylesTable.rowHeader}>
        <View style={[stylesTable.cell, stylesTable.column1]}>
          <Text>Data</Text>
        </View>
        <View style={[stylesTable.cell, stylesTable.column2]}>
          <Text>Godzina</Text>
        </View>
        <View style={[stylesTable.cell, stylesTable.column3]}>
          <Text>Adres nieruchomości</Text>
        </View>
        {/* <View style={[stylesTable.cell, stylesTable.column4]}>
          <Text>Imię i nazwisko Klienta</Text>
        </View> */}
        {/* <View style={[stylesTable.cell]}>
          <Text>Podpis Klienta</Text>
        </View> */}
      </View>

      {tableRows.map((obj, index) => (
        <View style={stylesTable.row} key={"".concat("tabRows", index)}>
          <View style={[stylesTable.cell, stylesTable.column1]}>
            <Text>{withPresentation ? obj.date : ""}</Text>
          </View>
          <View style={[stylesTable.cell, stylesTable.column2]}>
            <Text>{withPresentation ? obj.hour : ""}</Text>
          </View>
          <View style={[stylesTable.cell, stylesTable.column3]}>
            <Text>{withPresentation ? obj.address : ""}</Text>
          </View>
          {/* <View style={[stylesTable.cell, stylesTable.column4]}>
            <Text>{obj.client}</Text>
          </View> */}
          {/* <View style={stylesTable.cell}>
            <Text>{obj.signature}</Text>
          </View> */}
        </View>
      ))}
    </View>
  );

  return (
    <View break>
      <View style={{ marginTop: "-50px" }}>
        <View style={styles.title}>
          <Text>{"Załącznik Nr 5"}</Text>
          <Text>{"PROTOKÓŁ Z PREZENTACJI NIERUCHOMOŚCI"}</Text>
        </View>
        <View style={styles.person}>
          <View style={{ width: "65%" }}>
            <Text>
              {"Imię i nazwisko Klienta: "}
              <Text style={styles.parameter}> {withPresentation ? clientName + " " + clientSurname : ""}</Text>
            </Text>
          </View>
          {/* <View style={{ width: "35%" }}>{renderClientIdentificationDoc()}</View> */}
        </View>
        {/* <View style={styles.person}>
          <View style={{ width: "65%" }}>
            <Text>
              {"Imię i nazwisko Osoby współoglądającej: "}
              <Text style={styles.parameter}> {coviewerName + " " + coviewerSurname}</Text>
            </Text>
          </View>
          <View style={{ width: "35%" }}>{renderCoviewerIdentificationDoc()}</View>
        </View> */}
        <View style={styles.person}>
          <View>
            <Text>
              {"Imię i nazwisko Pośrednika: "}
              <Text style={styles.parameter}> {withPresentation ? agentName + " " + agentSurname : ""}</Text>
            </Text>
          </View>
        </View>
        {renderTable()}
      </View>
    </View>
  );
};

export default Printing;
