import axios from "axios";
import * as api from "../../config/ApiConfig";
import * as session from "./../SessionStorage/SessionStorage";

export const Send = async (sms) => {
  try {
    const response = await axios({
      method: "post",
      url: api.url_SMS_Send,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        to: sms.to,
        msg: sms.msg,
        clearPolish: 1,
        test: 0,
      },
    });
    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_SMS_Send, error);
  }
};
