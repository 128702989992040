import axios from "axios";
import * as api from "../../../config/ApiConfig";
import * as session from "../../../components/SessionStorage/SessionStorage";

class IntermediationContractPerson {}

export const Delete = async (objectId) => {
  try {
    const response = await axios({
      method: "delete",
      url: api.url_IntermediationContractPerson_Delete,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: [objectId],
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContractPerson_Delete, error);
  }
};

export const Create = async (object) => {
  try {
    const response = await axios({
      method: "post",
      url: api.url_IntermediationContractPerson_Create,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        createdBy: session.getUserId(),
        docId: object.docId,
        clientType: object.clientType,
        clientName: object.clientName,
        clientSurname: object.clientSurname,
        clientAddrStreet: object.clientAddrStreet,
        clientAddrStreetNumber: object.clientAddrStreetNumber,
        clientAddrLocaleNumber: object.clientAddrLocaleNumber,
        clientAddrPostcode: object.clientAddrPostcode,
        clientAddrCity: object.clientAddrCity,
        clientAddrCountry: object.clientAddrCountry,
        clientPesel: object.clientPesel,
        clientIdType: object.clientIdType,
        clientIdNumber: object.clientIdNumber,
        clientPhone: object.clientPhone,
        clientEmail: object.clientEmail,
        personName: object.personName,
        personSurname: object.personSurname,
        personPhone: object.personPhone,
        personEmail: object.personEmail,
        personAddrStreet: object.personAddrStreet,
        personAddrStreetNumber: object.personAddrStreetNumber,
        personAddrLocaleNumber: object.personAddrLocaleNumber,
        personAddrPostcode: object.personAddrPostcode,
        personAddrCity: object.personAddrCity,
        personAddrCountry: object.personAddrCountry,
        personPesel: object.personPesel,
        personIdType: object.personIdType,
        personIdNumber: object.personIdNumber,
        companyName: object.companyName,
        companyNIP: object.companyNIP,
        companyKRS: object.companyKRS,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContractPerson_Create, error);
  }
};

export const Update = async (objectId, object) => {
  try {
    let bodyData = {
      updatedBy: session.getUserId(),
      clientType: object.clientType,
      clientName: object.clientName,
      clientSurname: object.clientSurname,
      clientAddrStreet: object.clientAddrStreet,
      clientAddrStreetNumber: object.clientAddrStreetNumber,
      clientAddrLocaleNumber: object.clientAddrLocaleNumber,
      clientAddrPostcode: object.clientAddrPostcode,
      clientAddrCity: object.clientAddrCity,
      clientAddrCountry: object.clientAddrCountry,
      clientPesel: object.clientPesel,
      clientIdType: object.clientIdType,
      clientIdNumber: object.clientIdNumber,
      clientPhone: object.clientPhone,
      clientEmail: object.clientEmail,
      personName: object.personName,
      personSurname: object.personSurname,
      personPhone: object.personPhone,
      personEmail: object.personEmail,
      personAddrStreet: object.personAddrStreet,
      personAddrStreetNumber: object.personAddrStreetNumber,
      personAddrLocaleNumber: object.personAddrLocaleNumber,
      personAddrPostcode: object.personAddrPostcode,
      personAddrCity: object.personAddrCity,
      personAddrCountry: object.personAddrCountry,
      personPesel: object.personPesel,
      personIdType: object.personIdType,
      personIdNumber: object.personIdNumber,
      companyName: object.companyName,
      companyNIP: object.companyNIP,
      companyKRS: object.companyKRS,
    };

    const response = await axios({
      method: "post",
      url: api.url_IntermediationContractPerson_Update,
      headers: {
        "Content-Type": "multipart/form-data",
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.log(error);
    console.error("API method error: ", api.url_IntermediationContractPerson_Update, error);
  }
};

export const GetById = async (objectId) => {
  try {
    const response = await axios.get(api.url_IntermediationContractPerson_GetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    const object = new IntermediationContractPerson();

    object.id = response.data.id;
    object.createdBy = response.data.createdBy;
    object.updatedBy = response.data.updatedBy;

    object.clientType = response.data.clientType;
    object.clientName = response.data.clientName;
    object.clientSurname = response.data.clientSurname;
    object.clientAddrStreet = response.data.clientAddrStreet;
    object.clientAddrStreetNumber = response.data.clientAddrStreetNumber;
    object.clientAddrLocaleNumber = response.data.clientAddrLocaleNumber;
    object.clientAddrPostcode = response.data.clientAddrPostcode;
    object.clientAddrCity = response.data.clientAddrCity;
    object.clientAddrCountry = response.data.clientAddrCountry;
    object.clientPesel = response.data.clientPesel;
    object.clientIdType = response.data.clientIdType;
    object.clientIdNumber = response.data.clientIdNumber;
    object.clientPhone = response.data.clientPhone;
    object.clientEmail = response.data.clientEmail;

    object.personName = response.data.personName;
    object.personSurname = response.data.personSurname;
    object.personPhone = response.data.personPhone;
    object.personEmail = response.data.personEmail;
    object.personAddrStreet = response.data.personAddrStreet;
    object.personAddrStreetNumber = response.data.personAddrStreetNumber;
    object.personAddrLocaleNumber = response.data.personAddrLocaleNumber;
    object.personAddrPostcode = response.data.personAddrPostcode;
    object.personAddrCity = response.data.personAddrCity;
    object.personAddrCountry = response.data.personAddrCountry;
    object.personPesel = response.data.personPesel;
    object.personIdType = response.data.personIdType;
    object.personIdNumber = response.data.personIdNumber;

    object.companyName = response.data.companyName;
    object.companyNIP = response.data.companyNIP;
    object.companyKRS = response.data.companyKRS;

    return object;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContractPerson_GetById, error);
  }
};

export const GetByDocId = async (docId) => {
  try {
    const response = await axios.get(api.url_IntermediationContractPerson_GetByDocId, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: docId },
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContractPerson_GetByDocId, error);
  }
};

export const GetByDocIdUnauthorized = async (docId, sessionKey) => {
  try {
    const response = await axios.get(api.url_IntermediationContractPerson_GetByDocIdUnauthorized, {
      // headers: {
      //   login: session.getLogin(),
      //   token: session.getToken(),
      // },
      params: { id: docId, sessionKey: sessionKey },
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContractPerson_GetByDocIdUnauthorized, error);
  }
};
