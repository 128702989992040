import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Stack, Button } from "@mui/material";
import { Accordion, AccordionActions, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as dict from "../../../config/Dictionary";
import { phoneValidator, emailValidator } from "../../../utils/validator";
import * as ContractPurchaseCoownerApi from "./ContractPurchaseCoownerApi";
import Person from "../../../components/LegalEntity/Person";
import BusinessIndividual from "../../../components/LegalEntity/BusinessIndividual";
import Company from "../../../components/LegalEntity/Company";

const ContractPurchaseCoowner = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      validateCoowner: validateForm,
      saveCoowner: saveModeEdit,
    };
  });

  const objectId = props.id;
  const mode = props.mode;
  const isModePreview = props.mode === dict.FormMode.preview ? true : false;
  const docStatus = props.docStatus;

  // Form fields
  const [clientType, setClientType] = useState(dict.ClientType.person);

  const [companyName, setCompanyName] = useState("");
  const [companyNIP, setCompanyNIP] = useState("");
  const [companyKRS, setCompanyKRS] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [clientAddrStreet, setClientAddrStreet] = useState("");
  const [clientAddrStreetNumber, setClientAddrStreetNumber] = useState("");
  const [clientAddrLocaleNumber, setClientAddrLocaleNumber] = useState("");
  const [clientAddrPostcode, setClientAddrPostcode] = useState("");
  const [clientAddrCity, setClientAddrCity] = useState("");
  const [clientAddrCountry, setClientAddrCountry] = useState("Polska");
  const [clientPesel, setClientPesel] = useState("");
  const [clientIdType, setClientIdType] = useState("");
  const [clientIdNumber, setClientIdNumber] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientEmail, setClientEmail] = useState("");

  const [principalName, setPrincipalName] = useState("");
  const [principalSurname, setPrincipalSurname] = useState("");
  const [principalPhone, setPrincipalPhone] = useState("");
  const [principalEmail, setPrincipalEmail] = useState("");
  const [principalAddrStreet, setPrincipalAddrStreet] = useState("");
  const [principalAddrStreetNumber, setPrincipalAddrStreetNumber] = useState("");
  const [principalAddrLocaleNumber, setPrincipalAddrLocaleNumber] = useState("");
  const [principalAddrPostcode, setPrincipalAddrPostcode] = useState("");
  const [principalAddrCity, setPrincipalAddrCity] = useState("");
  const [principalAddrCountry, setPrincipalAddrCountry] = useState("Polska");
  const [principalPesel, setPrincipalPesel] = useState("");
  const [principalIdType, setPrincipalIdType] = useState("");
  const [principalIdNumber, setPrincipalIdNumber] = useState("");

  // Combo list
  const [clientIdTypeCombo, setClientIdTypeCombo] = useState(dict.IdentityDocumentType[0]);
  const [principalIdTypeCombo, setPrincipalIdTypeCombo] = useState(dict.IdentityDocumentType[0]);

  // Form fields error messages
  const [hasErrorCompanyName, setHasErrorCompanyName] = useState(false);
  const [hasErrorCompanyNIP, setHasErrorCompanyNIP] = useState(false);
  const [hasErrorCompanyKRS, setHasErrorCompanyKRS] = useState(false);

  const [hasErrorClientName, setHasErrorClientName] = useState(false);
  const [hasErrorClientSurname, setHasErrorClientSurname] = useState(false);
  const [hasErrorClientPhone, setHasErrorClientPhone] = useState(false);
  const [hasErrorClientEmail, setHasErrorClientEmail] = useState(false);
  const [hasErrorClientAddrCountry, setHasErrorClientAddrCountry] = useState(false);
  const [hasErrorClientPesel, setHasErrorClientPesel] = useState(false);
  const [hasErrorClientIdType, setHasErrorClientIdType] = useState(false);
  const [hasErrorClientIdNumber, setHasErrorClientIdNumber] = useState(false);
  const [hasErrorClientAddrStreet, setHasErrorClientAddrStreet] = useState(false);
  const [hasErrorClientAddrStreetNumber, setHasErrorClientAddrStreetNumber] = useState(false);
  const [hasErrorClientAddrCity, setHasErrorClientAddrCity] = useState(false);

  const [hasErrorRepresentativeName, setHasErrorRepresentativeName] = useState(false);
  const [hasErrorRepresentativeSurname, setHasErrorRepresentativeSurname] = useState(false);
  const [hasErrorRepresentativePhone, setHasErrorRepresentativePhone] = useState(false);
  const [hasErrorRepresentativeEmail, setHasErrorRepresentativeEmail] = useState(false);
  const [hasErrorRepresentativeAddrStreet, setHasErrorRepresentativeAddrStreet] = useState(false);
  const [hasErrorRepresentativeAddrStreetNumber, setHasErrorRepresentativeAddrStreetNumber] = useState(false);
  const [hasErrorRepresentativeAddrCity, setHasErrorRepresentativeAddrCity] = useState(false);
  const [hasErrorRepresentativeAddrCountry, setHasErrorRepresentativeAddrCountry] = useState(false);
  const [hasErrorRepresentativePesel, setHasErrorRepresentativePesel] = useState(false);
  const [hasErrorRepresentativeIdType, setHasErrorRepresentativeIdType] = useState(false);
  const [hasErrorRepresentativeIdNumber, setHasErrorRepresentativeIdNumber] = useState(false);

  // Sections
  // eslint-disable-next-line
  const [hasErrorSectionClient, setHasErrorSectionClient] = useState(false);
  // eslint-disable-next-line
  const [hasErrorSectionRepresentative, setHasErrorSectionRepresentative] = useState(false);

  const setFieldsDefault = async () => {};

  const setFields = async (objId) => {
    try {
      const object = await ContractPurchaseCoownerApi.GetById(objId);

      setClientType(object.clientType ? object.clientType : dict.ClientType.person);

      setCompanyName(object.companyName ? object.companyName : "");
      setCompanyNIP(object.companyNIP ? object.companyNIP : "");
      setCompanyKRS(object.companyKRS ? object.companyKRS : "");

      setClientName(object.clientName ? object.clientName : "");
      setClientSurname(object.clientSurname ? object.clientSurname : "");
      setClientAddrStreet(object.clientAddrStreet ? object.clientAddrStreet : "");
      setClientAddrStreetNumber(object.clientAddrStreetNumber ? object.clientAddrStreetNumber : "");
      setClientAddrLocaleNumber(object.clientAddrLocaleNumber ? object.clientAddrLocaleNumber : "");
      setClientAddrPostcode(object.clientAddrPostcode ? object.clientAddrPostcode : "");
      setClientAddrCity(object.clientAddrCity ? object.clientAddrCity : "");
      setClientAddrCountry(object.clientAddrCountry ? object.clientAddrCountry : "Polska");
      setClientPesel(object.clientPesel ? object.clientPesel : "");
      setClientIdType(object.clientIdType);
      if (object.clientIdType) {
        setClientIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === object.clientIdType)[0]);
      }
      setClientIdNumber(object.clientIdNumber ? object.clientIdNumber : "");
      setClientPhone(object.clientPhone ? object.clientPhone : "");
      setClientEmail(object.clientEmail ? object.clientEmail : "");

      setPrincipalName(object.principalName ? object.principalName : "");
      setPrincipalSurname(object.principalSurname ? object.principalSurname : "");
      setPrincipalPhone(object.principalPhone ? object.principalPhone : "");
      setPrincipalEmail(object.principalEmail ? object.principalEmail : "");
      setPrincipalAddrStreet(object.principalAddrStreet ? object.principalAddrStreet : "");
      setPrincipalAddrStreetNumber(object.principalAddrStreetNumber ? object.principalAddrStreetNumber : "");
      setPrincipalAddrLocaleNumber(object.principalAddrLocaleNumber ? object.principalAddrLocaleNumber : "");
      setPrincipalAddrPostcode(object.principalAddrPostcode ? object.principalAddrPostcode : "");
      setPrincipalAddrCity(object.principalAddrCity ? object.principalAddrCity : "");
      setPrincipalAddrCountry(object.principalAddrCountry ? object.principalAddrCountry : "Polska");
      setPrincipalPesel(object.principalPesel ? object.principalPesel : "");
      setPrincipalIdType(object.principalIdType);
      if (object.principalIdType) {
        setPrincipalIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === object.principalIdType)[0]);
      }
      setPrincipalIdNumber(object.principalIdNumber ? object.principalIdNumber : "");
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const setFieldsModePreview = async (objId) => {};

  useEffect(() => {
    if (mode === dict.FormMode.edit) {
      setFields(objectId);
    } else if (mode === dict.FormMode.preview) {
      setFields(objectId);
      setFieldsModePreview(objectId);
    } else if (mode === dict.FormMode.add) {
      setFieldsDefault();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode !== dict.FormMode.preview) {
      setHasErrorCompanyName(false);
      setHasErrorCompanyNIP(false);
      setHasErrorCompanyKRS(false);

      setHasErrorClientName(false);
      setHasErrorClientSurname(false);
      setHasErrorClientPhone(false);
      setHasErrorClientEmail(false);
      setHasErrorClientAddrCountry(false);
      setHasErrorClientPesel(false);
      setHasErrorClientIdType(false);
      setHasErrorClientIdNumber(false);
      setHasErrorClientAddrStreet(false);
      setHasErrorClientAddrStreetNumber(false);
      setHasErrorClientAddrCity(false);

      setHasErrorRepresentativeName(false);
      setHasErrorRepresentativeSurname(false);
      setHasErrorRepresentativeAddrStreet(false);
      setHasErrorRepresentativeAddrStreetNumber(false);
      setHasErrorRepresentativeAddrCity(false);
      setHasErrorRepresentativeAddrCountry(false);
      setHasErrorRepresentativePesel(false);
      setHasErrorRepresentativeIdType(false);
      setHasErrorRepresentativeIdNumber(false);
      setHasErrorRepresentativePhone(false);
      setHasErrorRepresentativeEmail(false);
    }
    // eslint-disable-next-line
  }, [
    clientType,

    companyName,
    companyNIP,
    companyKRS,

    clientName,
    clientSurname,
    clientAddrStreet,
    clientAddrStreetNumber,
    clientAddrLocaleNumber,
    clientAddrPostcode,
    clientAddrCity,
    clientAddrCountry,
    //clientPhone,
    //clientEmail,
    clientPesel,
    clientIdType,
    clientIdNumber,

    principalName,
    principalSurname,
    principalAddrStreet,
    principalAddrStreetNumber,
    principalAddrLocaleNumber,
    principalAddrPostcode,
    principalAddrCity,
    principalAddrCountry,
    principalPesel,
    principalIdType,
    principalIdNumber,
    // principalPhone,
    // principalEmail,
  ]);

  const validateClientFormFieldsClientErrorClient = () => {
    let stakeholder = "klienta";

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrCountry) {
      setHasErrorClientAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
      setHasErrorClientPesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(clientIdType && clientIdNumber)) {
      if (!clientIdType) {
        setHasErrorClientIdType(true);
      }
      if (!clientIdNumber) {
        setHasErrorClientIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateClientFormFieldsPrincipalErrorClient = (isCoowner) => {
    let stakeholder = !isCoowner ? "klienta" : "pełnomocnika";

    if (!principalName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!principalSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!principalPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(principalPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!principalAddrCountry) {
      setHasErrorClientAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (principalAddrCountry.toUpperCase().trim() === "POLSKA" && !principalPesel) {
      setHasErrorClientPesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(principalIdType && principalIdNumber)) {
      if (!principalIdType) {
        setHasErrorClientIdType(true);
      }
      if (!principalIdNumber) {
        setHasErrorClientIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!principalAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!principalAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!principalAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateClientFormFieldsClientErrorPrincipal = (isCoowner) => {
    let stakeholder = !isCoowner ? "klienta" : "pełnomocnika";

    if (!clientName) {
      setHasErrorRepresentativeName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorRepresentativeSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorRepresentativePhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrCountry) {
      setHasErrorRepresentativeAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
      setHasErrorRepresentativePesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(clientIdType && clientIdNumber)) {
      if (!clientIdType) {
        setHasErrorRepresentativeIdType(true);
      }
      if (!clientIdNumber) {
        setHasErrorRepresentativeIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorRepresentativeAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorRepresentativeAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorRepresentativeAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateBusinessIndividualFormFieldsClientErrorClient = () => {
    let stakeholder = "klienta";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP) {
      setHasErrorCompanyNIP(true);
      props.onNotificationShow("NIP firmy jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    // if (!clientAddrCountry) {
    //   setHasErrorClientAddrCountry(true);
    //   props.onNotificationShow(
    //     "Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }
    // if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
    //   setHasErrorClientPesel(true);
    //   props.onNotificationShow(
    //     "Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    // if (!(clientIdType && clientIdNumber)) {
    //   if (!clientIdType) {
    //     setHasErrorClientIdType(true);
    //   }
    //   if (!clientIdNumber) {
    //     setHasErrorClientIdNumber(true);
    //   }
    //   props.onNotificationShow(
    //     "Rodzaj i numer dokumentu identyfikacji " +
    //       stakeholder +
    //       " są wymagane. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateBusinessIndividualFormFieldsPrincipalErrorClient = () => {
    let stakeholder = "klienta";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP) {
      setHasErrorCompanyNIP(true);
      props.onNotificationShow("NIP firmy jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!principalName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!principalSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!principalPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(principalPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    // if (!clientAddrCountry) {
    //   setHasErrorClientAddrCountry(true);
    //   props.onNotificationShow(
    //     "Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }
    // if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
    //   setHasErrorClientPesel(true);
    //   props.onNotificationShow(
    //     "Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    // if (!(clientIdType && clientIdNumber)) {
    //   if (!clientIdType) {
    //     setHasErrorClientIdType(true);
    //   }
    //   if (!clientIdNumber) {
    //     setHasErrorClientIdNumber(true);
    //   }
    //   props.onNotificationShow(
    //     "Rodzaj i numer dokumentu identyfikacji " +
    //       stakeholder +
    //       " są wymagane. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    if (!principalAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!principalAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!principalAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateCompanyFormFieldsClientErrorClient = () => {
    let stakeholder = "reprezentanta";
    let company = "firmy";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP && !companyKRS) {
      setHasErrorCompanyNIP(true);
      setHasErrorCompanyKRS(true);
      props.onNotificationShow("NIP firmy lub KRS jest wymagany. Uzupełnij minimum jedno pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    // if (!clientAddrCountry) {
    //   setHasErrorClientAddrCountry(true);
    //   props.onNotificationShow(
    //     "Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }
    // if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
    //   setHasErrorClientPesel(true);
    //   props.onNotificationShow(
    //     "Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    // if (!(clientIdType && clientIdNumber)) {
    //   if (!clientIdType) {
    //     setHasErrorClientIdType(true);
    //   }
    //   if (!clientIdNumber) {
    //     setHasErrorClientIdNumber(true);
    //   }
    //   props.onNotificationShow(
    //     "Rodzaj i numer dokumentu identyfikacji " +
    //       stakeholder +
    //       " są wymagane. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }
  };

  const validateCompanyFormFieldsPrincipalErrorClient = () => {
    let company = "firmy";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP && !companyKRS) {
      setHasErrorCompanyNIP(true);
      setHasErrorCompanyKRS(true);
      props.onNotificationShow("NIP firmy lub KRS jest wymagany. Uzupełnij minimum jedno pole.", dict.NotificationType.error);
      return -1;
    }

    if (!principalAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!principalAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!principalAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateForm = () => {
    if (clientType === dict.ClientType.person) {
      let res = validateClientFormFieldsClientErrorClient();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.personWithRepresentative) {
      let res = validateClientFormFieldsPrincipalErrorClient(false);
      if (res === -1) return -1;
      res = validateClientFormFieldsClientErrorPrincipal(true);
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.businessIndividual) {
      let res = validateBusinessIndividualFormFieldsClientErrorClient();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.businessIndividualWithRepresentative) {
      let res = validateBusinessIndividualFormFieldsPrincipalErrorClient();
      if (res === -1) return -1;
      res = validateClientFormFieldsClientErrorPrincipal(true);
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.company) {
      let res = validateCompanyFormFieldsClientErrorClient();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.companyWithRepresentative) {
      let res = validateCompanyFormFieldsPrincipalErrorClient();
      if (res === -1) return -1;
      res = validateClientFormFieldsClientErrorPrincipal(true);
      if (res === -1) return -1;
    }
  };

  const getFields = (formMode) => {
    let data = {
      clientType: clientType,

      companyName: companyName,
      companyNIP: companyNIP,
      companyKRS: companyKRS,

      clientName: clientName,
      clientSurname: clientSurname,
      clientAddrStreet: clientAddrStreet,
      clientAddrStreetNumber: clientAddrStreetNumber,
      clientAddrLocaleNumber: clientAddrLocaleNumber,
      clientAddrPostcode: clientAddrPostcode,
      clientAddrCity: clientAddrCity,
      clientAddrCountry: clientAddrCountry,
      clientPesel: clientPesel,
      clientIdType: clientIdType,
      clientIdNumber: clientIdNumber,
      clientPhone: clientPhone,
      clientEmail: clientEmail,

      principalName: principalName,
      principalSurname: principalSurname,
      principalPhone: principalPhone,
      principalEmail: principalEmail,
      principalAddrStreet: principalAddrStreet,
      principalAddrStreetNumber: principalAddrStreetNumber,
      principalAddrLocaleNumber: principalAddrLocaleNumber,
      principalAddrPostcode: principalAddrPostcode,
      principalAddrCity: principalAddrCity,
      principalAddrCountry: principalAddrCountry,
      principalPesel: principalPesel,
      principalIdType: principalIdType,
      principalIdNumber: principalIdNumber,
    };

    if (formMode === dict.FormMode.add) {
      data = {
        ...data,
        //       status: dict.DocStatus.new,
      };
    } else if (formMode === dict.FormMode.edit) {
      data = {
        ...data,
        //   signatureAgent: signatureAgent.current,
      };
    }

    return data;
  };

  const saveModeEdit = async () => {
    await ContractPurchaseCoownerApi.Update(objectId, getFields(dict.FormMode.edit));
    return objectId;
  };

  const validateFormButtonSave = () => {
    if (!phoneValidator(principalPhone)) {
      props.onNotificationShow("Numer telefonu Współposzukującego posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }
  };

  // TODO - wywołać z zewnątrz (funkcja wysyłki lub udostępnienia)
  // eslint-disable-next-line
  const handleSaveClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormButtonSave();
      if (result === -1) {
        return -1;
      }
      // result = validateForm();
      // if (result === -1) {
      //   return -1;
      // }

      if (mode === dict.FormMode.add) {
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.edit) {
        // let p = await ContractPurchaseApi.GetStatusById(objectId);
        // if (p.status === dict.DocStatus.accepted) {
        //   throw Error(
        //     "Nie można aktualizować - dane zostały zatwierdzone przez klienta."
        //   );
        // }

        objId = await saveModeEdit();
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd: ".concat(error.message), dict.NotificationType.error);
    }
  };

  const handleDelete = async () => {
    const id = await ContractPurchaseCoownerApi.Delete(objectId);

    if (props.onDelete) {
      props.onDelete(id);
    }
  };

  const handleClientTypeChoosen = (value) => {
    setClientType(value);
  };

  const renderRepresentative = () => {
    return (
      <Person
        isRepresentative={true}
        isModePreview={isModePreview}
        hasErrorSectionClient={hasErrorSectionClient}
        clientType={clientType}
        onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
        clientName={clientName}
        onChangeClientName={(value) => setClientName(value)}
        hasErrorClientName={hasErrorRepresentativeName}
        clientSurname={clientSurname}
        onChangeClientSurname={(value) => setClientSurname(value)}
        hasErrorClientSurname={hasErrorRepresentativeSurname}
        clientPhone={clientPhone}
        onChangeClientPhone={(value) => {
          setClientPhone(value);
          phoneValidator(value) ? setHasErrorRepresentativePhone(false) : setHasErrorRepresentativePhone(true);
        }}
        hasErrorClientPhone={hasErrorRepresentativePhone}
        clientEmail={clientEmail}
        onChangeClientEmail={(value) => {
          setClientEmail(value);
          emailValidator(value) ? setHasErrorRepresentativeEmail(false) : setHasErrorRepresentativeEmail(true);
        }}
        hasErrorClientEmail={hasErrorRepresentativeEmail}
        clientAddrCountry={clientAddrCountry}
        onChangeClientAddrCountry={(value) => setClientAddrCountry(value)}
        hasErrorClientAddrCountry={hasErrorRepresentativeAddrCountry}
        clientPesel={clientPesel}
        onChangeClientPesel={(value) => setClientPesel(value)}
        hasErrorClientPesel={hasErrorRepresentativePesel}
        clientIdTypeCombo={clientIdTypeCombo}
        hasErrorClientIdType={hasErrorRepresentativeIdType}
        onChangeClientIdType={(value) => setClientIdType(value)}
        onChangeClientIdTypeCombo={(value) => setClientIdTypeCombo(value)}
        clientIdNumber={clientIdNumber}
        onChangeClientIdNumber={(value) => setClientIdNumber(value)}
        hasErrorClientIdNumber={hasErrorRepresentativeIdNumber}
        clientAddrStreet={clientAddrStreet}
        onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
        hasErrorClientAddrStreet={hasErrorRepresentativeAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
        hasErrorClientAddrStreetNumber={hasErrorRepresentativeAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
        clientAddrPostcode={clientAddrPostcode}
        onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
        clientAddrCity={clientAddrCity}
        onChangeClientAddrCity={(value) => setClientAddrCity(value)}
        hasErrorClientAddrCity={hasErrorRepresentativeAddrCity}
      />
    );
  };

  const renderClient = () => {
    if (clientType === dict.ClientType.businessIndividual) {
      return (
        <BusinessIndividual
          recordId={objectId}
          clientSectionName={""}
          addressSectionName={"Adres Współposzukującego"}
          isModePreview={isModePreview}
          hasErrorSectionClient={hasErrorSectionClient}
          clientType={clientType}
          onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
          companyName={companyName}
          onChangeCompanyName={(value) => setCompanyName(value)}
          hasErrorCompanyName={hasErrorCompanyName}
          companyNIP={companyNIP}
          onChangeCompanyNIP={(value) => setCompanyNIP(value)}
          hasErrorCompanyNIP={hasErrorCompanyNIP}
          clientName={clientName}
          onChangeClientName={(value) => setClientName(value)}
          hasErrorClientName={hasErrorClientName}
          clientSurname={clientSurname}
          onChangeClientSurname={(value) => setClientSurname(value)}
          hasErrorClientSurname={hasErrorClientSurname}
          clientPhone={clientPhone}
          onChangeClientPhone={(value) => {
            setClientPhone(value);
            phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
          }}
          hasErrorClientPhone={hasErrorClientPhone}
          clientEmail={clientEmail}
          onChangeClientEmail={(value) => {
            setClientEmail(value);
            emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
          }}
          hasErrorClientEmail={hasErrorClientEmail}
          clientAddrStreet={clientAddrStreet}
          onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
          hasErrorClientAddrStreet={hasErrorClientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
          hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
          clientAddrPostcode={clientAddrPostcode}
          onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
          clientAddrCity={clientAddrCity}
          onChangeClientAddrCity={(value) => setClientAddrCity(value)}
          hasErrorClientAddrCity={hasErrorClientAddrCity}
        />
      );
    } else if (clientType === dict.ClientType.businessIndividualWithRepresentative) {
      return (
        <Stack>
          <BusinessIndividual
            recordId={objectId}
            clientSectionName={""}
            addressSectionName={"Adres Współposzukującego"}
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionRepresentative}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            companyName={companyName}
            onChangeCompanyName={(value) => setCompanyName(value)}
            hasErrorCompanyName={hasErrorCompanyName}
            companyNIP={companyNIP}
            onChangeCompanyNIP={(value) => setCompanyNIP(value)}
            hasErrorCompanyNIP={hasErrorCompanyNIP}
            clientName={principalName}
            onChangeClientName={(value) => setPrincipalName(value)}
            hasErrorClientName={hasErrorClientName}
            clientSurname={principalSurname}
            onChangeClientSurname={(value) => setPrincipalSurname(value)}
            hasErrorClientSurname={hasErrorClientSurname}
            clientPhone={principalPhone}
            onChangeClientPhone={(value) => {
              setPrincipalPhone(value);
              phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
            }}
            hasErrorClientPhone={hasErrorClientPhone}
            clientEmail={principalEmail}
            onChangeClientEmail={(value) => {
              setPrincipalEmail(value);
              emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
            }}
            hasErrorClientEmail={hasErrorClientEmail}
            clientAddrStreet={principalAddrStreet}
            onChangeClientAddrStreet={(value) => setPrincipalAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={principalAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPrincipalAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={principalAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPrincipalAddrLocaleNumber(value)}
            clientAddrPostcode={principalAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPrincipalAddrPostcode(value)}
            clientAddrCity={principalAddrCity}
            onChangeClientAddrCity={(value) => setPrincipalAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } else if (clientType === dict.ClientType.company) {
      return (
        <Company
          recordId={objectId}
          clientSectionName={""}
          addressSectionName={"Adres Współposzukującego"}
          isRepresentative={false}
          isModePreview={isModePreview}
          hasErrorSectionClient={hasErrorSectionClient}
          clientType={clientType}
          onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
          companyName={companyName}
          onChangeCompanyName={(value) => setCompanyName(value)}
          hasErrorCompanyName={hasErrorCompanyName}
          companyNIP={companyNIP}
          onChangeCompanyNIP={(value) => setCompanyNIP(value)}
          hasErrorCompanyNIP={hasErrorCompanyNIP}
          companyKRS={companyKRS}
          onChangeCompanyKRS={(value) => setCompanyKRS(value)}
          hasErrorCompanyKRS={hasErrorCompanyKRS}
          clientAddrStreet={clientAddrStreet}
          onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
          hasErrorClientAddrStreet={hasErrorClientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
          hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
          clientAddrPostcode={clientAddrPostcode}
          onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
          clientAddrCity={clientAddrCity}
          onChangeClientAddrCity={(value) => setClientAddrCity(value)}
          hasErrorClientAddrCity={hasErrorClientAddrCity}
          clientName={clientName}
          onChangeClientName={(value) => setClientName(value)}
          hasErrorClientName={hasErrorClientName}
          clientSurname={clientSurname}
          onChangeClientSurname={(value) => setClientSurname(value)}
          hasErrorClientSurname={hasErrorClientSurname}
          clientPhone={clientPhone}
          onChangeClientPhone={(value) => {
            setClientPhone(value);
            phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
          }}
          hasErrorClientPhone={hasErrorClientPhone}
          clientEmail={clientEmail}
          onChangeClientEmail={(value) => {
            setClientEmail(value);
            emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
          }}
          hasErrorClientEmail={hasErrorClientEmail}
        />
      );
    } else if (clientType === dict.ClientType.companyWithRepresentative) {
      return (
        <Stack>
          <Company
            recordId={objectId}
            clientSectionName={""}
            addressSectionName={"Adres Współposzukującego"}
            isRepresentative={true}
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionRepresentative}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            companyName={companyName}
            onChangeCompanyName={(value) => setCompanyName(value)}
            hasErrorCompanyName={hasErrorCompanyName}
            companyNIP={companyNIP}
            onChangeCompanyNIP={(value) => setCompanyNIP(value)}
            hasErrorCompanyNIP={hasErrorCompanyNIP}
            companyKRS={companyKRS}
            onChangeCompanyKRS={(value) => setCompanyKRS(value)}
            hasErrorCompanyKRS={hasErrorCompanyKRS}
            clientAddrStreet={principalAddrStreet}
            onChangeClientAddrStreet={(value) => setPrincipalAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={principalAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPrincipalAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={principalAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPrincipalAddrLocaleNumber(value)}
            clientAddrPostcode={principalAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPrincipalAddrPostcode(value)}
            clientAddrCity={principalAddrCity}
            onChangeClientAddrCity={(value) => setPrincipalAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } else if (clientType === dict.ClientType.personWithRepresentative) {
      return (
        <Stack>
          <Person
            recordId={objectId}
            clientSectionName={""}
            addressSectionName={"Adres Współposzukującego"}
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionRepresentative}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            clientName={principalName}
            onChangeClientName={(value) => setPrincipalName(value)}
            hasErrorClientName={hasErrorClientName}
            clientSurname={principalSurname}
            onChangeClientSurname={(value) => setPrincipalSurname(value)}
            hasErrorClientSurname={hasErrorClientSurname}
            clientPhone={principalPhone}
            onChangeClientPhone={(value) => {
              setPrincipalPhone(value);
              phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
            }}
            hasErrorClientPhone={hasErrorClientPhone}
            clientEmail={principalEmail}
            onChangeClientEmail={(value) => {
              setPrincipalEmail(value);
              emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
            }}
            hasErrorClientEmail={hasErrorClientEmail}
            clientAddrCountry={principalAddrCountry}
            onChangeClientAddrCountry={(value) => setPrincipalAddrCountry(value)}
            hasErrorClientAddrCountry={hasErrorClientAddrCountry}
            clientPesel={principalPesel}
            onChangeClientPesel={(value) => setPrincipalPesel(value)}
            hasErrorClientPesel={hasErrorClientPesel}
            clientIdTypeCombo={principalIdTypeCombo}
            hasErrorClientIdType={hasErrorClientIdType}
            onChangeClientIdType={(value) => setPrincipalIdType(value)}
            onChangeClientIdTypeCombo={(value) => setPrincipalIdTypeCombo(value)}
            clientIdNumber={principalIdNumber}
            onChangeClientIdNumber={(value) => setPrincipalIdNumber(value)}
            hasErrorClientIdNumber={hasErrorClientIdNumber}
            clientAddrStreet={principalAddrStreet}
            onChangeClientAddrStreet={(value) => setPrincipalAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={principalAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPrincipalAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={principalAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPrincipalAddrLocaleNumber(value)}
            clientAddrPostcode={principalAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPrincipalAddrPostcode(value)}
            clientAddrCity={principalAddrCity}
            onChangeClientAddrCity={(value) => setPrincipalAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    }
    //else if (clientType === dict.ClientType.person) {
    return (
      <Person
        recordId={objectId}
        clientSectionName={""}
        addressSectionName={"Adres Współposzukującego"}
        isModePreview={isModePreview}
        hasErrorSectionClient={hasErrorSectionClient}
        clientType={clientType}
        onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
        clientName={clientName}
        onChangeClientName={(value) => setClientName(value)}
        hasErrorClientName={hasErrorClientName}
        clientSurname={clientSurname}
        onChangeClientSurname={(value) => setClientSurname(value)}
        hasErrorClientSurname={hasErrorClientSurname}
        clientPhone={clientPhone}
        onChangeClientPhone={(value) => {
          setClientPhone(value);
          phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
        }}
        hasErrorClientPhone={hasErrorClientPhone}
        clientEmail={clientEmail}
        onChangeClientEmail={(value) => {
          setClientEmail(value);
          emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
        }}
        hasErrorClientEmail={hasErrorClientEmail}
        clientAddrCountry={clientAddrCountry}
        onChangeClientAddrCountry={(value) => setClientAddrCountry(value)}
        hasErrorClientAddrCountry={hasErrorClientAddrCountry}
        clientPesel={clientPesel}
        onChangeClientPesel={(value) => setClientPesel(value)}
        hasErrorClientPesel={hasErrorClientPesel}
        clientIdTypeCombo={clientIdTypeCombo}
        hasErrorClientIdType={hasErrorClientIdType}
        onChangeClientIdType={(value) => setClientIdType(value)}
        onChangeClientIdTypeCombo={(value) => setClientIdTypeCombo(value)}
        clientIdNumber={clientIdNumber}
        onChangeClientIdNumber={(value) => setClientIdNumber(value)}
        hasErrorClientIdNumber={hasErrorClientIdNumber}
        clientAddrStreet={clientAddrStreet}
        onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
        hasErrorClientAddrStreet={hasErrorClientAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
        hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
        clientAddrPostcode={clientAddrPostcode}
        onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
        clientAddrCity={clientAddrCity}
        onChangeClientAddrCity={(value) => setClientAddrCity(value)}
        hasErrorClientAddrCity={hasErrorClientAddrCity}
      />
    );
  };

  return (
    <Stack style={{ paddingBottom: "20px" }}>
      <Accordion style={{ background: "#ebebeb" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 0, pb: 0 }} style={{ color: hasErrorSectionClient ? "red" : "" }}>
            {"Współposzukujący"}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{renderClient()}</AccordionDetails>
        <AccordionActions>
          <Button onClick={handleDelete} disabled={isModePreview || docStatus !== dict.DocStatus.new ? true : false}>
            {"Usuń"}
          </Button>
          {/* <Button onClick={handleSaveClick} disabled={isModePreview}>
            {"Zapisz"}
          </Button> */}
        </AccordionActions>
      </Accordion>
    </Stack>
  );
});

export default ContractPurchaseCoowner;
