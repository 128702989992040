import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

import * as dict from "./../../../config/Dictionary";
import {
  formatAddressLine1,
  phoneNumberFormat,
  convertBlobToBase64,
  translateMonthNumber,
  netToGrossRecalculate,
  formatNumberByCurrency,
} from "./../../../utils/validator";

import DocumentPDFAtt1 from "./DocumentPDFAtt1";
import * as TemplateDocumentBinaryApi from "./../../../business/TemplateDocument/TemplateDocumentBinary/TemplateDocumentBinaryApi";

// Register font
Font.register({
  family: "Roboto",
  fonts: [
    { src: "/assets/Fonts/Roboto/Roboto-Regular.ttf" },
    { src: "/assets/Fonts/Roboto/Roboto-Bold.ttf" },
    {
      src: "/assets/Fonts/Roboto/Roboto-Italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "/assets/Fonts/Roboto/Roboto-BoldItalic.ttf",
      fontStyle: "italic",
      fontWeight: "bold",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "3%",
    marginLeft: "15px",
    fontSize: "9px",
    marginRight: "20px",
    paddingBottom: "65px",
  },
  parameter: {
    fontWeight: "bold",
    color: "#163058",
  },
  title: {
    marginTop: "20px",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "12px",
  },
  attachmentTitle: {
    marginTop: "10px",
    marginBottom: "5px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "12px",
  },
  descr: {
    marginBottom: "7px",
    marginRight: "20px",
  },
  descrBold: {
    fontWeight: "bold",
  },
  descrBoldAndCenter: {
    fontWeight: "bold",
    textAlign: "center",
  },
  square: {
    width: 10, // Adjust width and height as needed
    height: 10,
    borderWidth: 0, // Set border width to 0 for no border
    border: "1px solid black",
  },
});

const stylesTable = StyleSheet.create({
  table: {
    display: "table",
    width: "96%",
    marginTop: "30px",
  },
  rowHeader: {
    flexDirection: "row",
    textAlign: "center",
  },
  row: {
    flexDirection: "row",
    height: "50px",
    fontWeight: "bold",
    color: "#163058",
    textAlign: "center",
  },
  cell: {
    display: "table-cell",
    margin: 0,
    padding: 5,
    width: "20%",
    whiteSpace: "pre-line",
    //border: "1px solid #000",
    marginLeft: "-1px",
    marginTop: "-1px",
  },
  column1: {
    width: "39%",
  },
  column2: {
    width: "22%",
  },
  column3: {
    width: "39%",
  },
});

const Printing = (props) => {
  const contractDate = props.contractDate;
  const contractCity = props.contractCity;
  const contractVariant = props.contractVariant;
  const contractDurationMonth = props.contractDurationMonth;
  const clientType = props.clientType;
  const clientName = props.clientName;
  const clientSurname = props.clientSurname;
  const clientAddrStreet = props.clientAddrStreet;
  const clientAddrStreetNumber = props.clientAddrStreetNumber;
  const clientAddrLocaleNumber = props.clientAddrLocaleNumber;
  const clientAddrPostcode = props.clientAddrPostcode;
  const clientAddrCity = props.clientAddrCity;
  // const clientAddrCountry = props.clientAddrCountry;
  const clientEmail = props.clientEmail;
  const clientPhone = phoneNumberFormat(props.clientPhone);
  const clientPesel = props.clientPesel;
  const clientIdType = props.clientIdType;
  const clientIdNumber = props.clientIdNumber;

  const propertyIsSeparateOwnership = props.propertyIsSeparateOwnership;
  const propertyIsLand = props.propertyIsLand;
  const propertyIsCooperativeOwnership = props.propertyIsCooperativeOwnership;

  const obligationShowPurchOffer = props.obligationShowPurchOffer;
  const obligationPresentation = props.obligationPresentation;
  const obligationPhotos = props.obligationPhotos;
  const obligationCollectingDocs = props.obligationCollectingDocs;
  const obligationDescrAndPubl = props.obligationDescrAndPubl;
  const obligationLegalVerif = props.obligationLegalVerif;
  // const obligationAdMother = props.obligationAdMother;
  const obligationAdMotherAndOthers = props.obligationAdMotherAndOthers;
  const obligationDescrUpgrade = props.obligationDescrUpgrade;
  const obligationCombTranContact = props.obligationCombTranContact;
  const obligationProtocols = props.obligationProtocols;
  const obligationTransPrepHelp = props.obligationTransPrepHelp;
  const obligationOthers = props.obligationOthers;
  const obligationOthersText = props.obligationOthersText;

  const agentPostTerminationWork = props.agentPostTerminationWork;

  const agentProvisionType = props.agentProvisionType;
  const agentProvisionPercentNet = props.agentProvisionPercentNet;
  const agentProvisionAmountNet = props.agentProvisionAmountNet;

  const agentName = props.agentName;
  const agentSurname = props.agentSurname;
  const agentPhone = phoneNumberFormat(props.agentPhone);
  const agentEmail = props.agentEmail;

  const branchName = props.branchName;
  const branchAddressLine1 = props.branchAddressLine1;
  const branchAddressLine2 = props.branchAddressLine2;
  const branchEmail = props.branchEmail;
  const branchPhone = phoneNumberFormat(props.branchPhone);
  const branchNip = "1111111111"; //props.branchNip;
  const branchWebPage = ""; //"www.itmarpris.pl"; //props.branchWebPage;

  // Person
  const personName = props.principalName;
  const personSurname = props.principalSurname;
  const personAddrStreet = props.principalAddrStreet;
  const personAddrStreetNumber = props.principalAddrStreetNumber;
  const personAddrLocaleNumber = props.principalAddrLocaleNumber;
  const personAddrPostcode = props.principalAddrPostcode;
  const personAddrCity = props.principalAddrCity;
  // const personAddrCountry = props.principalAddrCountry;
  const personEmail = props.principalEmail;
  const personPhone = phoneNumberFormat(props.principalPhone);
  const personPesel = props.principalPesel;
  const personIdType = props.principalIdType;
  const personIdNumber = props.principalIdNumber;

  const companyName = props.companyName;
  const companyNIP = props.companyNIP;
  const companyKRS = props.companyKRS;

  const signatureClient = props.signatureClient;
  const signatureAgent = props.signatureAgent;
  const signaturesAll = props.signatures?.filter((e) => e.actorType === "coowner").sort((a, b) => a.id - b.id);

  const id = props.id;
  const mode = props.mode;
  const sessionKey = props.sessionKey;
  const coownersData = props.coownersData;
  const attachmentsData = props.attachmentsData;
  // eslint-disable-next-line
  const invoiceEmail = props.useClientEmailToInvoice ? props.clientEmail : props.clientInvoiceEmail;

  const renderSignature = (value) => {
    if (value && value !== -1) {
      return (
        <Image
          source={value}
          type={"image/png"}
          style={{
            padding: "0",
            margin: "0",
            width: "180",
            height: "70",
          }}
          // width="360px"
          // height="140px"
        />
      );
    }
    return <div></div>;
  };

  const tableRows = [
    {
      agentSign: renderSignature(signatureAgent),
      date: contractDate + " " + props.contractHour,
      clientSign: renderSignature(signatureClient),
    },
  ];

  const renderDateAndSignature = () => (
    <View style={stylesTable.table}>
      <View style={stylesTable.rowHeader}>
        <View style={[stylesTable.cell, stylesTable.column1]}>
          <Text>Podpis Agenta</Text>
        </View>
        <View style={[stylesTable.cell, stylesTable.column2]}>
          <Text>Data</Text>
        </View>
        <View style={[stylesTable.cell, stylesTable.column3]}>
          <Text>Podpis Klienta</Text>
        </View>
      </View>

      {tableRows.map((obj, index) => (
        <View style={stylesTable.row} key={"".concat("tabRows", index)}>
          <View style={[stylesTable.cell, stylesTable.column1]}>{obj.agentSign}</View>
          <View style={[stylesTable.cell, stylesTable.column2]}>
            <Text>{obj.date}</Text>
          </View>
          <View style={[stylesTable.cell, stylesTable.column3]}>{obj.clientSign}</View>
        </View>
      ))}
      <View>
        {signaturesAll?.map((obj, index) => (
          <View style={stylesTable.row} key={"".concat("tabRowsCoowners", index)}>
            <View style={[stylesTable.cell, stylesTable.column1]}>{""}</View>
            <View style={[stylesTable.cell, stylesTable.column2]}>
              <Text>{""}</Text>
            </View>
            <View style={[stylesTable.cell, stylesTable.column3]}>{renderSignature(obj.sign)}</View>
          </View>
        ))}
      </View>
    </View>
  );

  const loadImage = async (imageId) => {
    let dbBlob;
    if (mode === dict.DocFormViewMode.client) {
      dbBlob = await TemplateDocumentBinaryApi.GetFileByIdSessionKey(imageId, sessionKey, id);
    } else {
      dbBlob = await TemplateDocumentBinaryApi.GetFileById(imageId);
    }
    const base64 = await convertBlobToBase64(dbBlob);
    return base64;
  };

  const renderAttachments = () => {
    return attachmentsData?.map((item) => (
      <View break key={item.id} style={{ textAlign: "center", marginTop: "10px", marginBottom: "0px", marginRight: "15px" }}>
        <Text style={styles.attachmentTitle}>{item.label}</Text>
        <Image style={{ maxWidth: "92%", height: "auto", maxHeight: "92%" }} source={loadImage(item.id)} alt="Rysunek" />
      </View>
    ));
  };

  const renderImagesShape = () => {
    let data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    return data?.map((item, index) => (
      <View key={"".concat("graphics", index)}>
        <Image
          style={{
            width: "25px",
            height: "25px",
            position: "absolute",
            marginTop: "".concat(30 * index * 2.3, "px"),
            marginLeft: "-17px",
          }}
          src={"/assets/Graphics/client/docShapeA.png"}
        />
        <Image
          style={{
            width: "25px",
            height: "25px",
            position: "absolute",
            marginTop: "".concat(33 + 30 * index * 2.3, "px"),
            marginLeft: "-17px",
          }}
          src={"/assets/Graphics/client/docShapeB.png"}
        />
      </View>
    ));
  };

  const renderHeaderLogo = () => {
    return <Image style={{ left: 13, width: "25px", position: "absolute" }} src={"/assets/Graphics/client/docLogoTop.png"} fixed />;
  };

  const renderFooterLeft = () => {
    return (
      <View
        style={{
          left: 40,
          bottom: 20,
          position: "absolute",
          color: "#163058",
          fontSize: "10px",
        }}
        fixed
      >
        <Text style={{ fontWeight: "bold", marginBottom: "3px" }}>{branchName}</Text>

        <Text style={{ marginBottom: "3px" }}>
          {branchAddressLine1} {branchAddressLine2}
        </Text>
        <Text style={{ marginBottom: "3px" }}>NIP: {branchNip}</Text>
      </View>
    );
  };

  const renderFooterRight = () => {
    return (
      <View
        style={{
          left: 380,
          bottom: 20,
          position: "absolute",
          color: "#163058",
          fontSize: "10px",
        }}
        fixed
      >
        <Text style={{ fontWeight: "bold", marginBottom: "3px" }}>{branchEmail}</Text>
        <Text style={{ marginBottom: "3px" }}>{branchPhone}</Text>
        <Text style={{ marginBottom: "3px" }}>{branchWebPage}</Text>
      </View>
    );
  };

  const renderFooterLogo = () => {
    return (
      <Image
        style={{
          bottom: 35,
          left: 530,
          width: "16px",
          height: "18px",
          position: "absolute",
        }}
        src={"/assets/Graphics/client/docLogoBottom.png"}
        fixed
      />
    );
  };

  const renderCkeckbox = (field, text) => {
    return (
      <View style={([styles.descr], { flexDirection: "row", marginTop: "4px" })}>
        <Text style={[styles.square, { marginLeft: "14px", marginRight: "3px" }]}></Text>
        <Text
          style={[
            styles.parameter,
            {
              left: -10.5,
              top: -1,
              fontWeight: "bold",
              fontSize: "9px",
            },
          ]}
        >
          {field ? "x" : "  "}
        </Text>
        <Text
          style={[
            styles.descr,
            {
              width: "90%",
              fontStyle: "italic",
              fontSize: "8px",
              marginBottom: "1px",
            },
          ]}
        >
          {text}
        </Text>
      </View>
    );
  };

  // eslint-disable-next-line
  const renderKeyValue = (key, value) => {
    return (
      <Text>
        {key}
        <Text style={styles.parameter}>{value}</Text>
      </Text>
    );
  };

  const setParagrapfNumber = (number) => {
    if (number >= 4 && contractVariant === dict.IntermediationContractVariant.open) number = number - 1;

    return number;
  };

  const renderParagrapf = (number) => {
    number = setParagrapfNumber(number);

    return (
      <View style={[styles.descrBoldAndCenter, { paddingTop: "7px", paddingBottom: "4px", fontSize: "11px" }]}>
        <Text>§{number}</Text>
      </View>
    );
  };

  const renderParagraph1VariantOpenAdditional = () => {
    if (contractVariant === dict.IntermediationContractVariant.open)
      return (
        <View style={[{ marginTop: "5px" }]}>
          <Text>
            {
              "2. Klient oświadcza, że: jest właścicielem Nieruchomości / działa na podstawie załączonego pełnomocnictwa udzielonego przez właściciela Nieruchomości / działa za zgodą właściciela Nieruchomości lecz we własnym imieniu i zleca Pośrednikowi pośrednictwo w zbyciu Nieruchomości."
            }
          </Text>
        </View>
      );

    return <Text></Text>;
  };

  const condP2VariantOpen = () => {
    if (contractVariant === dict.IntermediationContractVariant.open)
      return (
        <View>
          <View style={[{ marginTop: "5px" }]}>
            <Text>
              {
                "1. Pośrednik zobowiązuje się do podejmowania dla Klienta czynności pośrednictwa zmierzających do zbycia Nieruchomości przez Klienta, zgodnie z wiedzą i doświadczeniem Pośrednika. W szczególności Pośrednik zobowiązuje się do:"
              }
            </Text>
            <Text>- Reklamowanie i marketing w poszukiwaniu nabywcy nieruchomości</Text>
            <Text>- Dokonywanie w uzgodnionych terminach prezentacji nieruchomości potencjalnym nabywcom</Text>
            <Text>
              - Skontaktowanie klienta i potencjalnego nabywcy nieruchomości w celu uzgodnienia przyszłych warunków transakcji łączącej strony, udział
              w negocjacjach.
            </Text>
            <Text>- Zapewnienie obsługi organizacyjnej transakcji</Text>
          </View>
          <View style={[{ marginTop: "5px" }]}>
            <Text>
              {
                "2. Rezygnacja Klienta z poszczególnych czynności wymienionych powyżej nie stanowi podstawy do zmniejszenia lub niezapłacenia należnego Pośrednikowi Wynagrodzenia."
              }
            </Text>
          </View>
          <View style={[{ marginTop: "5px" }]}>
            <Text>{"3. Pośrednik może wykonywać czynności pośrednictwa na rzecz obu stron transakcji."}</Text>
          </View>
          <View style={[{ marginTop: "5px" }]}>
            <Text>
              {
                "4. Umowa nie ogranicza Klienta w zawieraniu umów pośrednictwa z innymi pośrednikami ani w poszukiwaniu nabywcy Nieruchomości we własnym zakresie."
              }
            </Text>
          </View>
        </View>
      );

    return <Text></Text>;
  };

  const condP2VariantNotOpen = () => {
    if (contractVariant === dict.IntermediationContractVariant.open) return <Text></Text>;

    return (
      <View style={[{ marginTop: "5px" }]}>
        <Text>
          {
            "1. Pośrednik zobowiązuje się do podejmowania dla Klienta czynności pośrednictwa zmierzających do zbycia Nieruchomości przez Klienta, zgodnie z wiedzą i doświadczeniem Pośrednika. W szczególności Pośrednik zobowiązuje się do: "
          }
        </Text>
        {renderCkeckbox(obligationShowPurchOffer, "przedstawiania Klientowi ofert kupna Nieruchomości")}
        {renderCkeckbox(obligationPresentation, "dokonywania w uzgodnionych terminach prezentacji Nieruchomości potencjalnym nabywcom")}
        {renderCkeckbox(obligationPhotos, "sporządzenia dokumentacji zdjęciowej")}
        {renderCkeckbox(obligationCollectingDocs, "skompletowania dokumentacji na podstawie przedłożonych przez Klienta dokumentów")}
        {renderCkeckbox(obligationDescrAndPubl, "sporządzenia oferty i opisu Nieruchomości oraz rejestracji oferty w bazie danych Pośrednika ")}
        {renderCkeckbox(obligationLegalVerif, "dokonania wstępnej weryfikacji stanu prawnego Nieruchomości")}
        {/* {renderCkeckbox(
          obligationAdMother,
          "umieszczenia reklamy na stronie internetowej Pośrednika "
        )} */}
        {renderCkeckbox(
          obligationAdMotherAndOthers,
          "umieszczenia reklamy na stronie internetowej Pośrednika oraz na innych portalach internetowych "
        )}
        {renderCkeckbox(obligationDescrUpgrade, "bieżącej aktualizacji danych dotyczących Nieruchomości ")}
        {renderCkeckbox(
          obligationCombTranContact,
          "skontaktowania Klienta i potencjalnego nabywcy Nieruchomości w celu uzgodnienia przyszłych warunków transakcji łączącej strony, udziału w negocjacjach "
        )}
        {renderCkeckbox(obligationProtocols, "przygotowania protokołu uzgodnień lub protokołu zdawczo-odbiorczego")}
        {renderCkeckbox(obligationTransPrepHelp, "zapewnienia obsługi związanej z przygotowaniem transakcji")}
        {renderCkeckbox(obligationOthers, "inne")}
        <Text
          style={[
            styles.parameter,
            {
              marginLeft: "50px",
              marginTop: "-11px",
              marginBottom: "2px",
            },
          ]}
        >
          {obligationOthersText + " "}
        </Text>
        <Text>
          {
            "2. Rezygnacja Klienta z poszczególnych czynności wymienionych powyżej nie stanowi podstawy do zmniejszenia lub niezapłacenia należnego Pośrednikowi Wynagrodzenia."
          }
        </Text>
        <Text>{"3. Pośrednik może wykonywać czynności pośrednictwa na rzecz obu stron transakcji."}</Text>
      </View>
    );
  };

  const condP3VariantNotOpenPage1 = () => {
    if (contractVariant === dict.IntermediationContractVariant.open) return <Text></Text>;

    return (
      <View>
        {renderParagrapf(3)}
        <View style={[{ marginTop: "5px" }]}>
          <Text>
            {
              "1. Klient oświadcza, że zawiera Umowę z zastrzeżeniem wyłączności na rzecz Pośrednika i zobowiązuje się do niezawierania umowy pośrednictwa w obrocie nieruchomościami dotyczącej Nieruchomości z innymi osobami oraz niepowierzania osobom trzecim czynności zmierzających do doprowadzenia do zawarcia umowy sprzedaży Nieruchomości."
            }
          </Text>
        </View>
      </View>
    );
  };

  const condP3VariantExclusiveAgentPage2 = () => {
    if (contractVariant === dict.IntermediationContractVariant.exclusiveAgent)
      return (
        <View style={[styles.descr]}>
          <Text>
            {
              "2. W przypadku zawarcia przez Klienta umowy przedwstępnej sprzedaży Nieruchomości lub umowy sprzedaży Nieruchomości z potencjalnym nabywcą, Klient zobowiązuje się zapłacić Pośrednikowi wynagrodzenie określone w § 4 Umowy - bez względu na osobę pośredniczącą w nawiązaniu kontaktu pomiędzy Klientem oraz nabywcą Nieruchomości."
            }
          </Text>
        </View>
      );

    return <Text></Text>;
  };

  const condP3VariantExclusiveAgencyPage2 = () => {
    if (contractVariant === dict.IntermediationContractVariant.exclusiveAgency)
      return (
        <View>
          <View style={[styles.descr]}>
            <Text>
              {
                "2. W przypadku zawarcia przez Klienta umowy przedwstępnej sprzedaży Nieruchomości lub umowy sprzedaży Nieruchomości z potencjalnym nabywcą, Klient zobowiązuje się zapłacić Pośrednikowi wynagrodzenie określone w § 4 Umowy - z zastrzeżeniem ust. 3 poniżej."
              }
            </Text>
          </View>
          <View style={[styles.descr]}>
            <Text>
              {
                "3. Wynagrodzenie nie przysługuje Pośrednikowi w przypadku zawarcia przez Klienta umowy wskazanej w ust. 2 powyżej, z nabywcą pozyskanym przez Klienta bezpośrednio, to jest bez udziału Pośrednika lub innego podmiotu (osoby trzeciej)."
              }
            </Text>
          </View>
        </View>
      );

    return <Text></Text>;
  };

  const setContractDuration = () => {
    const monthAsWord = translateMonthNumber(contractDurationMonth);

    if (monthAsWord && monthAsWord !== "") {
      return contractDurationMonth.toString() + " (" + monthAsWord + ")";
    }

    return contractDurationMonth.toString();
  };

  const contractTitle = () => {
    if (contractVariant === dict.IntermediationContractVariant.open) {
      return "NIE na wyłączność, zawarta poza lokalem przedsiębiorstwa";
    } else if (contractVariant === dict.IntermediationContractVariant.exclusiveAgency) {
      return "WYŁĄCZNOŚĆ AGENCYJNA zawarta poza lokalem przedsiębiorstwa";
    }

    return "na wyłączność, zawarta poza lokalem przedsiębiorstwa";
  };

  const renderRepresentative = (
    xClientName,
    xClientSurname,
    xClientAddrCity,
    xClientAddrPostcode,
    xClientAddrStreet,
    xClientAddrStreetNumber,
    xClientAddrLocaleNumber,
    xClientPesel,
    xClientIdType,
    xClientIdNumber,
    xClientEmail,
    xClientPhone
  ) => {
    return (
      <View style={[{ marginTop: "5px" }]}>
        <Text>
          <Text style={[styles.parameter]}>{xClientName + " " + xClientSurname}</Text>

          {" zamieszkałym w "}
          <Text style={[styles.parameter]}>{xClientAddrCity}</Text>
          {", kod pocztowy "}
          <Text style={[styles.parameter]}>{xClientAddrPostcode}</Text>
          {", "}
          <Text style={[styles.parameter]}>{formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber)}</Text>
          {", PESEL: "}
          <Text style={[styles.parameter]}>{xClientPesel}</Text>
          {", legitymujący się "}
          <Text style={[styles.parameter]}>{dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label}</Text>
          <Text style={[styles.parameter]}> {xClientIdNumber}</Text>
        </Text>
        <Text style={{ marginTop: "5px" }}>
          {"Adres e-mail: "}
          <Text style={[styles.parameter]}>{xClientEmail}</Text>
          {", telefon kontaktowy: "}
          <Text style={[styles.parameter]}>{xClientPhone}</Text>
        </Text>
      </View>
    );
  };

  const renderStakeholder = (
    isFirst,
    type,
    xClientName,
    xClientSurname,
    xClientAddrCity,
    xClientAddrPostcode,
    xClientAddrStreet,
    xClientAddrStreetNumber,
    xClientAddrLocaleNumber,
    xClientPesel,
    xClientIdType,
    xClientIdNumber,
    xClientEmail,
    xClientPhone,
    xPersonName,
    xPersonSurname,
    xPersonAddrCity,
    xPersonAddrPostcode,
    xPersonAddrStreet,
    xPersonAddrStreetNumber,
    xPersonAddrLocaleNumber,
    xPersonPesel,
    xPersonIdType,
    xPersonIdNumber,
    xPersonEmail,
    xPersonPhone,
    xCompanyName,
    xCompanyNIP,
    xCompanyKRS
  ) => {
    if (type === dict.ClientType.person) {
      return (
        <View style={[{ marginTop: "5px" }]}>
          <Text>
            {isFirst ? "(2) " : ""}
            <Text style={[styles.parameter]}>{xClientName + " " + xClientSurname}</Text>

            {" zamieszkałym w "}
            <Text style={[styles.parameter]}>{xClientAddrCity}</Text>
            {", kod pocztowy "}
            <Text style={[styles.parameter]}>{xClientAddrPostcode}</Text>
            {", "}
            <Text style={[styles.parameter]}>{formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber)}</Text>
            {", PESEL: "}
            <Text style={[styles.parameter]}>{xClientPesel}</Text>
            {", legitymujący się "}
            <Text style={[styles.parameter]}>{dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label}</Text>
            <Text style={[styles.parameter]}> {xClientIdNumber}</Text>
          </Text>
          <Text style={{ marginTop: "5px" }}>
            {"Adres e-mail: "}
            <Text style={[styles.parameter]}>{xClientEmail}</Text>
            {", telefon kontaktowy: "}
            <Text style={[styles.parameter]}>{xClientPhone}</Text>
          </Text>
        </View>
      );
    } else if (type === dict.ClientType.personWithRepresentative) {
      return (
        <View style={[{ marginTop: "5px" }]}>
          <Text>
            {isFirst ? "(2) " : ""}
            <Text style={[styles.parameter]}>{xPersonName + " " + xPersonSurname}</Text>

            {" zamieszkałym w "}
            <Text style={[styles.parameter]}>{xPersonAddrCity}</Text>
            {", kod pocztowy "}
            <Text style={[styles.parameter]}>{xPersonAddrPostcode}</Text>
            {", "}
            <Text style={[styles.parameter]}>{formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber)}</Text>
            {", PESEL: "}
            <Text style={[styles.parameter]}>{xPersonPesel}</Text>
            {", legitymujący się "}
            <Text style={[styles.parameter]}>{dict.IdentityDocumentType.filter((f) => f.code === xPersonIdType)[0]?.label}</Text>
            <Text style={[styles.parameter]}> {xPersonIdNumber}</Text>
          </Text>
          <Text style={{ marginTop: "5px" }}>
            {"Adres e-mail: "}
            <Text style={[styles.parameter]}>{xPersonEmail}</Text>
            {", telefon kontaktowy: "}
            <Text style={[styles.parameter]}>{xPersonPhone}</Text>
          </Text>
          <Text style={{ marginTop: "5px", marginBottom: "3px" }}>{"reprezentowanym przez: "}</Text>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </View>
      );
    } else if (type === dict.ClientType.businessIndividual) {
      return (
        <View style={[{ marginTop: "5px" }]}>
          <Text>
            {isFirst ? "(2) " : ""}
            <Text style={[styles.parameter]}>{xClientName + " " + xClientSurname}</Text>
            {" prowadzącym jednoosobową działalność gospodarczą pod nazwą "}
            <Text style={[styles.parameter]}>{xCompanyName}</Text>
            {", adres: "}
            <Text style={[styles.parameter]}>{xClientAddrCity}</Text>
            {", kod pocztowy "}
            <Text style={[styles.parameter]}>{xClientAddrPostcode}</Text>
            {", "}
            <Text style={[styles.parameter]}>{formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber)}</Text>
            {", NIP: "}
            <Text style={[styles.parameter]}>{xCompanyNIP}</Text>
            {", PESEL: "}
            <Text style={[styles.parameter]}>{xClientPesel}</Text>
            {", legitymujący się "}
            <Text style={[styles.parameter]}>{dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label}</Text>
            <Text style={[styles.parameter]}> {xClientIdNumber}</Text>
          </Text>
          <Text style={{ marginTop: "5px" }}>
            {"Adres e-mail: "}
            <Text style={[styles.parameter]}>{xClientEmail}</Text>
            {", telefon kontaktowy: "}
            <Text style={[styles.parameter]}>{xClientPhone}</Text>
          </Text>
        </View>
      );
    } else if (type === dict.ClientType.businessIndividualWithRepresentative) {
      return (
        <View style={[{ marginTop: "5px" }]}>
          <Text>
            {isFirst ? "(2) " : ""}
            <Text style={[styles.parameter]}>{xPersonName + " " + xPersonSurname}</Text>
            {" prowadzącym jednoosobową działalność gospodarczą pod nazwą "}
            <Text style={[styles.parameter]}>{xCompanyName}</Text>
            {", adres: "}
            <Text style={[styles.parameter]}>{xPersonAddrCity}</Text>
            {", kod pocztowy "}
            <Text style={[styles.parameter]}>{xPersonAddrPostcode}</Text>
            {", "}
            <Text style={[styles.parameter]}>{formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber)}</Text>
            {", NIP: "}
            <Text style={[styles.parameter]}>{xCompanyNIP}</Text>
            {", PESEL: "}
            <Text style={[styles.parameter]}>{xPersonPesel}</Text>
            {", legitymujący się "}
            <Text style={[styles.parameter]}>{dict.IdentityDocumentType.filter((f) => f.code === xPersonIdType)[0]?.label}</Text>
            <Text style={[styles.parameter]}> {xPersonIdNumber}</Text>
          </Text>
          <Text style={{ marginTop: "5px" }}>
            {"Adres e-mail: "}
            <Text style={[styles.parameter]}>{xPersonEmail}</Text>
            {", telefon kontaktowy: "}
            <Text style={[styles.parameter]}>{xPersonPhone}</Text>
          </Text>
          <Text style={{ marginTop: "5px", marginBottom: "3px" }}>{"reprezentowanym przez: "}</Text>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </View>
      );
    } else if (type === dict.ClientType.company) {
      return (
        <View style={[{ marginTop: "5px" }]}>
          <Text>
            {isFirst ? "(2) " : ""}
            <Text style={[styles.parameter]}>{xCompanyName}</Text>
            {", adres: "}
            <Text style={[styles.parameter]}>{xClientAddrCity}</Text>
            {", kod pocztowy "}
            <Text style={[styles.parameter]}>{xClientAddrPostcode}</Text>
            {", "}
            <Text style={[styles.parameter]}>{formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber)}</Text>
            {", NIP: "}
            <Text style={[styles.parameter]}>{xCompanyNIP}</Text>
            {", KRS: "}
            <Text style={[styles.parameter]}>{xCompanyKRS}</Text>
          </Text>
          <Text style={{ marginTop: "5px" }}>
            {"reprezentowaną przez "}
            <Text style={[styles.parameter]}>{xClientName + " " + xClientSurname}</Text>
            {" na podstawie KRS "}
          </Text>
          <Text style={{ marginTop: "5px" }}>
            {"Adres e-mail: "}
            <Text style={[styles.parameter]}>{xClientEmail}</Text>
            {", telefon kontaktowy: "}
            <Text style={[styles.parameter]}>{xClientPhone}</Text>
          </Text>
        </View>
      );
    } else if (type === dict.ClientType.companyWithRepresentative) {
      return (
        <View style={[{ marginTop: "5px" }]}>
          <Text>
            {isFirst ? "(2) " : ""}
            <Text style={[styles.parameter]}>{xCompanyName}</Text>
            {", adres: "}
            <Text style={[styles.parameter]}>{xPersonAddrCity}</Text>
            {", kod pocztowy "}
            <Text style={[styles.parameter]}>{xPersonAddrPostcode}</Text>
            {", "}
            <Text style={[styles.parameter]}>{formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber)}</Text>
            {", NIP: "}
            <Text style={[styles.parameter]}>{xCompanyNIP}</Text>
            {", KRS: "}
            <Text style={[styles.parameter]}>{xCompanyKRS}</Text>
          </Text>
          <Text style={{ marginTop: "5px", marginBottom: "3px" }}>{"reprezentowaną przez: "}</Text>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </View>
      );
    }
  };

  const renderOwner = () => {
    return renderStakeholder(
      true,
      clientType,
      clientName,
      clientSurname,
      clientAddrCity,
      clientAddrPostcode,
      clientAddrStreet,
      clientAddrStreetNumber,
      clientAddrLocaleNumber,
      clientPesel,
      clientIdType,
      clientIdNumber,
      clientEmail,
      clientPhone,
      personName,
      personSurname,
      personAddrCity,
      personAddrPostcode,
      personAddrStreet,
      personAddrStreetNumber,
      personAddrLocaleNumber,
      personPesel,
      personIdType,
      personIdNumber,
      personEmail,
      personPhone,
      companyName,
      companyNIP,
      companyKRS
    );
  };

  const renderCoowner = (obj) => {
    return renderStakeholder(
      false,
      obj.clientType,
      obj.clientName,
      obj.clientSurname,
      obj.clientAddrCity,
      obj.clientAddrPostcode,
      obj.clientAddrStreet,
      obj.clientAddrStreetNumber,
      obj.clientAddrLocaleNumber,
      obj.clientPesel,
      obj.clientIdType,
      obj.clientIdNumber,
      obj.clientEmail,
      obj.clientPhone,
      obj.principalName,
      obj.principalSurname,
      obj.principalAddrCity,
      obj.principalAddrPostcode,
      obj.principalAddrStreet,
      obj.principalAddrStreetNumber,
      obj.principalAddrLocaleNumber,
      obj.principalPesel,
      obj.principalIdType,
      obj.principalIdNumber,
      obj.principalEmail,
      obj.principalPhone,
      obj.companyName,
      obj.companyNIP,
      obj.companyKRS
    );
  };

  const renderAgentProvision = () => {
    if (agentProvisionType === dict.AgentProvisionType.amount) {
      return (
        <Text>
          {"1. Klient zobowiązuje się do zapłaty Pośrednikowi wynagrodzenia w wysokości "}
          <Text style={[styles.parameter]}>{formatNumberByCurrency(netToGrossRecalculate(agentProvisionAmountNet), null)}</Text>
          {" PLN brutto ("}
          <Text style={[styles.parameter]}>{formatNumberByCurrency(agentProvisionAmountNet, null)}</Text>
          {' PLN netto + 23% podatku od towarów i usług VAT) niezależnie od ceny transakcyjnej nieruchomości ("Wynagrodzenie"). '}
        </Text>
      );
    }

    return (
      <Text>
        {"1. Klient zobowiązuje się do zapłaty Pośrednikowi wynagrodzenia w wysokości "}
        <Text style={[styles.parameter]}>{formatNumberByCurrency(netToGrossRecalculate(agentProvisionPercentNet), null)}</Text>
        {"% brutto ("}
        <Text style={[styles.parameter]}>{formatNumberByCurrency(agentProvisionPercentNet, null)}</Text>
        {'% netto + 23% podatku od towarów i usług VAT) ceny transakcyjnej Nieruchomości brutto ("Wynagrodzenie"). '}
      </Text>
    );
  };

  return (
    <Document>
      <Page size="A4" style={[styles.page, { position: "relative" }]}>
        {renderHeaderLogo()}
        <View style={{ left: 13, marginTop: "80px" }} fixed>
          {renderImagesShape()}
        </View>
        {renderFooterLeft()}
        {renderFooterRight()}
        {renderFooterLogo()}
        <View style={[{ marginTop: "-60px" }, styles.descr]}>
          <View style={styles.title}>
            <Text>{"UMOWA POŚREDNICTWA W SPRZEDAŻY NIERUCHOMOŚCI"}</Text>
            <Text>{contractTitle()}</Text>
          </View>
          <View style={[styles.descr]}>
            <View>
              <Text>
                {"zawarta w dniu "}
                <Text style={styles.parameter}>{contractDate}</Text>
                {" r. w miejscowości "}
                <Text style={styles.parameter}>{contractCity}</Text>
                {" pomiędzy: "}
              </Text>
            </View>
            <View style={[{ marginTop: "5px" }]}>
              <Text>
                {
                  "(1) Demo Estate Spółką z ograniczoną odpowiedzialnością z siedzibą w Warszawa, pod adresem: ul. Demonstracyjna 111, lok. 2, 00-000 Warszawa, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Warszawa-Centrum w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000000000, o numerze NIP 1111111111, o kapitale zakładowym w wysokości 2 000 000,00 złotych, zwaną dalej „DEMO ESTATE”, reprezentowaną przez: "
                }
                <Text style={[styles.parameter]}>{agentName + " " + agentSurname}</Text>
                {" - pełnomocnika, tel.: "}
                <Text style={styles.parameter}>{agentPhone}</Text>
                {" email: "}
                <Text style={styles.parameter}>{agentEmail}</Text>
              </Text>
            </View>
            <View style={[{ marginTop: "5px" }]}>
              <Text>{"a "}</Text>
            </View>
            {renderOwner()}
            <View style={[{ marginTop: "5px" }]}>
              {coownersData?.map((item) => (
                <View key={item.id}>
                  <Text>{"i "}</Text>
                  {renderCoowner(item)}
                </View>
              ))}
            </View>
          </View>
          {renderParagrapf(1)}
          <View>
            <Text>
              {
                '1. Klient powierza, a Pośrednik zobowiązuje się do pośredniczenia w zawarciu przez Klienta umowy sprzedaży nieruchomości ("Nieruchomość") stanowiącej:'
              }
            </Text>
            {renderCkeckbox(propertyIsSeparateOwnership, "odrębny lokal mieszkalny/ użytkowy (prawo własności)")}
            {renderCkeckbox(propertyIsLand, "nieruchomość gruntową")}
            {renderCkeckbox(propertyIsCooperativeOwnership, "spółdzielcze własnościowe prawo do lokalu")}

            {renderParagraph1VariantOpenAdditional()}
          </View>
          {renderParagrapf(2)}
          {condP2VariantOpen()}
          {condP2VariantNotOpen()}

          {condP3VariantNotOpenPage1()}
        </View>

        {condP3VariantExclusiveAgentPage2()}
        {condP3VariantExclusiveAgencyPage2()}

        {renderParagrapf(4)}
        <View style={[{ marginTop: "5px" }, styles.descr]}>
          {renderAgentProvision()}
          <Text>
            {"2. Niniejsza Umowa zostaje zawarta na czas określony " +
              setContractDuration() +
              " miesięcy od dnia jej podpisania. Każda Strona może wypowiedzieć w tym czasie Umowę wyłącznie z ważnych powodów. "}
          </Text>
          <Text>{"3. Wszelkie zmiany do niniejszej Umowy wymagają formy pisemnej pod rygorem nieważności. "}</Text>
          <Text>
            {
              "4. Klient oświadcza, że został poinformowany przez Pośrednika o: (i) prawie do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa w terminie 14 (czternastu) dni od dnia jej zawarcia oraz sposobie odstąpienia (zgodnie z art. 27 ustawy z dnia 30 maja 2014r. o prawach konsumenta); (ii) obowiązku zapłaty Pośrednikowi za świadczenia spełnione do chwili odstąpienia od umowy, w przypadku odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa, jeśli zgłosił żądanie wykonywania usługi przed terminem do odstąpienia od umowy (zgodnie z art. 35 ustawy z dnia 30 maja 2014r. o prawach konsumenta), (iii) braku prawa do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa jeśli zgłosił żądanie wykonywania usługi przed terminem do odstąpienia od umowy (zgodnie z art. 12 ust. 1 pkt.12) ustawy z dnia 30 maja 2014r. o prawach konsumenta) w przypadku, gdy dojdzie do transakcji określonej w Umowie w wyniku podjętych w tym okresie przez Pośrednika czynności pośrednictwa.  "
            }
          </Text>
          <Text>{"5. Klient oświadcza, że otrzymał następujące Załączniki do umowy: "}</Text>
          <View>
            <Text>{"Załącznik Nr 1 - Pouczenie o odstąpieniu od umowy zawartej poza lokalem przedsiębiorstwa"}</Text>
          </View>
        </View>
        <View style={[{ marginTop: "15px" }, styles.descr]}></View>
        <View>
          {renderCkeckbox(
            agentPostTerminationWork,
            "Oświadczam, że żądam rozpoczęcia przez DEMO ESTATE świadczenia usługi na podstawie powyższej Umowy zawartej poza lokalem przedsiębiorstwa, przed upływem terminu do odstąpienia od umowy (zgodnie z art. 27 ustawy z dnia 30 maja 2014 r. o prawach konsumenta) i zapewniam, że zapłacę DEMO ESTATE Sp. z o.o. całość Wynagrodzenia ustalonego w Umowie, w przypadku, gdy dojdzie do transakcji określonej w Umowie w wyniku podjętych w tym okresie przez DEMO ESTATE Sp. z o.o. czynności pośrednictwa. "
          )}
        </View>
        <View>{renderDateAndSignature()}</View>

        <DocumentPDFAtt1 />
        {renderAttachments()}
      </Page>
    </Document>
  );
};

export default Printing;
