const DocumentForm = (props) => {
  const styles = {
    page: {
      fontFamily: "Roboto",
      paddingTop: "5px",
      marginLeft: "15px",
      fontSize: "12px",
      marginRight: "20px",
      paddingBottom: "30px",
    },

    title: {
      paddingBottom: "10px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
  };

  return (
    <div style={styles.page}>
      <p style={styles.title}>
        Załącznik Nr 4 - Kopia dokumentu aktualnego ubezpieczenia od odpowiedzialności cywilnej za szkody wyrządzone w związku z wykonywaniem
        czynności pośrednictwa
      </p>

      <img src={"/assets/Graphics/homeestate/insurance/polisaOC-1-2024.png"} type={"image/png"} alt="OC-1" width="464px" height="656px" />
      <img src={"/assets/Graphics/homeestate/insurance/polisaOC-2-2024.png"} type={"image/png"} alt="OC-2" width="464px" height="656px" />
    </div>
  );
};

export default DocumentForm;
