import { useState, forwardRef, useImperativeHandle } from "react";
import { Stack, TextField } from "@mui/material";
import { emailValidator } from "../../../utils/validator";
import * as ContractPurchaseCoownerApi from "../../../business/ContractPurchase/ContractPurchaseCoowner/ContractPurchaseCoownerApi";

const Emails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      validate: handleValidate,
      save: handleSave,
    };
  });

  const coowner = props.data;
  const objectId = props.data.id;

  const [email, setEmail] = useState(coowner.clientEmail);
  const [emailRepeat, setEmailRepeat] = useState("");
  const [showEmailRepeatError, setShowEmailRepeatError] = useState(false);

  const handleValidate = () => {
    if (!emailValidator(email)) {
      return -1;
    }
    return 0;
  };

  const handleSave = async () => {
    props.onAfterCoownerEmailRepeat(objectId, email, emailRepeat);

    if (emailRepeat !== email) {
      setShowEmailRepeatError(true);

      if (emailRepeat === "") {
        setShowEmailRepeatError(false);
      }

      return -1;
    }

    await ContractPurchaseCoownerApi.Update(objectId, { clientEmail: email });
    return objectId;
  };

  return (
    <Stack>
      <Stack sx={{ fontSize: "15px", marginBottom: "15px", fontWeight: "bold" }}>{"" + coowner.clientName + " " + coowner.clientSurname}</Stack>
      <Stack>
        <TextField
          size="small"
          label={"Wprowadź e-mail"}
          id={"email".concat(coowner.id)}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setShowEmailRepeatError(false);
          }}
          disabled={false}
          sx={{ pb: 2, width: "100%" }}
          required={true}
          type="email"
          error={!emailValidator(email) ? true : false}
          onBlur={handleSave}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          label={"Potwierdź e-mail"}
          id={"emailRepeat".concat(coowner.id)}
          value={emailRepeat}
          onChange={(e) => {
            setEmailRepeat(e.target.value);
            setShowEmailRepeatError(false);
          }}
          disabled={false}
          sx={{ pb: 2, width: "100%" }}
          required={true}
          type="email"
          error={showEmailRepeatError || !emailValidator(emailRepeat) ? true : false}
          helperText={showEmailRepeatError && "Ponowiony adres e-mail nie zgadza się z podanym powyżej."}
          onBlur={handleSave}
        />
      </Stack>
    </Stack>
  );
});

export default Emails;
