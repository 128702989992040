import { Text, View } from "@react-pdf/renderer";

const DocumentForm = (props) => {
  const styles = {
    page: {
      fontFamily: "Roboto",
      padding: "0%",
      marginLeft: "15px",
      fontSize: "9px",
      marginRight: "20px",
      marginTop: "-60px",
    },
    title: {
      marginTop: "20px",
      marginBottom: "40px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "12px",
    },
    newParagraph: {
      paddingBottom: "4px",
    },

    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tr: {
      textAlign: "left",
      fontWeight: "normal",
      fontSize: "8px",
      lineHeight: "500%",
    },
  };

  const stylesTable = {
    table: {
      display: "table",
      width: "96%",
      marginTop: "30px",
    },
    rowHeader: {
      flexDirection: "row",
      textAlign: "center",
    },
    row: {
      flexDirection: "row",
      height: "50px",
      fontWeight: "bold",
      color: "#163058",
      textAlign: "center",
    },
    cell: {
      display: "table-cell",
      margin: 0,
      padding: 5,
      width: "20%",
      whiteSpace: "pre-line",
      //border: "1px solid #000",
      marginLeft: "-1px",
      marginTop: "-1px",
    },
    column1: {
      width: "50%",
    },
    column2: {
      width: "50%",
    },
    column3: {
      width: "39%",
    },
  };

  return (
    <View break style={styles.page}>
      <View style={styles.title}>
        <View>
          <Text>Załącznik nr 2 - Wzór formularza odstąpienia od umowy zawartej poza lokalem</Text>
        </View>
        <View>
          <Text>przedsiębiorstwa (zgodnie z ustawą z dnia 30 maja 2014r. o prawach konsumenta)</Text>
        </View>
        <View>
          <Text>(formularz ten należy wypełnić i odesłać tylko w przypadku chęci odstąpienia od umowy)</Text>
        </View>
      </View>

      <View style={{ paddingBottom: "8px" }}>
        <View style={{ paddingBottom: "8px" }}>
          <Text>Niniejszym oświadczam, że odstępuję od Umowy pośrednictwa w obrocie nieruchomościami z dnia ___________r.</Text>
        </View>
        <View style={{ paddingBottom: "8px" }}>
          <Text>Imię i nazwisko konsumenta: _________________________________________</Text>
        </View>
        <View style={{ paddingBottom: "8px" }}>
          <Text>Adres konsumenta: __________________________________________________</Text>
        </View>
        <View style={{ paddingBottom: "8px" }}>
          <Text> ______________________________________________________________________</Text>
        </View>
      </View>
      <View style={stylesTable.table}>
        <View style={stylesTable.rowHeader}>
          <View style={[stylesTable.cell, stylesTable.column1]}>
            <Text>Podpis konsumenta</Text>
          </View>
          <View style={[stylesTable.cell, stylesTable.column2]}>
            <Text>Data</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default DocumentForm;
