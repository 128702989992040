import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationHelper";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "../../config/NavigationRoute/RoutePath";
import * as dict from "../../config/Dictionary";

import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
// eslint-disable-next-line
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import HomeWorkOutlined from "@mui/icons-material/HomeWorkOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import AddHomeWorkOutlinedIcon from "@mui/icons-material/AddHomeWorkOutlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const positions = [
    {
      id: t(formatTranslation("menuGroup", "presentation")),
      children: [
        {
          id: t(formatTranslation("menu", dict.PageCodes.presentationProtocolHistory)),
          code: dict.PageCodes.presentationProtocolHistory,
          icon: <WorkHistoryOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.presentationProtocolHistory ? true : false,
        },
        {
          id: t(formatTranslation("menu", dict.PageCodes.presentationProtocol)),
          code: dict.PageCodes.presentationProtocol,
          icon: <TaskOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.presentationProtocol ? true : false,
        },
      ],
    },
    {
      id: t(formatTranslation("menuGroup", "intermediation")),
      children: [
        {
          id: t(formatTranslation("menu", dict.PageCodes.intermediationContractHistory)),
          code: dict.PageCodes.intermediationContractHistory,
          icon: <SourceOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.intermediationContractHistory ? true : false,
        },
        {
          id: t(formatTranslation("menu", dict.PageCodes.intermediationContract)),
          code: dict.PageCodes.intermediationContract,
          icon: <AddHomeWorkOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.intermediationContract ? true : false,
        },
      ],
    },
    {
      id: t(formatTranslation("menuGroup", "contractPurchase")),
      children: [
        {
          id: t(formatTranslation("menu", dict.PageCodes.contractPurchaseHistory)),
          code: dict.PageCodes.contractPurchaseHistory,
          icon: <SourceOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.contractPurchaseHistory ? true : false,
        },
        {
          id: t(formatTranslation("menu", dict.PageCodes.contractPurchase)),
          code: dict.PageCodes.contractPurchase,
          icon: <AddHomeWorkOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.contractPurchase ? true : false,
        },
      ],
    },
    {
      id: t(formatTranslation("menuGroup", "contractLease")),
      children: [
        {
          id: t(formatTranslation("menu", dict.PageCodes.contractLeaseHistory)),
          code: dict.PageCodes.contractLeaseHistory,
          icon: <SourceOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.contractLeaseHistory ? true : false,
        },
        {
          id: t(formatTranslation("menu", dict.PageCodes.contractLease)),
          code: dict.PageCodes.contractLease,
          icon: <AddHomeWorkOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.contractLease ? true : false,
        },
      ],
    },
    // {
    //   id: t(formatTranslation("menuGroup", "templateDocument")),
    //   children: [
    //     {
    //       id: t(formatTranslation("menu", dict.PageCodes.templateDocumentHistory)),
    //       code: dict.PageCodes.templateDocumentHistory,
    //       icon: <WorkHistoryOutlinedIcon />,
    //       active: props.activepositioncode === dict.PageCodes.templateDocumentHistory ? true : false,
    //     },
    //     {
    //       id: t(formatTranslation("menu", dict.PageCodes.templateDocument)),
    //       code: dict.PageCodes.templateDocument,
    //       icon: <TaskOutlinedIcon />,
    //       active: props.activepositioncode === dict.PageCodes.templateDocument ? true : false,
    //     },
    //   ],
    // },
    {
      id: t(formatTranslation("menuGroup", "administration")),
      children: [
        // {
        //   id: t(formatTranslation("menu", dict.PageCodes.settings)),
        //   code: dict.PageCodes.settings,
        //   icon: <SettingsOutlinedIcon />,
        //   active: props.activepositioncode === dict.PageCodes.settings ? true : false,
        // },
        {
          id: t(formatTranslation("menu", dict.PageCodes.users)),
          code: dict.PageCodes.users,
          icon: <GroupOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.users ? true : false,
        },
        {
          id: t(formatTranslation("menu", dict.PageCodes.branches)),
          code: dict.PageCodes.branches,
          icon: <HomeWorkOutlined />,
          active: props.activepositioncode === dict.PageCodes.branches ? true : false,
        },
        {
          id: t(formatTranslation("menu", dict.PageCodes.contact)),
          code: dict.PageCodes.contact,
          icon: <CallOutlinedIcon />,
          active: props.activepositioncode === dict.PageCodes.contact ? true : false,
        },
      ],
    },
  ];

  const handleClick = (childId, code) => {
    if (code === props.activepositioncode) {
      return;
    } else if (code === dict.PageCodes.desktop) {
      navigate(RoutePath.Main);
    } else if (code === dict.PageCodes.settings) {
      navigate(RoutePath.Settings);
    } else if (code === dict.PageCodes.users) {
      navigate(RoutePath.Users);
    } else if (code === dict.PageCodes.contact) {
      navigate(RoutePath.Contact);
    } else if (code === dict.PageCodes.branches) {
      navigate(RoutePath.Branches);
    } else if (code === dict.PageCodes.presentationProtocolHistory) {
      navigate(RoutePath.PresentationProtocolHistory);
    } else if (code === dict.PageCodes.presentationProtocol) {
      navigate(RoutePath.PresentationProtocolAgent);
    } else if (code === dict.PageCodes.intermediationContractHistory) {
      navigate(RoutePath.IntermediationContractHistory);
    } else if (code === dict.PageCodes.intermediationContract) {
      navigate(RoutePath.IntermediationContractAgent);
    } else if (code === dict.PageCodes.templateDocumentHistory) {
      navigate(RoutePath.TemplateDocumentHistory);
    } else if (code === dict.PageCodes.templateDocument) {
      navigate(RoutePath.TemplateDocumentAdd);
    } else if (code === dict.PageCodes.contractPurchaseHistory) {
      navigate(RoutePath.ContractPurchaseHistory);
    } else if (code === dict.PageCodes.contractPurchase) {
      navigate(RoutePath.ContractPurchaseAdd);
    } else if (code === dict.PageCodes.contractLeaseHistory) {
      navigate(RoutePath.ContractLeaseHistory);
    } else if (code === dict.PageCodes.contractLease) {
      navigate(RoutePath.ContractLeaseAdd);
    }
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}>{t(formatTranslation("app", "appName"))}</ListItem>
        <ListItem sx={{ ...item, ...itemCategory, px: 0, py: 0 }}>
          <ListItemButton
            selected={props.activepositioncode === dict.PageCodes.desktop ? true : false}
            sx={{ ...item, height: 50 }}
            onClick={(a, b) => handleClick(0, dict.PageCodes.desktop)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>{t(formatTranslation("menu", dict.PageCodes.desktop))}</ListItemText>
          </ListItemButton>
        </ListItem>
        {positions.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#101F33" }}>
            <ListItem sx={{ py: 1.2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, code }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton selected={active} sx={item} onClick={(a, b) => handleClick(childId, code)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 1.5 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
