import { useEffect, useState } from "react";
import { convertBlobToBase64 } from "./../../../utils/validator";
import * as TemplateDocumentBinaryApi from "./../../../business/TemplateDocument/TemplateDocumentBinary/TemplateDocumentBinaryApi";
import * as dict from "./../../../config/Dictionary";

const ImageViewer = (props) => {
  const id = props.id;
  const docId = props.docId;
  const sessionKey = props.sessionKey;
  const mode = props.mode;
  const mimeType = props.mimeType;

  const [image, setImage] = useState([]);

  const loadImage = async () => {
    let dbBlob;

    if (mode === dict.DocFormViewMode.client) {
      dbBlob = await TemplateDocumentBinaryApi.GetFileByIdSessionKey(id, sessionKey, docId);
    } else {
      dbBlob = await TemplateDocumentBinaryApi.GetFileById(id);
    }
    const base64 = await convertBlobToBase64(dbBlob);
    setImage(base64);
  };

  useEffect(() => {
    loadImage();

    // eslint-disable-next-line
  }, []);

  return <img src={image} type={mimeType} alt="Rysunek" />;
};

export default ImageViewer;
