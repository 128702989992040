import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import * as UserApi from "./../UserApi";
import { useState, useEffect } from "react";

const PasswordChange = (props) => {
  const userId = props.id;

  const [showFirstPasswordChange, setShowFirstPasswordChange] = useState(false);

  const setFirstPasswordVisibility = async () => {
    const user = await UserApi.GetById(userId);

    if (!user.passwordHash) {
      setShowFirstPasswordChange(true);
    } else {
      setShowFirstPasswordChange(false);
    }
  };

  useEffect(() => {
    setFirstPasswordVisibility();
    // eslint-disable-next-line
  }, []);

  const handleSaveClick = () => {};

  return (
    <Stack direction="row" spacing={2}>
      {showFirstPasswordChange && (
        <Button variant="text" size="small" startIcon={<SaveOutlinedIcon />} onClick={handleSaveClick} color="error">
          {"Ustaw hasło początkowe"}
        </Button>
      )}
    </Stack>
  );
};

export default PasswordChange;
