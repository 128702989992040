import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ClientForm from "./ClientForm";
import { useParams } from "react-router";
import * as crypto from "./../../utils/crypto";
import * as ActiveLinkAPI from "./../../business/ActiveLink/ActiveLinkApi";
import * as dict from "../../config/Dictionary";

import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationHelper";
import * as sessionClient from "./../../components/SessionStorage/SessionStorageClient";

function ClientLogin() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  const appName = t(formatTranslation("app", "appName"));

  const { guid } = useParams();

  const [pin, setPin] = useState("");
  const [message, setMessage] = useState("");
  const [showPin, setShowPin] = useState(false);

  const [focusedElement, setFocusedElement] = useState("pin");
  const presentationProtocolId = useRef(-1);

  const handleFocus = (e) => {
    setFocusedElement(e.target.name);
  };

  const handleBlur = () => {
    setFocusedElement(null);
  };

  const modes = {
    login: "login",
    accept: "accept",
    finished: "finished",
  };
  const [mode, setMode] = useState(modes.login);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [pin]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && focusedElement === "pin") {
      handleClick();
    }
  };

  const handleSetPin = (event) => {
    setMessage("");
    setPin(event.target.value);
  };

  const handleAccept = () => {
    setMode(modes.finished);
  };

  const handleClick = async () => {
    try {
      if (!pin) {
        setMessage("Wprowadź PIN.");
        return;
      }

      let guidEncoded = crypto.activeLinkGuidEncode(guid);
      let pinEncoded = crypto.activeLinkPinEncode(pin);

      let activeLink = await ActiveLinkAPI.CheckCredentials({
        guid: guidEncoded,
        pin: pinEncoded,
      });

      presentationProtocolId.current = activeLink?.id;

      if (!presentationProtocolId.current) {
        setMessage("Niepoprawny login lub hasło.");
        return -1;
      }

      let presentationProtocolStatus = activeLink?.objStatus;

      if (presentationProtocolStatus !== dict.DocStatus.accepted) {
        sessionClient.setSessionKey(activeLink.sessionKey);
        setMode(modes.accept);
      } else {
        setMode(modes.finished);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  if (mode === modes.login) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1, p: 5, m: 2 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
            margin: 5,
          }}
        >
          <Typography variant="h4">
            <img
              style={{
                width: "25px",
                //position: "absolute",
                align: "left",
              }}
              src={"/assets/Graphics/client/docLogoTop.png"}
              alt={"logo"}
            />{" "}
            {appName}
          </Typography>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Wprowadź podany przez Agenta kod PIN
            </Typography>
          </Divider>

          <TextField
            name="pin"
            label="PIN"
            value={pin}
            onChange={handleSetPin}
            onFocus={handleFocus}
            onBlur={handleBlur}
            type={showPin ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPin(!showPin)} edge="end">
                    {showPin ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoFocus
          />
          <Stack
            style={{
              color: "red",
              fontWeight: "bold",
              minHeight: "25px",
              marginBottom: "5px",
              marginTop: "20px",
            }}
          >
            {message}
          </Stack>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="inherit"
            onClick={handleClick}
            style={{ background: "#163058", color: "#ffffff" }}
          >
            Dalej
          </Button>
        </Card>
      </Stack>
    );
  }

  if (mode === modes.accept) {
    return <ClientForm id={presentationProtocolId.current} onAccept={handleAccept} />;
  }

  if (mode === modes.finished) {
    return <Stack sx={{ p: 2 }}>Dziękujemy za skorzystanie z naszych usług.</Stack>;
  }
}

export default ClientLogin;
