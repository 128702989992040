const f = (x) => {
  return "".concat(process.env.REACT_APP_API, x);
};

export const url_User_GetSalt = f("user/get-salt");
export const url_User_Login = f("user/login");
export const url_User_VerifyToken = f("user/verify-token");
export const url_User_RefreshToken = f("user/refresh-token");
export const url_User_GetById = f("user/get-by-id");
export const url_User_GetAllFiltering = f("user/get-all-filtering");
export const url_User_Delete = f("user/delete");
export const url_User_Create = f("user/create");
export const url_User_Update = f("user/update");

export const url_PresentationProtocol_GetById = f("presentation-protocol/get-by-id");
export const url_PresentationProtocol_GetByIdUnauthorized = f("presentation-protocol/get-by-id-unauthorized");
export const url_PresentationProtocol_Create = f("presentation-protocol/create");
export const url_PresentationProtocol_Update = f("presentation-protocol/update");
export const url_PresentationProtocol_GetAll = f("presentation-protocol/get-all");
export const url_PresentationProtocol_GetAllFiltering = f("presentation-protocol/get-all-filtering");
export const url_PresentationProtocol_Delete = f("presentation-protocol/delete");
export const url_PresentationProtocol_UpdateUnauthorized = f("presentation-protocol/update-unauthorized");
export const url_PresentationProtocol_GetStatusById = f("presentation-protocol/get-status-by-id");
export const url_PresentationProtocol_MonitorById = f("presentation-protocol/monitor-by-id");
export const url_PresentationProtocol_GetBlobById = f("presentation-protocol/get-blob-by-id");

export const url_ActiveLink_GetById = f("active-link/get-by-id");
export const url_ActiveLink_Create = f("active-link/create");
export const url_ActiveLink_CheckCredentials = f("active-link/check-credentials");

export const url_SMS_Send = f("sms/send");

export const url_Mail_Send = f("mail/send");

export const url_Branch_Create = f("branch/create");
export const url_Branch_GetAllFiltering = f("branch/get-all-filtering");
export const url_Branch_GetById = f("branch/get-by-id");
export const url_Branch_Update = f("branch/update");

export const url_IntermediationContract_GetById = f("intermediation-contract/get-by-id");
export const url_IntermediationContract_GetByIdUnauthorized = f("intermediation-contract/get-by-id-unauthorized");
export const url_IntermediationContract_GetByIdAuthorized = f("intermediation-contract/get-by-id-authorized");
export const url_IntermediationContract_Create = f("intermediation-contract/create");
export const url_IntermediationContract_Update = f("intermediation-contract/update");
export const url_IntermediationContract_GetAll = f("intermediation-contract/get-all");
export const url_IntermediationContract_GetAllFiltering = f("intermediation-contract/get-all-filtering");
export const url_IntermediationContract_Delete = f("intermediation-contract/delete");
export const url_IntermediationContract_UpdateUnauthorized = f("intermediation-contract/update-unauthorized");
export const url_IntermediationContract_UpdateAuthorized = f("intermediation-contract/update-authorized");
export const url_IntermediationContract_GetStatusById = f("intermediation-contract/get-status-by-id");
export const url_IntermediationContract_MonitorById = f("intermediation-contract/monitor-by-id");
export const url_IntermediationContract_GetBlobById = f("intermediation-contract/get-blob-by-id");
export const url_IntermediationContract_GetSignatureAgentBlobById = f("intermediation-contract/get-signature-agent-blob-by-id");
export const url_IntermediationContractPerson_GetById = f("intermediation-contract-person/get-by-id");
export const url_IntermediationContractPerson_Create = f("intermediation-contract-person/create");
export const url_IntermediationContractPerson_Update = f("intermediation-contract-person/update");
export const url_IntermediationContractPerson_Delete = f("intermediation-contract-person/delete");
export const url_IntermediationContractPerson_GetByDocId = f("intermediation-contract-person/get-by-doc-id");
export const url_IntermediationContractPerson_GetByDocIdUnauthorized = f("intermediation-contract-person/get-by-doc-id-unauthorized");
export const url_IntermediationContractBinary_Create = f("intermediation-contract-binary/create");
export const url_IntermediationContractBinary_GetFileById = f("intermediation-contract-binary/get-file-by-id");
export const url_IntermediationContractBinary_GetFileByIdSessionKey = f("intermediation-contract-binary/get-file-by-id-session-key");
export const url_IntermediationContractBinary_GetById = f("intermediation-contract-binary/get-by-id");
export const url_IntermediationContractBinary_GetIdsByDocId = f("intermediation-contract-binary/get-ids-by-doc-id");
export const url_IntermediationContractBinary_GetIdsByDocIdSessionKey = f("intermediation-contract-binary/get-ids-by-doc-id-session-key");
export const url_IntermediationContractBinary_Update = f("intermediation-contract-binary/update");
export const url_IntermediationContractBinary_Delete = f("intermediation-contract-binary/delete");

export const url_TemplateDocument_Create = f("template-document/create");
export const url_TemplateDocument_Update = f("template-document/update");
export const url_TemplateDocument_Delete = f("template-document/delete");
export const url_TemplateDocument_GetAll = f("template-document/get-all");
export const url_TemplateDocument_GetAllFiltering = f("template-document/get-all-filtering");
export const url_TemplateDocument_GetById = f("template-document/get-by-id");
export const url_TemplateDocument_GetDocumentPDFById = f("template-document/get-document-pdf-by-id");
export const url_TemplateDocument_GetAgentSignatureById = f("template-document/get-agent-signature-by-id");
export const url_TemplateDocument_ClientGetById = f("template-document/client-get-by-id");
export const url_TemplateDocument_ClientGetByIdSessionKey = f("template-document/client-get-by-id-session-key");
export const url_TemplateDocument_ClientUpdateById = f("template-document/client-update-by-id");
export const url_TemplateDocument_ClientUpdateByIdSessionKey = f("template-document/client-update-by-id-session-key");
export const url_TemplateDocument_GetStatusById = f("template-document/get-status-by-id");
export const url_TemplateDocument_GetMonitorDataById = f("template-document/get-monitor-data-by-id");
export const url_TemplateDocumentCoowner_Create = f("template-document-coowner/create");
export const url_TemplateDocumentCoowner_Update = f("template-document-coowner/update");
export const url_TemplateDocumentCoowner_Delete = f("template-document-coowner/delete");
export const url_TemplateDocumentCoowner_GetById = f("template-document-coowner/get-by-id");
export const url_TemplateDocumentCoowner_GetByDocId = f("template-document-coowner/get-by-doc-id");
export const url_TemplateDocumentCoowner_GetByDocIdSessionKey = f("template-document-coowner/get-by-doc-id-session-key");
export const url_TemplateDocumentBinary_Create = f("template-document-binary/create");
export const url_TemplateDocumentBinary_GetFileById = f("template-document-binary/get-file-by-id");
export const url_TemplateDocumentBinary_GetFileByIdSessionKey = f("template-document-binary/get-file-by-id-session-key");
export const url_TemplateDocumentBinary_GetById = f("template-document-binary/get-by-id");
export const url_TemplateDocumentBinary_GetIdsByDocId = f("template-document-binary/get-ids-by-doc-id");
export const url_TemplateDocumentBinary_GetIdsByDocIdSessionKey = f("template-document-binary/get-ids-by-doc-id-session-key");
export const url_TemplateDocumentBinary_Update = f("template-document-binary/update");
export const url_TemplateDocumentBinary_Delete = f("template-document-binary/delete");

export const url_ContractPurchase_Create = f("contract-purchase/create");
export const url_ContractPurchase_Update = f("contract-purchase/update");
export const url_ContractPurchase_Delete = f("contract-purchase/delete");
export const url_ContractPurchase_GetAll = f("contract-purchase/get-all");
export const url_ContractPurchase_GetAllFiltering = f("contract-purchase/get-all-filtering");
export const url_ContractPurchase_GetById = f("contract-purchase/get-by-id");
export const url_ContractPurchase_GetDocumentPDFById = f("contract-purchase/get-document-pdf-by-id");
export const url_ContractPurchase_GetAgentSignatureById = f("contract-purchase/get-agent-signature-by-id");
export const url_ContractPurchase_ClientGetById = f("contract-purchase/client-get-by-id");
export const url_ContractPurchase_ClientGetByIdSessionKey = f("contract-purchase/client-get-by-id-session-key");
export const url_ContractPurchase_ClientUpdateById = f("contract-purchase/client-update-by-id");
export const url_ContractPurchase_ClientUpdateByIdSessionKey = f("contract-purchase/client-update-by-id-session-key");
export const url_ContractPurchase_GetStatusById = f("contract-purchase/get-status-by-id");
export const url_ContractPurchase_GetMonitorDataById = f("contract-purchase/get-monitor-data-by-id");
export const url_ContractPurchaseCoowner_Create = f("contract-purchase-coowner/create");
export const url_ContractPurchaseCoowner_Update = f("contract-purchase-coowner/update");
export const url_ContractPurchaseCoowner_Delete = f("contract-purchase-coowner/delete");
export const url_ContractPurchaseCoowner_GetById = f("contract-purchase-coowner/get-by-id");
export const url_ContractPurchaseCoowner_GetByDocId = f("contract-purchase-coowner/get-by-doc-id");
export const url_ContractPurchaseCoowner_GetByDocIdSessionKey = f("contract-purchase-coowner/get-by-doc-id-session-key");
export const url_ContractPurchaseBinary_Create = f("contract-purchase-binary/create");
export const url_ContractPurchaseBinary_GetFileById = f("contract-purchase-binary/get-file-by-id");
export const url_ContractPurchaseBinary_GetFileByIdSessionKey = f("contract-purchase-binary/get-file-by-id-session-key");
export const url_ContractPurchaseBinary_GetById = f("contract-purchase-binary/get-by-id");
export const url_ContractPurchaseBinary_GetIdsByDocId = f("contract-purchase-binary/get-ids-by-doc-id");
export const url_ContractPurchaseBinary_GetIdsByDocIdSessionKey = f("contract-purchase-binary/get-ids-by-doc-id-session-key");
export const url_ContractPurchaseBinary_Update = f("contract-purchase-binary/update");
export const url_ContractPurchaseBinary_Delete = f("contract-purchase-binary/delete");

export const url_ContractLease_Create = f("contract-lease/create");
export const url_ContractLease_Update = f("contract-lease/update");
export const url_ContractLease_Delete = f("contract-lease/delete");
export const url_ContractLease_GetAll = f("contract-lease/get-all");
export const url_ContractLease_GetAllFiltering = f("contract-lease/get-all-filtering");
export const url_ContractLease_GetById = f("contract-lease/get-by-id");
export const url_ContractLease_GetDocumentPDFById = f("contract-lease/get-document-pdf-by-id");
export const url_ContractLease_GetAgentSignatureById = f("contract-lease/get-agent-signature-by-id");
export const url_ContractLease_ClientGetById = f("contract-lease/client-get-by-id");
export const url_ContractLease_ClientGetByIdSessionKey = f("contract-lease/client-get-by-id-session-key");
export const url_ContractLease_ClientUpdateById = f("contract-lease/client-update-by-id");
export const url_ContractLease_ClientUpdateByIdSessionKey = f("contract-lease/client-update-by-id-session-key");
export const url_ContractLease_GetStatusById = f("contract-lease/get-status-by-id");
export const url_ContractLease_GetMonitorDataById = f("contract-lease/get-monitor-data-by-id");
export const url_ContractLeaseCoowner_Create = f("contract-lease-coowner/create");
export const url_ContractLeaseCoowner_Update = f("contract-lease-coowner/update");
export const url_ContractLeaseCoowner_Delete = f("contract-lease-coowner/delete");
export const url_ContractLeaseCoowner_GetById = f("contract-lease-coowner/get-by-id");
export const url_ContractLeaseCoowner_GetByDocId = f("contract-lease-coowner/get-by-doc-id");
export const url_ContractLeaseCoowner_GetByDocIdSessionKey = f("contract-lease-coowner/get-by-doc-id-session-key");
export const url_ContractLeaseBinary_Create = f("contract-lease-binary/create");
export const url_ContractLeaseBinary_GetFileById = f("contract-lease-binary/get-file-by-id");
export const url_ContractLeaseBinary_GetFileByIdSessionKey = f("contract-lease-binary/get-file-by-id-session-key");
export const url_ContractLeaseBinary_GetById = f("contract-lease-binary/get-by-id");
export const url_ContractLeaseBinary_GetIdsByDocId = f("contract-lease-binary/get-ids-by-doc-id");
export const url_ContractLeaseBinary_GetIdsByDocIdSessionKey = f("contract-lease-binary/get-ids-by-doc-id-session-key");
export const url_ContractLeaseBinary_Update = f("contract-lease-binary/update");
export const url_ContractLeaseBinary_Delete = f("contract-lease-binary/delete");
