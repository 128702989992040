import * as React from "react";
import { useState, useEffect, useRef } from "react";
import * as ContractPurchaseApi from "../../../business/ContractPurchase/ContractPurchaseApi";
import * as ContractPurchaseCoownerApi from "../../../business/ContractPurchase/ContractPurchaseCoowner/ContractPurchaseCoownerApi";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Grid, Button, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";

import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MessageOK from "../../../components/Messages/MessageOK";
import ContractPurchasePDF from "../DocumentPDF/DocumentPDF";
import { pdf } from "@react-pdf/renderer";
import * as dict from "../../../config/Dictionary";
import * as sessionClient from "../../../components/SessionStorage/SessionStorageClient";
import { useTranslation } from "react-i18next";
import { validateEmail, convertBlobToBase64 } from "../../../utils/validator";
import DrawingCanvas from "../../../components/DrawingCanvas/DrawingCanvas";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import * as RoutePath from "../../../config/NavigationRoute/RoutePath";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ContractPurchaseHTML from "../DocumentHTML/DocumentHTML";
import * as ContractPurchaseBinaryApi from "../../../business/ContractPurchase/ContractPurchaseBinary/ContractPurchaseBinaryApi";
import Emails from "./ContractPurchaseClientDecisionEmails";

const ClientForm = (props) => {
  const debug = false;

  const navigate = useNavigate();
  const location = useLocation();

  const isSm = useMediaQuery("(min-width:600px)");

  // eslint-disable-next-line
  const { t } = useTranslation();

  const docId = props.id ? props.id : location.state.id;
  const mode = location.state ? location.state.mode : dict.DocFormViewMode.client;

  const isInspection = location.state?.isInspection ? location.state.isInspection : false;

  // Part I
  // Form fields
  const [contractVariant, setContractVariant] = useState(dict.IntermediationContractVariantCombo[0].code);
  const [contractDate, setContractDate] = useState(dayjs(new Date().toUTCString()));
  const [contractCity, setContractCity] = useState("");
  const [contractDurationMonth, setContractDurationMonth] = useState(12);

  const [clientType, setClientType] = useState(dict.ClientType.person);

  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [clientAddrStreet, setClientAddrStreet] = useState("");
  const [clientAddrStreetNumber, setClientAddrStreetNumber] = useState("");
  const [clientAddrLocaleNumber, setClientAddrLocaleNumber] = useState("");
  const [clientAddrPostcode, setClientAddrPostcode] = useState("");
  const [clientAddrCity, setClientAddrCity] = useState("");
  const [clientAddrCountry, setClientAddrCountry] = useState("");
  const [clientPesel, setClientPesel] = useState("");
  const [clientIdType, setClientIdType] = useState("");
  const [clientIdNumber, setClientIdNumber] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNIP, setCompanyNIP] = useState("");
  const [companyKRS, setCompanyKRS] = useState("");

  const [principalName, setPrincipalName] = useState("");
  const [principalSurname, setPrincipalSurname] = useState("");
  const [principalAddrStreet, setPrincipalAddrStreet] = useState("");
  const [principalAddrStreetNumber, setPrincipalAddrStreetNumber] = useState("");
  const [principalAddrLocaleNumber, setPrincipalAddrLocaleNumber] = useState("");
  const [principalAddrPostcode, setPrincipalAddrPostcode] = useState("");
  const [principalAddrCity, setPrincipalAddrCity] = useState("");
  const [principalAddrCountry, setPrincipalAddrCountry] = useState("");
  const [principalPesel, setPrincipalPesel] = useState("");
  const [principalIdType, setPrincipalIdType] = useState("");
  const [principalIdNumber, setPrincipalIdNumber] = useState("");
  const [principalPhone, setPrincipalPhone] = useState("");
  const [principalEmail, setPrincipalEmail] = useState("");

  const [propertyAddrStreet, setPropertyAddrStreet] = useState("");
  const [propertyAddrStreetNumber, setPropertyAddrStreetNumber] = useState("");
  const [propertyAddrLocaleNumber, setPropertyAddrLocaleNumber] = useState("");
  const [propertyAddrPostcode, setPropertyAddrPostcode] = useState("");
  const [propertyAddrCity, setPropertyAddrCity] = useState("");
  const [propertyAddrCountry, setPropertyAddrCountry] = useState("");

  const [agentProvisionType, setAgentProvisionType] = useState(dict.AgentProvisionType.percent);
  const [agentProvisionPercentNet, setAgentProvisionPercentNet] = useState(3);
  const [agentProvisionAmountNet, setAgentProvisionAmountNet] = useState(dict.DefaultNumber);
  const [withPresentation, setWithPresentation] = useState(true);

  // Agent
  const [userName, setUserName] = useState("");
  const [userSurname, setUserSurname] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");

  // Branch
  const [branchName, setBranchName] = useState("");
  const [branchAddrStreet, setBranchAddrStreet] = useState("");
  const [branchAddrStreetNumber, setBranchAddrStreetNumber] = useState("");
  const [branchAddrLocaleNumber, setBranchAddrLocaleNumber] = useState("");
  const [branchAddrPostcode, setBranchAddrPostcode] = useState("");
  const [branchAddrCity, setBranchAddrCity] = useState("");
  // eslint-disable-next-line
  const [branchAddrCountry, setBranchAddrCountry] = useState("");
  const [branchEmail, setBranchEmail] = useState("");
  const [branchPhone, setBranchPhone] = useState("");

  // Client fields
  const [clientEmail, setClientEmail] = useState("");
  const [clientInvoiceEmail, setClientInvoiceEmail] = useState("");
  const [agentPostTerminationWork, setAgentPostTerminationWork] = useState(true);

  const [openMessage, setOpenMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Special fields
  const signatures = useRef([]);
  const emails = useRef([]);

  const signatureAgent = useRef(-1);
  const [showSignaturesAfterRender, setShowSignaturesAfterRender] = useState(false);

  const [clientEmailRepeat, setClientEmailRepeat] = useState("");
  const [clientInvoiceEmailRepeat, setClientInvoiceEmailRepeat] = useState("");
  const [useClientEmailToInvoice, setUseClientEmailToInvoice] = useState(true);

  // Texts
  const agentPostTerminationWorkText =
    "Oświadczam, że żądam rozpoczęcia przez HOME świadczenia usługi na podstawie powyższej Umowy zawartej poza lokalem przedsiębiorstwa, przed upływem terminu do odstąpienia od umowy (zgodnie z art. 27 ustawy z dnia 30 maja 2014 r. o prawach konsumenta) i zapewniam, że zapłacę HOME Sp. z o.o. całość Wynagrodzenia ustalonego w Umowie, w przypadku, gdy dojdzie do transakcji określonej w Umowie w wyniku podjętych w tym okresie przez HOME Sp. z o.o. czynności pośrednictwa. ";

  // Principal
  const [coowners, setCoowners] = useState([]);

  const refreshCoowners = async (docId, sessionKey) => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await ContractPurchaseCoownerApi.GetByDocIdSessionKey(docId, sessionKey);
      setCoowners(object);
      object.forEach((e) => {
        handleAfterCoownerEmailRepeat(e.id, e.clientEmail, null);
      });
    } else {
      const object = await ContractPurchaseCoownerApi.GetByDocId(docId, sessionKey);
      setCoowners(object);
      object.forEach((e) => {
        handleAfterCoownerEmailRepeat(e.id, e.clientEmail, null);
      });
    }
  };

  const setFields = async () => {
    try {
      let object;

      if (mode === dict.DocFormViewMode.client) {
        object = await ContractPurchaseApi.ClientGetByIdSessionKey(docId, sessionClient.getSessionKey());
      } else {
        object = await ContractPurchaseApi.ClientGetById(docId, sessionClient.getSessionKey());
      }

      await refreshCoowners(docId, sessionClient.getSessionKey());

      setContractVariant(object.contractVariant);
      setContractDate(object.contractDate);
      setContractCity(object.contractCity);
      setContractDurationMonth(object.contractDurationMonth);

      setClientType(object.clientType);

      setClientName(object.clientName);
      setClientSurname(object.clientSurname);
      setClientAddrStreet(object.clientAddrStreet);
      setClientAddrStreetNumber(object.clientAddrStreetNumber);
      setClientAddrLocaleNumber(object.clientAddrLocaleNumber);
      setClientAddrPostcode(object.clientAddrPostcode);
      setClientAddrCity(object.clientAddrCity);
      setClientAddrCountry(object.clientAddrCountry);
      setClientPhone(object.clientPhone);
      setClientEmail(object.clientEmail);
      setClientPesel(object.clientPesel);
      setClientIdType(object.clientIdType);
      setClientIdNumber(object.clientIdNumber);
      setCompanyName(object.companyName);
      setCompanyNIP(object.companyNIP);
      setCompanyKRS(object.companyKRS);

      // Principal
      setPrincipalName(object.principalName);
      setPrincipalSurname(object.principalSurname);
      setPrincipalAddrStreet(object.principalAddrStreet);
      setPrincipalAddrStreetNumber(object.principalAddrStreetNumber);
      setPrincipalAddrLocaleNumber(object.principalAddrLocaleNumber);
      setPrincipalAddrPostcode(object.principalAddrPostcode);
      setPrincipalAddrCity(object.principalAddrCity);
      setPrincipalAddrCountry(object.principalAddrCountry);
      setPrincipalPhone(object.principalPhone);
      setPrincipalEmail(object.principalEmail);
      setPrincipalPesel(object.principalPesel);
      setPrincipalIdType(object.principalIdType);
      setPrincipalIdNumber(object.principalIdNumber);

      setPropertyAddrStreet(object.propertyAddrStreet);
      setPropertyAddrStreetNumber(object.propertyAddrStreetNumber);
      setPropertyAddrLocaleNumber(object.propertyAddrLocaleNumber);
      setPropertyAddrPostcode(object.propertyAddrPostcode);
      setPropertyAddrCity(object.propertyAddrCity);
      setPropertyAddrCountry(object.propertyAddrCountry);

      setAgentProvisionType(object.agentProvisionType);
      setAgentProvisionPercentNet(object.agentProvisionPercentNet);
      setAgentProvisionAmountNet(object.agentProvisionAmountNet);
      setWithPresentation(object.withPresentation);

      // Agent
      setUserName(object.UserAgent.name);
      setUserSurname(object.UserAgent.surname);
      setUserPhone(object.UserAgent.phone);
      setUserEmail(object.UserAgent.email);

      // Branch
      setBranchName(object.Branch.name);
      setBranchAddrStreet(object.Branch.addrStreet);
      setBranchAddrStreetNumber(object.Branch.addrStreetNumber);
      setBranchAddrLocaleNumber(object.Branch.addrLocaleNumber);
      setBranchAddrPostcode(object.Branch.addrPostcode);
      setBranchAddrCity(object.Branch.addrCity);
      setBranchAddrCountry(object.Branch.addrCountry);
      setBranchEmail(object.Branch.email);
      setBranchPhone(object.Branch.phone);

      // Dedicated
      if (useClientEmailToInvoice) {
        setClientInvoiceEmail(object.clientEmail);
        setClientInvoiceEmailRepeat(object.clientEmail);
      }

      // Signature
      if (mode === dict.DocFormViewMode.client) {
        const signatureAgentBlob = await ContractPurchaseApi.GetAgentSignatureById(docId);

        if (signatureAgentBlob && signatureAgentBlob.size) {
          signatureAgent.current = await convertBlobToBase64(signatureAgentBlob);
          setShowSignaturesAfterRender((prevValue) => !prevValue);
        }
      }
    } catch (error) {
      console.error("Wystąpił błąd PDF: ", error); // TODO: wysłać na serwer do loga, trzeba reagować
    }
  };

  useEffect(() => {
    setFields();

    return () => {};
    // eslint-disable-next-line
  }, []);

  const coownersData = useRef([]);
  const attachmentsData = useRef([]);

  const getCoownersData = async () => {
    if (docId && mode === dict.DocFormViewMode.agent) {
      const object = await ContractPurchaseCoownerApi.GetByDocId(docId, sessionClient.getSessionKey());
      coownersData.current = object;
    } else if (docId && mode === dict.DocFormViewMode.client) {
      const object = await ContractPurchaseCoownerApi.GetByDocIdSessionKey(docId, sessionClient.getSessionKey());
      coownersData.current = object;
    }
  };

  const getAttachmentsData = async () => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await ContractPurchaseBinaryApi.GetIdsByDocIdSessionKey(docId, null, sessionClient.getSessionKey());
      attachmentsData.current = object;
    } else {
      const object = await ContractPurchaseBinaryApi.GetIdsByDocId(docId, null);
      // setAttachmentsData([
      //   { id: 19, label: "Kolorki", mimeType: "image/png" },
      //   { id: 20, label: "Lewek", mimeType: "image/png" },
      // ]);

      attachmentsData.current = object;
    }
  };

  const prepareExternalPrintingData = async () => {
    await getCoownersData();
    await getAttachmentsData();
  };

  const printingToBlob = async () => {
    await prepareExternalPrintingData();

    return await pdf(
      <ContractPurchasePDF
        id={docId}
        mode={mode}
        sessionKey={sessionClient.getSessionKey()}
        contractDate={dayjs(contractDate).format("YYYY-MM-DD")}
        contractHour={dayjs(contractDate).format("HH:mm")}
        contractCity={contractCity}
        clientName={clientName}
        clientSurname={clientSurname}
        clientAddrStreet={clientAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        clientAddrPostcode={clientAddrPostcode}
        clientAddrCity={clientAddrCity}
        clientAddrCountry={clientAddrCountry}
        clientEmail={clientEmail}
        clientPhone={clientPhone}
        clientPesel={clientPesel}
        clientIdType={clientIdType}
        clientIdNumber={clientIdNumber}
        propertyAddrStreet={propertyAddrStreet}
        propertyAddrStreetNumber={propertyAddrStreetNumber}
        propertyAddrLocaleNumber={propertyAddrLocaleNumber}
        propertyAddrPostcode={propertyAddrPostcode}
        propertyAddrCity={propertyAddrCity}
        propertyAddrCountry={propertyAddrCountry}
        agentPostTerminationWork={agentPostTerminationWork}
        agentProvisionType={agentProvisionType}
        agentProvisionPercentNet={agentProvisionPercentNet}
        agentProvisionAmountNet={agentProvisionAmountNet}
        withPresentation={withPresentation}
        agentName={userName}
        agentSurname={userSurname}
        agentPhone={userPhone}
        agentEmail={userEmail}
        branchName={branchName}
        branchAddressLine1={"".concat(
          branchAddrStreet ? "ul. ".concat(branchAddrStreet) : "",
          " ",
          branchAddrStreetNumber,
          branchAddrLocaleNumber ? "/".concat(branchAddrLocaleNumber) : ""
        )}
        branchAddressLine2={"".concat(branchAddrPostcode, " ", branchAddrCity)}
        branchEmail={branchEmail}
        branchPhone={branchPhone}
        signatureClient={getSignatureByActor("client")}
        signatureAgent={signatureAgent.current}
        signatures={signatures.current}
        contractVariant={contractVariant}
        contractDurationMonth={contractDurationMonth}
        clientType={clientType}
        principalName={principalName}
        principalSurname={principalSurname}
        principalAddrStreet={principalAddrStreet}
        principalAddrStreetNumber={principalAddrStreetNumber}
        principalAddrLocaleNumber={principalAddrLocaleNumber}
        principalAddrPostcode={principalAddrPostcode}
        principalAddrCity={principalAddrCity}
        principalAddrCountry={principalAddrCountry}
        principalEmail={principalEmail}
        principalPhone={principalPhone}
        principalPesel={principalPesel}
        principalIdType={principalIdType}
        principalIdNumber={principalIdNumber}
        companyName={companyName}
        companyNIP={companyNIP}
        companyKRS={companyKRS}
        coownersData={coownersData.current}
        attachmentsData={attachmentsData.current}
        useClientEmailToInvoice={useClientEmailToInvoice}
        clientInvoiceEmail={clientInvoiceEmail}
      />
    ).toBlob();
  };

  const confirmData = async () => {
    try {
      // mail klienta - wysyłka dokumentu
      if (!clientEmail) {
        setErrorMessage("Wprowadź adres e-mail. (" + clientName + " " + clientSurname + ")");
        setOpenMessage(true);
        return -1;
      }

      if (!validateEmail(clientEmail)) {
        setErrorMessage("Podany adres e-mail jest niepoprawny. Zweryfikuj i popraw. (" + clientName + " " + clientSurname + ")");
        setOpenMessage(true);
        return -1;
      }

      if (!clientEmailRepeat) {
        setErrorMessage("Wprowadź adres e-mail ponownie w pole potwierdzenia. (" + clientName + " " + clientSurname + ")");
        setOpenMessage(true);
        return -1;
      }

      if (!validateEmail(clientEmailRepeat)) {
        setErrorMessage("Podany ponownie adres e-mail jest niepoprawny. Zweryfikuj i popraw. (" + clientName + " " + clientSurname + ")");
        setOpenMessage(true);
        return -1;
      }

      if (clientEmail.toLocaleLowerCase() !== clientEmailRepeat.toLocaleLowerCase()) {
        setErrorMessage(
          "Adres e-mail w obu polach musi być jednakowy, występują różnice. Zweryfikuj i popraw. (" + clientName + " " + clientSurname + ")"
        );
        setOpenMessage(true);
        return -1;
      }

      // mail do faktury
      if (!clientInvoiceEmail) {
        setErrorMessage("Wprowadź adres e-mail do faktury. (" + clientName + " " + clientSurname + ")");
        setOpenMessage(true);
        return -1;
      }

      if (!validateEmail(clientInvoiceEmail)) {
        setErrorMessage("Podany adres e-mail do faktury jest niepoprawny. Zweryfikuj i popraw. (" + clientName + " " + clientSurname + ")");
        setOpenMessage(true);
        return -1;
      }

      if (!clientInvoiceEmailRepeat) {
        setErrorMessage("Wprowadź adres e-mail do faktury ponownie w pole potwierdzenia. (" + clientName + " " + clientSurname + ")");
        setOpenMessage(true);
        return -1;
      }

      if (!validateEmail(clientInvoiceEmailRepeat)) {
        setErrorMessage("Podany ponownie adres e-mail do faktury jest niepoprawny. Zweryfikuj i popraw. (" + clientName + " " + clientSurname + ")");
        setOpenMessage(true);
        return -1;
      }

      if (clientInvoiceEmail.toLocaleLowerCase() !== clientInvoiceEmailRepeat.toLocaleLowerCase()) {
        setErrorMessage(
          "Adres e-mail do faktury w obu polach musi być jednakowy, występują różnice. Zweryfikuj i popraw. (" +
            clientName +
            " " +
            clientSurname +
            ")"
        );
        setOpenMessage(true);
        return -1;
      }

      for (let i = 0; i < coowners.length; i++) {
        let coowner = null;
        coowner = emails.current.find((e) => e.actorId === coowners[i].id);
        let name = coowners[i].clientName;
        let surname = coowners[i].clientSurname;

        if (!coowner || !coowner.email) {
          setErrorMessage("Wprowadź adres e-mail. (" + name + " " + surname + ")");
          setOpenMessage(true);
          return -1;
        }

        if (!validateEmail(coowner.email)) {
          setErrorMessage("Podany adres e-mail jest niepoprawny. Zweryfikuj i popraw. (" + name + " " + surname + ")");
          setOpenMessage(true);
          return -1;
        }

        if (!coowner.emailRepeated) {
          setErrorMessage("Wprowadź adres e-mail ponownie w pole potwierdzenia. (" + name + " " + surname + ")");
          setOpenMessage(true);
          return -1;
        }

        if (!validateEmail(coowner.emailRepeated)) {
          setErrorMessage("Podany ponownie adres e-mail jest niepoprawny. Zweryfikuj i popraw. (" + name + " " + surname + ")");
          setOpenMessage(true);
          return -1;
        }

        if (coowner.email.toLocaleLowerCase() !== coowner.emailRepeated.toLocaleLowerCase()) {
          setErrorMessage("Adres e-mail w obu polach musi być jednakowy, występują różnice. Zweryfikuj i popraw. (" + name + " " + surname + ")");
          setOpenMessage(true);
          return -1;
        }
      }

      if (signatureAgent.current === -1) {
        setErrorMessage("Podpis Agenta jest wymagany.");
        setOpenMessage(true);
        return -1;
      }

      if (mode === dict.DocFormViewMode.agent && getSignatureCounterByActor("agent") < 800) {
        setErrorMessage("Podpis Agenta jest uzupełniony niepoprawnie i wymaga poprawy.");
        setOpenMessage(true);
        return -1;
      }

      if (getSignatureByActor("client") === -1) {
        setErrorMessage("Podpis Klienta jest wymagany.");
        setOpenMessage(true);
        return -1;
      }

      if (getSignatureCounterByActor("client") < 800) {
        setErrorMessage("Podpis Klienta jest uzupełniony niepoprawnie i wymaga poprawy.");
        setOpenMessage(true);
        return -1;
      }

      for (let i = 0; i < coowners.length; i++) {
        let coowner = null;
        coowner = signatures.current.find((e) => e.actorType === "coowner" && e.actorId === coowners[i].id);

        if (!coowner || (coowner && getSignatureByActor("coowner", coowners[i].id) === -1)) {
          let name = coowners[i].clientName;
          let surname = coowners[i].clientSurname;
          setErrorMessage("Podpis Klienta (" + name + " " + surname + ") jest wymagany.");
          setOpenMessage(true);
          return -1;
        }

        if (!coowner || (coowner && getSignatureCounterByActor("coowner", coowners[i].id) < 800)) {
          let name = coowners[i].clientName;
          let surname = coowners[i].clientSurname;
          setErrorMessage("Podpis Klienta (" + name + " " + surname + ") jest uzupełniony niepoprawnie i wymaga poprawy.");
          setOpenMessage(true);
          return -1;
        }
      }

      if (debug) {
        downloadPrintingDebugOnly();

        setErrorMessage("Błąd celowy");
        setOpenMessage(true);
        return -1;
      }

      let clientAcceptedDate = dayjs(new Date().toUTCString());
      let printingAsBlob = await printingToBlob(clientAcceptedDate);

      const fieldToUpdate = {
        documentPDF: printingAsBlob,
        status: dict.DocStatus.accepted,
        clientAcceptedAt: clientAcceptedDate,
        clientEmail: clientEmail,
        clientInvoiceEmail: useClientEmailToInvoice ? clientEmail : clientInvoiceEmail,
        agentPostTerminationWork: agentPostTerminationWork,
      };

      if (mode === dict.DocFormViewMode.client) {
        await ContractPurchaseApi.ClientUpdateByIdSessionKey(docId, sessionClient.getSessionKey(), fieldToUpdate);
      } else {
        await ContractPurchaseApi.ClientUpdateById(docId, sessionClient.getSessionKey(), fieldToUpdate);
      }

      // await MailApi.Send({
      //   to: clientEmail,
      //   subject: "Protokół prezentacji z oględzin mieszkania",
      //   text: "W załączniku przesyłamy zatwierdzony dokument. Dziękujemy.",
      //   attachments: [presentationProtocolId],
      // });
      if (props.onAccept) {
        //mode === dict.DocFormViewMode.client
        props.onAccept();
      } else {
        //mode === dict.DocFormViewMode.agent
        backToAgentForm();
      }
    } catch (error) {
      console.error("Wystąpił błąd: ", error); // TODO: wysłać na serwer do loga, trzeba reagować
    }
  };

  const handleDialogClose = () => {
    setOpenMessage(false);
  };

  const handleOnTouchEndSignature = (actorType, actorId, counter, value) => {
    let before = [];

    if (actorType === "agent" || actorType === "client") {
      before = signatures.current.filter((e) => e.actorType !== actorType);
      before.push({
        actorType: actorType,
        actorId: null,
        counter: counter,
        sign: value,
      });

      if (actorType === "agent") {
        signatureAgent.current = value;
      }
    } else {
      before = signatures.current.filter((e) => e.actorType !== actorType || e.actorId !== actorId);
      before.push({
        actorType: actorType,
        actorId: actorId,
        counter: counter,
        sign: value,
      });
    }

    signatures.current = before;
  };

  const getSignatureByActor = (actorType, actorId) => {
    let record;

    if (actorType === "client" || actorType === "agent") {
      record = signatures.current.find((e) => e.actorType === actorType);
      return record?.sign ? record.sign : -1;
    } else {
      record = signatures.current.find((e) => e.actorType === actorType && e.actorId === actorId);
      return record?.sign ? record.sign : -1;
    }
  };

  const getSignatureCounterByActor = (actorType, actorId) => {
    let record;

    if (actorType === "client" || actorType === "agent") {
      record = signatures.current.find((e) => e.actorType === actorType);
      return record?.counter ? record.counter : 0;
    } else {
      record = signatures.current.find((e) => e.actorType === actorType && e.actorId === actorId);
      return record?.counter ? record.counter : 0;
    }
  };

  const downloadPrintingDebugOnly = async () => {
    let link = document.createElement("a");
    link.download = "testa.pdf";
    let response = await printingToBlob();
    let blob = new Blob([response], { type: "application/pdf" });
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const backToForm = () => {
    navigate(RoutePath.ContractPurchaseAdd, {
      state: {
        id: docId,
      },
    });
  };

  const backToAgentForm = () => {
    navigate(RoutePath.ContractPurchaseClientFinish, {
      state: {
        id: docId,
        email: clientEmail,
      },
    });
  };

  const renderSignature = (value) => {
    if (value && value !== -1) {
      return (
        // <Image
        //   source={value}
        //   type={"image/png"}
        //   style={{
        //     padding: "0",
        //     margin: "0",
        //     width: "180",
        //     height: "70",
        //   }}
        //   // width="360px"
        //   // height="140px"
        // />
        <div style={{ marginTop: "-15px" }}>
          <h3>Podpis Agenta</h3>
          <img src={value} type={"image/png"} alt="Podpis Agenta" width="180px" height="70px" />
        </div>
      );
    }
    return <div></div>;
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2} sx={{ pt: 6, pb: 8, justifyContent: "center" }}>
        {mode === dict.DocFormViewMode.client && (
          <Button variant="outlined" size="large" startIcon={<RefreshOutlinedIcon />} onClick={setFields}>
            {"Odśwież dane"}
          </Button>
        )}
        {mode === dict.DocFormViewMode.agent && (
          <Button variant="outlined" size="large" startIcon={<UndoOutlinedIcon />} onClick={backToForm}>
            {"Wstecz"}
          </Button>
        )}
        {!isInspection && (
          <Button variant="contained" size="large" startIcon={<ThumbUpAltOutlinedIcon />} onClick={confirmData}>
            {"Potwierdzam"}
          </Button>
        )}
      </Stack>
    );
  };

  const handleAfterCoownerEmailRepeat = (actorId, email, emailRepeated) => {
    let before = [];

    before = emails.current.filter((e) => e.actorId !== actorId);
    before.push({
      actorId: actorId,
      email: email,
      emailRepeated: emailRepeated,
    });

    emails.current = before;
  };

  return (
    <Stack style={{ m: 0, p: 0 }}>
      <Stack direction="column" spacing={1} sx={{ p: 2, textAlign: "left", m: 1 }}>
        <MessageOK open={openMessage} onClose={handleDialogClose} title={"Błąd"} text={errorMessage} />
        <img
          style={{
            top: -5,
            left: -1,
            width: "25px",
            position: "absolute",
          }}
          src={"/assets/Graphics/client/docLogoTop.png"}
          alt={"logo"}
        />
        <Typography variant="h4" component="h1" sx={{ pl: 2, pb: 1 }}>
          {"Potwierdzenie Umowy pośrednictwa"}
        </Typography>
        <Stack>
          <ContractPurchaseHTML
            id={docId}
            mode={mode}
            sessionKey={sessionClient.getSessionKey()}
            contractDate={dayjs(contractDate).format("YYYY-MM-DD")}
            contractHour={dayjs(contractDate).format("HH:mm")}
            contractCity={contractCity}
            clientName={clientName}
            clientSurname={clientSurname}
            clientAddrStreet={clientAddrStreet}
            clientAddrStreetNumber={clientAddrStreetNumber}
            clientAddrLocaleNumber={clientAddrLocaleNumber}
            clientAddrPostcode={clientAddrPostcode}
            clientAddrCity={clientAddrCity}
            clientAddrCountry={clientAddrCountry}
            clientEmail={clientEmail}
            clientPhone={clientPhone}
            clientPesel={clientPesel}
            clientIdType={clientIdType}
            clientIdNumber={clientIdNumber}
            propertyAddrStreet={propertyAddrStreet}
            propertyAddrStreetNumber={propertyAddrStreetNumber}
            propertyAddrLocaleNumber={propertyAddrLocaleNumber}
            propertyAddrPostcode={propertyAddrPostcode}
            propertyAddrCity={propertyAddrCity}
            propertyAddrCountry={propertyAddrCountry}
            agentProvisionType={agentProvisionType}
            agentProvisionPercentNet={agentProvisionPercentNet}
            agentProvisionAmountNet={agentProvisionAmountNet}
            withPresentation={withPresentation}
            agentName={userName}
            agentSurname={userSurname}
            agentPhone={userPhone}
            agentEmail={userEmail}
            branchName={branchName}
            branchAddressLine1={"".concat(
              branchAddrStreet ? "ul. ".concat(branchAddrStreet) : "",
              " ",
              branchAddrStreetNumber,
              branchAddrLocaleNumber ? "/".concat(branchAddrLocaleNumber) : ""
            )}
            branchAddressLine2={"".concat(branchAddrPostcode, " ", branchAddrCity)}
            branchEmail={branchEmail}
            branchPhone={branchPhone}
            contractVariant={contractVariant}
            contractDurationMonth={contractDurationMonth}
            clientType={clientType}
            principalName={principalName}
            principalSurname={principalSurname}
            principalAddrStreet={principalAddrStreet}
            principalAddrStreetNumber={principalAddrStreetNumber}
            principalAddrLocaleNumber={principalAddrLocaleNumber}
            principalAddrPostcode={principalAddrPostcode}
            principalAddrCity={principalAddrCity}
            principalAddrCountry={principalAddrCountry}
            principalEmail={principalEmail}
            principalPhone={principalPhone}
            principalPesel={principalPesel}
            principalIdType={principalIdType}
            principalIdNumber={principalIdNumber}
            companyName={companyName}
            companyNIP={companyNIP}
            companyKRS={companyKRS}
            coowners={coowners}
          />
        </Stack>
        <Stack sx={{ fontSize: "15px", paddingBottom: "15px" }}>{"* Dokument zostanie przekazany drogą e-mail."}</Stack>
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="body1" sx={{ fontSize: "11px" }}>
                {agentPostTerminationWorkText}
              </Typography>
            }
            sx={{
              mr: 0,
            }}
            checked={agentPostTerminationWork}
            onChange={(e) => setAgentPostTerminationWork(e.target.checked)}
          />
        </Stack>
        <Stack sx={{ fontSize: "15px", fontWeight: "bold", paddingTop: "10px" }}>{clientName + " " + clientSurname}</Stack>
        <Stack direction="column" spacing={2}>
          <TextField
            size="small"
            label={"Wprowadź e-mail"}
            id={"clientEmail"}
            value={clientEmail}
            onChange={(e) => {
              setClientEmail(e.target.value);

              if (useClientEmailToInvoice) {
                setClientInvoiceEmail(e.target.value);
                setClientInvoiceEmailRepeat(e.target.value);
              }
            }}
            disabled={false}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            type="email"
            error={!validateEmail(clientEmail) ? true : false}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Potwierdź e-mail"}
            id={"clientEmailRepeat"}
            value={clientEmailRepeat}
            onChange={(e) => {
              setClientEmailRepeat(e.target.value);
            }}
            disabled={false}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            type="email"
            error={!validateEmail(clientEmailRepeat) ? true : false}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="body1" sx={{ fontSize: "11px" }}>
                {"Powyższy e-mail dotyczy również fakturowania"}
              </Typography>
            }
            sx={{
              mr: 0,
            }}
            checked={useClientEmailToInvoice}
            onChange={(e) => {
              setUseClientEmailToInvoice(e.target.checked);

              if (e.target.checked) {
                setClientInvoiceEmail(clientEmail);
                setClientInvoiceEmailRepeat(clientEmail);
              }
            }}
          />
        </Stack>
        {!useClientEmailToInvoice && (
          <Stack direction="column" spacing={2}>
            <TextField
              size="small"
              label={"Wprowadź e-mail do faktury"}
              id={"clientInvoiceEmail"}
              value={clientInvoiceEmail}
              onChange={(e) => {
                setClientInvoiceEmail(e.target.value);
              }}
              disabled={false}
              sx={{ pb: 2, width: "100%" }}
              required={true}
              type="email"
              error={!validateEmail(clientInvoiceEmail) ? true : false}
            />
          </Stack>
        )}
        {!useClientEmailToInvoice && (
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Potwierdź e-mail do faktury"}
              id={"clientInvoiceEmailRepeat"}
              value={clientInvoiceEmailRepeat}
              onChange={(e) => {
                setClientInvoiceEmailRepeat(e.target.value);
              }}
              disabled={false}
              sx={{ pb: 2, width: "100%" }}
              required={true}
              type="email"
              error={!validateEmail(clientInvoiceEmailRepeat) ? true : false}
            />
          </Stack>
        )}
        {coowners.map((item) => (
          <Emails data={item} key={"coownerEmail".concat(item.id)} onAfterCoownerEmailRepeat={(a, b, c) => handleAfterCoownerEmailRepeat(a, b, c)} />
        ))}
        <Grid container spacing={1}>
          {mode === dict.DocFormViewMode.client && (
            <Stack
              sx={{
                p: 1,
                maxWidth: 1200,
              }}
            >
              {showSignaturesAfterRender ? "" : ""}
              {renderSignature(signatureAgent.current)}
            </Stack>
          )}

          {mode === dict.DocFormViewMode.agent && (
            <Grid item xs={12} sm={12} md={12}>
              <DrawingCanvas
                id={"signatureAgent"}
                title="Podpis Agenta"
                subtitle={"".concat(userName, " ", userSurname)}
                onTouchEnd={(a, b) => handleOnTouchEndSignature("agent", null, a, b)}
                marginTop={"0px"}
                scale={isSm ? 3 : 2}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <DrawingCanvas
              id={"signatureClient"}
              title="Podpis Klienta"
              subtitle={"".concat(clientName, " ", clientSurname)}
              onTouchEnd={(a, b) => handleOnTouchEndSignature("client", null, a, b)}
              marginTop={"0px"}
              scale={isSm ? 3 : 2}
            />
          </Grid>
          {coowners.map((item) => (
            <Grid key={"".concat("gridCoowner", item.id)} item xs={12} sm={12} md={12}>
              <DrawingCanvas
                key={"".concat("signatureCoowner", item.id)}
                id={"".concat("signatureCoowner", item.id)}
                title={"Podpis Klienta"}
                subtitle={"".concat(item.clientName, " ", item.clientSurname)}
                onTouchEnd={(a, b) => handleOnTouchEndSignature("coowner", item.id, a, b)}
                marginTop={"0px"}
                scale={isSm ? 3 : 2}
              />
            </Grid>
          ))}
        </Grid>
        {renderButtons()}
      </Stack>
    </Stack>
  );
};

export default ClientForm;
