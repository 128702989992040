import * as React from "react";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import style from "./Content.module.css";

export default function Content(props) {
  const loadContent = () => {
    if (props.tabActiveIndex === 0) {
      return props.tab0Content;
    } else if (props.tabActiveIndex === 1) {
      return props.tab1Content;
    } else if (props.tabActiveIndex === 2) {
      return props.tab2Content;
    } else if (props.tabActiveIndex === 3) {
      return props.tab3Content;
    }
  };

  const loadTitle = () => {
    if (props.tabActiveIndex === 0) {
      return props.tab0Title;
    } else if (props.tabActiveIndex === 1) {
      return props.tab1Title;
    } else if (props.tabActiveIndex === 2) {
      return props.tab2Title;
    } else if (props.tabActiveIndex === 3) {
      return props.tab3Title;
    }
  };

  return (
    <Paper
      sx={{
        maxWidth: 1450,
        margin: "left",
        overflow: "hidden",
        textAlign: "left",
      }}
    >
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          pl: 1,
        }}
      >
        {loadTitle()}
      </AppBar>
      <div className={style.content}>{loadContent()}</div>
    </Paper>
  );
}
