import { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Stack from "@mui/material/Stack";
import * as dict from "../../config/Dictionary";
import * as UserApi from "./UserApi";
import PasswordChange from "./Operations/PasswordChange";
import { phoneValidator, emailValidator } from "./../../utils/validator";
//import { SHA256 } from "crypto-js";

const UserForm = (props) => {
  const objectId = props.id;
  const mode = props.mode;
  const isModePreview = props.mode === dict.FormMode.preview ? true : false;
  const isModeEdit = props.mode === dict.FormMode.edit ? true : false;

  const [login, setLogin] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [hasPhoneError, setHasPhoneError] = useState(false);

  const [email, setEmail] = useState("");
  const [hasEmailError, setHasEmailError] = useState(false);

  const setPresentationProtocolFields = async (userId) => {
    try {
      const user = await UserApi.GetById(userId);

      setLogin(user.login);
      setName(user.name);
      setSurname(user.surname);
      setPhone(user.phone);
      setEmail(user.email);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const setPresentationProtocolDedicatedFields = async (presentationProtocolId) => {
    try {
      // const presentationProtocol = await UserApi.GetById(
      //   presentationProtocolId
      // );
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (mode === dict.FormMode.edit) {
      setPresentationProtocolFields(objectId);
    } else if (mode === dict.FormMode.preview) {
      setPresentationProtocolFields(objectId);
      setPresentationProtocolDedicatedFields(objectId);
    } else if (mode === dict.FormMode.add) {
    }

    // eslint-disable-next-line
  }, []);

  const validateFormCorrectness = () => {
    if (!name && !surname && !login && !phone) {
      props.onNotificationShow("Przed zapisem wymagane jest uzupełnienie minimum jednego pola", dict.NotificationType.error);
      return -1;
    }

    if (!phoneValidator(phone)) {
      props.onNotificationShow("Numer telefonu posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!emailValidator(email)) {
      props.onNotificationShow("E-mail posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }
  };

  const getCurrentFormData = (formMode) => {
    let data = {
      login: login,
      name: name,
      surname: surname,
      phone: phone,
      email: email,
    };

    if (formMode === dict.FormMode.add) {
      data = {
        ...data,
        //status: dict.DocStatus.new,
      };
    } else if (formMode === dict.FormMode.edit) {
      data = {
        ...data,
      };
    }

    return data;
  };

  const saveForAddMode = async () => {
    // eslint-disable-next-line
    let objId = await UserApi.Create(getCurrentFormData(dict.FormMode.add));
    return objId;
  };

  const saveForEditMode = async () => {
    await UserApi.Update(objectId, getCurrentFormData(dict.FormMode.edit));

    // console.log(
    //   "salt: ",
    //   //tmp.passwordSalt,
    //   name,
    //   "-hasło: ",
    //   SHA256(surname.concat(name)).toString()
    // );
    return objectId;
  };

  const handleSaveClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormCorrectness();
      if (result === -1) {
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveForAddMode();
      } else if (mode === dict.FormMode.edit) {
        objId = await saveForEditMode();
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd: ".concat(error.message), dict.NotificationType.error);
    }
  };

  const handleCancelClick = () => {
    if (props.onAfterCancel) {
      props.onAfterCancel(mode);
    }
  };

  const handleBackClick = () => {
    if (props.onAfterBack) {
      props.onAfterBack(mode);
    }
  };

  const renderPreviewInfo = () => {
    if (mode !== dict.FormMode.preview) return;

    return <Stack sx={{ pt: 2, fontSize: 13 }}></Stack>;
  };

  const renderPasswordChange = () => {
    if (!isModeEdit) return;

    return <PasswordChange id={objectId} />;
  };

  return (
    <Stack>
      <FormGroup
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          maxWidth: 1200,
          mb: 2,
        }}
      >
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 0, pb: 1 }}>
          {""}
        </Stack>

        {renderPreviewInfo()}
        {renderPasswordChange()}
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
          {"Użytkownik"}
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Login"}
            id={"login"}
            value={login}
            onChange={(e) => {
              setLogin(e.target.value);
            }}
            disabled={isModePreview || isModeEdit}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Imię"}
            id={"name"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Nazwisko"}
            id={"surname"}
            value={surname}
            onChange={(e) => {
              setSurname(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"E-mail"}
            id={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              emailValidator(e.target.value) ? setHasEmailError(false) : setHasEmailError(true);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={hasEmailError}
            helperText="Prawidłowy format: nazwa@dome.na"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Telefon"}
            id={"phone"}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              phoneValidator(e.target.value) ? setHasPhoneError(false) : setHasPhoneError(true);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={hasPhoneError}
            helperText="Prawidłowy format: 123456789 lub +48123456789"
          />
        </Stack>
      </FormGroup>

      <Stack direction="row" spacing={2}>
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<SaveOutlinedIcon />} onClick={handleSaveClick}>
            {"Zapisz"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<CancelOutlinedIcon />} onClick={handleCancelClick}>
            {"Wyjdź"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? true : false) && (
          <Button variant="contained" size="small" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackClick}>
            {"Wstecz"}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default UserForm;
