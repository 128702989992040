import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const Address = (props) => {
  return (
    <Stack>
      <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
        {props.title ? props.title : "Adres"}
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          label={"Ulica"}
          id={"addrStreet"}
          value={props.addrStreet}
          onChange={(e) => props.onChangeAddrStreet(e.target.value)}
          disabled={props.isModePreview}
          sx={{ pb: 2, width: "100%" }}
          required={true}
          error={props.hasErrorAddrStreet}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          label={"Numer budynku"}
          id={"addrStreetNumber"}
          value={props.addrStreetNumber}
          onChange={(e) => props.onChangeAddrStreetNumber(e.target.value)}
          disabled={props.isModePreview}
          sx={{ pb: 2, width: "50%" }}
          required={true}
          error={props.hasErrorAddrStreetNumber}
        />
        <TextField
          size="small"
          label={"Numer lokalu"}
          id={"addrLocaleNumber"}
          value={props.addrLocaleNumber}
          onChange={(e) => props.onChangeAddrLocaleNumber(e.target.value)}
          disabled={props.isModePreview}
          sx={{ pb: 2, width: "50%" }}
          required={false}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          label={"Kod pocztowy"}
          id={"addrPostcode"}
          value={props.addrPostcode}
          onChange={(e) => props.onChangeAddrPostcode(e.target.value)}
          disabled={props.isModePreview}
          sx={{ pb: 2, width: "100%" }}
          required={false}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          label={"Miejscowość"}
          id={"addrCity"}
          value={props.addrCity}
          onChange={(e) => props.onChangeAddrCity(e.target.value)}
          disabled={props.isModePreview}
          sx={{ pb: 2, width: "100%" }}
          required={true}
          error={props.hasErrorAddrCity}
        />
      </Stack>
    </Stack>
  );
};

export default Address;
