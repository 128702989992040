import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import LegalEntityType from "./LegalEntityType";
import Address from "./Address";

import * as dict from "../../config/Dictionary";

const Person = (props) => {
  const isRepresentative = props.isRepresentative;

  return (
    <Stack>
      <Stack>
        {!isRepresentative && (
          <LegalEntityType
            sectionName={props.clientSectionName}
            isModePreview={props.isModePreview}
            hasError={props.hasErrorSectionClient}
            defaultType={props.clientType}
            onChange={(a) => props.onClientTypeChoosen(a)}
          />
        )}
        {isRepresentative && (
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }} style={{ color: props.hasErrorSectionClient ? "red" : "" }}>
            {"Pełnomocnik"}
          </Stack>
        )}
        <Stack direction="row" spacing={2}>
          <TextField
            key={"pclientName".concat(props.clientType, props.recordId)}
            size="small"
            label={"Imię"}
            id={"clientName"}
            value={props.clientName}
            onChange={(e) => {
              props.onChangeClientName(e.target.value);
            }}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={props.hasErrorClientName}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"pclientSurname".concat(props.clientType, props.recordId)}
            size="small"
            label={"Nazwisko"}
            id={"clientSurname"}
            value={props.clientSurname}
            onChange={(e) => {
              props.onChangeClientSurname(e.target.value);
            }}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={props.hasErrorClientSurname}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"pclientPhone".concat(props.clientType, props.recordId)}
            size="small"
            label={"Telefon"}
            id={"clientPhone"}
            value={props.clientPhone}
            onChange={(e) => {
              props.onChangeClientPhone(e.target.value);
            }}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={props.hasErrorClientPhone}
            helperText="Prawidłowy format: 123456789 lub +48123456789"
            type={"number"}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"pclientEmail".concat(props.clientType, props.recordId)}
            size="small"
            label={"E-mail"}
            id={"clientEmail"}
            value={props.clientEmail}
            onChange={(e) => {
              props.onChangeClientEmail(e.target.value);
            }}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={false}
            error={props.hasErrorClientEmail}
            helperText="Prawidłowy format: example@example.com"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"pclientAddrCountry".concat(props.clientType, props.recordId)}
            size="small"
            label={"Kraj obywatelstwa"}
            id={"clientAddrCountry"}
            value={props.clientAddrCountry}
            onChange={(e) => {
              props.onChangeClientAddrCountry(e.target.value);
            }}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={props.hasErrorClientAddrCountry}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"pclientPesel".concat(props.clientType, props.recordId)}
            size="small"
            label={"Pesel"}
            id={"clientPesel"}
            value={props.clientPesel}
            onChange={(e) => {
              props.onChangeClientPesel(e.target.value);
            }}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={false}
            error={props.hasErrorClientPesel}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            key={"pclientIdTypeAutocomplete".concat(props.clientType, props.recordId)}
            disablePortal
            id="clientIdType"
            options={dict.IdentityDocumentType}
            sx={{ width: "100%", pb: 2 }}
            renderInput={(params) => (
              <TextField
                key={"pclientIdType".concat(props.clientType, props.recordId)}
                {...params}
                label="Rodzaj dokumentu identyfikacji"
                size="small"
                required={true}
                error={props.hasErrorClientIdType}
              />
            )}
            value={props.clientIdTypeCombo}
            onChange={(e, value) => {
              props.onChangeClientIdType(value?.code);
              props.onChangeClientIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === value?.code)[0]);
            }}
            disabled={props.isModePreview}
            disableClearable={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"pclientIdNumber".concat(props.clientType, props.recordId)}
            size="small"
            label={"Numer dokumentu identyfikacji"}
            id={"clientIdNumber"}
            value={props.clientIdNumber}
            onChange={(e) => props.onChangeClientIdNumber(e.target.value)}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={props.hasErrorClientIdNumber}
          />
        </Stack>
        <Address
          isModePreview={props.isModePreview}
          title={props.addressSectionName ? props.addressSectionName : !isRepresentative ? "Adres Klienta" : "Adres Pełnomocnika"}
          addrStreet={props.clientAddrStreet}
          onChangeAddrStreet={(value) => props.onChangeClientAddrStreet(value)}
          hasErrorAddrStreet={props.hasErrorClientAddrStreet}
          addrStreetNumber={props.clientAddrStreetNumber}
          onChangeAddrStreetNumber={(value) => props.onChangeClientAddrStreetNumber(value)}
          hasErrorAddrStreetNumber={props.hasErrorClientAddrStreetNumber}
          addrLocaleNumber={props.clientAddrLocaleNumber}
          onChangeAddrLocaleNumber={(value) => props.onChangeClientAddrLocaleNumber(value)}
          addrPostcode={props.clientAddrPostcode}
          onChangeAddrPostcode={(value) => props.onChangeClientAddrPostcode(value)}
          addrCity={props.clientAddrCity}
          onChangeAddrCity={(value) => props.onChangeClientAddrCity(value)}
          hasErrorAddrCity={props.hasErrorClientAddrCity}
        />
      </Stack>
    </Stack>
  );
};

export default Person;
