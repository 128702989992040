import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import LegalEntityType from "./LegalEntityType";
import Address from "./Address";

const Company = (props) => {
  const isRepresentative = props.isRepresentative;

  return (
    <Stack>
      <Stack>
        <LegalEntityType
          sectionName={props.clientSectionName}
          isModePreview={props.isModePreview}
          hasError={props.hasErrorSectionClient}
          defaultType={props.clientType}
          onChange={(a) => props.onClientTypeChoosen(a)}
        />
      </Stack>
      <Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"ccompanyName".concat(props.clientType, props.recordId)}
            size="small"
            label={"Nazwa firmy"}
            id={"companyName"}
            value={props.companyName}
            onChange={(e) => props.onChangeCompanyName(e.target.value)}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={props.hasErrorCompanyName}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"ccompanyNIP".concat(props.clientType, props.recordId)}
            size="small"
            label={"NIP"}
            id={"companyNIP"}
            value={props.companyNIP}
            onChange={(e) => props.onChangeCompanyNIP(e.target.value)}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={props.hasErrorCompanyNIP}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            key={"ccompanyKRS".concat(props.clientType, props.recordId)}
            size="small"
            label={"KRS"}
            id={"companyKRS"}
            value={props.companyKRS}
            onChange={(e) => props.onChangeCompanyKRS(e.target.value)}
            disabled={props.isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={props.hasErrorCompanyKRS}
          />
        </Stack>
      </Stack>
      <Address
        isModePreview={props.isModePreview}
        title={props.addressSectionName ? props.addressSectionName : "Adres Klienta"}
        addrStreet={props.clientAddrStreet}
        onChangeAddrStreet={(value) => props.onChangeClientAddrStreet(value)}
        hasErrorAddrStreet={props.hasErrorClientAddrStreet}
        addrStreetNumber={props.clientAddrStreetNumber}
        onChangeAddrStreetNumber={(value) => props.onChangeClientAddrStreetNumber(value)}
        hasErrorAddrStreetNumber={props.hasErrorClientAddrStreetNumber}
        addrLocaleNumber={props.clientAddrLocaleNumber}
        onChangeAddrLocaleNumber={(value) => props.onChangeClientAddrLocaleNumber(value)}
        addrPostcode={props.clientAddrPostcode}
        onChangeAddrPostcode={(value) => props.onChangeClientAddrPostcode(value)}
        addrCity={props.clientAddrCity}
        onChangeAddrCity={(value) => props.onChangeClientAddrCity(value)}
        hasErrorAddrCity={props.hasErrorClientAddrCity}
      />
      {!isRepresentative && (
        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {"Reprezentant"}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              key={"cclientName".concat(props.clientType, props.recordId)}
              size="small"
              label={"Imię"}
              id={"clientName"}
              value={props.clientName}
              onChange={(e) => {
                props.onChangeClientName(e.target.value);
              }}
              disabled={props.isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={true}
              error={props.hasErrorClientName}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              key={"cclientSurname".concat(props.clientType, props.recordId)}
              size="small"
              label={"Nazwisko"}
              id={"clientSurname"}
              value={props.clientSurname}
              onChange={(e) => {
                props.onChangeClientSurname(e.target.value);
              }}
              disabled={props.isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={true}
              error={props.hasErrorClientSurname}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              key={"cclientPhone".concat(props.clientType, props.recordId)}
              size="small"
              label={"Telefon"}
              id={"clientPhone"}
              value={props.clientPhone}
              onChange={(e) => {
                props.onChangeClientPhone(e.target.value);
              }}
              disabled={props.isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={true}
              error={props.hasErrorClientPhone}
              helperText="Prawidłowy format: 123456789 lub +48123456789"
              type={"number"}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              key={"cclientEmail".concat(props.clientType, props.recordId)}
              size="small"
              label={"E-mail"}
              id={"clientEmail"}
              value={props.clientEmail}
              onChange={(e) => {
                props.onChangeClientEmail(e.target.value);
              }}
              disabled={props.isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={false}
              error={props.hasErrorClientEmail}
              helperText="Prawidłowy format: example@example.com"
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Company;
