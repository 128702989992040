import axios from "axios";
import * as api from "../../config/ApiConfig";
import { getSaltLoginEncode, getSaltSaltDecode, loginLoginEncode, loginPasswordEncode } from "../../utils/crypto";
import * as session from "../../components/SessionStorage/SessionStorage";
import * as Token from "../../api/Token";

export const GetSalt = async (login) => {
  try {
    const encodedLogin = getSaltLoginEncode(login);

    const response = await axios.get(api.url_User_GetSalt, {
      headers: { login: encodedLogin },
    });

    const salt = getSaltSaltDecode(response.data.salt);

    return salt;
  } catch (error) {
    console.error("API method error: ", api.url_User_GetSalt, error);
  }
};

export const Login = async (login, hashedPassword) => {
  let answer = {
    token: "",
    refreshToken: "",
    userId: "",
    error: "",
    errorMsg: "",
  };

  try {
    const encodedLogin = loginLoginEncode(login);
    const encodedPassword = loginPasswordEncode(hashedPassword);

    const response = await axios({
      method: "post",
      url: api.url_User_Login,
      params: {
        login: encodedLogin,
        hashedPassword: encodedPassword,
      },
    });

    answer.token = response.data.token;
    answer.refreshToken = response.data.refreshToken;
    answer.userId = response.data.userId;
  } catch (error) {
    answer.error = error;
    answer.errorMsg = error.message;
  } finally {
    return answer;
  }
};

export const VerifyToken = async (login, token) => {
  let answer = { valid: false, expired: false };

  try {
    const response = await axios({
      method: "post",
      url: api.url_User_VerifyToken,
      headers: {
        login: login,
        token: token,
      },
    });

    answer.valid = response.data.result.valid;
    answer.expired = response.data.result.expired;
  } catch (error) {
    answer.valid = false;
  } finally {
    return answer;
  }
};

export const RefreshToken = async (login, rtoken) => {
  let answer = {
    token: "",
    refreshToken: "",
    userId: "",
    error: "",
    errorMsg: "",
  };

  try {
    const response = await axios({
      method: "post",
      url: api.url_User_RefreshToken,
      headers: {
        login: login,
        rtoken: rtoken,
      },
    });

    answer.token = response.data.token;
    answer.refreshToken = response.data.refreshToken;
    answer.userId = response.data.userId;
  } catch (error) {
    answer.error = error;
  } finally {
    return answer;
  }
};

export const GetById = async (objectId) => {
  try {
    const response = await axios.get(api.url_User_GetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_User_GetById, error);
  }
};

export const GetAllFiltering = async (filter, retry) => {
  try {
    const response = await axios.get(api.url_User_GetAllFiltering, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: !filter
        ? {}
        : {
            qUserId: session.getUserId(),
            login: filter.login,
            name: filter.name,
            surname: filter.surname,
            email: filter.email,
            phone: filter.phone,
            isLocked: filter.isLocked,
          },
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 403 && !retry) {
      await Token.isTokenValid();
      return await GetAllFiltering(filter, true);
    } else if (error.response.status === 403) {
      throw new Error("403");
    }
    console.error("Error: ", error);
    throw new Error("Error, ".concat(api.url_User_GetAllFiltering, error.message));
  }
};

export const Delete = async (objectId) => {
  try {
    const response = await axios({
      method: "delete",
      url: api.url_User_Delete,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: [objectId],
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_User_Delete, error);
  }
};

export const Create = async (user) => {
  try {
    const response = await axios({
      method: "post",
      url: api.url_User_Create,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        createdBy: session.getUserId(),
        login: user.login,
        name: user.name,
        surname: user.surname,
        email: user.email,
        phone: user.phone,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_User_Create, error);
  }
};

export const Update = async (objectId, user) => {
  try {
    let bodyData = {
      updatedBy: session.getUserId(),
      name: user.name,
      surname: user.surname,
      email: user.email,
      phone: user.phone,
    };

    const response = await axios.post(api.url_User_Update, bodyData, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_User_Update, error);
  }
};
