import axios from "axios";
import * as api from "../../config/ApiConfig";
import * as session from "../../components/SessionStorage/SessionStorage";
import * as Token from "../../api/Token";
import * as UserApi from "../User/UserApi";
import { base64ToBlob } from "../../utils/validator";

class ContractPurchase {}

export const Create = async (object) => {
  try {
    let user = await UserApi.GetById(session.getUserId());

    const response = await axios({
      method: "post",
      url: api.url_ContractPurchase_Create,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        createdBy: session.getUserId(),
        status: object.status,
        contractVariant: object.contractVariant,
        contractDate: object.contractDate,
        contractCity: object.contractCity,
        contractDurationMonth: object.contractDurationMonth,
        branchId: user.branchId,
        agentId: object.agentId,
        agentSentAt: object.agentSentAt,
        clientAcceptedAt: object.clientAcceptedAt,
        propertyAddrStreet: object.propertyAddrStreet,
        propertyAddrStreetNumber: object.propertyAddrStreetNumber,
        propertyAddrLocaleNumber: object.propertyAddrLocaleNumber,
        propertyAddrPostcode: object.propertyAddrPostcode,
        propertyAddrCity: object.propertyAddrCity,
        propertyAddrCountry: object.propertyAddrCountry,
        clientType: object.clientType,
        companyName: object.companyName,
        companyNIP: object.companyNIP,
        companyKRS: object.companyKRS,
        clientName: object.clientName,
        clientSurname: object.clientSurname,
        clientAddrStreet: object.clientAddrStreet,
        clientAddrStreetNumber: object.clientAddrStreetNumber,
        clientAddrLocaleNumber: object.clientAddrLocaleNumber,
        clientAddrPostcode: object.clientAddrPostcode,
        clientAddrCity: object.clientAddrCity,
        clientAddrCountry: object.clientAddrCountry,
        clientPesel: object.clientPesel,
        clientIdType: object.clientIdType,
        clientIdNumber: object.clientIdNumber,
        clientPhone: object.clientPhone,
        clientEmail: object.clientEmail,
        principalName: object.principalName,
        principalSurname: object.principalSurname,
        principalAddrStreet: object.principalAddrStreet,
        principalAddrStreetNumber: object.principalAddrStreetNumber,
        principalAddrLocaleNumber: object.principalAddrLocaleNumber,
        principalAddrPostcode: object.principalAddrPostcode,
        principalAddrCity: object.principalAddrCity,
        principalAddrCountry: object.principalAddrCountry,
        principalPesel: object.principalPesel,
        principalIdType: object.principalIdType,
        principalIdNumber: object.principalIdNumber,
        principalPhone: object.principalPhone,
        principalEmail: object.principalEmail,
        agentProvisionType: object.agentProvisionType,
        agentProvisionPercentNet: object.agentProvisionPercentNet,
        agentProvisionAmountNet: object.agentProvisionAmountNet,
        withPresentation: object.withPresentation,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_Create, error);
  }
};

export const Update = async (objectId, object) => {
  try {
    let first = {
      updatedBy: session.getUserId(),
      status: object.status,
      contractVariant: object.contractVariant,
      contractDate: object.contractDate,
      contractCity: object.contractCity,
      contractDurationMonth: object.contractDurationMonth,
      agentId: object.agentId,
      agentSentAt: object.agentSentAt,
      //clientAcceptedAt: object.clientAcceptedAt, CELOWO ZABLOKOWANE
      propertyAddrStreet: object.propertyAddrStreet,
      propertyAddrStreetNumber: object.propertyAddrStreetNumber,
      propertyAddrLocaleNumber: object.propertyAddrLocaleNumber,
      propertyAddrPostcode: object.propertyAddrPostcode,
      propertyAddrCity: object.propertyAddrCity,
      propertyAddrCountry: object.propertyAddrCountry,
      clientType: object.clientType,
      companyName: object.companyName,
      companyNIP: object.companyNIP,
      companyKRS: object.companyKRS,
      clientName: object.clientName,
      clientSurname: object.clientSurname,
      clientAddrStreet: object.clientAddrStreet,
      clientAddrStreetNumber: object.clientAddrStreetNumber,
      clientAddrLocaleNumber: object.clientAddrLocaleNumber,
      clientAddrPostcode: object.clientAddrPostcode,
      clientAddrCity: object.clientAddrCity,
      clientAddrCountry: object.clientAddrCountry,
      clientPesel: object.clientPesel,
      clientIdType: object.clientIdType,
      clientIdNumber: object.clientIdNumber,
      clientPhone: object.clientPhone,
      clientEmail: object.clientEmail,
      principalName: object.principalName,
      principalSurname: object.principalSurname,
      principalAddrStreet: object.principalAddrStreet,
      principalAddrStreetNumber: object.principalAddrStreetNumber,
      principalAddrLocaleNumber: object.principalAddrLocaleNumber,
      principalAddrPostcode: object.principalAddrPostcode,
      principalAddrCity: object.principalAddrCity,
      principalAddrCountry: object.principalAddrCountry,
      principalPesel: object.principalPesel,
      principalIdType: object.principalIdType,
      principalIdNumber: object.principalIdNumber,
      principalPhone: object.principalPhone,
      principalEmail: object.principalEmail,
      agentProvisionType: object.agentProvisionType,
      agentProvisionPercentNet: object.agentProvisionPercentNet === "" ? null : object.agentProvisionPercentNet,
      agentProvisionAmountNet: object.agentProvisionAmountNet === "" ? null : object.agentProvisionAmountNet,
      withPresentation: object.withPresentation,
    };

    let bodyData;

    if (base64ToBlob(object.agentSignature)) {
      bodyData = {
        ...first,
        agentSignature: base64ToBlob(object.agentSignature),
      };
    } else {
      bodyData = {
        ...first,
      };
    }

    const response = await axios({
      method: "post",
      url: api.url_ContractPurchase_Update,
      headers: {
        "Content-Type": "multipart/form-data",
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.log(error);
    console.error("API method error: ", api.url_ContractPurchase_Update, error);
  }
};

export const Delete = async (objectId) => {
  try {
    const response = await axios({
      method: "delete",
      url: api.url_ContractPurchase_Delete,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: [objectId],
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_Delete, error);
  }
};

export const GetAllFiltering = async (filter, retry) => {
  try {
    const response = await axios.get(api.url_ContractPurchase_GetAllFiltering, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: !filter
        ? { qUserId: session.getUserId() }
        : {
            qUserId: session.getUserId(),
            clientName: filter.clientName,
            clientSurname: filter.clientSurname,
            contractDateFrom: filter.contractDateFrom,
            contractDateTo: filter.contractDateTo,
            clientPesel: filter.clientPesel,
            clientPhone: filter.clientPhone,
            status: filter.status,
          },
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 403 && !retry) {
      await Token.isTokenValid();
      return await GetAllFiltering(filter, true);
    } else if (error.response.status === 403) {
      throw new Error("403");
    }

    throw new Error("Error, ".concat(api.url_ContractPurchase_GetAllFiltering, error.message));
  }
};

export const GetById = async (objectId) => {
  try {
    const response = await axios.get(api.url_ContractPurchase_GetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    const object = new ContractPurchase();

    object.id = response.data.id;
    object.createdBy = response.data.createdBy;
    object.updatedBy = response.data.updatedBy;
    //object.sessionKey = response.data.sessionKey;
    object.status = response.data.status;
    object.contractVariant = response.data.contractVariant;
    object.contractDate = response.data.contractDate;
    object.contractCity = response.data.contractCity;
    object.contractDurationMonth = response.data.contractDurationMonth;
    object.branchId = response.data.branchId;
    object.agentId = response.data.agentId;
    object.agentSentAt = response.data.agentSentAt;
    object.clientAcceptedAt = response.data.clientAcceptedAt;
    object.propertyAddrStreet = response.data.propertyAddrStreet;
    object.propertyAddrStreetNumber = response.data.propertyAddrStreetNumber;
    object.propertyAddrLocaleNumber = response.data.propertyAddrLocaleNumber;
    object.propertyAddrPostcode = response.data.propertyAddrPostcode;
    object.propertyAddrCity = response.data.propertyAddrCity;
    object.propertyAddrCountry = response.data.propertyAddrCountry;
    object.clientType = response.data.clientType;
    object.companyName = response.data.companyName;
    object.companyNIP = response.data.companyNIP;
    object.companyKRS = response.data.companyKRS;
    object.clientName = response.data.clientName;
    object.clientSurname = response.data.clientSurname;
    object.clientAddrStreet = response.data.clientAddrStreet;
    object.clientAddrStreetNumber = response.data.clientAddrStreetNumber;
    object.clientAddrLocaleNumber = response.data.clientAddrLocaleNumber;
    object.clientAddrPostcode = response.data.clientAddrPostcode;
    object.clientAddrCity = response.data.clientAddrCity;
    object.clientAddrCountry = response.data.clientAddrCountry;
    object.clientPesel = response.data.clientPesel;
    object.clientIdType = response.data.clientIdType;
    object.clientIdNumber = response.data.clientIdNumber;
    object.clientPhone = response.data.clientPhone;
    object.clientEmail = response.data.clientEmail;
    object.principalName = response.data.principalName;
    object.principalSurname = response.data.principalSurname;
    object.principalAddrStreet = response.data.principalAddrStreet;
    object.principalAddrStreetNumber = response.data.principalAddrStreetNumber;
    object.principalAddrLocaleNumber = response.data.principalAddrLocaleNumber;
    object.principalAddrPostcode = response.data.principalAddrPostcode;
    object.principalAddrCity = response.data.principalAddrCity;
    object.principalAddrCountry = response.data.principalAddrCountry;
    object.principalPesel = response.data.principalPesel;
    object.principalIdType = response.data.principalIdType;
    object.principalIdNumber = response.data.principalIdNumber;
    object.principalPhone = response.data.principalPhone;
    object.principalEmail = response.data.principalEmail;
    object.agentProvisionType = response.data.agentProvisionType;
    object.agentProvisionPercentNet = response.data.agentProvisionPercentNet;
    object.agentProvisionAmountNet = response.data.agentProvisionAmountNet;
    object.withPresentation = response.data.withPresentation;

    return object;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_GetById, error);
  }
};

export const GetDocumentPDFById = async (objectId) => {
  try {
    const response = await axios({
      method: "get",
      url: api.url_ContractPurchase_GetDocumentPDFById,
      params: { id: objectId },
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_GetDocumentPDFById, error);
  }
};

export const GetAgentSignatureById = async (objectId) => {
  try {
    const response = await axios({
      method: "get",
      url: api.url_ContractPurchase_GetAgentSignatureById,
      params: { id: objectId },
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_GetAgentSignatureById, error);
  }
};

export const ClientGetById = async (objectId, sessionKey) => {
  try {
    const response = await axios.get(api.url_ContractPurchase_ClientGetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId, sessionKey: sessionKey },
    });

    const object = {};

    object.id = response.data.id;
    //object.createdBy = response.data.createdBy;
    //object.updatedBy = response.data.updatedBy;
    // object.branchId = response.data.branchId;
    object.contractVariant = response.data.contractVariant;
    object.contractDate = response.data.contractDate;
    object.contractCity = response.data.contractCity;
    object.contractDurationMonth = response.data.contractDurationMonth;

    object.propertyAddrStreet = response.data.propertyAddrStreet;
    object.propertyAddrStreetNumber = response.data.propertyAddrStreetNumber;
    object.propertyAddrLocaleNumber = response.data.propertyAddrLocaleNumber;
    object.propertyAddrPostcode = response.data.propertyAddrPostcode;
    object.propertyAddrCity = response.data.propertyAddrCity;
    object.propertyAddrCountry = response.data.propertyAddrCountry;

    object.clientType = response.data.clientType;
    object.companyName = response.data.companyName;
    object.companyNIP = response.data.companyNIP;
    object.companyKRS = response.data.companyKRS;
    object.clientName = response.data.clientName;
    object.clientSurname = response.data.clientSurname;
    object.clientAddrStreet = response.data.clientAddrStreet;
    object.clientAddrStreetNumber = response.data.clientAddrStreetNumber;
    object.clientAddrLocaleNumber = response.data.clientAddrLocaleNumber;
    object.clientAddrPostcode = response.data.clientAddrPostcode;
    object.clientAddrCity = response.data.clientAddrCity;
    object.clientAddrCountry = response.data.clientAddrCountry;
    object.clientPesel = response.data.clientPesel;
    object.clientIdType = response.data.clientIdType;
    object.clientIdNumber = response.data.clientIdNumber;
    object.clientPhone = response.data.clientPhone;
    object.clientEmail = response.data.clientEmail;
    object.principalName = response.data.principalName;
    object.principalSurname = response.data.principalSurname;
    object.principalPhone = response.data.principalPhone;
    object.principalEmail = response.data.principalEmail;
    object.principalAddrStreet = response.data.principalAddrStreet;
    object.principalAddrStreetNumber = response.data.principalAddrStreetNumber;
    object.principalAddrLocaleNumber = response.data.principalAddrLocaleNumber;
    object.principalAddrPostcode = response.data.principalAddrPostcode;
    object.principalAddrCity = response.data.principalAddrCity;
    object.principalAddrCountry = response.data.principalAddrCountry;
    object.principalPesel = response.data.principalPesel;
    object.principalIdType = response.data.principalIdType;
    object.principalIdNumber = response.data.principalIdNumber;
    object.agentProvisionType = response.data.agentProvisionType;
    object.agentProvisionPercentNet = response.data.agentProvisionPercentNet;
    object.agentProvisionAmountNet = response.data.agentProvisionAmountNet;
    object.withPresentation = response.data.withPresentation;

    //object.clientInvoiceEmail = response.data.clientInvoiceEmail;
    //object.agentId = response.data.agentId;
    //object.agentSentAt = response.data.agentSentAt;
    object.clientAcceptedAt = response.data.clientAcceptedAt;
    object.status = response.data.status;
    //object.sessionKey = response.data.sessionKey;

    // UserAgent
    object.UserAgent = {};
    object.UserAgent.name = response.data.contractPurchaseUserAgent.name;
    object.UserAgent.surname = response.data.contractPurchaseUserAgent.surname;
    object.UserAgent.phone = response.data.contractPurchaseUserAgent.phone;
    object.UserAgent.email = response.data.contractPurchaseUserAgent.email;

    // Branch
    object.Branch = {};
    object.Branch.name = response.data.Branch.name;
    object.Branch.addrStreet = response.data.Branch.addrStreet;
    object.Branch.addrStreetNumber = response.data.Branch.addrStreetNumber;
    object.Branch.addrLocaleNumber = response.data.Branch.addrLocaleNumber;
    object.Branch.addrPostcode = response.data.Branch.addrPostcode;
    object.Branch.addrCity = response.data.Branch.addrCity;
    object.Branch.addrCountry = response.data.Branch.addrCountry;
    object.Branch.email = response.data.Branch.email;
    object.Branch.phone = response.data.Branch.phone;

    return object;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_ClientGetById, error);
  }
};

export const ClientGetByIdSessionKey = async (objectId, sessionKey) => {
  try {
    const response = await axios.get(api.url_ContractPurchase_ClientGetByIdSessionKey, {
      params: { id: objectId, sessionKey: sessionKey },
    });

    const object = {};

    object.id = response.data.id;
    //object.createdBy = response.data.createdBy;
    //object.updatedBy = response.data.updatedBy;
    // object.branchId = response.data.branchId;
    object.contractVariant = response.data.contractVariant;
    object.contractDate = response.data.contractDate;
    object.contractCity = response.data.contractCity;
    object.contractDurationMonth = response.data.contractDurationMonth;

    object.propertyAddrStreet = response.data.propertyAddrStreet;
    object.propertyAddrStreetNumber = response.data.propertyAddrStreetNumber;
    object.propertyAddrLocaleNumber = response.data.propertyAddrLocaleNumber;
    object.propertyAddrPostcode = response.data.propertyAddrPostcode;
    object.propertyAddrCity = response.data.propertyAddrCity;
    object.propertyAddrCountry = response.data.propertyAddrCountry;

    object.clientType = response.data.clientType;
    object.companyName = response.data.companyName;
    object.companyNIP = response.data.companyNIP;
    object.companyKRS = response.data.companyKRS;
    object.clientName = response.data.clientName;
    object.clientSurname = response.data.clientSurname;
    object.clientAddrStreet = response.data.clientAddrStreet;
    object.clientAddrStreetNumber = response.data.clientAddrStreetNumber;
    object.clientAddrLocaleNumber = response.data.clientAddrLocaleNumber;
    object.clientAddrPostcode = response.data.clientAddrPostcode;
    object.clientAddrCity = response.data.clientAddrCity;
    object.clientAddrCountry = response.data.clientAddrCountry;
    object.clientPesel = response.data.clientPesel;
    object.clientIdType = response.data.clientIdType;
    object.clientIdNumber = response.data.clientIdNumber;
    object.clientPhone = response.data.clientPhone;
    object.clientEmail = response.data.clientEmail;
    object.principalName = response.data.principalName;
    object.principalSurname = response.data.principalSurname;
    object.principalPhone = response.data.principalPhone;
    object.principalEmail = response.data.principalEmail;
    object.principalAddrStreet = response.data.principalAddrStreet;
    object.principalAddrStreetNumber = response.data.principalAddrStreetNumber;
    object.principalAddrLocaleNumber = response.data.principalAddrLocaleNumber;
    object.principalAddrPostcode = response.data.principalAddrPostcode;
    object.principalAddrCity = response.data.principalAddrCity;
    object.principalAddrCountry = response.data.principalAddrCountry;
    object.principalPesel = response.data.principalPesel;
    object.principalIdType = response.data.principalIdType;
    object.principalIdNumber = response.data.principalIdNumber;
    object.agentProvisionType = response.data.agentProvisionType;
    object.agentProvisionPercentNet = response.data.agentProvisionPercentNet;
    object.agentProvisionAmountNet = response.data.agentProvisionAmountNet;
    object.withPresentation = response.data.withPresentation;

    //object.clientInvoiceEmail = response.data.clientInvoiceEmail;
    //object.agentId = response.data.agentId;
    //object.agentSentAt = response.data.agentSentAt;
    object.clientAcceptedAt = response.data.clientAcceptedAt;
    object.status = response.data.status;
    //object.sessionKey = response.data.sessionKey;

    // UserAgent
    object.UserAgent = {};
    object.UserAgent.name = response.data.contractPurchaseUserAgent.name;
    object.UserAgent.surname = response.data.contractPurchaseUserAgent.surname;
    object.UserAgent.phone = response.data.contractPurchaseUserAgent.phone;
    object.UserAgent.email = response.data.contractPurchaseUserAgent.email;

    // Branch
    object.Branch = {};
    object.Branch.name = response.data.Branch.name;
    object.Branch.addrStreet = response.data.Branch.addrStreet;
    object.Branch.addrStreetNumber = response.data.Branch.addrStreetNumber;
    object.Branch.addrLocaleNumber = response.data.Branch.addrLocaleNumber;
    object.Branch.addrPostcode = response.data.Branch.addrPostcode;
    object.Branch.addrCity = response.data.Branch.addrCity;
    object.Branch.addrCountry = response.data.Branch.addrCountry;
    object.Branch.email = response.data.Branch.email;
    object.Branch.phone = response.data.Branch.phone;

    return object;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_ClientGetByIdSessionKey, error);
  }
};

export const ClientUpdateById = async (objectId, sessionKey, doc) => {
  try {
    let bodyData = {
      clientAcceptedAt: doc.clientAcceptedAt,
      status: doc.status,
      clientEmail: doc.clientEmail,
      documentPDF: doc.documentPDF,
      clientInvoiceEmail: doc.clientInvoiceEmail,
    };

    const response = await axios({
      method: "post",
      url: api.url_ContractPurchase_ClientUpdateById,
      headers: {
        "Content-Type": "multipart/form-data",
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
        //sessionKey: sessionKey,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_ClientUpdateById, error);
  }
};

export const ClientUpdateByIdSessionKey = async (objectId, sessionKey, doc) => {
  try {
    let bodyData = {
      clientAcceptedAt: doc.clientAcceptedAt,
      status: doc.status,
      clientEmail: doc.clientEmail,
      documentPDF: doc.documentPDF,
      clientInvoiceEmail: doc.clientInvoiceEmail,
    };

    const response = await axios({
      method: "post",
      url: api.url_ContractPurchase_ClientUpdateByIdSessionKey,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        id: objectId,
        sessionKey: sessionKey,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_ClientUpdateByIdSessionKey, error);
  }
};

export const GetStatusById = async (objectId) => {
  try {
    const response = await axios.get(api.url_ContractPurchase_GetStatusById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    let doc = {};
    doc.status = response.data.status;

    return doc;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_GetStatusById, error);
  }
};

export const GetMonitorDataById = async (objectId) => {
  try {
    const response = await axios.get(api.url_ContractPurchase_GetMonitorDataById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    let doc = {};
    doc.status = response.data.status;
    doc.clientEmail = response.data.clientEmail;

    return doc;
  } catch (error) {
    console.error("API method error: ", api.url_ContractPurchase_GetMonitorDataById, error);
  }
};
