const DocumentForm = (props) => {
  const styles = {
    page: {
      fontFamily: "Roboto",
      paddingTop: "5px",
      marginLeft: "15px",
      fontSize: "12px",
      marginRight: "20px",
      paddingBottom: "30px",
    },

    title: {
      paddingBottom: "10px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
  };

  return (
    <div style={styles.page}>
      <p style={styles.title}>
        Załącznik nr 1 - Pouczenie o odstąpieniu od umowy zawartej poza lokalem przedsiębiorstwa (zgodnie z ustawą z dnia 30 maja 2014r. o prawach
        konsumenta)
      </p>

      <ul>
        <li>Mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania przyczyny.</li>
        <li>Termin do odstąpienia od umowy wygasa po upływie 14 (czternastu) dni od dnia zawarcia umowy.</li>
        <li>
          Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować DEMO ESTATE Sp. z o.o. z siedzibą w Warszawie o swojej decyzji o
          odstąpieniu od umowy w drodze jednoznacznego oświadczenia w formie pisma wysłanego pocztą na następujący adres: ul. Demonstracyjna 111, lok.
          1.1, 00-000 Warszawa.
        </li>
        <li>Mogą Państwo skorzystać z wzoru formularza odstąpienia od umowy, jednak nie jest to obowiązkowe.</li>
        <li>
          Aby zachować termin do odstąpienia od umowy, wystarczy aby wysłali Państwo informację dotyczącą wykonania przysługującego Państwu prawa
          odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.
        </li>
        <li>
          W przypadku odstąpienia od niniejszej umowy zwracamy Państwu wszystkie otrzymane od Państwa płatności, w tym koszty dostarczenia rzeczy (z
          wyjątkiem dodatkowych kosztów wynikających z wybranego przez Państwa sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia
          oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o Państwa decyzji
          o wykonaniu prawa odstąpienia od niniejszej umowy.
        </li>
        <li>
          Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że
          wyraźnie zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat w związku z tym zwrotem.
        </li>
        <li>
          Jeżeli zażądali Państwo rozpoczęcia świadczenia usług przed upływem terminu do odstąpienia od umowy, zapłacą Państwo DEMO ESTATE kwotę
          proporcjonalną do zakresu świadczeń spełnionych do chwili, w której poinformowali nas Państwo o odstąpieniu od niniejszej umowy.
        </li>
      </ul>
    </div>
  );
};

export default DocumentForm;
