import { useState, useEffect, useRef } from "react";
import MyGrid from "../../components/MyGrid/MyGrid";
import { Button } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
// eslint-disable-next-line
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import dayjs from "dayjs";
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/material";
import * as BranchApi from "./BranchApi";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "../../config/NavigationRoute/RoutePath";

export default function BranchList(props) {
  const navigate = useNavigate();
  const recordId = useRef(0);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      renderCell: (params) => (
        <strong>
          <Button onClick={(e, id) => handleClickPreview(e, params.id)}>{params.row.id}</Button>
        </strong>
      ),
    },
    {
      field: "edit",
      headerName: "Edytuj",
      width: 55,
      renderCell: (params) => (
        <strong>
          <Button style={{ marginLeft: -15 }} onClick={(e, id) => handleClickEdit(e, params.id)}>
            <ModeEditOutlineOutlinedIcon />
          </Button>
        </strong>
      ),
    },
    // {
    //   field: "delete",
    //   headerName: "Usuń",
    //   width: 50,
    //   renderCell: (params) => (
    //     <strong>
    //       <Button
    //         style={{ marginLeft: -15 }}
    //         onClick={(e, id) => handleClickDelete(e, params.id)}
    //       >
    //         <DeleteForeverOutlinedIcon />
    //       </Button>
    //     </strong>
    //   ),
    // },
    {
      field: "createdAt",
      headerName: "Data utworzenia",
      width: 140,
      editable: false,
      sortable: true,
      //valueFormatter: (params) => (params.value).format("DD/MM/YYYY"),
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        // Convert the decimal value to a percentage
        //return new Date(params.value).toISOString().split("T")[0]; //.format("YYYY-MM-DD");

        return dayjs(params.value).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      field: "code",
      headerName: "Kod",
      width: 250,
      editable: false,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Nazwa",
      width: 350,
      editable: false,
      sortable: true,
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 260,
      editable: false,
      sortable: true,
    },
    {
      field: "phone",
      headerName: "Telefon",
      width: 180,
      editable: false,
      sortable: true,
    },
  ];

  const clearFilters = {
    fCode: "",
    fName: "",
  };
  const [filters, setFilters] = useState(clearFilters);

  const [fCode, setFCode] = useState("");
  const [fName, setFName] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogCloseNo = () => {
    recordId.current = 0;
    setDialogOpen(false);
  };

  const handleDialogCloseYes = () => {
    deleteRecord(null, recordId.current);
    setDialogOpen(false);
  };

  // eslint-disable-next-line
  const handleClickDelete = (e, id) => {
    handleDialogClickOpen();
    recordId.current = id;
  };

  const deleteRecord = async (e, id) => {
    // try {
    //   await BranchApi.Delete(id);
    //   if (props.onObjectDelete) {
    //     props.onObjectDelete(id);
    //   }
    //   let objectsFiltered = await BranchApi.GetAllFiltering({
    //     code: fCode,
    //     name: fName,
    //     surname: fSurname,
    //     email: fEmail,
    //     phone: fPhone,
    //   });
    //   setRecords({
    //     data: formatPresentedData(objectsFiltered),
    //   });
    // } catch (error) {
    //   console.error("Error: ", error);
    // }
  };

  const serializeFilters = () => {
    let filters = {
      fCode: fCode,
      fName: fName,
    };

    setFilters(filters);
    return filters;
  };

  const handleClickEdit = (e, id) => {
    if (props.onObjectEdit) {
      props.onObjectEdit(id, serializeFilters());
    }
  };

  const handleClickPreview = (e, id) => {
    if (props.onObjectPreview) {
      props.onObjectPreview(id, serializeFilters());
    }
  };

  const formatPresentedData = (dbData) => {
    let data = [];

    dbData?.forEach((e) => {
      data.push({
        ...e,
        //     address: "".concat(
        //       e.addrStreet ? "ul. ".concat(e.addrStreet) : "",
        //       " ",
        //       e.addrStreetNumber,
        //       e.addrLocaleNumber ? "/".concat(e.addrLocaleNumber) : "",
        //       " ",
        //       e.addrPostcode,
        //       " ",
        //       e.addrCity
        //     ),
      });
    });

    return data;
  };

  const updateObjects = async (data) => {
    try {
      let objectsFiltered = await BranchApi.GetAllFiltering(data);
      setRecords({
        data: formatPresentedData(objectsFiltered),
      });
    } catch (error) {
      console.error("Błąd metody updateObjects", error);
    }
  };

  useEffect(() => {
    let localCode = props?.filters?.fCode ? props?.filters?.fCode : fCode;
    let localName = props?.filters?.fName ? props?.filters?.fName : fName;

    setFCode(localCode);
    setFName(localName);

    updateObjects({
      code: localCode,
      name: localName,
    });

    // eslint-disable-next-line
  }, []);

  const [records, setRecords] = useState({
    data: [],
  });

  // eslint-disable-next-line
  const [recordsSelected, setRecordsSelected] = useState([]);

  const handleSelectionModelChange = (model) => {
    // if (model.length > 1) {
    //   props.onNotificationShow(
    //     "Powinieneś wybrać dokładnie jedną osobę nie więcej!",
    //     "error"
    //   );
    //   return;
    // }

    setRecordsSelected(model);
    if (props.onRecordSelected) {
      props.onRecordSelected(model);
    }
  };

  const renderDialogCancel = () => {
    return (
      <Dialog open={dialogOpen} onClose={handleDialogCloseNo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Usuwanie osoby badanej"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ whiteSpace: "pre-wrap" }}>
            {"Przypadkowe usunięcie może spowodować nieodwracalną utratę danych.\nCzy kontynuować?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCloseNo}>Nie</Button>
          <Button onClick={handleDialogCloseYes} autoFocus>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [openFilter, setOpenFilter] = useState(false);

  const handleOnClickSearchBtn = () => {
    setOpenFilter(true);
  };

  const isFilterActive = () => {
    if ((fCode && fCode !== "") || (fName && fName !== "")) {
      return true;
    }
    return false;
  };

  const handleOnClickDisableSearchBtn = async () => {
    try {
      setFilters(clearFilters);
      setFCode("");
      setFName("");

      let objectsFiltered = await BranchApi.GetAllFiltering();

      if (props.onObjectFiltered) {
        props.onObjectFiltered(clearFilters);
      }

      setRecords({
        data: formatPresentedData(objectsFiltered),
      });
    } catch (error) {
      props.onNotificationShow(error, "error");
    }
  };

  const handleOnClickFilteringCancel = () => {
    setOpenFilter(false);
  };

  const handleOnClickFilteringSearch = async (e) => {
    try {
      let objectsFiltered = await BranchApi.GetAllFiltering({
        code: fCode,
        name: fName,
      });

      if (props.onObjectFiltered) {
        props.onObjectFiltered(serializeFilters());
      }

      setRecords({
        data: formatPresentedData(objectsFiltered),
      });

      setOpenFilter(false);
    } catch (error) {
      if (error.message === "403") {
        navigate(RoutePath.Login);
      } else {
        // wyświetl komunikat błędu (TODO: wymaga naprawy)
        props.onNotificationShow(error, "error");
      }
    }
  };

  const renderDialogSearch = () => {
    return (
      <Dialog open={openFilter} onClose={handleOnClickFilteringCancel} fullWidth maxWidth={"md"}>
        <DialogTitle>{"Wyszukiwanie"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Uzupełnij wybrane pola, a następnie wciśnij przycisk "Szukaj". Możesz używać symbolu wieloznaczności: %.'}
          </DialogContentText>
          <br />

          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Kod"}
              id={"fCode"}
              value={fCode}
              onChange={(e) => setFCode(e.target.value)}
              sx={{ pb: 2, width: "100%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: fName !== "" ? "visible" : "hidden" }}
                    onClick={() => {
                      setFCode("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Nazwa"}
              id={"fName"}
              value={fName}
              onChange={(e) => setFName(e.target.value)}
              sx={{ pb: 2, width: "100%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: fName !== "" ? "visible" : "hidden" }}
                    onClick={() => {
                      setFName("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Stack>
          <TextField
            size="small"
            disabled
            id={"validator"}
            variant="standard"
            sx={{ width: "100%" }}
            error={fCode !== filters?.fCode || fName !== filters?.fName}
            helperText={
              fCode !== filters?.fCode || fName !== filters?.fName
                ? "* Wprowadzono nowe wartości w polach wyszukiwania. Wymagane użycie - Szukaj / Enter."
                : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClickFilteringCancel}>Anuluj</Button>
          <Button onClick={(e) => handleOnClickFilteringSearch(e)}>{"Szukaj"}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ width: "1400px", overflow: "auto" }}>
      <br />
      {renderDialogCancel()}
      {renderDialogSearch()}
      <Button sx={{ mr: 2 }} variant="outlined" onClick={handleOnClickSearchBtn} startIcon={<SearchOutlinedIcon />} size={"small"}>
        {"Wyszukaj"}
      </Button>
      <Button
        sx={{ mr: 2 }}
        variant="outlined"
        onClick={handleOnClickDisableSearchBtn}
        startIcon={<SearchOffOutlinedIcon />}
        size={"small"}
        disabled={!isFilterActive()}
      >
        {"Wyczyść filtry"}
      </Button>
      <MyGrid
        rows={records.data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection={true}
        disableSelectionOnClick={false}
        experimentalFeatures={{ newEditingApi: false }}
        onSelectionModelChange={(a) => handleSelectionModelChange(a)}
        width={1380}
        sx={{ mt: 1 }}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
}
