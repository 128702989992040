import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import * as dict from "../../../config/Dictionary";
import * as IntermediationContractPersonApi from "./IntermediationContractPersonApi";
import { Stack, Button } from "@mui/material";
import { phoneValidator, emailValidator } from "../../../utils/validator";
import { Accordion, AccordionActions, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClientPerson from "../Form/ClientPerson";
import ClientBusinessIndividual from "../Form/ClientBusinessIndividual";
import ClientCompany from "../Form/ClientCompany";

const Person = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      validateCoowner: validateForm,
      saveCoowner: saveModeEdit,
    };
  });

  const objectId = props.id;
  const mode = props.mode;
  const isModePreview = props.mode === dict.FormMode.preview ? true : false;
  const docStatus = props.docStatus;

  // Form fields
  const [clientType, setClientType] = useState(dict.ClientType.person);
  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [clientAddrStreet, setClientAddrStreet] = useState("");
  const [clientAddrStreetNumber, setClientAddrStreetNumber] = useState("");
  const [clientAddrLocaleNumber, setClientAddrLocaleNumber] = useState("");
  const [clientAddrPostcode, setClientAddrPostcode] = useState("");
  const [clientAddrCity, setClientAddrCity] = useState("");
  const [clientAddrCountry, setClientAddrCountry] = useState("Polska");
  const [clientPesel, setClientPesel] = useState("");
  const [clientIdType, setClientIdType] = useState("");
  const [clientIdNumber, setClientIdNumber] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientEmail, setClientEmail] = useState("");

  const [personName, setPersonName] = useState("");
  const [personSurname, setPersonSurname] = useState("");
  const [personPhone, setPersonPhone] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personAddrStreet, setPersonAddrStreet] = useState("");
  const [personAddrStreetNumber, setPersonAddrStreetNumber] = useState("");
  const [personAddrLocaleNumber, setPersonAddrLocaleNumber] = useState("");
  const [personAddrPostcode, setPersonAddrPostcode] = useState("");
  const [personAddrCity, setPersonAddrCity] = useState("");
  const [personAddrCountry, setPersonAddrCountry] = useState("Polska");
  const [personPesel, setPersonPesel] = useState("");
  const [personIdType, setPersonIdType] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyNIP, setCompanyNIP] = useState("");
  const [companyKRS, setCompanyKRS] = useState("");

  // Combo list
  const [clientIdTypeCombo, setClientIdTypeCombo] = useState(dict.IdentityDocumentType[0]);
  const [personIdTypeCombo, setPersonIdTypeCombo] = useState(dict.IdentityDocumentType[0]);

  // Form fields error messages
  const [hasErrorClientName, setHasErrorClientName] = useState(false);
  const [hasErrorClientSurname, setHasErrorClientSurname] = useState(false);
  const [hasErrorClientPhone, setHasErrorClientPhone] = useState(false);
  const [hasErrorClientEmail, setHasErrorClientEmail] = useState(false);
  const [hasErrorClientAddrCountry, setHasErrorClientAddrCountry] = useState(false);
  const [hasErrorClientPesel, setHasErrorClientPesel] = useState(false);
  const [hasErrorClientIdType, setHasErrorClientIdType] = useState(false);
  const [hasErrorClientIdNumber, setHasErrorClientIdNumber] = useState(false);
  const [hasErrorClientAddrStreet, setHasErrorClientAddrStreet] = useState(false);
  const [hasErrorClientAddrStreetNumber, setHasErrorClientAddrStreetNumber] = useState(false);
  const [hasErrorClientAddrCity, setHasErrorClientAddrCity] = useState(false);

  const [hasErrorPersonName, setHasErrorPersonName] = useState(false);
  const [hasErrorPersonSurname, setHasErrorPersonSurname] = useState(false);
  const [hasErrorPersonPhone, setHasErrorPersonPhone] = useState(false);
  // eslint-disable-next-line
  const [hasErrorPersonEmail, setHasErrorPersonEmail] = useState(false);
  const [hasErrorPersonAddrStreet, setHasErrorPersonAddrStreet] = useState(false);
  const [hasErrorPersonAddrStreetNumber, setHasErrorPersonAddrStreetNumber] = useState(false);
  const [hasErrorPersonAddrCity, setHasErrorPersonAddrCity] = useState(false);
  const [hasErrorPersonAddrCountry, setHasErrorPersonAddrCountry] = useState(false);
  const [hasErrorPersonPesel, setHasErrorPersonPesel] = useState(false);
  const [hasErrorPersonIdType, setHasErrorPersonIdType] = useState(false);
  const [hasErrorPersonIdNumber, setHasErrorPersonIdNumber] = useState(false);

  const [hasErrorCompanyName, setHasErrorCompanyName] = useState(false);
  const [hasErrorCompanyNIP, setHasErrorCompanyNIP] = useState(false);
  const [hasErrorCompanyKRS, setHasErrorCompanyKRS] = useState(false);

  // Sections
  // eslint-disable-next-line
  const [hasErrorSectionClient, setHasErrorSectionClient] = useState(false);
  // eslint-disable-next-line
  const [hasErrorSectionPerson, setHasErrorSectionPerson] = useState(false);

  const setFieldsDefault = async () => {};

  const setFields = async (objId) => {
    try {
      const object = await IntermediationContractPersonApi.GetById(objId);

      setClientType(object.clientType ? object.clientType : dict.ClientType.person);
      setClientName(object.clientName ? object.clientName : "");
      setClientSurname(object.clientSurname ? object.clientSurname : "");
      setClientAddrStreet(object.clientAddrStreet ? object.clientAddrStreet : "");
      setClientAddrStreetNumber(object.clientAddrStreetNumber ? object.clientAddrStreetNumber : "");
      setClientAddrLocaleNumber(object.clientAddrLocaleNumber ? object.clientAddrLocaleNumber : "");
      setClientAddrPostcode(object.clientAddrPostcode ? object.clientAddrPostcode : "");
      setClientAddrCity(object.clientAddrCity ? object.clientAddrCity : "");
      setClientAddrCountry(object.clientAddrCountry ? object.clientAddrCountry : "Polska");
      setClientPesel(object.clientPesel ? object.clientPesel : "");
      setClientIdType(object.clientIdType);
      if (object.clientIdType) {
        setClientIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === object.clientIdType)[0]);
      }
      setClientIdNumber(object.clientIdNumber ? object.clientIdNumber : "");
      setClientPhone(object.clientPhone ? object.clientPhone : "");
      setClientEmail(object.clientEmail ? object.clientEmail : "");

      setPersonName(object.personName ? object.personName : "");
      setPersonSurname(object.personSurname ? object.personSurname : "");
      setPersonPhone(object.personPhone ? object.personPhone : "");
      setPersonEmail(object.personEmail ? object.personEmail : "");
      setPersonAddrStreet(object.personAddrStreet ? object.personAddrStreet : "");
      setPersonAddrStreetNumber(object.personAddrStreetNumber ? object.personAddrStreetNumber : "");
      setPersonAddrLocaleNumber(object.personAddrLocaleNumber ? object.personAddrLocaleNumber : "");
      setPersonAddrPostcode(object.personAddrPostcode ? object.personAddrPostcode : "");
      setPersonAddrCity(object.personAddrCity ? object.personAddrCity : "");
      setPersonAddrCountry(object.personAddrCountry ? object.personAddrCountry : "Polska");
      setPersonPesel(object.personPesel ? object.personPesel : "");
      setPersonIdType(object.personIdType);
      if (object.personIdType) {
        setPersonIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === object.personIdType)[0]);
      }
      setPersonIdNumber(object.personIdNumber ? object.personIdNumber : "");

      setCompanyName(object.companyName ? object.companyName : "");
      setCompanyNIP(object.companyNIP ? object.companyNIP : "");
      setCompanyKRS(object.companyKRS ? object.companyKRS : "");
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const setFieldsModePreview = async (objId) => {};

  useEffect(() => {
    if (mode === dict.FormMode.edit) {
      setFields(objectId);
    } else if (mode === dict.FormMode.preview) {
      setFields(objectId);
      setFieldsModePreview(objectId);
    } else if (mode === dict.FormMode.add) {
      setFieldsDefault();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode !== dict.FormMode.preview) {
      setHasErrorClientName(false);
      setHasErrorClientSurname(false);
      setHasErrorClientPhone(false);
      setHasErrorClientEmail(false);
      setHasErrorClientAddrCountry(false);
      setHasErrorClientPesel(false);
      setHasErrorClientIdType(false);
      setHasErrorClientIdNumber(false);
      setHasErrorClientAddrStreet(false);
      setHasErrorClientAddrStreetNumber(false);
      setHasErrorClientAddrCity(false);

      setHasErrorPersonName(false);
      setHasErrorPersonSurname(false);
      setHasErrorPersonAddrStreet(false);
      setHasErrorPersonAddrStreetNumber(false);
      setHasErrorPersonAddrCity(false);
      setHasErrorPersonAddrCountry(false);
      setHasErrorPersonPesel(false);
      setHasErrorPersonIdType(false);
      setHasErrorPersonIdNumber(false);
      setHasErrorPersonPhone(false);
      setHasErrorPersonEmail(false);

      setHasErrorCompanyName(false);
      setHasErrorCompanyNIP(false);
      setHasErrorCompanyKRS(false);
    }
    // eslint-disable-next-line
  }, [
    clientName,
    clientSurname,
    clientAddrStreet,
    clientAddrStreetNumber,
    clientAddrLocaleNumber,
    clientAddrPostcode,
    clientAddrCity,
    clientAddrCountry,
    //clientPhone,
    //clientEmail,
    clientPesel,
    clientIdType,
    clientIdNumber,

    personName,
    personSurname,
    personAddrStreet,
    personAddrStreetNumber,
    personAddrLocaleNumber,
    personAddrPostcode,
    personAddrCity,
    personAddrCountry,
    personPesel,
    personIdType,
    personIdNumber,
    // personPhone,
    // personEmail,

    clientType,
    companyName,
    companyNIP,
    companyKRS,
  ]);

  const validateClientFormFieldsClientErrorClient = () => {
    let stakeholder = "klienta";

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrCountry) {
      setHasErrorClientAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
      setHasErrorClientPesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(clientIdType && clientIdNumber)) {
      if (!clientIdType) {
        setHasErrorClientIdType(true);
      }
      if (!clientIdNumber) {
        setHasErrorClientIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateClientFormFieldsPersonErrorClient = (isCoowner) => {
    let stakeholder = !isCoowner ? "klienta" : "pełnomocnika";

    if (!personName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!personSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!personPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(personPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!personAddrCountry) {
      setHasErrorClientAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (personAddrCountry.toUpperCase().trim() === "POLSKA" && !personPesel) {
      setHasErrorClientPesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(personIdType && personIdNumber)) {
      if (!personIdType) {
        setHasErrorClientIdType(true);
      }
      if (!personIdNumber) {
        setHasErrorClientIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!personAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!personAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!personAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateClientFormFieldsClientErrorPerson = (isCoowner) => {
    let stakeholder = !isCoowner ? "klienta" : "pełnomocnika";

    if (!clientName) {
      setHasErrorPersonName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorPersonSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorPersonPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrCountry) {
      setHasErrorPersonAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
      setHasErrorPersonPesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(clientIdType && clientIdNumber)) {
      if (!clientIdType) {
        setHasErrorPersonIdType(true);
      }
      if (!clientIdNumber) {
        setHasErrorPersonIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorPersonAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorPersonAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorPersonAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateBusinessIndividualFormFieldsClientErrorClient = () => {
    let stakeholder = "klienta";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP) {
      setHasErrorCompanyNIP(true);
      props.onNotificationShow("NIP firmy jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    // if (!clientAddrCountry) {
    //   setHasErrorClientAddrCountry(true);
    //   props.onNotificationShow(
    //     "Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }
    // if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
    //   setHasErrorClientPesel(true);
    //   props.onNotificationShow(
    //     "Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    // if (!(clientIdType && clientIdNumber)) {
    //   if (!clientIdType) {
    //     setHasErrorClientIdType(true);
    //   }
    //   if (!clientIdNumber) {
    //     setHasErrorClientIdNumber(true);
    //   }
    //   props.onNotificationShow(
    //     "Rodzaj i numer dokumentu identyfikacji " +
    //       stakeholder +
    //       " są wymagane. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateBusinessIndividualFormFieldsPersonErrorClient = () => {
    let stakeholder = "klienta";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP) {
      setHasErrorCompanyNIP(true);
      props.onNotificationShow("NIP firmy jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!personName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!personSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!personPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(personPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    // if (!clientAddrCountry) {
    //   setHasErrorClientAddrCountry(true);
    //   props.onNotificationShow(
    //     "Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }
    // if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
    //   setHasErrorClientPesel(true);
    //   props.onNotificationShow(
    //     "Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    // if (!(clientIdType && clientIdNumber)) {
    //   if (!clientIdType) {
    //     setHasErrorClientIdType(true);
    //   }
    //   if (!clientIdNumber) {
    //     setHasErrorClientIdNumber(true);
    //   }
    //   props.onNotificationShow(
    //     "Rodzaj i numer dokumentu identyfikacji " +
    //       stakeholder +
    //       " są wymagane. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    if (!personAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!personAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!personAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateCompanyFormFieldsClientErrorClient = () => {
    let stakeholder = "reprezentanta";
    let company = "firmy";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP && !companyKRS) {
      setHasErrorCompanyNIP(true);
      setHasErrorCompanyKRS(true);
      props.onNotificationShow("NIP firmy lub KRS jest wymagany. Uzupełnij minimum jedno pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    // if (!clientAddrCountry) {
    //   setHasErrorClientAddrCountry(true);
    //   props.onNotificationShow(
    //     "Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }
    // if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
    //   setHasErrorClientPesel(true);
    //   props.onNotificationShow(
    //     "Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }

    // if (!(clientIdType && clientIdNumber)) {
    //   if (!clientIdType) {
    //     setHasErrorClientIdType(true);
    //   }
    //   if (!clientIdNumber) {
    //     setHasErrorClientIdNumber(true);
    //   }
    //   props.onNotificationShow(
    //     "Rodzaj i numer dokumentu identyfikacji " +
    //       stakeholder +
    //       " są wymagane. Uzupełnij dane.",
    //     dict.NotificationType.error
    //   );
    //   return -1;
    // }
  };

  const validateCompanyFormFieldsPersonErrorClient = () => {
    let company = "firmy";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP && !companyKRS) {
      setHasErrorCompanyNIP(true);
      setHasErrorCompanyKRS(true);
      props.onNotificationShow("NIP firmy lub KRS jest wymagany. Uzupełnij minimum jedno pole.", dict.NotificationType.error);
      return -1;
    }

    if (!personAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!personAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!personAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
  };

  // TODO - wywołać z zewnątrz (funkcja wysyłki lub udostępnienia)
  // eslint-disable-next-line
  const validateForm = () => {
    if (clientType === dict.ClientType.person) {
      let res = validateClientFormFieldsClientErrorClient();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.personWithRepresentative) {
      let res = validateClientFormFieldsPersonErrorClient(false);
      if (res === -1) return -1;
      res = validateClientFormFieldsClientErrorPerson(true);
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.businessIndividual) {
      let res = validateBusinessIndividualFormFieldsClientErrorClient();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.businessIndividualWithRepresentative) {
      let res = validateBusinessIndividualFormFieldsPersonErrorClient();
      if (res === -1) return -1;
      res = validateClientFormFieldsClientErrorPerson(true);
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.company) {
      let res = validateCompanyFormFieldsClientErrorClient();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.companyWithRepresentative) {
      let res = validateCompanyFormFieldsPersonErrorClient();
      if (res === -1) return -1;
      res = validateClientFormFieldsClientErrorPerson(true);
      if (res === -1) return -1;
    }
  };

  const getFields = (formMode) => {
    let data = {
      clientType: clientType,
      clientName: clientName,
      clientSurname: clientSurname,
      clientAddrStreet: clientAddrStreet,
      clientAddrStreetNumber: clientAddrStreetNumber,
      clientAddrLocaleNumber: clientAddrLocaleNumber,
      clientAddrPostcode: clientAddrPostcode,
      clientAddrCity: clientAddrCity,
      clientAddrCountry: clientAddrCountry,
      clientPesel: clientPesel,
      clientIdType: clientIdType,
      clientIdNumber: clientIdNumber,
      clientPhone: clientPhone,
      clientEmail: clientEmail,

      personName: personName,
      personSurname: personSurname,
      personPhone: personPhone,
      personEmail: personEmail,
      personAddrStreet: personAddrStreet,
      personAddrStreetNumber: personAddrStreetNumber,
      personAddrLocaleNumber: personAddrLocaleNumber,
      personAddrPostcode: personAddrPostcode,
      personAddrCity: personAddrCity,
      personAddrCountry: personAddrCountry,
      personPesel: personPesel,
      personIdType: personIdType,
      personIdNumber: personIdNumber,

      companyName: companyName,
      companyNIP: companyNIP,
      companyKRS: companyKRS,
    };

    if (formMode === dict.FormMode.add) {
      data = {
        ...data,
        //       status: dict.DocStatus.new,
      };
    } else if (formMode === dict.FormMode.edit) {
      data = {
        ...data,
        //   signatureAgent: signatureAgent.current,
      };
    }

    return data;
  };

  const saveModeEdit = async () => {
    await IntermediationContractPersonApi.Update(objectId, getFields(dict.FormMode.edit));
    return objectId;
  };

  const validateFormButtonSave = () => {
    if (!phoneValidator(personPhone)) {
      props.onNotificationShow("Numer telefonu współwłaściciela posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }
  };

  // TODO - wywołać z zewnątrz (funkcja wysyłki lub udostępnienia)
  // eslint-disable-next-line
  const handleSaveClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormButtonSave();
      if (result === -1) {
        return -1;
      }
      // result = validateForm();
      // if (result === -1) {
      //   return -1;
      // }

      if (mode === dict.FormMode.add) {
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.edit) {
        // let p = await IntermediationContractApi.GetStatusById(objectId);
        // if (p.status === dict.DocStatus.accepted) {
        //   throw Error(
        //     "Nie można aktualizować - dane zostały zatwierdzone przez klienta."
        //   );
        // }

        objId = await saveModeEdit();
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd: ".concat(error.message), dict.NotificationType.error);
    }
  };

  const handleDelete = async () => {
    const id = await IntermediationContractPersonApi.Delete(objectId);

    if (props.onDelete) {
      props.onDelete(id);
    }
  };

  const handleClientTypeChoosen = (value) => {
    setClientType(value);
  };

  const renderRepresentative = () => {
    return (
      <ClientPerson
        isRepresentative={true}
        isModePreview={isModePreview}
        hasErrorSectionClient={hasErrorSectionClient}
        clientType={clientType}
        onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
        clientName={clientName}
        onChangeClientName={(value) => setClientName(value)}
        hasErrorClientName={hasErrorPersonName}
        clientSurname={clientSurname}
        onChangeClientSurname={(value) => setClientSurname(value)}
        hasErrorClientSurname={hasErrorPersonSurname}
        clientPhone={clientPhone}
        onChangeClientPhone={(value) => {
          setClientPhone(value);
          phoneValidator(value) ? setHasErrorPersonPhone(false) : setHasErrorPersonPhone(true);
        }}
        hasErrorClientPhone={hasErrorPersonPhone}
        clientEmail={clientEmail}
        onChangeClientEmail={(value) => {
          setClientEmail(value);
          emailValidator(value) ? setHasErrorPersonEmail(false) : setHasErrorPersonEmail(true);
        }}
        hasErrorClientEmail={hasErrorPersonEmail}
        clientAddrCountry={clientAddrCountry}
        onChangeClientAddrCountry={(value) => setClientAddrCountry(value)}
        hasErrorClientAddrCountry={hasErrorPersonAddrCountry}
        clientPesel={clientPesel}
        onChangeClientPesel={(value) => setClientPesel(value)}
        hasErrorClientPesel={hasErrorPersonPesel}
        clientIdTypeCombo={clientIdTypeCombo}
        hasErrorClientIdType={hasErrorPersonIdType}
        onChangeClientIdType={(value) => setClientIdType(value)}
        onChangeClientIdTypeCombo={(value) => setClientIdTypeCombo(value)}
        clientIdNumber={clientIdNumber}
        onChangeClientIdNumber={(value) => setClientIdNumber(value)}
        hasErrorClientIdNumber={hasErrorPersonIdNumber}
        clientAddrStreet={clientAddrStreet}
        onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
        hasErrorClientAddrStreet={hasErrorPersonAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
        hasErrorClientAddrStreetNumber={hasErrorPersonAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
        clientAddrPostcode={clientAddrPostcode}
        onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
        clientAddrCity={clientAddrCity}
        onChangeClientAddrCity={(value) => setClientAddrCity(value)}
        hasErrorClientAddrCity={hasErrorPersonAddrCity}
      />
    );
  };

  const renderClient = () => {
    if (clientType === dict.ClientType.businessIndividual) {
      return (
        <ClientBusinessIndividual
          recordId={objectId}
          clientSectionName={""}
          addressSectionName={"Adres Współwłaściciela"}
          isModePreview={isModePreview}
          hasErrorSectionClient={hasErrorSectionClient}
          clientType={clientType}
          onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
          companyName={companyName}
          onChangeCompanyName={(value) => setCompanyName(value)}
          hasErrorCompanyName={hasErrorCompanyName}
          companyNIP={companyNIP}
          onChangeCompanyNIP={(value) => setCompanyNIP(value)}
          hasErrorCompanyNIP={hasErrorCompanyNIP}
          clientName={clientName}
          onChangeClientName={(value) => setClientName(value)}
          hasErrorClientName={hasErrorClientName}
          clientSurname={clientSurname}
          onChangeClientSurname={(value) => setClientSurname(value)}
          hasErrorClientSurname={hasErrorClientSurname}
          clientPhone={clientPhone}
          onChangeClientPhone={(value) => {
            setClientPhone(value);
            phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
          }}
          hasErrorClientPhone={hasErrorClientPhone}
          clientEmail={clientEmail}
          onChangeClientEmail={(value) => {
            setClientEmail(value);
            emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
          }}
          hasErrorClientEmail={hasErrorClientEmail}
          clientAddrStreet={clientAddrStreet}
          onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
          hasErrorClientAddrStreet={hasErrorClientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
          hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
          clientAddrPostcode={clientAddrPostcode}
          onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
          clientAddrCity={clientAddrCity}
          onChangeClientAddrCity={(value) => setClientAddrCity(value)}
          hasErrorClientAddrCity={hasErrorClientAddrCity}
        />
      );
    } else if (clientType === dict.ClientType.businessIndividualWithRepresentative) {
      return (
        <Stack>
          <ClientBusinessIndividual
            recordId={objectId}
            clientSectionName={""}
            addressSectionName={"Adres Współwłaściciela"}
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionClient}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            companyName={companyName}
            onChangeCompanyName={(value) => setCompanyName(value)}
            hasErrorCompanyName={hasErrorCompanyName}
            companyNIP={companyNIP}
            onChangeCompanyNIP={(value) => setCompanyNIP(value)}
            hasErrorCompanyNIP={hasErrorCompanyNIP}
            clientName={personName}
            onChangeClientName={(value) => setPersonName(value)}
            hasErrorClientName={hasErrorClientName}
            clientSurname={personSurname}
            onChangeClientSurname={(value) => setPersonSurname(value)}
            hasErrorClientSurname={hasErrorClientSurname}
            clientPhone={personPhone}
            onChangeClientPhone={(value) => {
              setPersonPhone(value);
              phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
            }}
            hasErrorClientPhone={hasErrorClientPhone}
            clientEmail={personEmail}
            onChangeClientEmail={(value) => {
              setPersonEmail(value);
              emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
            }}
            hasErrorClientEmail={hasErrorClientEmail}
            clientAddrStreet={personAddrStreet}
            onChangeClientAddrStreet={(value) => setPersonAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={personAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPersonAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={personAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPersonAddrLocaleNumber(value)}
            clientAddrPostcode={personAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPersonAddrPostcode(value)}
            clientAddrCity={personAddrCity}
            onChangeClientAddrCity={(value) => setPersonAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } else if (clientType === dict.ClientType.company) {
      return (
        <ClientCompany
          recordId={objectId}
          clientSectionName={""}
          addressSectionName={"Adres Współwłaściciela"}
          isRepresentative={false}
          isModePreview={isModePreview}
          hasErrorSectionClient={hasErrorSectionClient}
          clientType={clientType}
          onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
          companyName={companyName}
          onChangeCompanyName={(value) => setCompanyName(value)}
          hasErrorCompanyName={hasErrorCompanyName}
          companyNIP={companyNIP}
          onChangeCompanyNIP={(value) => setCompanyNIP(value)}
          hasErrorCompanyNIP={hasErrorCompanyNIP}
          companyKRS={companyKRS}
          onChangeCompanyKRS={(value) => setCompanyKRS(value)}
          hasErrorCompanyKRS={hasErrorCompanyKRS}
          clientAddrStreet={clientAddrStreet}
          onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
          hasErrorClientAddrStreet={hasErrorClientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
          hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
          clientAddrPostcode={clientAddrPostcode}
          onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
          clientAddrCity={clientAddrCity}
          onChangeClientAddrCity={(value) => setClientAddrCity(value)}
          hasErrorClientAddrCity={hasErrorClientAddrCity}
          clientName={clientName}
          onChangeClientName={(value) => setClientName(value)}
          hasErrorClientName={hasErrorClientName}
          clientSurname={clientSurname}
          onChangeClientSurname={(value) => setClientSurname(value)}
          hasErrorClientSurname={hasErrorClientSurname}
          clientPhone={clientPhone}
          onChangeClientPhone={(value) => {
            setClientPhone(value);
            phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
          }}
          hasErrorClientPhone={hasErrorClientPhone}
          clientEmail={clientEmail}
          onChangeClientEmail={(value) => {
            setClientEmail(value);
            emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
          }}
          hasErrorClientEmail={hasErrorClientEmail}
        />
      );
    } else if (clientType === dict.ClientType.companyWithRepresentative) {
      return (
        <Stack>
          <ClientCompany
            recordId={objectId}
            clientSectionName={""}
            addressSectionName={"Adres Współwłaściciela"}
            isRepresentative={true}
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionClient}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            companyName={companyName}
            onChangeCompanyName={(value) => setCompanyName(value)}
            hasErrorCompanyName={hasErrorCompanyName}
            companyNIP={companyNIP}
            onChangeCompanyNIP={(value) => setCompanyNIP(value)}
            hasErrorCompanyNIP={hasErrorCompanyNIP}
            companyKRS={companyKRS}
            onChangeCompanyKRS={(value) => setCompanyKRS(value)}
            hasErrorCompanyKRS={hasErrorCompanyKRS}
            clientAddrStreet={personAddrStreet}
            onChangeClientAddrStreet={(value) => setPersonAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={personAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPersonAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={personAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPersonAddrLocaleNumber(value)}
            clientAddrPostcode={personAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPersonAddrPostcode(value)}
            clientAddrCity={personAddrCity}
            onChangeClientAddrCity={(value) => setPersonAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } else if (clientType === dict.ClientType.personWithRepresentative) {
      return (
        <Stack>
          <ClientPerson
            recordId={objectId}
            clientSectionName={""}
            addressSectionName={"Adres Współwłaściciela"}
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionClient}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            clientName={personName}
            onChangeClientName={(value) => setPersonName(value)}
            hasErrorClientName={hasErrorClientName}
            clientSurname={personSurname}
            onChangeClientSurname={(value) => setPersonSurname(value)}
            hasErrorClientSurname={hasErrorClientSurname}
            clientPhone={personPhone}
            onChangeClientPhone={(value) => {
              setPersonPhone(value);
              phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
            }}
            hasErrorClientPhone={hasErrorClientPhone}
            clientEmail={personEmail}
            onChangeClientEmail={(value) => {
              setPersonEmail(value);
              emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
            }}
            hasErrorClientEmail={hasErrorClientEmail}
            clientAddrCountry={personAddrCountry}
            onChangeClientAddrCountry={(value) => setPersonAddrCountry(value)}
            hasErrorClientAddrCountry={hasErrorClientAddrCountry}
            clientPesel={personPesel}
            onChangeClientPesel={(value) => setPersonPesel(value)}
            hasErrorClientPesel={hasErrorClientPesel}
            clientIdTypeCombo={personIdTypeCombo}
            hasErrorClientIdType={hasErrorClientIdType}
            onChangeClientIdType={(value) => setPersonIdType(value)}
            onChangeClientIdTypeCombo={(value) => setPersonIdTypeCombo(value)}
            clientIdNumber={personIdNumber}
            onChangeClientIdNumber={(value) => setPersonIdNumber(value)}
            hasErrorClientIdNumber={hasErrorClientIdNumber}
            clientAddrStreet={personAddrStreet}
            onChangeClientAddrStreet={(value) => setPersonAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={personAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPersonAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={personAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPersonAddrLocaleNumber(value)}
            clientAddrPostcode={personAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPersonAddrPostcode(value)}
            clientAddrCity={personAddrCity}
            onChangeClientAddrCity={(value) => setPersonAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } //else if (clientType === dict.ClientType.person) {

    return (
      <ClientPerson
        recordId={objectId}
        clientSectionName={""}
        addressSectionName={"Adres Współwłaściciela"}
        isModePreview={isModePreview}
        hasErrorSectionClient={hasErrorSectionClient}
        clientType={clientType}
        onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
        clientName={clientName}
        onChangeClientName={(value) => setClientName(value)}
        hasErrorClientName={hasErrorClientName}
        clientSurname={clientSurname}
        onChangeClientSurname={(value) => setClientSurname(value)}
        hasErrorClientSurname={hasErrorClientSurname}
        clientPhone={clientPhone}
        onChangeClientPhone={(value) => {
          setClientPhone(value);
          phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
        }}
        hasErrorClientPhone={hasErrorClientPhone}
        clientEmail={clientEmail}
        onChangeClientEmail={(value) => {
          setClientEmail(value);
          emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
        }}
        hasErrorClientEmail={hasErrorClientEmail}
        clientAddrCountry={clientAddrCountry}
        onChangeClientAddrCountry={(value) => setClientAddrCountry(value)}
        hasErrorClientAddrCountry={hasErrorClientAddrCountry}
        clientPesel={clientPesel}
        onChangeClientPesel={(value) => setClientPesel(value)}
        hasErrorClientPesel={hasErrorClientPesel}
        clientIdTypeCombo={clientIdTypeCombo}
        hasErrorClientIdType={hasErrorClientIdType}
        onChangeClientIdType={(value) => setClientIdType(value)}
        onChangeClientIdTypeCombo={(value) => setClientIdTypeCombo(value)}
        clientIdNumber={clientIdNumber}
        onChangeClientIdNumber={(value) => setClientIdNumber(value)}
        hasErrorClientIdNumber={hasErrorClientIdNumber}
        clientAddrStreet={clientAddrStreet}
        onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
        hasErrorClientAddrStreet={hasErrorClientAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
        hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
        clientAddrPostcode={clientAddrPostcode}
        onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
        clientAddrCity={clientAddrCity}
        onChangeClientAddrCity={(value) => setClientAddrCity(value)}
        hasErrorClientAddrCity={hasErrorClientAddrCity}
      />
    );
  };

  return (
    <Stack style={{ paddingBottom: "20px" }}>
      <Accordion style={{ background: "#ebebeb" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 0, pb: 0 }} style={{ color: hasErrorSectionClient ? "red" : "" }}>
            {"Współwłaściciel"}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{renderClient()}</AccordionDetails>
        <AccordionActions>
          <Button onClick={handleDelete} disabled={isModePreview || docStatus !== dict.DocStatus.new ? true : false}>
            {"Usuń"}
          </Button>
          {/* <Button onClick={handleSaveClick} disabled={isModePreview}>
            {"Zapisz"}
          </Button> */}
        </AccordionActions>
      </Accordion>
    </Stack>
  );
});

export default Person;
