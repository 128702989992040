import { useState, useEffect, useRef } from "react";
import MyGrid from "../../components/MyGrid/MyGrid";
import { Button } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
// eslint-disable-next-line
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import * as Dict from "../../config/Dictionary";
import { Box } from "@mui/material";
import * as PresentationProtocolApi from "./PresentationProtocolApi";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "./../../config/NavigationRoute/RoutePath";
import Autocomplete from "@mui/material/Autocomplete";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { setFileName } from "./../../utils/validator";

export default function PresentationProtocolList(props) {
  const navigate = useNavigate();
  const recordId = useRef(0);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      renderCell: (params) => (
        <strong>
          <Button onClick={(e, id) => handleClickPreview(e, params.id)}>{params.row.id}</Button>
        </strong>
      ),
    },
    {
      field: "edit",
      headerName: "Edytuj",
      width: 55,
      renderCell: (params) => (
        <strong>
          <Button style={{ marginLeft: -15 }} onClick={(e, id) => handleClickEdit(e, params.id)}>
            <ModeEditOutlineOutlinedIcon />
          </Button>
        </strong>
      ),
    },
    {
      field: "download",
      headerName: "Pobierz",
      width: 60,
      renderCell: (params) =>
        params.row.status === Dict.DocStatus.accepted && (
          <strong>
            <Button style={{ marginLeft: -15 }} onClick={(e, id) => downloadDocument(params.id)}>
              {" "}
              <CloudDownloadOutlinedIcon />
            </Button>
          </strong>
        ),
    },
    // {
    //   field: "delete",
    //   headerName: "Usuń",
    //   width: 50,
    //   renderCell: (params) => (
    //     <strong>
    //       <Button
    //         style={{ marginLeft: -15 }}
    //         onClick={(e, id) => handleClickDelete(e, params.id)}
    //       >
    //         <DeleteForeverOutlinedIcon />
    //       </Button>
    //     </strong>
    //   ),
    // },
    {
      field: "agent",
      headerName: "Agent",
      width: 130,
      editable: false,
      sortable: true,
    },
    {
      field: "visitDate",
      headerName: "Data prezentacji",
      width: 140,
      editable: false,
      sortable: true,
      //valueFormatter: (params) => (params.value).format("DD/MM/YYYY"),
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        // Convert the decimal value to a percentage
        //return new Date(params.value).toISOString().split("T")[0]; //.format("YYYY-MM-DD");

        return dayjs(params.value).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      field: "address",
      headerName: "Adres nieruchomości",
      width: 340,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      editable: false,
      sortable: true,
      valueGetter: (params) => {
        return Dict.DocStatusGetName(params.value);
      },
    },
    {
      field: "clientName",
      headerName: "Imię klienta",
      width: 190,
      editable: false,
      sortable: true,
    },
    {
      field: "clientSurname",
      headerName: "Nazwisko klienta",
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "clientPhone",
      headerName: "Telefon klienta",
      width: 180,
      editable: false,
      sortable: true,
    },
    {
      field: "clientPesel",
      headerName: "Pesel klienta",
      width: 160,
      editable: false,
      sortable: true,
    },
  ];

  const clearFilters = {
    fName: "",
    fSurname: "",
    fVisitDateFrom: null,
    fVisitDateTo: null,
    fPesel: "",
    fPhone: "",
    fStatus: "",
  };
  const [filters, setFilters] = useState(clearFilters);
  const [fName, setFName] = useState("");
  const [fSurname, setFSurname] = useState("");
  const [fVisitDateFrom, setFVisitDateFrom] = useState(dayjs(Dict.DefaultDate));
  const [fVisitDateTo, setFVisitDateTo] = useState(dayjs(Dict.DefaultDate));
  const [fPesel, setFPesel] = useState("");
  const [fPhone, setFPhone] = useState("");
  const [fStatus, setFStatus] = useState("");
  const [statusCombo, setStatusCombo] = useState(Dict.DocStatusCombo[0]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogCloseNo = () => {
    recordId.current = 0;
    setDialogOpen(false);
  };

  const handleDialogCloseYes = () => {
    deleteRecord(null, recordId.current);
    setDialogOpen(false);
  };

  // eslint-disable-next-line
  const handleClickDelete = (e, id) => {
    handleDialogClickOpen();
    recordId.current = id;
  };

  const deleteRecord = async (e, id) => {
    try {
      await PresentationProtocolApi.Delete(id);

      if (props.onObjectDelete) {
        props.onObjectDelete(id);
      }

      let objectsFiltered = await PresentationProtocolApi.GetAllFiltering({
        clientName: fName,
        clientSurname: fSurname,
        visitDateFrom: fVisitDateFrom,
        visitDateTo: fVisitDateTo,
        clientPesel: fPesel,
        clientPhone: fPhone,
        status: fStatus,
      });

      setRecords({
        data: formatPresentedData(objectsFiltered),
      });
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const serializeFilters = () => {
    let filters = {
      fName: fName,
      fSurname: fSurname,
      fVisitDateFrom: fVisitDateFrom,
      fVisitDateTo: fVisitDateTo,
      fPesel: fPesel,
      fPhone: fPhone,
      fStatus: fStatus,
    };

    setFilters(filters);
    return filters;
  };

  const handleClickEdit = (e, id) => {
    if (props.onObjectEdit) {
      props.onObjectEdit(id, serializeFilters());
    }
  };

  const handleClickPreview = (e, id) => {
    if (props.onObjectPreview) {
      props.onObjectPreview(id, serializeFilters());
    }
  };

  const formatPresentedData = (dbData) => {
    let data = [];

    dbData?.forEach((e) => {
      data.push({
        ...e,
        address: "".concat(
          e.addrStreet ? "ul. ".concat(e.addrStreet) : "",
          " ",
          e.addrStreetNumber,
          e.addrLocaleNumber ? "/".concat(e.addrLocaleNumber) : "",
          " ",
          e.addrPostcode,
          " ",
          e.addrCity
        ),
        agent: e.UserAgent ? e.UserAgent.login : e.UserCreated.login,
      });
    });

    return data;
  };

  const updateObjects = async (data) => {
    try {
      let objectsFiltered = await PresentationProtocolApi.GetAllFiltering(data);
      setRecords({
        data: formatPresentedData(objectsFiltered),
      });
    } catch (error) {
      console.error("Błąd metody updateObjects", error);
    }
  };

  useEffect(() => {
    let localName = props?.filters?.fName ? props?.filters?.fName : fName;
    let localSurname = props?.filters?.fSurname ? props?.filters?.fSurname : fSurname;
    let localVisitDateFrom = props?.filters?.fVisitDateFrom ? props?.filters?.fVisitDateFrom : fVisitDateFrom;
    let localVisitDateTo = props?.filters?.fVisitDateTo ? props?.filters?.fVisitDateTo : fVisitDateTo;
    let localPesel = props?.filters?.fPesel ? props?.filters?.fPesel : fPesel;
    let localPhone = props?.filters?.fPhone ? props?.filters?.fPhone : fPhone;
    let localStatus = props?.filters?.fStatus ? props?.filters?.fStatus : fStatus;

    if (localVisitDateFrom?.isSame(Dict.DefaultDate, "day")) {
      localVisitDateFrom = null;
    }
    if (localVisitDateTo?.isSame(Dict.DefaultDate, "day")) {
      localVisitDateTo = null;
    }

    setFName(localName);
    setFSurname(localSurname);
    setFVisitDateFrom(localVisitDateFrom);
    setFVisitDateTo(localVisitDateTo);
    setFPesel(localPesel);
    setFPhone(localPhone);
    setFStatus(localStatus);

    updateObjects({
      clientName: localName,
      clientSurname: localSurname,
      visitDateFrom: localVisitDateFrom,
      visitDateTo: localVisitDateTo,
      clientPesel: localPesel,
      clientPhone: localPhone,
      status: localStatus,
    });

    // eslint-disable-next-line
  }, []);

  const [records, setRecords] = useState({
    data: [],
  });

  // eslint-disable-next-line
  const [recordsSelected, setRecordsSelected] = useState([]);

  const handleSelectionModelChange = (model) => {
    if (model.length > 1) {
      props.onNotyficationShow("Powinieneś wybrać dokładnie jedną osobę nie więcej!", "error");
      return;
    }

    setRecordsSelected(model);
    if (props.onRecordSelected) {
      props.onRecordSelected(model);
    }
  };

  const renderDialogCancel = () => {
    return (
      <Dialog open={dialogOpen} onClose={handleDialogCloseNo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Usuwanie osoby badanej"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ whiteSpace: "pre-wrap" }}>
            {"Przypadkowe usunięcie może spowodować nieodwracalną utratę danych.\nCzy kontynuować?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCloseNo}>Nie</Button>
          <Button onClick={handleDialogCloseYes} autoFocus>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [openFilter, setOpenFilter] = useState(false);

  const handleOnClickSearchBtn = () => {
    setOpenFilter(true);
  };

  const isFilterActive = () => {
    if (
      (fName && fName !== "") ||
      (fSurname && fSurname !== "") ||
      fVisitDateFrom ||
      fVisitDateTo ||
      (fPesel && fPesel !== "") ||
      (fPhone && fPhone !== "") ||
      (fStatus && fStatus !== "")
    ) {
      return true;
    }
    return false;
  };

  const handleOnClickDisableSearchBtn = async () => {
    try {
      setFilters(clearFilters);
      setFName("");
      setFSurname("");
      setFVisitDateFrom(null);
      setFVisitDateTo(null);
      setFPesel("");
      setFPhone("");
      setFStatus("");

      let objectsFiltered = await PresentationProtocolApi.GetAllFiltering();

      if (props.onObjectFiltered) {
        props.onObjectFiltered(clearFilters);
      }

      setRecords({
        data: formatPresentedData(objectsFiltered),
      });
    } catch (error) {
      props.onNotificationShow(error, "error");
    }
  };

  const handleOnClickFilteringCancel = () => {
    setOpenFilter(false);
  };

  const handleOnClickFilteringSearch = async (e) => {
    try {
      let objectsFiltered = await PresentationProtocolApi.GetAllFiltering({
        clientName: fName,
        clientSurname: fSurname,
        visitDateFrom: fVisitDateFrom,
        visitDateTo: fVisitDateTo,
        clientPesel: fPesel,
        clientPhone: fPhone,
        status: fStatus,
      });

      if (props.onObjectFiltered) {
        props.onObjectFiltered(serializeFilters());
      }

      setRecords({
        data: formatPresentedData(objectsFiltered),
      });

      setOpenFilter(false);
    } catch (error) {
      if (error.message === "403") {
        navigate(RoutePath.Login);
      } else {
        // wyświetl komunikat błędu (TODO: wymaga naprawy)
        props.onNotificationShow(error, "error");
      }
    }
  };

  const renderDialogSearch = () => {
    return (
      <Dialog open={openFilter} onClose={handleOnClickFilteringCancel} fullWidth maxWidth={"md"}>
        <DialogTitle>{"Wyszukiwanie"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Uzupełnij wybrane pola, a następnie wciśnij przycisk "Szukaj". Możesz używać symbolu wieloznaczności: %.'}
          </DialogContentText>
          <br />

          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Imię klienta"}
              id={"fName"}
              value={fName}
              onChange={(e) => setFName(e.target.value)}
              sx={{ pb: 2, width: "50%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: fName !== "" ? "visible" : "hidden" }}
                    onClick={() => {
                      setFName("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
            <TextField
              size="small"
              label={"Nazwisko klienta"}
              id={"fSurname"}
              value={fSurname}
              onChange={(e) => setFSurname(e.target.value)}
              sx={{ pb: 2, width: "50%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: fSurname !== "" ? "visible" : "hidden" }}
                    onClick={() => {
                      setFSurname("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={"Data prezentacji - OD"}
                id={"fVisitDateFrom"}
                value={fVisitDateFrom}
                format="YYYY-MM-DD"
                onChange={(e) => setFVisitDateFrom(e)}
                slotProps={{
                  actionBar: {
                    actions: ["clear", "today"],
                  },
                  textField: {
                    size: "small",
                  },
                }}
                sx={{ pb: 2, width: "35%" }}
                error={false}
                helperText={null}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={"Data prezentacji - DO"}
                id={"fVisitDateTo"}
                value={fVisitDateTo}
                format="YYYY-MM-DD"
                onChange={(e) => setFVisitDateTo(e)}
                slotProps={{
                  actionBar: { actions: ["clear", "today"] },
                  textField: {
                    size: "small",
                  },
                }}
                sx={{ pb: 2, width: "35%" }}
              />
            </LocalizationProvider>
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Pesel klienta"}
              id={"fPesel"}
              value={fPesel}
              onChange={(e) => setFPesel(e.target.value)}
              sx={{ pb: 2, width: "50%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: fPesel !== "" ? "visible" : "hidden" }}
                    onClick={() => {
                      setFPesel("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
            <TextField
              size="small"
              label={"Telefon klienta"}
              id={"fPhone"}
              value={fPhone}
              onChange={(e) => setFPhone(e.target.value)}
              sx={{ pb: 2, width: "50%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: fPhone !== "" ? "visible" : "hidden" }}
                    onClick={() => {
                      setFPhone("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Stack>
          <Stack>
            <Autocomplete
              disablePortal
              id="Status"
              options={Dict.DocStatusCombo}
              sx={{ width: "49%", mb: 2 }}
              renderInput={(params) => <TextField {...params} label="Status" size="small" required={true} />}
              value={statusCombo}
              onChange={(e, value) => {
                setFStatus(value?.code);
                setStatusCombo(Dict.DocStatusCombo.filter((f) => f.code === value?.code)[0]);
              }}
              //disabled={isModePreview}
              disableClearable={true}
            />
          </Stack>
          <TextField
            size="small"
            disabled
            id={"validator"}
            variant="standard"
            sx={{ width: "100%" }}
            error={
              fName !== filters?.fName ||
              fSurname !== filters?.fSurname ||
              fVisitDateFrom !== filters?.fVisitDateFrom ||
              fVisitDateTo !== filters?.fVisitDateTo ||
              fPesel !== filters?.fPesel ||
              fPhone !== filters?.fPhone ||
              fStatus !== filters?.fStatus
            }
            helperText={
              fName !== filters?.fName ||
              fSurname !== filters?.fSurname ||
              fVisitDateFrom !== filters?.fVisitDateFrom ||
              fVisitDateTo !== filters?.fVisitDateTo ||
              fPesel !== filters?.fPesel ||
              fPhone !== filters?.fPhone ||
              fStatus !== filters?.fStatus
                ? "* Wprowadzono nowe wartości w polach wyszukiwania. Wymagane użycie - Szukaj / Enter."
                : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClickFilteringCancel}>Anuluj</Button>
          <Button onClick={(e) => handleOnClickFilteringSearch(e)}>{"Szukaj"}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const downloadDocument = async (docId) => {
    let data = await PresentationProtocolApi.GetById(docId);

    let link = document.createElement("a");
    link.download = setFileName(
      Dict.DocTypes.presentationProtocol,
      "pdf",
      dayjs(data.visitDate).format("YYYY-MM-DDTHH:mm"),
      data.addrCity,
      data.addrStreet,
      data.addrStreetNumber,
      data.addrLocaleNumber
    );

    let response = await PresentationProtocolApi.GetBlobById(docId);
    let blob = new Blob([response], { type: "application/pdf" });

    link.href = URL.createObjectURL(blob);
    link.click();

    URL.revokeObjectURL(link.href);
  };

  return (
    <Box sx={{ width: "1400px", overflow: "auto" }}>
      <br />
      {renderDialogCancel()}
      {renderDialogSearch()}
      <Button sx={{ mr: 2 }} variant="outlined" onClick={handleOnClickSearchBtn} startIcon={<SearchOutlinedIcon />} size={"small"}>
        {"Wyszukaj"}
      </Button>
      <Button
        sx={{ mr: 2 }}
        variant="outlined"
        onClick={handleOnClickDisableSearchBtn}
        startIcon={<SearchOffOutlinedIcon />}
        size={"small"}
        disabled={!isFilterActive()}
      >
        {"Wyczyść filtry"}
      </Button>
      <MyGrid
        rows={records.data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection={false}
        disableSelectionOnClick={false}
        experimentalFeatures={{ newEditingApi: false }}
        onSelectionModelChange={(a) => handleSelectionModelChange(a)}
        width={1730}
        sx={{ mt: 1 }}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
}
