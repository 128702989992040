import * as React from "react";
import Paperbase from "../../layout/Admin/Paperbase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Token from "./../../api/Token";
import * as RoutePath from "./../../config/NavigationRoute/RoutePath";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import * as dict from "../../config/Dictionary";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationHelper";

const currentTabIndex = 0;
const handleTabChange = () => {};
const notification = null;

function HomePage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const checkToken = async () => {
    const isTokenValid = await Token.isTokenValid();

    if (!isTokenValid) {
      navigate(RoutePath.Login);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return null;
  }

  const displayContentTab0 = () => {
    return (
      <div
        spacing={2}
        style={{
          backgroundImage: `url('/assets/Graphics/client/background.jpg')`,
          backgroundSize: "100% 100%",
          margin: "-30px -30px",
          paddingTop: "15px",
        }}
      >
        <Grid container spacing={2}>
          {/* Cards */}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} justifyContent="right" container>
            {/* Card 1 */}
            <Card sx={{ maxWidth: 400, m: 1, background: "#eaeff1" }}>
              <CardMedia
                sx={{
                  height: 140,
                }}
                image={"/assets/Graphics/client/presentationModule.png"}
                title="Presentation protocol"
                style={{ backgroundPosition: "0px 0px" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "left" }}>
                  Prezentacja nieruchomości
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                  Przyspiesz i zdigitalizuj proces oględzin nieruchomości mając na uwadze jednoczesne zwiększenie poziomu bezpieczeństwa zarówno
                  własnej osoby jak i klienta.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href={RoutePath.PresentationProtocolAgent}>
                  {"Utwórz protokół prezentacji"}
                </Button>
                <Button size="small">{"Archiwum"}</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} justifyContent="left" container>
            <Card sx={{ maxWidth: 400, m: 1, background: "#eaeff1" }}>
              <CardMedia
                sx={{
                  height: 140,
                }}
                image={"/assets/Graphics/client/reminderModule.png"}
                title="Intermediation contract"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "left" }}>
                  Umowa pośrednictwa
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                  Zawieranie umowy pośrednictwa jeszcze nigdy nie było tak proste, innowacyjne i szybkie dzięki formule elektronicznego podpisu
                  odręcznego.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href={RoutePath.IntermediationContractAgent}>
                  {"Utwórz umowę pośrednictwa"}
                </Button>
                <Button size="small">{"Archiwum"}</Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} justifyContent="right" container>
            <Card sx={{ maxWidth: 400, m: 1, background: "#eaeff1" }}>
              <CardMedia
                sx={{
                  height: 140,
                }}
                image={"/assets/Graphics/client/clientsModule.jpg"}
                title="Reminder"
                style={{ opacity: 1 }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "left" }}>
                  Moduł powiadomień
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                  Wypełniaj swoje obowiązki jeszcze bardziej efektywnie dzięki optymalnemu planowaniu pracy i przypomieniom o ważnych wydarzeniach.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">{"Zapytaj o dostępność"}</Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} justifyContent="left" container>
            {/* Card 4 */}
            <Card sx={{ maxWidth: 400, m: 1, background: "#eaeff1" }}>
              <CardMedia
                sx={{
                  height: 140,
                }}
                image={"/assets/Graphics/client/estateModule.jpg"}
                title="Estates"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "left" }}>
                  Kartoteka lokali
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                  Historia wszystkich operacji związanych z nieruchomością prezentowana w jednym miejscu zwiększa celowość i jakość podejmowanych
                  działań.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">{"Zapytaj o dostępność"}</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Paperbase
        activepositioncode={dict.PageCodes.desktop}
        title={t(formatTranslation("menu", dict.PageCodes.desktop))}
        subtitle={"Lider rynku nieruchomości"}
        tabActiveIndex={currentTabIndex}
        onTabChange={(a, b) => handleTabChange(a, b)}
        notification={notification}
        tab0Label={""}
        tab0Title={""}
        tab0Content={displayContentTab0()}
      />
    </React.Fragment>
  );
}

export default HomePage;
