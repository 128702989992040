import { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Stack from "@mui/material/Stack";
import * as dict from "../../config/Dictionary";
import * as PresentationProtocolApi from "./PresentationProtocolApi";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import * as ActiveLinkAPI from "./../ActiveLink/ActiveLinkApi";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import * as SMSApi from "./../../components/SMS/SMSApi";
import * as session from "./../../components/SessionStorage/SessionStorage";
import Autocomplete from "@mui/material/Autocomplete";
import * as UserApi from "./../../business/User/UserApi";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import PresentationProtocolMonitor from "./PresentationProtocolMonitor";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import Printing from "../../printing/PresentationProtocol";
import { phoneValidator, phoneAsPin, setFileName } from "./../../utils/validator";

const PresentationProtocolForm = (props) => {
  const objectId = props.id;
  const mode = props.mode;
  const isModePreview = props.mode === dict.FormMode.preview ? true : false;

  const [visitDate, setVisitDate] = useState(dayjs(new Date().toUTCString()));

  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [clientPesel, setClientPesel] = useState("");
  const [clientIdType, setClientIdType] = useState("");
  const [clientIdTypeCombo, setClientIdTypeCombo] = useState(dict.IdentityDocumentType[0]);
  const [clientIdNumber, setClientIdNumber] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [hasClientPhoneError, setHasClientPhoneError] = useState(false);

  const [existsCoviewer, setExistsCoviewer] = useState(false);
  const [coviewerName, setCoviewerName] = useState("");
  const [coviewerSurname, setCoviewerSurname] = useState("");
  const [coviewerPesel, setCoviewerPesel] = useState("");
  const [coviewerIdType, setCoviewerIdType] = useState("");
  const [coviewerIdTypeCombo, setCoviewerIdTypeCombo] = useState(dict.IdentityDocumentType[0]);

  const [coviewerIdNumber, setCoviewerIdNumber] = useState("");

  const [addrStreet, setAddrStreet] = useState("");
  const [addrStreetNumber, setAddrStreetNumber] = useState("");
  const [addrLocaleNumber, setAddrLocaleNumber] = useState("");
  const [addrPostcode, setAddrPostcode] = useState("");
  const [addrCity, setAddrCity] = useState("");
  //const [addrCountry, setAddrCountry] = useState("");

  const [remark, setRemark] = useState("");

  // dedykowane pola
  const [status, setStatus] = useState(0);
  const [clientEmail, setClientEmail] = useState("");
  const [clientAcceptedAt, setClientAcceptedAt] = useState(dayjs(dict.DefaultDate.toUTCString()));
  const [agentSentAt, setAgentSentAt] = useState(dayjs(dict.DefaultDate.toUTCString()));

  const [consentPrivacy, setConsentPrivacy] = useState(false);
  const [consentMarketing, setConsentMarketing] = useState(false);
  const [consentTrade, setConsentTrade] = useState(false);
  const [consentMediation, setConsentMediation] = useState(false);

  // logika sterowania widokiem
  const [hideSendToClientButton, setHideSendToClientButton] = useState(false);

  const setFields = async (objId) => {
    try {
      const object = await PresentationProtocolApi.GetById(objId);

      setVisitDate(dayjs(object.visitDate));

      setClientName(object.clientName);
      setClientSurname(object.clientSurname);
      setClientPesel(object.clientPesel);
      setClientIdType(object.clientIdType);
      if (object.clientIdType) {
        setClientIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === object.clientIdType)[0]);
      }
      setClientIdNumber(object.clientIdNumber);
      setClientPhone(object.clientPhone);

      setCoviewerName(object.coviewerName);
      setCoviewerSurname(object.coviewerSurname);
      setCoviewerPesel(object.coviewerPesel);
      setCoviewerIdType(object.coviewerIdType);
      if (object.coviewerIdType) {
        setCoviewerIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === object.coviewerIdType)[0]);
      }
      setCoviewerIdNumber(object.coviewerIdNumber);
      // logika wyświetlania pól osoby współoglądającej
      setExistsCoviewer(object.coviewerName ? true : false);

      setAddrStreet(object.addrStreet);
      setAddrStreetNumber(object.addrStreetNumber);
      setAddrLocaleNumber(object.addrLocaleNumber);
      setAddrPostcode(object.addrPostcode);
      setAddrCity(object.addrCity);

      setRemark(object.remark);

      setStatus(object.status);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const setFieldsModePreview = async (objId) => {
    try {
      const object = await PresentationProtocolApi.GetById(objId);

      // dedykowana logika
      setClientEmail(object.clientEmail);
      if (object.clientAcceptedAt) {
        setClientAcceptedAt(dayjs(object.clientAcceptedAt));
      }
      if (object.agentSentAt) {
        setAgentSentAt(dayjs(object.agentSentAt));
      }
      setConsentPrivacy(object.consentPrivacy ? true : false);
      setConsentMarketing(object.consentMarketing ? true : false);
      setConsentTrade(object.consentTrade ? true : false);
      setConsentMediation(object.consentMediation ? true : false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (mode === dict.FormMode.edit) {
      setFields(objectId);
    } else if (mode === dict.FormMode.preview) {
      setFields(objectId);
      setFieldsModePreview(objectId);
    } else if (mode === dict.FormMode.add) {
    }

    // eslint-disable-next-line
  }, []);

  const validateFormButtonSave = () => {
    if (
      !clientName &&
      !clientSurname &&
      !clientPesel &&
      !clientIdType &&
      !clientIdNumber &&
      !clientPhone &&
      !coviewerName &&
      !coviewerSurname &&
      !coviewerPesel &&
      !coviewerIdType &&
      !coviewerIdNumber &&
      !addrStreet &&
      !addrStreetNumber &&
      !addrLocaleNumber &&
      !addrPostcode &&
      !addrCity &&
      !remark
    ) {
      props.onNotificationShow("Przed zapisem wymagane jest uzupełnienie minimum jednego pola", dict.NotificationType.error);
      return -1;
    }

    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }
  };

  const validateFormButtonSend = () => {
    if (!visitDate) {
      props.onNotificationShow("Data prezentacji jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      props.onNotificationShow("Imię klienta jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      props.onNotificationShow("Nazwisko klienta jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      props.onNotificationShow("Telefon klienta jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPesel && !(clientIdType && clientIdNumber)) {
      props.onNotificationShow("Pesel klienta albo rodzaj i numer dokumentu identyfikacji są wymagane. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }
    if (existsCoviewer && !coviewerName) {
      props.onNotificationShow(
        "Imię osoby współoglądającej jest wymagane. Uzupełnij pole albo usuń osobę współoglądającą w przypadku pomyłki.",
        dict.NotificationType.error
      );
      return -1;
    }
    if (existsCoviewer && !coviewerSurname) {
      props.onNotificationShow(
        "Nazwisko osoby współoglądającej jest wymagane. Uzupełnij pole albo usuń osobę współoglądającą w przypadku pomyłki.",
        dict.NotificationType.error
      );
      return -1;
    }
    if (existsCoviewer && !coviewerPesel && !(coviewerIdType && coviewerIdNumber)) {
      props.onNotificationShow(
        "Pesel osoby współoglądającej albo rodzaj i numer dokumentu identyfikacji są wymagane. Uzupełnij dane albo usuń osobę współoglądającą w przypadku pomyłki.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!addrStreet) {
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
      return -1;
    }
    if (!addrStreetNumber) {
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
      return -1;
    }
    if (!addrCity) {
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
      return -1;
    }
  };

  const getFields = (formMode) => {
    let data = {
      visitDate: visitDate,
      clientName: clientName,
      clientSurname: clientSurname,
      clientPesel: clientPesel,
      clientIdType: clientIdType,
      clientIdNumber: clientIdNumber,
      clientPhone: clientPhone,
      coviewerName: coviewerName,
      coviewerSurname: coviewerSurname,
      coviewerPesel: coviewerPesel,
      coviewerIdType: coviewerIdType,
      coviewerIdNumber: coviewerIdNumber,
      addrStreet: addrStreet,
      addrStreetNumber: addrStreetNumber,
      addrLocaleNumber: addrLocaleNumber,
      addrPostcode: addrPostcode,
      addrCity: addrCity,
      // addrCountry: addrCountry,
      remark: remark,
    };

    if (formMode === dict.FormMode.add) {
      data = {
        ...data,
        status: dict.DocStatus.new,
      };
    } else if (formMode === dict.FormMode.edit) {
      data = {
        ...data,
      };
    }

    return data;
  };

  const saveModeAdd = async () => {
    // eslint-disable-next-line
    let objId = await PresentationProtocolApi.Create(getFields(dict.FormMode.add));
    return objId;
  };

  const saveModeEdit = async () => {
    await PresentationProtocolApi.Update(objectId, getFields(dict.FormMode.edit));
    return objectId;
  };

  const handleSaveClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormButtonSave();
      if (result === -1) {
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        let p = await PresentationProtocolApi.GetStatusById(objectId);
        if (p.status === dict.DocStatus.accepted) {
          throw Error("Nie można aktualizować - dane zostały zatwierdzone przez klienta.");
        }

        objId = await saveModeEdit();
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd: ".concat(error.message), dict.NotificationType.error);
    }
  };

  const handleCancelClick = () => {
    if (props.onAfterCancel) {
      props.onAfterCancel(mode);
    }
  };

  const handleBackClick = () => {
    if (props.onAfterBack) {
      props.onAfterBack(mode);
    }
  };

  const handleSendClick = async () => {
    let objId = null;
    try {
      setHideSendToClientButton(true);

      let result = 0;
      result = validateFormButtonSend();
      if (result === -1) {
        setHideSendToClientButton(false);
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.preview) {
        objId = objectId;
      }

      await PresentationProtocolApi.Update(objId, {
        agentId: session.getUserId(),
        agentSentAt: dayjs(new Date().toUTCString()),
        status: dict.DocStatus.sended,
      });

      let activeLink = await ActiveLinkAPI.Create({
        pin: phoneAsPin(clientPhone),
        objCategory: dict.ActiveLinkCategory.presentationProtocol,
        objId: objId,
        objStage: 0,
        isActive: true,
      });

      let link = "".concat(process.env.REACT_APP_SYSTEM_URL, "c-prepr/", activeLink.guid);

      let agent = await UserApi.GetById(session.getUserId());

      let sms = {
        to: clientPhone,
        msg: "Potwierdź prezentację nieruchomości. Kliknij: ".concat(link, " ", agent.name, " ", agent.surname, ", tel. ", agent.phone),
      };

      let smsValidator = await SMSApi.Send(sms);

      if (smsValidator?.errorMsg) {
        throw new Error({
          message: "SMS - (" + smsValidator?.errorCode + ") " + smsValidator?.errorMsg,
        });
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Wystąpił błąd sendToClient: ".concat(error.message), dict.NotificationType.error);
      setHideSendToClientButton(false);
      return;
    }
  };

  const handleCoviewerAddClick = () => {
    setExistsCoviewer(true);
  };
  const handleCoviewerDeleteClick = () => {
    setExistsCoviewer(false);
    setCoviewerName("");
    setCoviewerSurname("");
    setCoviewerPesel("");
  };

  const handleDownloadClick = async () => {
    let link = document.createElement("a");
    link.download = setFileName(
      dict.DocTypes.presentationProtocol,
      "pdf",
      dayjs(visitDate).format("YYYY-MM-DDTHH:mm"),
      addrCity,
      addrStreet,
      addrStreetNumber,
      addrLocaleNumber
    );

    let response = await PresentationProtocolApi.GetBlobById(props.id);
    let blob = new Blob([response], { type: "application/pdf" });

    link.href = URL.createObjectURL(blob);
    link.click();

    URL.revokeObjectURL(link.href);
  };

  const renderPreviewInfo = () => {
    if (mode !== dict.FormMode.preview) return;

    return (
      <Stack>
        <Stack sx={{ pt: 2, fontSize: 13 }}>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Status: "}</b> {dict.DocStatusGetName(status)}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Data wysłania: "}</b>{" "}
            {!agentSentAt || agentSentAt.valueOf() === dict.DefaultDate.valueOf() ? null : dayjs(agentSentAt).format("YYYY-MM-DD HH:mm")}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Data akceptacji: "}</b>{" "}
            {!clientAcceptedAt || clientAcceptedAt.valueOf() === dict.DefaultDate.valueOf()
              ? null
              : dayjs(clientAcceptedAt).format("YYYY-MM-DD HH:mm")}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Email klienta: "}</b> {clientEmail}
          </Typography>
          <FormGroup>
            <FormControlLabel disabled control={<Checkbox size="small" checked={consentPrivacy} />} label="Zgoda na przetwarzanie danych" />
            <FormControlLabel
              disabled
              control={<Checkbox size="small" checked={consentMediation} />}
              label="Oświadczenie podpisu umowy pośrednictwa"
            />
            <FormControlLabel disabled control={<Checkbox size="small" checked={consentTrade} />} label="Zgoda handlowa" />
            <FormControlLabel disabled control={<Checkbox size="small" checked={consentMarketing} />} label="Zgoda marketingowa" />
          </FormGroup>
        </Stack>
        <Stack width={"150px"}>
          {clientEmail && (
            <Button variant="text" onClick={handleDownloadClick}>
              Pobierz dokument
            </Button>
          )}
        </Stack>{" "}
      </Stack>
    );
  };

  const renderCoviewer = () => {
    if (existsCoviewer) {
      return (
        <div>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {"Osoba współoglądająca"}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Imię"}
              id={"coviewerName"}
              value={coviewerName}
              onChange={(e) => {
                setCoviewerName(e.target.value);
              }}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={true}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Nazwisko"}
              id={"coviewerSurname"}
              value={coviewerSurname}
              onChange={(e) => {
                setCoviewerSurname(e.target.value);
              }}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={true}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Pesel"}
              id={"coviewerPesel"}
              value={coviewerPesel}
              onChange={(e) => {
                setCoviewerPesel(e.target.value);
              }}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={false}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              disablePortal
              id="coviewerIdType"
              options={dict.IdentityDocumentType}
              sx={{ width: "100%", pb: 2 }}
              renderInput={(params) => <TextField {...params} label="Rodzaj dokumentu identyfikacji" size="small" required={false} />}
              value={coviewerIdTypeCombo}
              onChange={(e, value) => {
                setCoviewerIdType(value?.code);
                setCoviewerIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === value?.code)[0]);
              }}
              disabled={isModePreview}
              disableClearable={true}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Numer dokumentu identyfikacji"}
              id={"coviewerIdNumber"}
              value={coviewerIdNumber}
              onChange={(e) => setCoviewerIdNumber(e.target.value)}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={false}
            />
          </Stack>
        </div>
      );
    }

    return null;
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<SaveOutlinedIcon />} onClick={handleSaveClick}>
            {(mode === dict.FormMode.add && !hideSendToClientButton) || (mode === dict.FormMode.edit && !hideSendToClientButton)
              ? "Zapisz"
              : "Aktualizuj"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<CancelOutlinedIcon />} onClick={handleCancelClick}>
            {"Wyjdź"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? true : false) && (
          <Button variant="contained" size="small" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackClick}>
            {"Wstecz"}
          </Button>
        )}
        {status === dict.DocStatus.new && !isModePreview && !hideSendToClientButton && (
          <Button variant="contained" size="small" startIcon={<SendToMobileOutlinedIcon />} onClick={handleSendClick}>
            {"Wyślij"}
          </Button>
        )}
        {/* <Stack>
    <PDFDownloadLink
      document={
        <Printing
          clientName={clientName}
          clientSurname={clientSurname}
          clientPesel={clientPesel}
          clientIdType={clientIdType}
          clientIdNumber={clientIdNumber}
          coviewerName={coviewerName}
          coviewerSurname={coviewerSurname}
          coviewerPesel={coviewerPesel}
          coviewerIdType={coviewerIdType}
          coviewerIdNumber={coviewerIdNumber}
          agentName={"Imię Agenta"}
          agentSurname={"Nazwisko Agenta"}
          consentPrivacy={consentPrivacy}
          consentMediation={consentMediation}
          consentTrade={consentTrade}
          consentMarketing={consentMarketing}
          presentationDate={dayjs(new Date())
            .format("YYYY-MM-DD HH:mm")
            .toString()}
          clientSignature={clientName + " " + clientSurname}
          visitDate={dayjs(visitDate).format("YYYY-MM-DD")}
          visitHour={dayjs(visitDate).format("HH:mm")}
          visitAddress={"".concat(
            addrStreet ? "ul. ".concat(addrStreet) : "",
            " ",
            addrStreetNumber,
            addrLocaleNumber ? "/".concat(addrLocaleNumber) : "",
            "\n",
            addrPostcode,
            " ",
            addrCity
          )}
        />
      }
      fileName="somename.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now!"
      }
    </PDFDownloadLink>
  </Stack> */}
      </Stack>
    );
  };

  return (
    <div>
      <FormGroup
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          maxWidth: 1200,
          mb: 2,
        }}
      >
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 0, pb: 2 }}>
          {"Informacje"}
        </Stack>
        <Stack direction="row" spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={"Data prezentacji *"}
              id={"visitDate"}
              value={visitDate?.isSame(dict.DefaultDate.toUTCString()) ? null : visitDate}
              format="YYYY-MM-DD HH:mm"
              onChange={(e) => setVisitDate(e)}
              disabled={isModePreview}
              slotProps={{
                textField: {
                  size: "small",
                  //helperText: "Format YYYY-MM-DD HH:mm",
                },
              }}
              sx={{ mb: 2, width: "200px" }}
            />
          </LocalizationProvider>
        </Stack>
        {renderPreviewInfo()}
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
          {"Klient"}
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Imię"}
            id={"clientName"}
            value={clientName}
            onChange={(e) => {
              setClientName(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Nazwisko"}
            id={"clientSurname"}
            value={clientSurname}
            onChange={(e) => {
              setClientSurname(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Telefon"}
            id={"clientPhone"}
            value={clientPhone}
            onChange={(e) => {
              setClientPhone(e.target.value);
              phoneValidator(e.target.value) ? setHasClientPhoneError(false) : setHasClientPhoneError(true);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={hasClientPhoneError}
            helperText="Prawidłowy format: 123456789 lub +48123456789"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Pesel"}
            id={"clientPesel"}
            value={clientPesel}
            onChange={(e) => {
              setClientPesel(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={false}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            id="clientIdType"
            options={dict.IdentityDocumentType}
            sx={{ width: "100%", pb: 2 }}
            renderInput={(params) => <TextField {...params} label="Rodzaj dokumentu identyfikacji" size="small" required={false} />}
            value={clientIdTypeCombo}
            onChange={(e, value) => {
              setClientIdType(value?.code);
              setClientIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === value?.code)[0]);
            }}
            disabled={isModePreview}
            disableClearable={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Numer dokumentu identyfikacji"}
            id={"clientIdNumber"}
            value={clientIdNumber}
            onChange={(e) => setClientIdNumber(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={false}
          />
        </Stack>
        {!isModePreview && !existsCoviewer && (
          <Stack direction="row" spacing={2} sx={{ pt: 1.5, pb: 2 }}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<PersonAddOutlinedIcon />}
              onClick={handleCoviewerAddClick}
              sx={{
                visibility: existsCoviewer ? "hidden" : "visible",
              }}
            >
              {"Dodaj osobę współoglądającą"}
            </Button>
          </Stack>
        )}
        {!isModePreview && existsCoviewer && (
          <Stack direction="row" spacing={2} sx={{ pt: 1.5, pb: 2 }}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<PersonAddOutlinedIcon />}
              onClick={handleCoviewerDeleteClick}
              sx={{
                visibility: !existsCoviewer ? "hidden" : "visible",
              }}
            >
              {"Usuń osobę współoglądającą"}
            </Button>
          </Stack>
        )}
        {renderCoviewer()}
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
          {"Adres nieruchomości"}
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Ulica"}
            id={"addrStreet"}
            value={addrStreet}
            onChange={(e) => setAddrStreet(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Numer budynku"}
            id={"addrStreetNumber"}
            value={addrStreetNumber}
            onChange={(e) => setAddrStreetNumber(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "50%" }}
            required={true}
          />
          <TextField
            size="small"
            label={"Numer lokalu"}
            id={"addrLocaleNumber"}
            value={addrLocaleNumber}
            onChange={(e) => setAddrLocaleNumber(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "50%" }}
            required={false}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Kod pocztowy"}
            id={"addrPostcode"}
            value={addrPostcode}
            onChange={(e) => setAddrPostcode(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={false}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Miejscowość"}
            id={"addrCity"}
            value={addrCity}
            onChange={(e) => setAddrCity(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
          {"Dodatkowe"}
        </Stack>
        <Typography variant="subtitle2" color="secondary" sx={{ marginTop: "-3px", paddingBottom: "5px" }}>
          {"Poniższe notatki są niewidoczne dla klienta"}
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Notatka"}
            id={"remark"}
            value={remark}
            onChange={(e) => {
              setRemark(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={false}
            multiline
            rows={3}
          />
        </Stack>
      </FormGroup>
      <PresentationProtocolMonitor id={objectId} mode={mode} />
      {renderButtons()}
    </div>
  );
};

export default PresentationProtocolForm;
