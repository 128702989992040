import { Stack, Autocomplete, TextField, FormLabel, FormControl } from "@mui/material";
//import { Radio, RadioGroup, FormControlLabel } from "@mui/material";

import * as dict from "../../config/Dictionary";

const LegalEntityType = (props) => {
  const defaultType = props.defaultType ? props.defaultType : dict.ClientType.person;

  const handleChange = (value) => {
    props.onChange(parseInt(value));
  };

  return (
    <Stack sx={{ pt: 0, pb: 2 }}>
      {props.sectionName !== "" && (
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }} style={{ color: props.hasError ? "red" : "" }}>
          {props.sectionName ? props.sectionName : "Klient"}
        </Stack>
      )}
      <Stack spacing={2}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">{/* Etykieta */}</FormLabel>
          {/* <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={defaultType} name="radio-buttons-group">
            <FormControlLabel
              value={dict.ClientType.person}
              control={<Radio />}
              label="jest osobą fizyczną"
              disabled={props.isModePreview}
              onChange={(e) => handleChange(e.target.value)}
            />
            <FormControlLabel
              value={dict.ClientType.personWithRepresentative}
              control={<Radio />}
              label="jest osobą fizyczną reprezentowaną przez pełnomocnika"
              disabled={props.isModePreview}
              onChange={(e) => handleChange(e.target.value)}
            />
            <FormControlLabel
              value={dict.ClientType.businessIndividual}
              control={<Radio />}
              label="jest osobą fizyczną prowadzącą działalność gospodarczą"
              disabled={props.isModePreview}
              onChange={(e) => handleChange(e.target.value)}
            />
            <FormControlLabel
              value={dict.ClientType.businessIndividualWithRepresentative}
              control={<Radio />}
              label="jest osobą fizyczną prowadzącą działalność gospodarczą i reprezentowaną przez pełnomocnika"
              disabled={props.isModePreview}
              onChange={(e) => handleChange(e.target.value)}
            />
            <FormControlLabel
              value={dict.ClientType.company}
              control={<Radio />}
              label="jest podmiotem zarejestrowanym w KRS"
              disabled={props.isModePreview}
              onChange={(e) => handleChange(e.target.value)}
            />
            <FormControlLabel
              value={dict.ClientType.companyWithRepresentative}
              control={<Radio />}
              label="jest podmiotem zarejestrowanym w KRS z odrębnym pełnomocnictwem"
              disabled={props.isModePreview}
              onChange={(e) => handleChange(e.target.value)}
            />
          </RadioGroup> */}
          <Autocomplete
            disablePortal
            id="legalEntityType"
            options={dict.ClientTypeCombo}
            sx={{ pt: 1, pb: 2 }}
            renderInput={(params) => <TextField {...params} label="Osobowość prawna / pełnomocnictwo" size="small" required={false} />}
            defaultValue={dict.ClientTypeCombo.filter((f) => f.code === defaultType)[0]}
            onChange={(e, value) => {
              handleChange(value.code);
            }}
            disabled={props.isModePreview}
            disableClearable={true}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default LegalEntityType;
