import * as React from "react";
import {
  formatAddressLine1,
  formatAddressLine2,
  phoneNumberFormat,
  netToGrossRecalculate,
  formatPrice,
  formatNumberByCurrency,
  translateMonthNumber,
} from "./../../utils/validator";
import * as dict from "./../../config/Dictionary";
import { useEffect, useRef, useState } from "react";
import DocumentFormAtt1 from "./DocumentFormAtt1";
import DocumentFormAtt2 from "./DocumentFormAtt2";
import DocumentFormAtt3 from "./DocumentFormAtt3";
import DocumentFormAtt4 from "./DocumentFormAtt4";
import * as IntermediationContractPersonApi from "./../../business/IntermediationContract/IntermediationContractPerson/IntermediationContractPersonApi";
import * as IntermediationContractBinaryApi from "./../../business/IntermediationContract/IntermediationContractBinary/IntermediationContractBinaryApi";
import ImageViewer from "./../../business/IntermediationContract/IntermediationContractBinary/ImageViewer";

const DocumentForm = (props) => {
  const id = props.id;
  const mode = props.mode;
  const sessionKey = props.sessionKey;

  const hiddenInputRef = useRef(null);

  const [coownersData, setCoownersData] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);

  const getCoownersData = async () => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await IntermediationContractPersonApi.GetByDocIdUnauthorized(id, sessionKey);
      setCoownersData(object);
    } else {
      const object = await IntermediationContractPersonApi.GetByDocId(id, sessionKey);
      setCoownersData(object);
    }
  };

  const getAttachmentsData = async () => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await IntermediationContractBinaryApi.GetIdsByDocIdSessionKey(id, null, sessionKey);
      setAttachmentsData(object);
    } else {
      const object = await IntermediationContractBinaryApi.GetIdsByDocId(id, sessionKey);
      setAttachmentsData(object);
    }
  };

  useEffect(() => {
    hiddenInputRef.current.focus();
    getCoownersData();
    getAttachmentsData();

    return () => {};
    // eslint-disable-next-line
  }, []);

  const styles = {
    page: {
      fontFamily: "Roboto",
      padding: "3%",
      paddingTop: "5px",
      marginLeft: "15px",
      fontSize: "12px",
      marginRight: "20px",
    },
    parameter: {
      fontWeight: "bold",
      color: "#163058",
    },
    title: {
      paddingBottom: "40px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
    attachmentTitle: {
      paddingBottom: "5px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
    descr: {
      paddingBottom: "7px",
      marginRight: "20px",
    },
    descrBold: {
      fontWeight: "bold",
    },
    descrBoldAndCenter: {
      fontWeight: "bold",
      textAlign: "center",
    },
    square: {
      width: 10, // Adjust width and height as needed
      height: 10,
      borderWidth: 0, // Set border width to 0 for no border
      border: "1px solid black",
    },
    parameterLack: {
      fontWeight: "bold",
      //color: "red",
      color: "#163058",
    },
    paragrapf: {
      textAlign: "center",
      fontSize: "12px",
      fontWeight: "bold",
    },
  };

  const loadData = (value) => {
    if (!value) {
      return <span style={styles.parameterLack}>{" - "}</span>;
    }
    return <span style={styles.parameter}>{value}</span>;
  };

  const renderParagraph1VariantOpenAdditional = () => {
    if (props.variant === dict.IntermediationContractVariant.open)
      return (
        <p style={{ marginTop: "5px" }}>
          <span>
            {
              "2. Klient oświadcza, że: jest właścicielem Nieruchomości / działa na podstawie załączonego pełnomocnictwa udzielonego przez właściciela Nieruchomości / działa za zgodą właściciela Nieruchomości lecz we własnym imieniu i zleca Pośrednikowi pośrednictwo w zbyciu Nieruchomości."
            }
          </span>
        </p>
      );

    return <span></span>;
  };

  const condP2VariantOpen = () => {
    if (props.variant === dict.IntermediationContractVariant.open)
      return (
        <div>
          <p>
            {
              "1. Pośrednik zobowiązuje się do podejmowania dla Klienta czynności pośrednictwa zmierzających do zbycia Nieruchomości przez Klienta, zgodnie z wiedzą i doświadczeniem Pośrednika. W szczególności Pośrednik zobowiązuje się do:"
            }
          </p>
          <p>- Reklamowanie i marketing w poszukiwaniu nabywcy nieruchomości</p>
          <p>- Dokonywanie w uzgodnionych terminach prezentacji nieruchomości potencjalnym nabywcom</p>
          <p>
            - Skontaktowanie klienta i potencjalnego nabywcy nieruchomości w celu uzgodnienia przyszłych warunków transakcji łączącej strony, udział w
            negocjacjach.
          </p>
          <p>- Zapewnienie obsługi organizacyjnej transakcji</p>

          <p>
            {
              "2. Rezygnacja Klienta z poszczególnych czynności wymienionych powyżej nie stanowi podstawy do zmniejszenia lub niezapłacenia należnego Pośrednikowi Wynagrodzenia."
            }
          </p>

          <p>{"3. Pośrednik może wykonywać czynności pośrednictwa na rzecz obu stron transakcji."}</p>

          <p>
            {
              "4. Umowa nie ogranicza Klienta w zawieraniu umów pośrednictwa z innymi pośrednikami ani w poszukiwaniu nabywcy Nieruchomości we własnym zakresie."
            }
          </p>
        </div>
      );

    return <span></span>;
  };

  const condP2VariantNotOpen = () => {
    if (props.variant === dict.IntermediationContractVariant.open) return <span></span>;

    return (
      <div>
        <p>
          1. Pośrednik zobowiązuje się do podejmowania dla Klienta czynności pośrednictwa zmierzających do zbycia Nieruchomości przez Klienta, zgodnie
          z wiedzą i doświadczeniem Pośrednika. W szczególności Pośrednik zobowiązuje się do:
        </p>
        <p>
          <input
            type="checkbox"
            id="obligationShowPurchOffer"
            name="obligationShowPurchOffer"
            value=""
            checked={props.obligationShowPurchOffer ? "checked" : ""}
            disabled
          />
          <label>przedstawiania Klientowi ofert kupna Nieruchomości</label>
          <br />
          <input
            type="checkbox"
            id="obligationPresentation"
            name="obligationPresentation"
            value=""
            checked={props.obligationPresentation ? "checked" : ""}
            disabled
          />
          <label>dokonywania w uzgodnionych terminach prezentacji Nieruchomości potencjalnym nabywcom</label>
          <br />
          <input type="checkbox" id="obligationPhotos" name="obligationPhotos" value="" checked={props.obligationPhotos ? "checked" : ""} disabled />
          <label>sporządzenia dokumentacji zdjęciowej</label>
          <br />
          <input
            type="checkbox"
            id="obligationCollectingDocs"
            name="obligationCollectingDocs"
            value=""
            checked={props.obligationCollectingDocs ? "checked" : ""}
            disabled
          />
          <label>skompletowania dokumentacji na podstawie przedłożonych przez Klienta dokumentów</label>
          <br />
          <input
            type="checkbox"
            id="obligationDescrAndPubl"
            name="obligationDescrAndPubl"
            value=""
            checked={props.obligationDescrAndPubl ? "checked" : ""}
            disabled
          />
          <label>sporządzenia oferty i opisu Nieruchomości oraz rejestracji oferty w bazie danych Pośrednika</label>
          <br />
          <input
            type="checkbox"
            id="obligationLegalVerif"
            name="obligationLegalVerif"
            value=""
            checked={props.obligationLegalVerif ? "checked" : ""}
            disabled
          />
          <label>dokonania wstępnej weryfkacji stanu prawnego Nieruchomości</label>
          <br />
          <input
            type="checkbox"
            id="obligationAdMother"
            name="obligationAdMother"
            value=""
            checked={props.obligationAdMother ? "checked" : ""}
            disabled
          />
          {/* <label>umieszczenia reklamy na stronie internetowej Pośrednika</label>
          <br />
          <input
            type="checkbox"
            id="obligationAdMotherAndOthers"
            name="obligationAdMotherAndOthers"
            value=""
            checked={props.obligationAdMotherAndOthers ? "checked" : ""}
            disabled
          /> */}
          <label>umieszczenia reklamy na stronie internetowej Pośrednika oraz na innych portalach internetowych</label>
          <br />
          <input
            type="checkbox"
            id="obligationDescrUpgrade"
            name="obligationDescrUpgrade"
            value=""
            checked={props.obligationDescrUpgrade ? "checked" : ""}
            disabled
          />
          <label>bieżącej aktualizacji danych dotyczących Nieruchomości </label>
          <br />
          <input
            type="checkbox"
            id="obligationCombTranContact"
            name="obligationCombTranContact"
            value=""
            checked={props.obligationCombTranContact ? "checked" : ""}
            disabled
          />
          <label>
            skontaktowania Klienta i potencjalnego nabywcy Nieruchomości w celu uzgodnienia przyszłych warunków transakcji łączącej strony, udziału w
            negocjacjach{" "}
          </label>
          <br />
          <input
            type="checkbox"
            id="obligationProtocols"
            name="obligationProtocols"
            value=""
            checked={props.obligationProtocols ? "checked" : ""}
            disabled
          />
          <label>przygotowania protokołu uzgodnień lub protokołu zdawczo-odbiorczego</label>
          <br />
          <input
            type="checkbox"
            id="obligationTransPrepHelp"
            name="obligationTransPrepHelp"
            value=""
            checked={props.obligationTransPrepHelp ? "checked" : ""}
            disabled
          />
          <label>zapewnienia obsługi związanej z przygotowaniem transakcji</label>
          <br />
          <input type="checkbox" id="obligationOthers" name="obligationOthers" value="" checked={props.obligationOthers ? "checked" : ""} disabled />
          <label>inne</label>
          <label>{props.obligationOthersText}</label>
          <br />
        </p>
        <p>
          2. Rezygnacja Klienta z poszczególnych czynności wymienionych powyżej nie stanowi podstawy do zmniejszenia lub niezapłacenia należnego
          Pośrednikowi Wynagrodzenia.
        </p>
        <p>3. Pośrednik może wykonywać czynności pośrednictwa na rzecz obu stron transakcji.</p>
      </div>
    );
  };

  const condP3VariantNotOpenPage1 = () => {
    if (props.variant === dict.IntermediationContractVariant.open) return <span></span>;

    return (
      <div>
        <p style={styles.paragrapf}>§3</p>
        <p>
          {
            "1. Klient oświadcza, że zawiera Umowę z zastrzeżeniem wyłączności na rzecz Pośrednika i zobowiązuje się do niezawierania umowy pośrednictwa w obrocie nieruchomościami dotyczącej Nieruchomości z innymi osobami oraz niepowierzania osobom trzecim czynności zmierzających do doprowadzenia do zawarcia umowy sprzedaży Nieruchomości."
          }
        </p>
      </div>
    );
  };

  const condP3VariantExclusiveAgentPage2 = () => {
    if (props.variant === dict.IntermediationContractVariant.exclusiveAgent)
      return (
        <p>
          {
            "2. W przypadku zawarcia przez Klienta umowy przedwstępnej sprzedaży Nieruchomości lub umowy sprzedaży Nieruchomości z potencjalnym nabywcą, Klient zobowiązuje się zapłacić Pośrednikowi wynagrodzenie określone w § 4 Umowy - bez względu na osobę pośredniczącą w nawiązaniu kontaktu pomiędzy Klientem oraz nabywcą Nieruchomości."
          }
        </p>
      );

    return <span></span>;
  };

  const condP3VariantExclusiveAgencyPage2 = () => {
    if (props.variant === dict.IntermediationContractVariant.exclusiveAgency)
      return (
        <div>
          <p>
            {
              "2. W przypadku zawarcia przez Klienta umowy przedwstępnej sprzedaży Nieruchomości lub umowy sprzedaży Nieruchomości z potencjalnym nabywcą, Klient zobowiązuje się zapłacić Pośrednikowi wynagrodzenie określone w § 4 Umowy - z zastrzeżeniem ust. 3 poniżej."
            }
          </p>
          <p>
            {
              "3. Wynagrodzenie nie przysługuje Pośrednikowi w przypadku zawarcia przez Klienta umowy wskazanej w ust. 2 powyżej, z nabywcą pozyskanym przez Klienta bezpośrednio, to jest bez udziału Pośrednika lub innego podmiotu (osoby trzeciej)."
            }
          </p>
        </div>
      );

    return <span></span>;
  };

  const condP5Text1VariantNotOpen = () => {
    if (props.variant === dict.IntermediationContractVariant.open) return <span></span>;

    return <span>{" oraz znane są mu konsekwencje wynikające z zawarcia umowy z klauzulą wyłączności"}</span>;
  };

  const setParagrapfNumber = (number) => {
    if (number >= 4 && props.variant === dict.IntermediationContractVariant.open) number = number - 1;

    return number;
  };

  const setContractDuration = () => {
    const monthAsWord = translateMonthNumber(props.contractDurationMonth);

    if (monthAsWord && monthAsWord !== "") {
      return props.contractDurationMonth.toString() + " (" + monthAsWord + ")";
    }

    return props.contractDurationMonth.toString();
  };

  const contractTitle = () => {
    if (props.variant === dict.IntermediationContractVariant.open) {
      return "NIE na wyłączność, zawarta poza lokalem przedsiębiorstwa v1.0";
    } else if (props.variant === dict.IntermediationContractVariant.exclusiveAgency) {
      return "WYŁĄCZNOŚĆ AGENCYJNA zawarta poza lokalem przedsiębiorstwa v1.01";
    }

    return "na wyłączność, zawarta poza lokalem przedsiębiorstwa v1.01";
  };

  const renderRepresentative = (
    xClientName,
    xClientSurname,
    xClientAddrCity,
    xClientAddrPostcode,
    xClientAddrStreet,
    xClientAddrStreetNumber,
    xClientAddrLocaleNumber,
    xClientPesel,
    xClientIdType,
    xClientIdNumber,
    xClientEmail,
    xClientPhone
  ) => {
    return (
      <div>
        <p>
          {loadData(xClientName)} {loadData(xClientSurname)} zamieszkałym w {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},{" "}
          {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, PESEL: {loadData(xClientPesel)},
          legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)} {loadData(xClientIdNumber)}
        </p>
        <p>
          Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
        </p>
      </div>
    );
  };

  const renderStakeholder = (
    isFirst,
    type,
    xClientName,
    xClientSurname,
    xClientAddrCity,
    xClientAddrPostcode,
    xClientAddrStreet,
    xClientAddrStreetNumber,
    xClientAddrLocaleNumber,
    xClientPesel,
    xClientIdType,
    xClientIdNumber,
    xClientEmail,
    xClientPhone,
    xPersonName,
    xPersonSurname,
    xPersonAddrCity,
    xPersonAddrPostcode,
    xPersonAddrStreet,
    xPersonAddrStreetNumber,
    xPersonAddrLocaleNumber,
    xPersonPesel,
    xPersonIdType,
    xPersonIdNumber,
    xPersonEmail,
    xPersonPhone,
    xCompanyName,
    xCompanyNIP,
    xCompanyKRS
  ) => {
    if (type === dict.ClientType.person) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xClientName)} {loadData(xClientSurname)} zamieszkałym w {loadData(xClientAddrCity)}, kod pocztowy{" "}
            {loadData(xClientAddrPostcode)}, {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))},
            PESEL: {loadData(xClientPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)}{" "}
            {loadData(xClientIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.personWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xPersonName)} {loadData(xPersonSurname)} zamieszkałym w {loadData(xPersonAddrCity)}, kod pocztowy{" "}
            {loadData(xPersonAddrPostcode)}, {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))},
            PESEL: {loadData(xPersonPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xPersonIdType)[0]?.label)}{" "}
            {loadData(xPersonIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xPersonEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xPersonPhone))}
          </p>
          <p>reprezentowanym przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    } else if (type === dict.ClientType.businessIndividual) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xClientName)} {loadData(xClientSurname)} prowadzącym jednoosobową działalność gospodarczą pod nazwą {loadData(xCompanyName)},
            adres: {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, PESEL:{" "}
            {loadData(xClientPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)}{" "}
            {loadData(xClientIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.businessIndividualWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xPersonName)} {loadData(xPersonSurname)} prowadzącym jednoosobową działalność gospodarczą pod nazwą {loadData(xCompanyName)},
            adres: {loadData(xPersonAddrCity)}, kod pocztowy {loadData(xPersonAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, PESEL:{" "}
            {loadData(xPersonPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xPersonIdType)[0]?.label)}{" "}
            {loadData(xPersonIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xPersonEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xPersonPhone))}
          </p>
          <p>reprezentowanym przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    } else if (type === dict.ClientType.company) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xCompanyName)}, adres: {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, KRS:{" "}
            {loadData(xCompanyKRS)}
          </p>
          <p>
            reprezentowaną przez {loadData(xClientName)} {loadData(xClientSurname)} na podstawie KRS
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.companyWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xCompanyName)}, adres: {loadData(xPersonAddrCity)}, kod pocztowy {loadData(xPersonAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, KRS:{" "}
            {loadData(xCompanyKRS)}
          </p>
          <p>reprezentowaną przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    }
  };

  const renderOwner = () => {
    return renderStakeholder(
      true,
      props.clientType,
      props.clientName,
      props.clientSurname,
      props.clientAddrCity,
      props.clientAddrPostcode,
      props.clientAddrStreet,
      props.clientAddrStreetNumber,
      props.clientAddrLocaleNumber,
      props.clientPesel,
      props.clientIdType,
      props.clientIdNumber,
      props.clientEmail,
      props.clientPhone,
      props.personName,
      props.personSurname,
      props.personAddrCity,
      props.personAddrPostcode,
      props.personAddrStreet,
      props.personAddrStreetNumber,
      props.personAddrLocaleNumber,
      props.personPesel,
      props.personIdType,
      props.personIdNumber,
      props.personEmail,
      props.personPhone,
      props.companyName,
      props.companyNIP,
      props.companyKRS
    );
  };

  const renderCoowner = (obj) => {
    return renderStakeholder(
      false,
      obj.clientType,
      obj.clientName,
      obj.clientSurname,
      obj.clientAddrCity,
      obj.clientAddrPostcode,
      obj.clientAddrStreet,
      obj.clientAddrStreetNumber,
      obj.clientAddrLocaleNumber,
      obj.clientPesel,
      obj.clientIdType,
      obj.clientIdNumber,
      obj.clientEmail,
      obj.clientPhone,
      obj.personName,
      obj.personSurname,
      obj.personAddrCity,
      obj.personAddrPostcode,
      obj.personAddrStreet,
      obj.personAddrStreetNumber,
      obj.personAddrLocaleNumber,
      obj.personPesel,
      obj.personIdType,
      obj.personIdNumber,
      obj.personEmail,
      obj.personPhone,
      obj.companyName,
      obj.companyNIP,
      obj.companyKRS
    );
  };

  const renderCoowners = () => {
    return coownersData.map((item) => (
      <div key={item.id}>
        <span>{"i "}</span>
        {renderCoowner(item)}
      </div>
    ));
  };

  const renderAttachments = () => {
    return attachmentsData.map((item) => (
      <div key={item.id} style={{ textAlign: "center", marginTop: "10px", marginBottom: "0px" }}>
        <div style={styles.attachmentTitle}>{item.label}</div>
        <ImageViewer id={item.id} sessionKey={sessionKey} mode={mode} mimeType={item.mimeType} docId={id} />
      </div>
    ));
  };

  const renderAgentProvision = () => {
    if (props.agentProvisionType === dict.AgentProvisionType.amount) {
      return (
        <span>
          1. Klient zobowiązuje się do zapłaty Pośrednikowi wynagrodzenia w wysokości{" "}
          {loadData(formatNumberByCurrency(netToGrossRecalculate(props.agentProvisionAmountNet), props.offerPriceCurrency))} PLN brutto ({""}
          {loadData(formatNumberByCurrency(props.agentProvisionAmountNet, props.offerPriceCurrency))} PLN netto + 23% podatku od towarów i usług VAT)
          niezależnie od ceny transakcyjnej nieruchomości ("Wynagrodzenie")
        </span>
      );
    }

    return (
      <span>
        1. Klient zobowiązuje się do zapłaty Pośrednikowi wynagrodzenia w wysokości{" "}
        {loadData(formatNumberByCurrency(netToGrossRecalculate(props.agentProvisionPercentNet), props.offerPriceCurrency))}% brutto ({""}
        {loadData(formatNumberByCurrency(props.agentProvisionPercentNet, props.offerPriceCurrency))}% netto + 23% podatku od towarów i usług VAT) ceny
        transakcyjnej Nieruchomości brutto ("Wynagrodzenie")
      </span>
    );
  };

  return (
    <div>
      <div style={styles.page}>
        <input type="checkbox" ref={hiddenInputRef} style={{ width: 0, height: 0 }} />
        <p style={styles.title}>
          UMOWA POŚREDNICTWA W SPRZEDAŻY NIERUCHOMOŚCI <br />
          {contractTitle()}
        </p>
        <p>
          zawarta w dniu {loadData(props.contractDate)} r. w miejscowości {loadData(props.contractCity)} pomiędzy:
        </p>
        <p>
          (1) Home Spółką z ograniczoną odpowiedzialnością z siedzibą w Szczecin, pod adresem: ul. Zielonogórska 31, lok. 1.1, 71-084 Szczecin,
          wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Szczecin-Centrum w Szczecinie, XIII Wydział
          Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000854950, o numerze NIP 8522665760, o kapitale zakładowym w wysokości 5 000,00
          złotych, zwaną dalej „HOME”, reprezentowaną przez: {loadData(props.agentName)} {loadData(props.agentSurname)} - pełnomocnika, tel.:{" "}
          {loadData(phoneNumberFormat(props.agentPhone))} email: {loadData(props.agentEmail)}
        </p>
        <p>a</p>
        {renderOwner()}
        {renderCoowners()}
        <p style={styles.paragrapf}>§1</p>
        <p>
          1. Klient powierza, a Pośrednik zobowiązuje się do pośredniczenia w zawarciu przez Klienta umowy sprzedaży nieruchomości ("Nieruchomość")
          stanowiącej:
        </p>
        <p>
          <input
            type="checkbox"
            id="propertyIsSeparateOwnership"
            name="propertyIsSeparateOwnership"
            value=""
            checked={props.propertyIsSeparateOwnership ? "checked" : ""}
            disabled
          />
          <label>odrębny lokal mieszkalny/ użytkowy (prawo własności)</label>
          <br />
          <input type="checkbox" id="propertyIsLand" name="propertyIsLand" value="" checked={props.propertyIsLand ? "checked" : ""} disabled />
          <label>nieruchomość gruntową </label>
          <br />
          <input
            type="checkbox"
            id="propertyIsCooperativeOwnership"
            name="propertyIsCooperativeOwnership"
            value=""
            checked={props.propertyIsCooperativeOwnership ? "checked" : ""}
            disabled
          />
          <label>spółdzielcze własnościowe prawo do lokalu </label>
          <br />
          <input type="checkbox" id="propertyIsOthers" name="propertyIsOthers" value="" checked={props.propertyIsOthers ? "checked" : ""} disabled />
          <label>inne</label> <label>{props.propertyIsOthersText}</label>
          <br />
        </p>
        <p>
          Powierzchnia: {loadData(props.propertyArea)}
          <br />
          Numer działki: {loadData(props.landNumber)}
          <br />
          Adres: {loadData(formatAddressLine1(props.propertyAddrStreet, props.propertyAddrStreetNumber, props.propertyAddrLocaleNumber))}{" "}
          {loadData(formatAddressLine2(props.propertyAddrPostcode, props.propertyAddrCity))} <br />
          Numer księgi wieczystej: {loadData(props.mortgageRegister)}
          <br />
          Cena oferowana: {loadData(formatPrice(props.offerPrice, props.offerPriceCurrency))} <br />
        </p>
        {renderParagraph1VariantOpenAdditional()}
        <p style={styles.paragrapf}>§2</p>
        {condP2VariantNotOpen()}
        {condP2VariantOpen()}

        {condP3VariantNotOpenPage1()}
        {condP3VariantExclusiveAgentPage2()}
        {condP3VariantExclusiveAgencyPage2()}

        <p style={styles.paragrapf}>§{setParagrapfNumber(4)}</p>
        <p>{renderAgentProvision()}</p>
        <p>
          2. Wynagrodzenie dla Pośrednika będzie wymagalne i płatne w dwóch częściach: (i) pierwsza część w wysokości 50% Wynagrodzenia zostanie
          zapłacona po zawarciu przez Klienta umowy przedwstępnej sprzedaży Nieruchomości z nabywcą wskazanym przez Pośrednika, na podstawie
          wystawionej przez Pośrednika faktury VAT z 7- dniowym terminem płatności; (ii) druga część w wysokości 50% Wynagrodzenia zostanie zapłacona
          po zawarciu przez Klienta umowy przyrzeczonej z nabywcą wskazanym przez Pośrednika, na podstawie wystawionej przez Pośrednika faktury VAT z
          7- dniowym terminem płatności.{" "}
        </p>
        <p>
          3. Pośrednikowi przysługuje prawo zatrzymania pierwszej części Wynagrodzenia, jeżeli pomimo zawartej umowy przedwstępnej nie dojdzie do
          zawarcia umowy przenoszącej własność Nieruchomości, tytułem ryczałtowego wynagrodzenia Pośrednika za czynności podjęte w wykonaniu Umowy
        </p>
        <p>
          4. W przypadku, gdy zawarcie umowy przenoszącej własność Nieruchomości nie zostanie poprzedzone umową przedwstępną, całość Wynagrodzenia
          zostanie zapłacona Pośrednikowi po zawarciu umowy przenoszącej własność Nieruchomości, na podstawie wystawionej przez Pośrednika faktury VAT
          z 7- dniowym terminem płatności.
        </p>
        <p>
          5. Wynagrodzenie przysługuje Pośrednikowi również w sytuacji, gdy przeniesienie własności Nieruchomości nastąpi na podstawie innej czynności
          prawnej niż umowa sprzedaży
        </p>
        <p>
          6. Wynagrodzenie Pośrednika ustalone w § {setParagrapfNumber(4)} ust. 1 Umowy należne jest również przypadku, gdy w okresie 12 miesięcy po
          rozwiązaniu lub wygaśnięciu niniejszej Umowy dojdzie do zawarcia umowy sprzedaży albo umowy przedwstępnej sprzedaży albo umowy najmu
          Nieruchomości pomiędzy Klientem a osobą, której Nieruchomość została uprzednio przedstawiona przez Pośrednika, lub jej osobą bliską. Przez
          osobę bliską należy rozumieć: zstępnych, wstępnych, rodzeństwo, dzieci rodzeństwa, małżonka, konkubenta, osoby przysposabiające i
          przysposobione.{" "}
        </p>
        <p>
          7. Klient zobowiązuje się do przekazania Pośrednikowi kopii przedwstępnej umowy sprzedaży Nieruchomości i umowy przenoszącej własność
          Nieruchomości oraz okazania Pośrednikowi oryginałów tych umów w celu prawidłowego wyliczenia wynagrodzenia należnego Pośrednikowi. W
          przypadku niewykonania tego zobowiązania przez Klienta, Pośrednik wyliczy wynagrodzenie według ceny oferowanej, wymienionej w § 1 Umowy.
        </p>
        <p style={styles.paragrapf}>§{setParagrapfNumber(5)}</p>
        <p>
          1. Klient zobowiązuje się dostarczyć Pośrednikowi dokumenty niezbędne do ustalenia stanu prawnego Nieruchomości i zawarcia umowy sprzedaży,
          przekazywać na bieżąco informacje mogące mieć wpływ na transakcję sprzedaży i cenę Nieruchomości oraz współdziałać z Pośrednikiem, w tym
          informować Pośrednika o ewentualnej zmianie wysokości proponowanej ceny.{" "}
        </p>
        <p>
          2. Klient oświadcza, że Nieruchomość nie jest przedmiotem zawartej uprzednio umowy z klauzulą wyłączności
          {condP5Text1VariantNotOpen()}
          {"."}
        </p>
        <p>3. Klient wyraża zgodę na przekazanie przez Pośrednika faktury VAT drogą elektroniczną na adres: {loadData(props.clientInvoiceEmail)}</p>
        <p>
          4. Klient przyjmuje do wiadomości i akceptuje, iż w związku z niniejszą Umową Pośrednik (w tym osoby wykonujące czynności pośrednictwa w
          imieniu Pośrednika) jest uprawniony do wglądu oraz pobierania odpowiednich odpisów, wypisów, zaświadczeń dotyczących Nieruchomości,
          ujawnionych w: a) księgach wieczystych b) katastrze nieruchomości, c) ewidencji sieci uzbrojenia terenu, d) tabelach taksacyjnych i na
          mapach taksacyjnych tworzonych na podstawie art. 169 ustawy o gospodarce nieruchomościami e) planach miejscowych, studiach uwarunkowań i
          kierunków zagospodarowania przestrzennego gmin oraz decyzjach o warunkach zabudowy i zagospodarowania terenu, f) rejestrów cen i wartości
          nieruchomości, g) rejestrach osób, którym przysługują prawa: spółdzielcze własnościowe prawo do lokalu, spółdzielcze prawo do domu
          jednorodzinnego, spółdzielcze prawo stanowiska parkingowego w garażu wielostanowiskowym, oraz prawa odrębnej własności lokalu, h) ewidencji
          ludności w zakresie obowiązku meldunkowego, i) świadectwach charakterystyki energetycznej.
        </p>
        <p>5. Klient zobowiązuje się do zachowania poufności danych potencjalnych nabywców Nieruchomości. </p>
        <p style={styles.paragrapf}>§{setParagrapfNumber(6)}</p>
        <p>
          1. Pośrednik oświadcza, że zgodnie z obowiązkiem określonym w art. 181 ustawy o gospodarce nieruchomościami jest ubezpieczony od
          odpowiedzialności cywilnej za szkody wyrządzone w związku z wykonywaniem czynności pośrednictwa, na dowód czego przekazuje Klientowi kopię
          dokumentu ubezpieczenia.{" "}
        </p>
        <p style={styles.paragrapf}>§{setParagrapfNumber(7)}</p>
        <p>
          1. Niniejsza Umowa zostaje zawarta na czas określony {setContractDuration()} miesięcy od dnia jej podpisania. Każda Strona może wypowiedzieć
          w tym czasie Umowę wyłącznie z ważnych powodów.{" "}
        </p>
        <p>2. Wszelkie zmiany do niniejszej Umowy wymagają formy pisemnej pod rygorem nieważności. </p>
        <p>
          3. Klient oświadcza, że został poinformowany przez Pośrednika o: (i) prawie do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa w
          terminie 14 (czternastu) dni od dnia jej zawarcia oraz sposobie odstąpienia (zgodnie z art. 27 ustawy z dnia 30 maja 2014r. o prawach
          konsumenta); (ii) obowiązku zapłaty Pośrednikowi za świadczenia spełnione do chwili odstąpienia od umowy, w przypadku odstąpienia od umowy
          zawartej poza lokalem przedsiębiorstwa, jeśli zgłosił żądanie wykonywania usługi przed terminem do odstąpienia od umowy (zgodnie z art. 35
          ustawy z dnia 30 maja 2014r. o prawach konsumenta), (iii) braku prawa do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa jeśli
          zgłosił żądanie wykonywania usługi przed terminem do odstąpienia od umowy (zgodnie z art. 12 ust. 1 pkt.12) ustawy z dnia 30 maja 2014r. o
          prawach konsumenta) w przypadku, gdy dojdzie do transakcji określonej w Umowie w wyniku podjętych w tym okresie przez Pośrednika czynności
          pośrednictwa.{" "}
        </p>
        <p>
          4. Klient oświadcza, że otrzymał następujące Załączniki do umowy:
          <br />
          Załącznik Nr 1 - Pouczenie o odstąpieniu od umowy zawartej poza lokalem przedsiębiorstwa <br />
          Załącznik Nr 2 - Wzór formularza o odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa <br />
          Załącznik Nr 3 - informacja o ochronie danych osobowych <br />
          Załącznik Nr 4 - kopia dokumentu aktualnego ubezpieczenia od odpowiedzialności cywilnej za szkody wyrządzone w związku z wykonywaniem
          czynności pośrednictwa.
        </p>
        <p>5. Reklamacje należy kierować na adres mailowy: reklamacje@home-estate.pl</p>
        <p>6. Niniejsza umowa została sporządzona w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze Stron.</p>
      </div>

      <DocumentFormAtt1 />
      <DocumentFormAtt2 />
      <DocumentFormAtt3 />
      <DocumentFormAtt4 />
      <div>{renderAttachments()}</div>
    </div>
  );
};

export default DocumentForm;
