export const FormMode = {
  add: 0,
  edit: 1,
  preview: 2,
};

export const DocFormViewMode = {
  agent: 1,
  client: 2,
};

export const NotificationType = {
  error: "error",
  success: "success",
};

export const DefaultDate = new Date("1900-01-01T00:00:00Z");
export const DefaultNumber = -1;

export const SessionStorageAuth = {
  login: "167hj675a8vbnv14kas9jbaD9JAS",
  token: "dsgsad4gSfdskjaDFw35SDd",
  refreshToken: "jkhjkfl3%gfdG34lHaHkjmDfsd",
  userId: "kjjsJHKGkjhgejk2345nnjkfdkjnlsdflkh",
};

export const SessionStorageClientAuth = {
  sessionKey: "064hj675a8vbnv14kas9jbaD9JAG",
};

export const DocTypes = {
  presentationProtocol: "presentationProtocol",
  intermediationContract: "intermediationContract",
};

export const ActiveLinkCategory = {
  presentationProtocol: "presentationProtocol",
  intermediationContract: "intermediationContract",
  contractPurchase: "contractPurchase",
  contractLease: "contractLease",
};

export const ActiveLinkCategoryShortcut = {
  contractPurchase: "c-pur",
  contractLease: "c-lea",
  templateDocument: "c-tmp",
};

export const DocStatus = {
  new: 0,
  sended: 100,
  accepted: 200,
  closed: 9, // TODO: obsłużyć status zamknięcia, przy okazji zdezaktywować link dostępowy
};

export const DocStatusGetName = (status) => {
  if (status === DocStatus.new) {
    return "Nowy";
  } else if (status === DocStatus.sended) {
    return "Wysłany";
  } else if (status === DocStatus.accepted) {
    return "Zaakceptowany";
  } else if (status === DocStatus.closed) {
    return "Zamknięty";
  }
  //return `${status}`;
  return "";
};

export const DocStatusCombo = [
  { code: "", label: "Dowolny" },
  { code: "0", label: "Nowy" },
  { code: "100", label: "Wysłany" },
  { code: "200", label: "Zaakceptowany" },
];

export const LogicalField = (value) => {
  if (value) {
    return "Tak";
  }

  return "Nie";
};

export const IdentityDocumentType = [
  { code: "", label: "Brak danych" },
  { code: "identityCard", label: "Dowód osobisty" },
  { code: "passport", label: "Paszport" },
  { code: "mdowod", label: "mDowód (mObywatel)" },
];

export const Currency = [
  { code: "PLN", label: "PLN" },
  { code: "EUR", label: "EUR" },
];

export const PageCodes = {
  desktop: "desktop",
  settings: "settings",
  users: "users",
  contact: "contact",
  branches: "branches",
  presentationProtocol: "presentationProtocol",
  presentationProtocolHistory: "presentationProtocolHistory",
  intermediationContract: "intermediationContract",
  intermediationContractHistory: "intermediationContractHistory",
  templateDocument: "templateDocument",
  templateDocumentHistory: "templateDocumentHistory",
  contractPurchase: "contractPurchase",
  contractPurchaseHistory: "contractPurchaseHistory",
  contractLease: "contractLease",
  contractLeaseHistory: "contractLeaseHistory",
};

export const IntermediationContractVariant = {
  exclusiveAgent: 10,
  exclusiveAgency: 20,
  open: 30,
};

export const IntermediationContractVariantCombo = [
  { code: 10, label: "Wyłączność" },
  { code: 20, label: "Wyłączność agencyjna" },
  { code: 30, label: "Umowa otwarta" },
];

export const ClientType = {
  person: 10,
  personWithRepresentative: 11,
  businessIndividual: 20,
  businessIndividualWithRepresentative: 21,
  company: 30,
  companyWithRepresentative: 31,
};

export const ClientTypeCombo = [
  { code: 10, label: "jest osobą fizyczną" },
  { code: 11, label: "jest osobą fizyczną reprezentowaną przez pełnomocnika" },
  { code: 20, label: "jest osobą fizyczną prowadzącą działalność gospodarczą" },
  { code: 21, label: "jest osobą fizyczną prowadzącą działalność gospodarczą i reprezentowaną przez pełnomocnika" },
  { code: 30, label: "jest podmiotem zarejestrowanym w KRS" },
  { code: 31, label: "jest podmiotem zarejestrowanym w KRS z odrębnym pełnomocnictwem" },
];

export const AgentProvisionType = {
  percent: 1,
  amount: 2,
};
