import * as React from "react";
import {
  formatAddressLine1,
  phoneNumberFormat,
  translateMonthNumber,
  formatNumberByCurrency,
  netToGrossRecalculate,
} from "./../../../utils/validator";
import * as dict from "./../../../config/Dictionary";
import { useEffect, useRef, useState } from "react";
import DocumentHTMLAtt1 from "./DocumentHTMLAtt1";
import * as TemplateDocumentCoownerApi from "./../../../business/TemplateDocument/TemplateDocumentCoowner/TemplateDocumentCoownerApi";
import * as TemplateDocumentBinaryApi from "./../../../business/TemplateDocument/TemplateDocumentBinary/TemplateDocumentBinaryApi";
import ImageViewer from "./../../../business/TemplateDocument/TemplateDocumentBinary/ImageViewer";

const DocumentForm = (props) => {
  const id = props.id;
  const mode = props.mode;
  const sessionKey = props.sessionKey;

  const hiddenInputRef = useRef(null);

  const [coownersData, setCoownersData] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);

  const getCoownersData = async () => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await TemplateDocumentCoownerApi.GetByDocIdSessionKey(id, sessionKey);
      setCoownersData(object);
    } else {
      const object = await TemplateDocumentCoownerApi.GetByDocId(id, sessionKey);
      setCoownersData(object);
    }
  };

  const getAttachmentsData = async () => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await TemplateDocumentBinaryApi.GetIdsByDocIdSessionKey(id, null, sessionKey);
      setAttachmentsData(object);
    } else {
      const object = await TemplateDocumentBinaryApi.GetIdsByDocId(id, null);
      setAttachmentsData(object);
    }
  };

  useEffect(() => {
    hiddenInputRef.current.focus();
    getCoownersData();
    getAttachmentsData();

    return () => {};
    // eslint-disable-next-line
  }, []);

  const styles = {
    page: {
      fontFamily: "Roboto",
      padding: "3%",
      paddingTop: "5px",
      marginLeft: "15px",
      fontSize: "12px",
      marginRight: "20px",
    },
    parameter: {
      fontWeight: "bold",
      color: "#163058",
    },
    title: {
      paddingBottom: "40px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
    attachmentTitle: {
      paddingBottom: "5px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
    descr: {
      paddingBottom: "7px",
      marginRight: "20px",
    },
    descrBold: {
      fontWeight: "bold",
    },
    descrBoldAndCenter: {
      fontWeight: "bold",
      textAlign: "center",
    },
    square: {
      width: 10, // Adjust width and height as needed
      height: 10,
      borderWidth: 0, // Set border width to 0 for no border
      border: "1px solid black",
    },
    parameterLack: {
      fontWeight: "bold",
      //color: "red",
      color: "#163058",
    },
    paragrapf: {
      textAlign: "center",
      fontSize: "12px",
      fontWeight: "bold",
    },
  };

  const loadData = (value) => {
    if (!value) {
      return <span style={styles.parameterLack}>{" - "}</span>;
    }
    return <span style={styles.parameter}>{value}</span>;
  };

  const renderParagraph1VariantOpenAdditional = () => {
    if (props.contractvariant === dict.IntermediationContractVariant.open)
      return (
        <p style={{ marginTop: "5px" }}>
          <span>
            {
              "2. Klient oświadcza, że: jest właścicielem Nieruchomości / działa na podstawie załączonego pełnomocnictwa udzielonego przez właściciela Nieruchomości / działa za zgodą właściciela Nieruchomości lecz we własnym imieniu i zleca Pośrednikowi pośrednictwo w zbyciu Nieruchomości."
            }
          </span>
        </p>
      );

    return <span></span>;
  };

  const condP2VariantOpen = () => {
    if (props.contractvariant === dict.IntermediationContractVariant.open)
      return (
        <div>
          <p>
            {
              "1. Pośrednik zobowiązuje się do podejmowania dla Klienta czynności pośrednictwa zmierzających do zbycia Nieruchomości przez Klienta, zgodnie z wiedzą i doświadczeniem Pośrednika. W szczególności Pośrednik zobowiązuje się do:"
            }
          </p>
          <p>- Reklamowanie i marketing w poszukiwaniu nabywcy nieruchomości</p>
          <p>- Dokonywanie w uzgodnionych terminach prezentacji nieruchomości potencjalnym nabywcom</p>
          <p>
            - Skontaktowanie klienta i potencjalnego nabywcy nieruchomości w celu uzgodnienia przyszłych warunków transakcji łączącej strony, udział w
            negocjacjach.
          </p>
          <p>- Zapewnienie obsługi organizacyjnej transakcji</p>

          <p>
            {
              "2. Rezygnacja Klienta z poszczególnych czynności wymienionych powyżej nie stanowi podstawy do zmniejszenia lub niezapłacenia należnego Pośrednikowi Wynagrodzenia."
            }
          </p>

          <p>{"3. Pośrednik może wykonywać czynności pośrednictwa na rzecz obu stron transakcji."}</p>

          <p>
            {
              "4. Umowa nie ogranicza Klienta w zawieraniu umów pośrednictwa z innymi pośrednikami ani w poszukiwaniu nabywcy Nieruchomości we własnym zakresie."
            }
          </p>
        </div>
      );

    return <span></span>;
  };

  const condP2VariantNotOpen = () => {
    if (props.contractvariant === dict.IntermediationContractVariant.open) return <span></span>;

    return (
      <div>
        <p>
          1. Pośrednik zobowiązuje się do podejmowania dla Klienta czynności pośrednictwa zmierzających do zbycia Nieruchomości przez Klienta, zgodnie
          z wiedzą i doświadczeniem Pośrednika. W szczególności Pośrednik zobowiązuje się do:
        </p>
        <p>
          <input
            type="checkbox"
            id="obligationShowPurchOffer"
            name="obligationShowPurchOffer"
            value=""
            checked={props.obligationShowPurchOffer ? "checked" : ""}
            disabled
          />
          <label>przedstawiania Klientowi ofert kupna Nieruchomości</label>
          <br />
          <input
            type="checkbox"
            id="obligationPresentation"
            name="obligationPresentation"
            value=""
            checked={props.obligationPresentation ? "checked" : ""}
            disabled
          />
          <label>dokonywania w uzgodnionych terminach prezentacji Nieruchomości potencjalnym nabywcom</label>
          <br />
          <input type="checkbox" id="obligationPhotos" name="obligationPhotos" value="" checked={props.obligationPhotos ? "checked" : ""} disabled />
          <label>sporządzenia dokumentacji zdjęciowej</label>
          <br />
          <input
            type="checkbox"
            id="obligationCollectingDocs"
            name="obligationCollectingDocs"
            value=""
            checked={props.obligationCollectingDocs ? "checked" : ""}
            disabled
          />
          <label>skompletowania dokumentacji na podstawie przedłożonych przez Klienta dokumentów</label>
          <br />
          <input
            type="checkbox"
            id="obligationDescrAndPubl"
            name="obligationDescrAndPubl"
            value=""
            checked={props.obligationDescrAndPubl ? "checked" : ""}
            disabled
          />
          <label>sporządzenia oferty i opisu Nieruchomości oraz rejestracji oferty w bazie danych Pośrednika</label>
          <br />
          <input
            type="checkbox"
            id="obligationLegalVerif"
            name="obligationLegalVerif"
            value=""
            checked={props.obligationLegalVerif ? "checked" : ""}
            disabled
          />
          <label>dokonania wstępnej weryfkacji stanu prawnego Nieruchomości</label>
          <br />
          <input
            type="checkbox"
            id="obligationAdMother"
            name="obligationAdMother"
            value=""
            checked={props.obligationAdMother ? "checked" : ""}
            disabled
          />
          {/* <label>umieszczenia reklamy na stronie internetowej Pośrednika</label>
          <br />
          <input
            type="checkbox"
            id="obligationAdMotherAndOthers"
            name="obligationAdMotherAndOthers"
            value=""
            checked={props.obligationAdMotherAndOthers ? "checked" : ""}
            disabled
          /> */}
          <label>umieszczenia reklamy na stronie internetowej Pośrednika oraz na innych portalach internetowych</label>
          <br />
          <input
            type="checkbox"
            id="obligationDescrUpgrade"
            name="obligationDescrUpgrade"
            value=""
            checked={props.obligationDescrUpgrade ? "checked" : ""}
            disabled
          />
          <label>bieżącej aktualizacji danych dotyczących Nieruchomości </label>
          <br />
          <input
            type="checkbox"
            id="obligationCombTranContact"
            name="obligationCombTranContact"
            value=""
            checked={props.obligationCombTranContact ? "checked" : ""}
            disabled
          />
          <label>
            skontaktowania Klienta i potencjalnego nabywcy Nieruchomości w celu uzgodnienia przyszłych warunków transakcji łączącej strony, udziału w
            negocjacjach{" "}
          </label>
          <br />
          <input
            type="checkbox"
            id="obligationProtocols"
            name="obligationProtocols"
            value=""
            checked={props.obligationProtocols ? "checked" : ""}
            disabled
          />
          <label>przygotowania protokołu uzgodnień lub protokołu zdawczo-odbiorczego</label>
          <br />
          <input
            type="checkbox"
            id="obligationTransPrepHelp"
            name="obligationTransPrepHelp"
            value=""
            checked={props.obligationTransPrepHelp ? "checked" : ""}
            disabled
          />
          <label>zapewnienia obsługi związanej z przygotowaniem transakcji</label>
          <br />
          <input type="checkbox" id="obligationOthers" name="obligationOthers" value="" checked={props.obligationOthers ? "checked" : ""} disabled />
          <label>inne</label>
          <label>{props.obligationOthersText}</label>
          <br />
        </p>
        <p>
          2. Rezygnacja Klienta z poszczególnych czynności wymienionych powyżej nie stanowi podstawy do zmniejszenia lub niezapłacenia należnego
          Pośrednikowi Wynagrodzenia.
        </p>
        <p>3. Pośrednik może wykonywać czynności pośrednictwa na rzecz obu stron transakcji.</p>
      </div>
    );
  };

  const condP3VariantNotOpenPage1 = () => {
    if (props.contractvariant === dict.IntermediationContractVariant.open) return <span></span>;

    return (
      <div>
        <p style={styles.paragrapf}>§3</p>
        <p>
          {
            "1. Klient oświadcza, że zawiera Umowę z zastrzeżeniem wyłączności na rzecz Pośrednika i zobowiązuje się do niezawierania umowy pośrednictwa w obrocie nieruchomościami dotyczącej Nieruchomości z innymi osobami oraz niepowierzania osobom trzecim czynności zmierzających do doprowadzenia do zawarcia umowy sprzedaży Nieruchomości."
          }
        </p>
      </div>
    );
  };

  const condP3VariantExclusiveAgentPage2 = () => {
    if (props.contractvariant === dict.IntermediationContractVariant.exclusiveAgent)
      return (
        <p>
          {
            "2. W przypadku zawarcia przez Klienta umowy przedwstępnej sprzedaży Nieruchomości lub umowy sprzedaży Nieruchomości z potencjalnym nabywcą, Klient zobowiązuje się zapłacić Pośrednikowi wynagrodzenie określone w § 4 Umowy - bez względu na osobę pośredniczącą w nawiązaniu kontaktu pomiędzy Klientem oraz nabywcą Nieruchomości."
          }
        </p>
      );

    return <span></span>;
  };

  const condP3VariantExclusiveAgencyPage2 = () => {
    if (props.contractvariant === dict.IntermediationContractVariant.exclusiveAgency)
      return (
        <div>
          <p>
            {
              "2. W przypadku zawarcia przez Klienta umowy przedwstępnej sprzedaży Nieruchomości lub umowy sprzedaży Nieruchomości z potencjalnym nabywcą, Klient zobowiązuje się zapłacić Pośrednikowi wynagrodzenie określone w § 4 Umowy - z zastrzeżeniem ust. 3 poniżej."
            }
          </p>
          <p>
            {
              "3. Wynagrodzenie nie przysługuje Pośrednikowi w przypadku zawarcia przez Klienta umowy wskazanej w ust. 2 powyżej, z nabywcą pozyskanym przez Klienta bezpośrednio, to jest bez udziału Pośrednika lub innego podmiotu (osoby trzeciej)."
            }
          </p>
        </div>
      );

    return <span></span>;
  };

  const setParagrapfNumber = (number) => {
    if (number >= 4 && props.contractvariant === dict.IntermediationContractVariant.open) number = number - 1;

    return number;
  };

  const setContractDuration = () => {
    const monthAsWord = translateMonthNumber(props.contractDurationMonth);

    if (monthAsWord && monthAsWord !== "") {
      return props.contractDurationMonth.toString() + " (" + monthAsWord + ")";
    }

    return props.contractDurationMonth.toString();
  };

  const contractTitle = () => {
    if (props.contractvariant === dict.IntermediationContractVariant.open) {
      return "NIE na wyłączność, zawarta poza lokalem przedsiębiorstwa";
    } else if (props.contractvariant === dict.IntermediationContractVariant.exclusiveAgency) {
      return "WYŁĄCZNOŚĆ AGENCYJNA zawarta poza lokalem przedsiębiorstwa";
    }

    return "na wyłączność, zawarta poza lokalem przedsiębiorstwa";
  };

  const renderRepresentative = (
    xClientName,
    xClientSurname,
    xClientAddrCity,
    xClientAddrPostcode,
    xClientAddrStreet,
    xClientAddrStreetNumber,
    xClientAddrLocaleNumber,
    xClientPesel,
    xClientIdType,
    xClientIdNumber,
    xClientEmail,
    xClientPhone
  ) => {
    return (
      <div>
        <p>
          {loadData(xClientName)} {loadData(xClientSurname)} zamieszkałym w {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},{" "}
          {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, PESEL: {loadData(xClientPesel)},
          legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)} {loadData(xClientIdNumber)}
        </p>
        <p>
          Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
        </p>
      </div>
    );
  };

  const renderStakeholder = (
    isFirst,
    type,
    xClientName,
    xClientSurname,
    xClientAddrCity,
    xClientAddrPostcode,
    xClientAddrStreet,
    xClientAddrStreetNumber,
    xClientAddrLocaleNumber,
    xClientPesel,
    xClientIdType,
    xClientIdNumber,
    xClientEmail,
    xClientPhone,
    xPersonName,
    xPersonSurname,
    xPersonAddrCity,
    xPersonAddrPostcode,
    xPersonAddrStreet,
    xPersonAddrStreetNumber,
    xPersonAddrLocaleNumber,
    xPersonPesel,
    xPersonIdType,
    xPersonIdNumber,
    xPersonEmail,
    xPersonPhone,
    xCompanyName,
    xCompanyNIP,
    xCompanyKRS
  ) => {
    if (type === dict.ClientType.person) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xClientName)} {loadData(xClientSurname)} zamieszkałym w {loadData(xClientAddrCity)}, kod pocztowy{" "}
            {loadData(xClientAddrPostcode)}, {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))},
            PESEL: {loadData(xClientPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)}{" "}
            {loadData(xClientIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.personWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xPersonName)} {loadData(xPersonSurname)} zamieszkałym w {loadData(xPersonAddrCity)}, kod pocztowy{" "}
            {loadData(xPersonAddrPostcode)}, {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))},
            PESEL: {loadData(xPersonPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xPersonIdType)[0]?.label)}{" "}
            {loadData(xPersonIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xPersonEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xPersonPhone))}
          </p>
          <p>reprezentowanym przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    } else if (type === dict.ClientType.businessIndividual) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xClientName)} {loadData(xClientSurname)} prowadzącym jednoosobową działalność gospodarczą pod nazwą {loadData(xCompanyName)},
            adres: {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, PESEL:{" "}
            {loadData(xClientPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xClientIdType)[0]?.label)}{" "}
            {loadData(xClientIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.businessIndividualWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xPersonName)} {loadData(xPersonSurname)} prowadzącym jednoosobową działalność gospodarczą pod nazwą {loadData(xCompanyName)},
            adres: {loadData(xPersonAddrCity)}, kod pocztowy {loadData(xPersonAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, PESEL:{" "}
            {loadData(xPersonPesel)}, legitymujący się {loadData(dict.IdentityDocumentType.filter((f) => f.code === xPersonIdType)[0]?.label)}{" "}
            {loadData(xPersonIdNumber)}
          </p>
          <p>
            Adres e-mail: {loadData(xPersonEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xPersonPhone))}
          </p>
          <p>reprezentowanym przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    } else if (type === dict.ClientType.company) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xCompanyName)}, adres: {loadData(xClientAddrCity)}, kod pocztowy {loadData(xClientAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xClientAddrStreet, xClientAddrStreetNumber, xClientAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, KRS:{" "}
            {loadData(xCompanyKRS)}
          </p>
          <p>
            reprezentowaną przez {loadData(xClientName)} {loadData(xClientSurname)} na podstawie KRS
          </p>
          <p>
            Adres e-mail: {loadData(xClientEmail)}, telefon kontaktowy: {loadData(phoneNumberFormat(xClientPhone))}
          </p>
        </div>
      );
    } else if (type === dict.ClientType.companyWithRepresentative) {
      return (
        <div>
          <p>
            {isFirst ? "(2) " : ""}
            {loadData(xCompanyName)}, adres: {loadData(xPersonAddrCity)}, kod pocztowy {loadData(xPersonAddrPostcode)},{" "}
            {loadData(formatAddressLine1(xPersonAddrStreet, xPersonAddrStreetNumber, xPersonAddrLocaleNumber))}, NIP: {loadData(xCompanyNIP)}, KRS:{" "}
            {loadData(xCompanyKRS)}
          </p>
          <p>reprezentowaną przez</p>
          {renderRepresentative(
            xClientName,
            xClientSurname,
            xClientAddrCity,
            xClientAddrPostcode,
            xClientAddrStreet,
            xClientAddrStreetNumber,
            xClientAddrLocaleNumber,
            xClientPesel,
            xClientIdType,
            xClientIdNumber,
            xClientEmail,
            xClientPhone
          )}
        </div>
      );
    }
  };

  const renderOwner = () => {
    return renderStakeholder(
      true,
      props.clientType,
      props.clientName,
      props.clientSurname,
      props.clientAddrCity,
      props.clientAddrPostcode,
      props.clientAddrStreet,
      props.clientAddrStreetNumber,
      props.clientAddrLocaleNumber,
      props.clientPesel,
      props.clientIdType,
      props.clientIdNumber,
      props.clientEmail,
      props.clientPhone,
      props.principalName,
      props.principalSurname,
      props.principalAddrCity,
      props.principalAddrPostcode,
      props.principalAddrStreet,
      props.principalAddrStreetNumber,
      props.principalAddrLocaleNumber,
      props.principalPesel,
      props.principalIdType,
      props.principalIdNumber,
      props.principalEmail,
      props.principalPhone,
      props.companyName,
      props.companyNIP,
      props.companyKRS
    );
  };

  const renderCoowner = (obj) => {
    return renderStakeholder(
      false,
      obj.clientType,
      obj.clientName,
      obj.clientSurname,
      obj.clientAddrCity,
      obj.clientAddrPostcode,
      obj.clientAddrStreet,
      obj.clientAddrStreetNumber,
      obj.clientAddrLocaleNumber,
      obj.clientPesel,
      obj.clientIdType,
      obj.clientIdNumber,
      obj.clientEmail,
      obj.clientPhone,
      obj.principalName,
      obj.principalSurname,
      obj.principalAddrCity,
      obj.principalAddrPostcode,
      obj.principalAddrStreet,
      obj.principalAddrStreetNumber,
      obj.principalAddrLocaleNumber,
      obj.principalPesel,
      obj.principalIdType,
      obj.principalIdNumber,
      obj.principalEmail,
      obj.principalPhone,
      obj.companyName,
      obj.companyNIP,
      obj.companyKRS
    );
  };

  const renderCoowners = () => {
    return coownersData?.map((item) => (
      <div key={item.id}>
        <span>{"i "}</span>
        {renderCoowner(item)}
      </div>
    ));
  };

  const renderAttachments = () => {
    return attachmentsData?.map((item) => (
      <div key={item.id} style={{ textAlign: "center", marginTop: "10px", marginBottom: "0px" }}>
        <div style={styles.attachmentTitle}>{item.label}</div>
        <ImageViewer id={item.id} sessionKey={sessionKey} mode={mode} mimeType={item.mimeType} docId={id} />
      </div>
    ));
  };

  const renderAgentProvision = () => {
    if (props.agentProvisionType === dict.AgentProvisionType.amount) {
      return (
        <span>
          1. Klient zobowiązuje się do zapłaty Pośrednikowi wynagrodzenia w wysokości{" "}
          {loadData(formatNumberByCurrency(netToGrossRecalculate(props.agentProvisionAmountNet), props.offerPriceCurrency))} PLN brutto ({""}
          {loadData(formatNumberByCurrency(props.agentProvisionAmountNet, props.offerPriceCurrency))} PLN netto + 23% podatku od towarów i usług VAT)
          niezależnie od ceny transakcyjnej nieruchomości ("Wynagrodzenie")
        </span>
      );
    }

    return (
      <span>
        1. Klient zobowiązuje się do zapłaty Pośrednikowi wynagrodzenia w wysokości{" "}
        {loadData(formatNumberByCurrency(netToGrossRecalculate(props.agentProvisionPercentNet), props.offerPriceCurrency))}% brutto ({""}
        {loadData(formatNumberByCurrency(props.agentProvisionPercentNet, props.offerPriceCurrency))}% netto + 23% podatku od towarów i usług VAT) ceny
        transakcyjnej Nieruchomości brutto ("Wynagrodzenie")
      </span>
    );
  };

  return (
    <div>
      <div style={styles.page}>
        <input type="checkbox" ref={hiddenInputRef} style={{ width: 0, height: 0 }} />
        <p style={styles.title}>
          UMOWA POŚREDNICTWA W SPRZEDAŻY NIERUCHOMOŚCI <br />
          {contractTitle()}
        </p>
        <p>
          zawarta w dniu {loadData(props.contractDate)} r. w miejscowości {loadData(props.contractCity)} pomiędzy:
        </p>
        <p>
          (1) Demo Estate Spółką z ograniczoną odpowiedzialnością z siedzibą w Warszawa, pod adresem: ul. Demonstracyjna 111, lok. 2, 00-000 Warszawa,
          wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Warszawa-Centrum w Warszawie, XIII Wydział
          Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000000000, o numerze NIP 1111111111, o kapitale zakładowym w wysokości 2 000 000,00
          złotych, zwaną dalej „DEMO ESTATE”, reprezentowaną przez: {loadData(props.agentName)} {loadData(props.agentSurname)} - pełnomocnika, tel.:{" "}
          {loadData(phoneNumberFormat(props.agentPhone))} email: {loadData(props.agentEmail)}
        </p>
        <p>a</p>
        {renderOwner()}
        {renderCoowners()}
        <p style={styles.paragrapf}>§1</p>
        <p>
          1. Klient powierza, a Pośrednik zobowiązuje się do pośredniczenia w zawarciu przez Klienta umowy sprzedaży nieruchomości ("Nieruchomość")
          stanowiącej:
        </p>
        <p>
          <input
            type="checkbox"
            id="propertyIsSeparateOwnership"
            name="propertyIsSeparateOwnership"
            value=""
            checked={props.propertyIsSeparateOwnership ? "checked" : ""}
            disabled
          />
          <label>odrębny lokal mieszkalny/ użytkowy (prawo własności)</label>
          <br />
          <input type="checkbox" id="propertyIsLand" name="propertyIsLand" value="" checked={props.propertyIsLand ? "checked" : ""} disabled />
          <label>nieruchomość gruntową </label>
          <br />
          <input
            type="checkbox"
            id="propertyIsCooperativeOwnership"
            name="propertyIsCooperativeOwnership"
            value=""
            checked={props.propertyIsCooperativeOwnership ? "checked" : ""}
            disabled
          />
          <label>spółdzielcze własnościowe prawo do lokalu </label>
          <br />
        </p>

        {renderParagraph1VariantOpenAdditional()}
        <p style={styles.paragrapf}>§2</p>
        {condP2VariantNotOpen()}
        {condP2VariantOpen()}

        {condP3VariantNotOpenPage1()}
        {condP3VariantExclusiveAgentPage2()}
        {condP3VariantExclusiveAgencyPage2()}

        <p style={styles.paragrapf}>§{setParagrapfNumber(4)}</p>
        <p>{renderAgentProvision()}</p>
        <p>
          2. Niniejsza Umowa zostaje zawarta na czas określony {setContractDuration()} miesięcy od dnia jej podpisania. Każda Strona może wypowiedzieć
          w tym czasie Umowę wyłącznie z ważnych powodów.{" "}
        </p>
        <p>3. Wszelkie zmiany do niniejszej Umowy wymagają formy pisemnej pod rygorem nieważności. </p>
        <p>
          4. Klient oświadcza, że został poinformowany przez Pośrednika o: (i) prawie do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa w
          terminie 14 (czternastu) dni od dnia jej zawarcia oraz sposobie odstąpienia (zgodnie z art. 27 ustawy z dnia 30 maja 2014r. o prawach
          konsumenta); (ii) obowiązku zapłaty Pośrednikowi za świadczenia spełnione do chwili odstąpienia od umowy, w przypadku odstąpienia od umowy
          zawartej poza lokalem przedsiębiorstwa, jeśli zgłosił żądanie wykonywania usługi przed terminem do odstąpienia od umowy (zgodnie z art. 35
          ustawy z dnia 30 maja 2014r. o prawach konsumenta), (iii) braku prawa do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa jeśli
          zgłosił żądanie wykonywania usługi przed terminem do odstąpienia od umowy (zgodnie z art. 12 ust. 1 pkt.12) ustawy z dnia 30 maja 2014r. o
          prawach konsumenta) w przypadku, gdy dojdzie do transakcji określonej w Umowie w wyniku podjętych w tym okresie przez Pośrednika czynności
          pośrednictwa.{" "}
        </p>
        <p>
          5. Klient oświadcza, że otrzymał następujące Załączniki do umowy:
          <br />
          Załącznik Nr 1 - Pouczenie o odstąpieniu od umowy zawartej poza lokalem przedsiębiorstwa <br />
        </p>
      </div>
      <div>{renderAttachments()}</div>
      <DocumentHTMLAtt1 />
    </div>
  );
};

export default DocumentForm;
