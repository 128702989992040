import axios from "axios";
import * as api from "../../config/ApiConfig";
import * as session from "../../components/SessionStorage/SessionStorage";
import * as Token from "../../api/Token";
import * as UserApi from "../User/UserApi";
import { base64ToBlob } from "./../../utils/validator";

class IntermediationContract {}

export const Delete = async (objectId) => {
  try {
    const response = await axios({
      method: "delete",
      url: api.url_IntermediationContract_Delete,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: [objectId],
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_Delete, error);
  }
};

export const Create = async (object) => {
  try {
    let user = await UserApi.GetById(session.getUserId());

    const response = await axios({
      method: "post",
      url: api.url_IntermediationContract_Create,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        createdBy: session.getUserId(),
        branchId: user.branchId,
        number: object.number,
        variant: object.variant,
        contractDurationMonth: object.contractDurationMonth,
        contractDate: object.contractDate,
        contractCity: object.contractCity,
        clientType: object.clientType,
        clientName: object.clientName,
        clientSurname: object.clientSurname,
        clientAddrStreet: object.clientAddrStreet,
        clientAddrStreetNumber: object.clientAddrStreetNumber,
        clientAddrLocaleNumber: object.clientAddrLocaleNumber,
        clientAddrPostcode: object.clientAddrPostcode,
        clientAddrCity: object.clientAddrCity,
        clientAddrCountry: object.clientAddrCountry,
        clientPesel: object.clientPesel,
        clientIdType: object.clientIdType,
        clientIdNumber: object.clientIdNumber,
        clientPhone: object.clientPhone,
        clientEmail: object.clientEmail,
        propertyIsSeparateOwnership: object.propertyIsSeparateOwnership,
        propertyIsLand: object.propertyIsLand,
        propertyIsCooperativeOwnership: object.propertyIsCooperativeOwnership,
        propertyIsOthers: object.propertyIsOthers,
        propertyIsOthersText: object.propertyIsOthersText,
        propertyArea: object.propertyArea,
        propertyAddrStreet: object.propertyAddrStreet,
        propertyAddrStreetNumber: object.propertyAddrStreetNumber,
        propertyAddrLocaleNumber: object.propertyAddrLocaleNumber,
        propertyAddrPostcode: object.propertyAddrPostcode,
        propertyAddrCity: object.propertyAddrCity,
        propertyAddrCountry: object.propertyAddrCountry,
        landNumber: object.landNumber,
        mortgageRegister: object.mortgageRegister,
        offerPrice: object.offerPrice,
        offerPriceCurrency: object.offerPriceCurrency,
        obligationShowPurchOffer: object.obligationShowPurchOffer,
        obligationPresentation: object.obligationPresentation,
        obligationPhotos: object.obligationPhotos,
        obligationCollectingDocs: object.obligationCollectingDocs,
        obligationDescrAndPubl: object.obligationDescrAndPubl,
        obligationLegalVerif: object.obligationLegalVerif,
        obligationAdMother: object.obligationAdMother,
        obligationAdMotherAndOthers: object.obligationAdMotherAndOthers,
        obligationDescrUpgrade: object.obligationDescrUpgrade,
        obligationCombTranContact: object.obligationCombTranContact,
        obligationProtocols: object.obligationProtocols,
        obligationTransPrepHelp: object.obligationTransPrepHelp,
        obligationOthers: object.obligationOthers,
        obligationOthersText: object.obligationOthersText,
        agentProvisionType: object.agentProvisionType,
        agentProvisionPercentNet: object.agentProvisionPercentNet,
        agentProvisionAmountNet: object.agentProvisionAmountNet,
        clientInvoiceEmail: object.clientInvoiceEmail,
        agentId: object.agentId,
        agentSentAt: object.agentSentAt,
        clientAcceptedAt: object.clientAcceptedAt,
        status: object.status,

        companyName: object.companyName,
        companyNIP: object.companyNIP,
        companyKRS: object.companyKRS,
        personName: object.personName,
        personSurname: object.personSurname,
        personAddrStreet: object.personAddrStreet,
        personAddrStreetNumber: object.personAddrStreetNumber,
        personAddrLocaleNumber: object.personAddrLocaleNumber,
        personAddrPostcode: object.personAddrPostcode,
        personAddrCity: object.personAddrCity,
        personAddrCountry: object.personAddrCountry,
        personPesel: object.personPesel,
        personIdType: object.personIdType,
        personIdNumber: object.personIdNumber,
        personPhone: object.personPhone,
        personEmail: object.personEmail,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_Create, error);
  }
};

export const Update = async (objectId, object) => {
  try {
    let first = {
      updatedBy: session.getUserId(),
      number: object.number,
      variant: object.variant,
      contractDurationMonth: object.contractDurationMonth,
      contractDate: object.contractDate,
      contractCity: object.contractCity,
      clientType: object.clientType,
      clientName: object.clientName,
      clientSurname: object.clientSurname,
      clientAddrStreet: object.clientAddrStreet,
      clientAddrStreetNumber: object.clientAddrStreetNumber,
      clientAddrLocaleNumber: object.clientAddrLocaleNumber,
      clientAddrPostcode: object.clientAddrPostcode,
      clientAddrCity: object.clientAddrCity,
      clientAddrCountry: object.clientAddrCountry,
      clientPesel: object.clientPesel,
      clientIdType: object.clientIdType,
      clientIdNumber: object.clientIdNumber,
      clientPhone: object.clientPhone,
      clientEmail: object.clientEmail,
      propertyIsSeparateOwnership: object.propertyIsSeparateOwnership,
      propertyIsLand: object.propertyIsLand,
      propertyIsCooperativeOwnership: object.propertyIsCooperativeOwnership,
      propertyIsOthers: object.propertyIsOthers,
      propertyIsOthersText: object.propertyIsOthersText,
      propertyArea: object.propertyArea,
      propertyAddrStreet: object.propertyAddrStreet,
      propertyAddrStreetNumber: object.propertyAddrStreetNumber,
      propertyAddrLocaleNumber: object.propertyAddrLocaleNumber,
      propertyAddrPostcode: object.propertyAddrPostcode,
      propertyAddrCity: object.propertyAddrCity,
      propertyAddrCountry: object.propertyAddrCountry,
      landNumber: object.landNumber,
      mortgageRegister: object.mortgageRegister,
      offerPrice: object.offerPrice,
      offerPriceCurrency: object.offerPriceCurrency,
      obligationShowPurchOffer: object.obligationShowPurchOffer,
      obligationPresentation: object.obligationPresentation,
      obligationPhotos: object.obligationPhotos,
      obligationCollectingDocs: object.obligationCollectingDocs,
      obligationDescrAndPubl: object.obligationDescrAndPubl,
      obligationLegalVerif: object.obligationLegalVerif,
      obligationAdMother: object.obligationAdMother,
      obligationAdMotherAndOthers: object.obligationAdMotherAndOthers,
      obligationDescrUpgrade: object.obligationDescrUpgrade,
      obligationCombTranContact: object.obligationCombTranContact,
      obligationProtocols: object.obligationProtocols,
      obligationTransPrepHelp: object.obligationTransPrepHelp,
      obligationOthers: object.obligationOthers,
      obligationOthersText: object.obligationOthersText,
      agentProvisionType: object.agentProvisionType,
      agentProvisionPercentNet: object.agentProvisionPercentNet === "" ? null : object.agentProvisionPercentNet,
      agentProvisionAmountNet: object.agentProvisionAmountNet === "" ? null : object.agentProvisionAmountNet,
      clientInvoiceEmail: object.clientInvoiceEmail,
      agentId: object.agentId,
      agentSentAt: object.agentSentAt,
      //clientAcceptedAt: object.clientAcceptedAt, CELOWO ZABLOKOWANE
      status: object.status,

      companyName: object.companyName,
      companyNIP: object.companyNIP,
      companyKRS: object.companyKRS,
      personName: object.personName,
      personSurname: object.personSurname,
      personAddrStreet: object.personAddrStreet,
      personAddrStreetNumber: object.personAddrStreetNumber,
      personAddrLocaleNumber: object.personAddrLocaleNumber,
      personAddrPostcode: object.personAddrPostcode,
      personAddrCity: object.personAddrCity,
      personAddrCountry: object.personAddrCountry,
      personPesel: object.personPesel,
      personIdType: object.personIdType,
      personIdNumber: object.personIdNumber,
      personPhone: object.personPhone,
      personEmail: object.personEmail,
    };

    let bodyData;

    if (base64ToBlob(object.signatureAgent)) {
      bodyData = {
        ...first,
        signatureAgent: base64ToBlob(object.signatureAgent),
      };
    } else {
      bodyData = {
        ...first,
      };
    }

    const response = await axios({
      method: "post",
      url: api.url_IntermediationContract_Update,
      headers: {
        "Content-Type": "multipart/form-data",
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.log(error);
    console.error("API method error: ", api.url_IntermediationContract_Update, error);
  }
};

export const GetById = async (objectId) => {
  try {
    const response = await axios.get(api.url_IntermediationContract_GetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    const object = new IntermediationContract();

    object.id = response.data.id;
    object.createdBy = response.data.createdBy;
    object.updatedBy = response.data.updatedBy;
    object.branchId = response.data.branchId;
    object.number = response.data.number;
    object.variant = response.data.variant;
    object.contractDurationMonth = response.data.contractDurationMonth;
    object.contractDate = response.data.contractDate;
    object.contractCity = response.data.contractCity;

    object.clientType = response.data.clientType;
    object.clientName = response.data.clientName;
    object.clientSurname = response.data.clientSurname;
    object.clientAddrStreet = response.data.clientAddrStreet;
    object.clientAddrStreetNumber = response.data.clientAddrStreetNumber;
    object.clientAddrLocaleNumber = response.data.clientAddrLocaleNumber;
    object.clientAddrPostcode = response.data.clientAddrPostcode;
    object.clientAddrCity = response.data.clientAddrCity;
    object.clientAddrCountry = response.data.clientAddrCountry;
    object.clientPesel = response.data.clientPesel;
    object.clientIdType = response.data.clientIdType;
    object.clientIdNumber = response.data.clientIdNumber;
    object.clientPhone = response.data.clientPhone;
    object.clientEmail = response.data.clientEmail;

    object.personName = response.data.personName;
    object.personSurname = response.data.personSurname;
    object.personAddrStreet = response.data.personAddrStreet;
    object.personAddrStreetNumber = response.data.personAddrStreetNumber;
    object.personAddrLocaleNumber = response.data.personAddrLocaleNumber;
    object.personAddrPostcode = response.data.personAddrPostcode;
    object.personAddrCity = response.data.personAddrCity;
    object.personAddrCountry = response.data.personAddrCountry;
    object.personPesel = response.data.personPesel;
    object.personIdType = response.data.personIdType;
    object.personIdNumber = response.data.personIdNumber;
    object.personPhone = response.data.personPhone;
    object.personEmail = response.data.personEmail;

    object.companyName = response.data.companyName;
    object.companyNIP = response.data.companyNIP;
    object.companyKRS = response.data.companyKRS;

    object.propertyIsSeparateOwnership = response.data.propertyIsSeparateOwnership;
    object.propertyIsLand = response.data.propertyIsLand;
    object.propertyIsCooperativeOwnership = response.data.propertyIsCooperativeOwnership;
    object.propertyIsOthers = response.data.propertyIsOthers;
    object.propertyIsOthersText = response.data.propertyIsOthersText;
    object.propertyArea = response.data.propertyArea;
    object.propertyAddrStreet = response.data.propertyAddrStreet;
    object.propertyAddrStreetNumber = response.data.propertyAddrStreetNumber;
    object.propertyAddrLocaleNumber = response.data.propertyAddrLocaleNumber;
    object.propertyAddrPostcode = response.data.propertyAddrPostcode;
    object.propertyAddrCity = response.data.propertyAddrCity;
    object.propertyAddrCountry = response.data.propertyAddrCountry;
    object.landNumber = response.data.landNumber;
    object.mortgageRegister = response.data.mortgageRegister;
    object.offerPrice = response.data.offerPrice;
    object.offerPriceCurrency = response.data.offerPriceCurrency;
    object.obligationShowPurchOffer = response.data.obligationShowPurchOffer;
    object.obligationPresentation = response.data.obligationPresentation;
    object.obligationPhotos = response.data.obligationPhotos;
    object.obligationCollectingDocs = response.data.obligationCollectingDocs;
    object.obligationDescrAndPubl = response.data.obligationDescrAndPubl;
    object.obligationLegalVerif = response.data.obligationLegalVerif;
    object.obligationAdMother = response.data.obligationAdMother;
    object.obligationAdMotherAndOthers = response.data.obligationAdMotherAndOthers;
    object.obligationDescrUpgrade = response.data.obligationDescrUpgrade;
    object.obligationCombTranContact = response.data.obligationCombTranContact;
    object.obligationProtocols = response.data.obligationProtocols;
    object.obligationTransPrepHelp = response.data.obligationTransPrepHelp;
    object.obligationOthers = response.data.obligationOthers;
    object.obligationOthersText = response.data.obligationOthersText;
    object.agentProvisionType = response.data.agentProvisionType;
    object.agentProvisionPercentNet = response.data.agentProvisionPercentNet;
    object.agentProvisionAmountNet = response.data.agentProvisionAmountNet;
    object.clientInvoiceEmail = response.data.clientInvoiceEmail;
    object.agentId = response.data.agentId;
    object.agentSentAt = response.data.agentSentAt;
    object.clientAcceptedAt = response.data.clientAcceptedAt;
    object.status = response.data.status;
    object.sessionKey = response.data.sessionKey;

    return object;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_GetById, error);
  }
};

export const GetAllFiltering = async (filter, retry) => {
  try {
    const response = await axios.get(api.url_IntermediationContract_GetAllFiltering, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: !filter
        ? { qUserId: session.getUserId() }
        : {
            qUserId: session.getUserId(),
            clientName: filter.clientName,
            clientSurname: filter.clientSurname,
            contractDateFrom: filter.contractDateFrom,
            contractDateTo: filter.contractDateTo,
            clientPesel: filter.clientPesel,
            clientPhone: filter.clientPhone,
            status: filter.status,
          },
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 403 && !retry) {
      await Token.isTokenValid();
      return await GetAllFiltering(filter, true);
    } else if (error.response.status === 403) {
      throw new Error("403");
    }

    throw new Error("Error, ".concat(api.url_IntermediationContract_GetAllFiltering, error.message));
  }
};

export const GetByIdUnauthorized = async (objectId, sessionKey) => {
  try {
    const response = await axios.get(api.url_IntermediationContract_GetByIdUnauthorized, {
      params: { id: objectId, sessionKey: sessionKey },
    });

    const object = {};

    object.id = response.data.id;
    //object.createdBy = response.data.createdBy;
    //object.updatedBy = response.data.updatedBy;
    // object.branchId = response.data.branchId;
    //object.number = response.data.number;
    object.contractDate = response.data.contractDate;
    object.contractCity = response.data.contractCity;
    object.variant = response.data.variant;
    object.contractDurationMonth = response.data.contractDurationMonth;
    object.clientType = response.data.clientType;
    object.clientName = response.data.clientName;
    object.clientSurname = response.data.clientSurname;
    object.clientAddrStreet = response.data.clientAddrStreet;
    object.clientAddrStreetNumber = response.data.clientAddrStreetNumber;
    object.clientAddrLocaleNumber = response.data.clientAddrLocaleNumber;
    object.clientAddrPostcode = response.data.clientAddrPostcode;
    object.clientAddrCity = response.data.clientAddrCity;
    object.clientAddrCountry = response.data.clientAddrCountry;
    object.clientPesel = response.data.clientPesel;
    object.clientIdType = response.data.clientIdType;
    object.clientIdNumber = response.data.clientIdNumber;
    object.clientPhone = response.data.clientPhone;
    object.clientEmail = response.data.clientEmail;

    object.personName = response.data.personName;
    object.personSurname = response.data.personSurname;
    object.personPhone = response.data.personPhone;
    object.personEmail = response.data.personEmail;
    object.personAddrStreet = response.data.personAddrStreet;
    object.personAddrStreetNumber = response.data.personAddrStreetNumber;
    object.personAddrLocaleNumber = response.data.personAddrLocaleNumber;
    object.personAddrPostcode = response.data.personAddrPostcode;
    object.personAddrCity = response.data.personAddrCity;
    object.personAddrCountry = response.data.personAddrCountry;
    object.personPesel = response.data.personPesel;
    object.personIdType = response.data.personIdType;
    object.personIdNumber = response.data.personIdNumber;

    object.companyName = response.data.companyName;
    object.companyNIP = response.data.companyNIP;
    object.companyKRS = response.data.companyKRS;

    object.propertyIsSeparateOwnership = response.data.propertyIsSeparateOwnership;
    object.propertyIsLand = response.data.propertyIsLand;
    object.propertyIsCooperativeOwnership = response.data.propertyIsCooperativeOwnership;
    object.propertyIsOthers = response.data.propertyIsOthers;
    object.propertyIsOthersText = response.data.propertyIsOthersText;
    object.propertyArea = response.data.propertyArea;
    object.propertyAddrStreet = response.data.propertyAddrStreet;
    object.propertyAddrStreetNumber = response.data.propertyAddrStreetNumber;
    object.propertyAddrLocaleNumber = response.data.propertyAddrLocaleNumber;
    object.propertyAddrPostcode = response.data.propertyAddrPostcode;
    object.propertyAddrCity = response.data.propertyAddrCity;
    object.propertyAddrCountry = response.data.propertyAddrCountry;
    object.landNumber = response.data.landNumber;
    object.mortgageRegister = response.data.mortgageRegister;
    object.offerPrice = response.data.offerPrice;
    object.offerPriceCurrency = response.data.offerPriceCurrency;
    object.obligationShowPurchOffer = response.data.obligationShowPurchOffer;
    object.obligationPresentation = response.data.obligationPresentation;
    object.obligationPhotos = response.data.obligationPhotos;
    object.obligationCollectingDocs = response.data.obligationCollectingDocs;
    object.obligationDescrAndPubl = response.data.obligationDescrAndPubl;
    object.obligationLegalVerif = response.data.obligationLegalVerif;
    object.obligationAdMother = response.data.obligationAdMother;
    object.obligationAdMotherAndOthers = response.data.obligationAdMotherAndOthers;
    object.obligationDescrUpgrade = response.data.obligationDescrUpgrade;
    object.obligationCombTranContact = response.data.obligationCombTranContact;
    object.obligationProtocols = response.data.obligationProtocols;
    object.obligationTransPrepHelp = response.data.obligationTransPrepHelp;
    object.obligationOthers = response.data.obligationOthers;
    object.obligationOthersText = response.data.obligationOthersText;
    object.agentProvisionType = response.data.agentProvisionType;
    object.agentProvisionPercentNet = response.data.agentProvisionPercentNet;
    object.agentProvisionAmountNet = response.data.agentProvisionAmountNet;

    //object.clientInvoiceEmail = response.data.clientInvoiceEmail;
    //object.agentId = response.data.agentId;
    //object.agentSentAt = response.data.agentSentAt;
    object.clientAcceptedAt = response.data.clientAcceptedAt;
    object.status = response.data.status;
    object.sessionKey = response.data.sessionKey;

    // UserAgent
    object.UserAgent = {};
    object.UserAgent.name = response.data.intermediationContractUserAgent.name;
    object.UserAgent.surname = response.data.intermediationContractUserAgent.surname;
    object.UserAgent.phone = response.data.intermediationContractUserAgent.phone;
    object.UserAgent.email = response.data.intermediationContractUserAgent.email;

    // Branch
    object.Branch = {};
    object.Branch.name = response.data.Branch.name;
    object.Branch.addrStreet = response.data.Branch.addrStreet;
    object.Branch.addrStreetNumber = response.data.Branch.addrStreetNumber;
    object.Branch.addrLocaleNumber = response.data.Branch.addrLocaleNumber;
    object.Branch.addrPostcode = response.data.Branch.addrPostcode;
    object.Branch.addrCity = response.data.Branch.addrCity;
    object.Branch.addrCountry = response.data.Branch.addrCountry;
    object.Branch.email = response.data.Branch.email;
    object.Branch.phone = response.data.Branch.phone;

    return object;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_GetByIdUnauthorized, error);
  }
};

export const UpdateUnauthorized = async (objectId, sessionKey, doc) => {
  try {
    let bodyData = {
      pdfDocument: doc.pdfDocument,
      status: doc.status,
      clientAcceptedAt: doc.clientAcceptedAt,
      clientEmail: doc.clientEmail,
      clientInvoiceEmail: doc.clientInvoiceEmail,
    };

    const response = await axios({
      method: "post",
      url: api.url_IntermediationContract_UpdateUnauthorized,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        id: objectId,
        sessionKey: sessionKey,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_UpdateUnauthorized, error);
  }
};

export const GetByIdAuthorized = async (objectId, sessionKey) => {
  try {
    const response = await axios.get(api.url_IntermediationContract_GetByIdAuthorized, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId, sessionKey: sessionKey },
    });

    const object = {};

    object.id = response.data.id;
    //object.createdBy = response.data.createdBy;
    //object.updatedBy = response.data.updatedBy;
    // object.branchId = response.data.branchId;
    //object.number = response.data.number;
    object.contractDate = response.data.contractDate;
    object.contractCity = response.data.contractCity;
    object.variant = response.data.variant;
    object.contractDurationMonth = response.data.contractDurationMonth;
    object.clientType = response.data.clientType;
    object.clientName = response.data.clientName;
    object.clientSurname = response.data.clientSurname;
    object.clientAddrStreet = response.data.clientAddrStreet;
    object.clientAddrStreetNumber = response.data.clientAddrStreetNumber;
    object.clientAddrLocaleNumber = response.data.clientAddrLocaleNumber;
    object.clientAddrPostcode = response.data.clientAddrPostcode;
    object.clientAddrCity = response.data.clientAddrCity;
    object.clientAddrCountry = response.data.clientAddrCountry;
    object.clientPesel = response.data.clientPesel;
    object.clientIdType = response.data.clientIdType;
    object.clientIdNumber = response.data.clientIdNumber;
    object.clientPhone = response.data.clientPhone;
    object.clientEmail = response.data.clientEmail;
    object.propertyIsSeparateOwnership = response.data.propertyIsSeparateOwnership;
    object.propertyIsLand = response.data.propertyIsLand;
    object.propertyIsCooperativeOwnership = response.data.propertyIsCooperativeOwnership;
    object.propertyIsOthers = response.data.propertyIsOthers;
    object.propertyIsOthersText = response.data.propertyIsOthersText;
    object.propertyArea = response.data.propertyArea;
    object.propertyAddrStreet = response.data.propertyAddrStreet;
    object.propertyAddrStreetNumber = response.data.propertyAddrStreetNumber;
    object.propertyAddrLocaleNumber = response.data.propertyAddrLocaleNumber;
    object.propertyAddrPostcode = response.data.propertyAddrPostcode;
    object.propertyAddrCity = response.data.propertyAddrCity;
    object.propertyAddrCountry = response.data.propertyAddrCountry;
    object.landNumber = response.data.landNumber;
    object.mortgageRegister = response.data.mortgageRegister;
    object.offerPrice = response.data.offerPrice;
    object.offerPriceCurrency = response.data.offerPriceCurrency;
    object.obligationShowPurchOffer = response.data.obligationShowPurchOffer;
    object.obligationPresentation = response.data.obligationPresentation;
    object.obligationPhotos = response.data.obligationPhotos;
    object.obligationCollectingDocs = response.data.obligationCollectingDocs;
    object.obligationDescrAndPubl = response.data.obligationDescrAndPubl;
    object.obligationLegalVerif = response.data.obligationLegalVerif;
    object.obligationAdMother = response.data.obligationAdMother;
    object.obligationAdMotherAndOthers = response.data.obligationAdMotherAndOthers;
    object.obligationDescrUpgrade = response.data.obligationDescrUpgrade;
    object.obligationCombTranContact = response.data.obligationCombTranContact;
    object.obligationProtocols = response.data.obligationProtocols;
    object.obligationTransPrepHelp = response.data.obligationTransPrepHelp;
    object.obligationOthers = response.data.obligationOthers;
    object.obligationOthersText = response.data.obligationOthersText;
    object.agentProvisionType = response.data.agentProvisionType;
    object.agentProvisionPercentNet = response.data.agentProvisionPercentNet;
    object.agentProvisionAmountNet = response.data.agentProvisionAmountNet;

    //object.clientInvoiceEmail = response.data.clientInvoiceEmail;
    //object.agentId = response.data.agentId;
    //object.agentSentAt = response.data.agentSentAt;
    object.clientAcceptedAt = response.data.clientAcceptedAt;
    object.status = response.data.status;
    object.sessionKey = response.data.sessionKey;

    // UserAgent
    object.UserAgent = {};
    object.UserAgent.name = response.data.intermediationContractUserAgent.name;
    object.UserAgent.surname = response.data.intermediationContractUserAgent.surname;
    object.UserAgent.phone = response.data.intermediationContractUserAgent.phone;
    object.UserAgent.email = response.data.intermediationContractUserAgent.email;

    // Branch
    object.Branch = {};
    object.Branch.name = response.data.Branch.name;
    object.Branch.addrStreet = response.data.Branch.addrStreet;
    object.Branch.addrStreetNumber = response.data.Branch.addrStreetNumber;
    object.Branch.addrLocaleNumber = response.data.Branch.addrLocaleNumber;
    object.Branch.addrPostcode = response.data.Branch.addrPostcode;
    object.Branch.addrCity = response.data.Branch.addrCity;
    object.Branch.addrCountry = response.data.Branch.addrCountry;
    object.Branch.email = response.data.Branch.email;
    object.Branch.phone = response.data.Branch.phone;

    // Person
    object.personName = response.data.personName;
    object.personSurname = response.data.personSurname;
    object.personPhone = response.data.personPhone;
    object.personEmail = response.data.personEmail;
    object.personAddrStreet = response.data.personAddrStreet;
    object.personAddrStreetNumber = response.data.personAddrStreetNumber;
    object.personAddrLocaleNumber = response.data.personAddrLocaleNumber;
    object.personAddrPostcode = response.data.personAddrPostcode;
    object.personAddrCity = response.data.personAddrCity;
    object.personAddrCountry = response.data.personAddrCountry;
    object.personPesel = response.data.personPesel;
    object.personIdType = response.data.personIdType;
    object.personIdNumber = response.data.personIdNumber;
    object.companyName = response.data.companyName;
    object.companyNIP = response.data.companyNIP;
    object.companyKRS = response.data.companyKRS;

    return object;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_GetByIdAuthorized, error);
  }
};

export const UpdateAuthorized = async (objectId, sessionKey, doc) => {
  try {
    let bodyData = {
      pdfDocument: doc.pdfDocument,
      status: doc.status,
      clientAcceptedAt: doc.clientAcceptedAt,
      clientEmail: doc.clientEmail,
      clientInvoiceEmail: doc.clientInvoiceEmail,
    };

    const response = await axios({
      method: "post",
      url: api.url_IntermediationContract_UpdateAuthorized,
      headers: {
        "Content-Type": "multipart/form-data",
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
        sessionKey: sessionKey,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_UpdateAuthorized, error);
  }
};

export const GetBlobById = async (objectId) => {
  try {
    const response = await axios({
      method: "get",
      url: api.url_IntermediationContract_GetBlobById,
      params: { id: objectId },
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_GetBlobById, error);
  }
};

export const GetSignatureAgentBlobById = async (objectId) => {
  try {
    const response = await axios({
      method: "get",
      url: api.url_IntermediationContract_GetSignatureAgentBlobById,
      params: { id: objectId },
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_GetSignatureAgentBlobById, error);
  }
};

export const GetStatusById = async (objectId) => {
  try {
    const response = await axios.get(api.url_IntermediationContract_GetStatusById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    let presentationProtocol = {};
    presentationProtocol.status = response.data.status;

    return presentationProtocol;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_GetStatusById, error);
  }
};

export const GetMonitorById = async (objectId) => {
  try {
    const response = await axios.get(api.url_IntermediationContract_MonitorById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    let presentationProtocol = {};
    presentationProtocol.status = response.data.status;
    presentationProtocol.clientEmail = response.data.clientEmail;

    return presentationProtocol;
  } catch (error) {
    console.error("API method error: ", api.url_IntermediationContract_MonitorById, error);
  }
};
