exports.getSaltLoginEncode = (login) => {
  return btoa(btoa(btoa("x1".concat(login, "dD!"))));
};

exports.getSaltSaltDecode = (salt) => {
  let decoded = atob(atob(salt)).slice(5).slice(0, -3);
  return decoded;
};

exports.loginLoginEncode = (login) => {
  return btoa(btoa(btoa(btoa("y22!".concat(login, "d#VD!")))));
};

exports.loginPasswordEncode = (password) => {
  return btoa(btoa(btoa("e2!".concat(password, "f##vAhVD!"))));
};

exports.activeLinkGuidEncode = (value) => {
  return btoa(btoa(btoa("9!x#1GV".concat(value, "8dD!5S#1p"))));
};

exports.activeLinkPinEncode = (value) => {
  return btoa(btoa(btoa(btoa("Th!7$dO3".concat(value, "H7t2#m")))));
};
