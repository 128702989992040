import * as React from "react";
import Paperbase from "../../layout/Admin/Paperbase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Token from "./../../api/Token";
import * as RoutePath from "./../../config/NavigationRoute/RoutePath";
import * as dict from "../../config/Dictionary";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationHelper";

const currentTabIndex = 0;
const handleTabChange = () => {};
const notification = null;

const Contact = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const checkToken = async () => {
    const isTokenValid = await Token.isTokenValid();

    if (!isTokenValid) {
      navigate(RoutePath.Login);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return null;
  }

  const displayContentTab0 = () => {
    return (
      <div
        spacing={2}
        style={{
          textAlign: "center",
          color: "#003c6c",
        }}
      >
        <h1 style={{ margin: 0, padding: 0 }}>ITMarpris Michał Marcinkowski</h1>
        <h3>kontakt@itmarpris.pl</h3>
        <h2 style={{ margin: 0, padding: 0 }}>tel. 609-206-351</h2>
        <br />
        <h3>Profesjonalna obsługa systemów informatycznych</h3>
        <h4>Analiza, projektowanie, programowanie, testowanie, wdrażanie i utrzymanie</h4>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Paperbase
        activepositioncode={dict.PageCodes.contact}
        title={t(formatTranslation("menu", dict.PageCodes.contact))}
        subtitle={"Producent oprogramowania"}
        tabActiveIndex={currentTabIndex}
        onTabChange={(a, b) => handleTabChange(a, b)}
        notification={notification}
        tab0Label={""}
        tab0Title={"Dane kontaktowe"}
        tab0Content={displayContentTab0()}
      />
    </React.Fragment>
  );
};

export default Contact;
