import { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Stack from "@mui/material/Stack";
import * as dict from "../../config/Dictionary";
import * as BranchApi from "./BranchApi";

const BranchForm = (props) => {
  const objectId = props.id;
  const mode = props.mode;
  const isModePreview = props.mode === dict.FormMode.preview ? true : false;
  const isModeEdit = props.mode === dict.FormMode.edit ? true : false;

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [addrStreet, setAddrStreet] = useState("");
  const [addrStreetNumber, setAddrStreetNumber] = useState("");
  const [addrLocaleNumber, setAddrLocaleNumber] = useState("");
  const [addrPostcode, setAddrPostcode] = useState("");
  const [addrCity, setAddrCity] = useState("");
  //const [addrCountry, setAddrCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [hasPhoneError, setHasPhoneError] = useState(false);

  const [email, setEmail] = useState("");
  const [hasEmailError, setHasEmailError] = useState(false);

  const setPresentationProtocolFields = async (branchId) => {
    try {
      const branch = await BranchApi.GetById(branchId);

      setCode(branch.code);
      setName(branch.name);
      setAddrStreet(branch.addrStreet);
      setAddrStreetNumber(branch.addrStreetNumber);
      setAddrLocaleNumber(branch.addrLocaleNumber);
      setAddrPostcode(branch.addrPostcode);
      setAddrCity(branch.addrCity);
      setPhone(branch.phone);
      setEmail(branch.email);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const setPresentationProtocolDedicatedFields = async (presentationProtocolId) => {
    try {
      // const presentationProtocol = await UserApi.GetById(
      //   presentationProtocolId
      // );
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (mode === dict.FormMode.edit) {
      setPresentationProtocolFields(objectId);
    } else if (mode === dict.FormMode.preview) {
      setPresentationProtocolFields(objectId);
      setPresentationProtocolDedicatedFields(objectId);
    } else if (mode === dict.FormMode.add) {
    }

    // eslint-disable-next-line
  }, []);

  const validateFormCorrectness = () => {
    if (!code) {
      props.onNotificationShow("Kod oddziału jest polem wymaganym.", dict.NotificationType.error);
      return -1;
    }

    if (!name) {
      props.onNotificationShow("Kod oddziału jest polem wymaganym.", dict.NotificationType.error);
      return -1;
    }

    if (!phoneValidator(phone)) {
      props.onNotificationShow("Numer telefonu posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!emailValidator(email)) {
      props.onNotificationShow("E-mail posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }
  };

  const getCurrentFormData = (formMode) => {
    let data = {
      code: code,
      name: name,
      addrStreet: addrStreet,
      addrStreetNumber: addrStreetNumber,
      addrLocaleNumber: addrLocaleNumber,
      addrPostcode: addrPostcode,
      addrCity: addrCity,
      phone: phone,
      email: email,
    };

    if (formMode === dict.FormMode.add) {
      data = {
        ...data,
        //status: dict.DocStatus.new,
      };
    } else if (formMode === dict.FormMode.edit) {
      data = {
        ...data,
      };
    }

    return data;
  };

  const saveForAddMode = async () => {
    // eslint-disable-next-line
    let objId = await BranchApi.Create(getCurrentFormData(dict.FormMode.add));
    return objId;
  };

  const saveForEditMode = async () => {
    await BranchApi.Update(objectId, getCurrentFormData(dict.FormMode.edit));
    return objectId;
  };

  const handleSaveClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormCorrectness();
      if (result === -1) {
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveForAddMode();
      } else if (mode === dict.FormMode.edit) {
        objId = await saveForEditMode();
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd: ".concat(error.message), dict.NotificationType.error);
    }
  };

  const handleCancelClick = () => {
    if (props.onAfterCancel) {
      props.onAfterCancel(mode);
    }
  };

  const handleBackClick = () => {
    if (props.onAfterBack) {
      props.onAfterBack(mode);
    }
  };

  const phoneValidator = (phone) => {
    if (!phone) return true;
    const regex = /^\d{9}$|^[+]{1}\d{11,}$/;
    return regex.test(phone);
  };

  const emailValidator = (email) => {
    if (!email) return true;
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  return (
    <Stack>
      <FormGroup
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          maxWidth: 1200,
          mb: 2,
        }}
      >
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 1, pb: 1 }}>
          {"Identyfikacja"}
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Kod"}
            id={"code"}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            disabled={isModePreview || isModeEdit}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Nazwa"}
            id={"name"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
          {"Adres"}
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Ulica"}
            id={"addrStreet"}
            value={addrStreet}
            onChange={(e) => setAddrStreet(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Numer budynku"}
            id={"addrStreetNumber"}
            value={addrStreetNumber}
            onChange={(e) => setAddrStreetNumber(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "50%" }}
            required={true}
          />
          <TextField
            size="small"
            label={"Numer lokalu"}
            id={"addrLocaleNumber"}
            value={addrLocaleNumber}
            onChange={(e) => setAddrLocaleNumber(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "50%" }}
            required={false}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Kod pocztowy"}
            id={"addrPostcode"}
            value={addrPostcode}
            onChange={(e) => setAddrPostcode(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "30%" }}
            required={false}
          />
          <TextField
            size="small"
            label={"Miejscowość"}
            id={"addrCity"}
            value={addrCity}
            onChange={(e) => setAddrCity(e.target.value)}
            disabled={isModePreview}
            sx={{ pb: 2, width: "70%" }}
            required={true}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
          {"Kontakt"}
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"E-mail"}
            id={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              emailValidator(e.target.value) ? setHasEmailError(false) : setHasEmailError(true);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={hasEmailError}
            helperText="Prawidłowy format: nazwa@dome.na"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Telefon"}
            id={"phone"}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              phoneValidator(e.target.value) ? setHasPhoneError(false) : setHasPhoneError(true);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={hasPhoneError}
            helperText="Prawidłowy format: 123456789 lub +48123456789"
          />
        </Stack>
      </FormGroup>

      <Stack direction="row" spacing={2}>
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<SaveOutlinedIcon />} onClick={handleSaveClick}>
            {"Zapisz"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<CancelOutlinedIcon />} onClick={handleCancelClick}>
            {"Wyjdź"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? true : false) && (
          <Button variant="contained" size="small" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackClick}>
            {"Wstecz"}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default BranchForm;
