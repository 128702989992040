import { useState, useEffect } from "react";
import { Stack, Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import * as ContractPurchaseBinaryApi from "./ContractPurchaseBinaryApi";
import Resizer from "react-image-file-resizer";
import FileList from "./FileList";
import * as dict from "../../../config/Dictionary";

const ContractPurchaseBinary = (props) => {
  const docId = props.docId;
  const mode = props.mode;

  useEffect(() => {
    return () => {};
    // eslint-disable-next-line
  }, []);

  // Attachment
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  // const [attachmentInfo, setAttachmentInfo] = useState("");
  const [reloadList, setReloadList] = useState("");

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  function base64ToBlob(base64, contentType = "", sliceSize = 512) {
    // Usuń nagłówki, jeśli base64 zaczyna się od "data:image/png;base64," itd.
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  const handleFileChange = async (event) => {
    if (mode !== dict.FormMode.edit) {
      props.onNotificationShow("Dodawanie załączników wymaga wcześniejszego zapisu umowy.", dict.NotificationType.error);
      return -1;
    }

    const files = Array.from(event.target.files);
    // let filesOmmited = 0;
    // let filesImported = 0;

    //let fileNames = "";

    for (let i = 0; i < files.length; i++) {
      if (!files[i].type.startsWith("image/")) {
        //filesOmmited++;
        continue;
      }
      //filesImported++;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const blob = new Blob([arrayBuffer], { type: files[i].type });
        const image = await resizeFile(blob);
        const blob2 = base64ToBlob(image, { type: files[i].type });

        let x = await ContractPurchaseBinaryApi.Create({
          data: blob2,
          fileName: files[i].name,
          mimeType: files[i].type,
          label: "",
          docId: docId,
        });

        setReloadList(x);
      };

      reader.readAsArrayBuffer(files[i]);
      // setAttachmentInfo("Ilość dodanych plików: [" + files.length + "].");
    }
  };

  return (
    <Stack>
      <Stack direction="column" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
        {"Załączniki"}

        <Typography variant="subtitle2" color="secondary" sx={{ paddingTop: "5px" }}>
          {"Tylko pliki graficzne / zdjęcia"}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          disabled={mode !== dict.FormMode.edit ? true : false}
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          {"Dodaj pliki"}
          <VisuallyHiddenInput type="file" multiple="multiple" onChange={handleFileChange} />
        </Button>
        {/* {attachmentInfo && <p>{attachmentInfo}</p>} */}
      </Stack>
      <FileList docId={docId} reload={reloadList} />
    </Stack>
  );
};

export default ContractPurchaseBinary;
