const DocumentForm = (props) => {
  const styles = {
    page: {
      fontFamily: "Roboto",
      paddingTop: "5px",
      marginLeft: "15px",
      fontSize: "12px",
      marginRight: "20px",
      paddingBottom: "30px",
    },

    title: {
      paddingBottom: "10px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "13px",
    },
    parameter: {
      fontWeight: "bold",
      color: "#163058",
    },
    parameterLack: {
      fontWeight: "bold",
      //color: "red",
      color: "#163058",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tr: {
      textAlign: "left",
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "500%",
    },
    td: {
      border: "1px solid black",
      height: "40px",
      paddingLeft: "10px",
    },
  };

  const withPresentation = props.withPresentation;

  const loadData = (value) => {
    if (!value) {
      return <span style={styles.parameterLack}>{" - "}</span>;
    }
    return <span style={styles.parameter}>{value}</span>;
  };

  return (
    <div style={styles.page}>
      <p style={styles.title}>Załącznik Nr 5 - Protokół z prezentacji nieruchomości</p>
      <p>Imię i nazwisko Klienta: {withPresentation ? loadData(props.clientName + " " + props.clientSurname) : ""}</p>
      <p>Imię i nazwisko Pośrednika: {withPresentation ? loadData(props.agentName + " " + props.agentSurname) : ""}</p>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.td}>Data</th>
            <th style={styles.td}>Godzina</th>
            <th style={styles.td}>Adres nieruchomości</th>
          </tr>
        </thead>
        <tbody style={styles.tr}>
          <tr>
            <td style={styles.td}>{withPresentation ? loadData(props.contractDate) : " "}</td>
            <td style={styles.td}>{withPresentation ? loadData(props.contractHour) : " "}</td>
            <td style={styles.td}>{withPresentation ? loadData(props.visitAddress) : " "}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DocumentForm;
