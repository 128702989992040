import { useState, useEffect, useRef, createRef } from "react";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "../../config/NavigationRoute/RoutePath";
import * as session from "../../components/SessionStorage/SessionStorage";
import {
  Stack,
  FormGroup,
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import {
  SaveOutlined as SaveOutlinedIcon,
  CancelOutlined as CancelOutlinedIcon,
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  SendToMobileOutlined as SendToMobileOutlinedIcon,
  BorderColorOutlined,
  SearchOutlined as SearchOutlinedIcon,
  PersonAddAlt1Outlined as PersonAddAlt1OutlinedIcon,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import * as dict from "../../config/Dictionary";
import * as UserApi from "../User/UserApi";
import * as BranchApi from "../Branch/BranchApi";
import * as SMSApi from "../../components/SMS/SMSApi";
import * as ActiveLinkAPI from "../ActiveLink/ActiveLinkApi";
import * as IntermediationContractApi from "./IntermediationContractApi";
import * as IntermediationContractPersonApi from "./../IntermediationContract/IntermediationContractPerson/IntermediationContractPersonApi";
import { PDFViewer } from "@react-pdf/renderer";
import IntermediationContractPDF from "../../printing/IntermediationContract";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { phoneValidator, emailValidator, phoneAsPin, setFileName, returnFormattedValue, convertBlobToBase64 } from "./../../utils/validator";
import DrawingCanvas from "./../../components/DrawingCanvas/DrawingCanvas";
import IntermediationContractMonitor from "./IntermediationContractMonitor";
import Coowner from "./../IntermediationContract/IntermediationContractPerson/IntermediationContractPerson";
import ClientPerson from "./Form/ClientPerson";
import ClientBusinessIndividual from "./Form/ClientBusinessIndividual";
import ClientCompany from "./Form/ClientCompany";
import IntermediationContractBinary from "./IntermediationContractBinary/IntermediationContractBinary";
import * as IntermediationContractBinaryApi from "./../IntermediationContract/IntermediationContractBinary/IntermediationContractBinaryApi";

const IntermediationContractForm = (props) => {
  const navigate = useNavigate();

  const isSm = useMediaQuery("(min-width:600px)");

  const debug = false;
  const isAfterFirstRender = useRef(false);
  const autoSaveLastDate = useRef(0);

  const objectId = props.id;
  const mode = props.mode;
  const isModePreview = props.mode === dict.FormMode.preview ? true : false;

  // Part I
  // Form fields
  const [contractDate, setContractDate] = useState(dayjs(new Date().toUTCString()));
  const [contractCity, setContractCity] = useState("");
  const [number, setNumber] = useState("");
  const [variant, setVariant] = useState(dict.IntermediationContractVariantCombo[0].code);
  const [contractDurationMonth, setContractDurationMonth] = useState(12);
  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [clientAddrStreet, setClientAddrStreet] = useState("");
  const [clientAddrStreetNumber, setClientAddrStreetNumber] = useState("");
  const [clientAddrLocaleNumber, setClientAddrLocaleNumber] = useState("");
  const [clientAddrPostcode, setClientAddrPostcode] = useState("");
  const [clientAddrCity, setClientAddrCity] = useState("");
  const [clientAddrCountry, setClientAddrCountry] = useState("Polska");
  const [clientPesel, setClientPesel] = useState("");
  const [clientIdType, setClientIdType] = useState("");
  const [clientIdNumber, setClientIdNumber] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [propertyIsSeparateOwnership, setPropertyIsSeparateOwnership] = useState(false);
  const [propertyIsLand, setPropertyIsLand] = useState(false);
  const [propertyIsCooperativeOwnership, setpropertyIsCooperativeOwnership] = useState(false);
  const [propertyIsOthers, setPropertyIsOthers] = useState(false);
  const [propertyIsOthersText, setPropertyIsOthersText] = useState("");
  const [propertyArea, setPropertyArea] = useState(dict.DefaultNumber);
  const [propertyAddrStreet, setPropertyAddrStreet] = useState("");
  const [propertyAddrStreetNumber, setPropertyAddrStreetNumber] = useState("");
  const [propertyAddrLocaleNumber, setPropertyAddrLocaleNumber] = useState("");
  const [propertyAddrPostcode, setPropertyAddrPostcode] = useState("");
  const [propertyAddrCity, setPropertyAddrCity] = useState("");
  const [propertyAddrCountry, setPropertyAddrCountry] = useState("");
  const [landNumber, setLandNumber] = useState("");
  const [mortgageRegister, setMortgageRegister] = useState("");
  const [offerPrice, setOfferPrice] = useState(dict.DefaultNumber);
  const [offerPriceCurrency, setOfferPriceCurrency] = useState(dict.Currency[0].code);
  const [obligationShowPurchOffer, setObligationShowPurchOffer] = useState(true);
  const [obligationPresentation, setObligationPresentation] = useState(true);
  const [obligationPhotos, setObligationPhotos] = useState(true);
  const [obligationCollectingDocs, setObligationCollectingDocs] = useState(true);
  const [obligationDescrAndPubl, setObligationDescrAndPubl] = useState(true);
  const [obligationLegalVerif, setObligationLegalVerif] = useState(true);
  const [obligationAdMother, setObligationAdMother] = useState(true);
  const [obligationAdMotherAndOthers, setObligationAdMotherAndOthers] = useState(true);
  const [obligationDescrUpgrade, setObligationDescrUpgrade] = useState(true);
  const [obligationCombTranContact, setObligationCombTranContact] = useState(true);
  const [obligationProtocols, setObligationProtocols] = useState(true);
  const [obligationTransPrepHelp, setObligationTransPrepHelp] = useState(true);
  const [obligationOthers, setObligationOthers] = useState(false);
  const [obligationOthersText, setObligationOthersText] = useState("");
  const [agentProvisionType, setAgentProvisionType] = useState(dict.AgentProvisionType.percent);
  const [agentProvisionPercentNet, setAgentProvisionPercentNet] = useState(3);
  const [agentProvisionAmountNet, setAgentProvisionAmountNet] = useState(dict.DefaultNumber);
  const [clientInvoiceEmail, setClientInvoiceEmail] = useState("");

  const [agentSentAt, setAgentSentAt] = useState(dayjs(dict.DefaultDate.toUTCString()));
  const [clientAcceptedAt, setClientAcceptedAt] = useState(dayjs(dict.DefaultDate.toUTCString()));
  const [status, setStatus] = useState(0);
  const [sessionKey, setSessionKey] = useState("");

  const [clientType, setClientType] = useState(dict.ClientType.person);
  const [companyName, setCompanyName] = useState("");
  const [companyNIP, setCompanyNIP] = useState("");
  const [companyKRS, setCompanyKRS] = useState("");
  const [personName, setPersonName] = useState("");
  const [personSurname, setPersonSurname] = useState("");
  const [personAddrStreet, setPersonAddrStreet] = useState("");
  const [personAddrStreetNumber, setPersonAddrStreetNumber] = useState("");
  const [personAddrLocaleNumber, setPersonAddrLocaleNumber] = useState("");
  const [personAddrPostcode, setPersonAddrPostcode] = useState("");
  const [personAddrCity, setPersonAddrCity] = useState("");
  const [personAddrCountry, setPersonAddrCountry] = useState("Polska");
  const [personPesel, setPersonPesel] = useState("");
  const [personIdType, setPersonIdType] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");
  const [personPhone, setPersonPhone] = useState("");
  const [personEmail, setPersonEmail] = useState("");

  // Combo fields
  const [clientIdTypeCombo, setClientIdTypeCombo] = useState(dict.IdentityDocumentType[0]);
  const [offerPriceCurrencyCombo, setOfferPriceCurrencyCombo] = useState(dict.Currency[0]);
  const [variantCombo, setVariantCombo] = useState(dict.IntermediationContractVariantCombo[0]);
  const [personIdTypeCombo, setPersonIdTypeCombo] = useState(dict.IdentityDocumentType[0]);

  // Error fields
  const [hasErrorContractDate, setHasErrorContractDate] = useState(false);
  const [hasErrorContractCity, setHasErrorContractCity] = useState(false);
  const [hasErrorVariant, setHasErrorVariant] = useState(false);
  const [hasErrorContractDurationMonth, setHasErrorContractDurationMonth] = useState(false);
  const [hasErrorClientName, setHasErrorClientName] = useState(false);
  const [hasErrorClientSurname, setHasErrorClientSurname] = useState(false);
  const [hasErrorClientPhone, setHasErrorClientPhone] = useState(false);
  const [hasErrorClientEmail, setHasErrorClientEmail] = useState(false);
  const [hasErrorClientAddrCountry, setHasErrorClientAddrCountry] = useState(false);
  const [hasErrorClientPesel, setHasErrorClientPesel] = useState(false);
  const [hasErrorClientIdType, setHasErrorClientIdType] = useState(false);
  const [hasErrorClientIdNumber, setHasErrorClientIdNumber] = useState(false);
  const [hasErrorClientAddrStreet, setHasErrorClientAddrStreet] = useState(false);
  const [hasErrorClientAddrStreetNumber, setHasErrorClientAddrStreetNumber] = useState(false);
  const [hasErrorClientAddrCity, setHasErrorClientAddrCity] = useState(false);

  const [hasErrorPropertyArea, setHasErrorPropertyArea] = useState(false);
  const [hasErrorPropertyAddrStreet, setHasErrorPropertyAddrStreet] = useState(false);
  const [hasErrorPropertyAddrStreetNumber, setHasErrorPropertyAddrStreetNumber] = useState(false);
  const [hasErrorPropertyAddrCity, setHasErrorPropertyAddrCity] = useState(false);
  const [hasErrorOfferPrice, setHasErrorOfferPrice] = useState(false);
  const [hasErrorAgentProvisionPercentNet, setHasErrorAgentProvisionPercentNet] = useState(false);
  const [hasErrorAgentProvisionAmountNet, setHasErrorAgentProvisionAmountNet] = useState(false);
  const [hasErrorPropertyIsOthersText, setHasErrorPropertyIsOthersText] = useState(false);
  const [hasErrorObligationOthersText, setHasErrorObligationOthersText] = useState(false);
  const [hasErrorPropertyIsSection, setHasErrorPropertyIsSection] = useState(false);
  const [hasErrorSectionClient, setHasErrorSectionClient] = useState(false);

  const [hasErrorCompanyName, setHasErrorCompanyName] = useState(false);
  const [hasErrorCompanyNIP, setHasErrorCompanyNIP] = useState(false);
  const [hasErrorCompanyKRS, setHasErrorCompanyKRS] = useState(false);
  const [hasErrorRepresentativeName, setHasErrorRepresentativeName] = useState(false);
  const [hasErrorRepresentativeSurname, setHasErrorRepresentativeSurname] = useState(false);
  const [hasErrorRepresentativeAddrStreet, setHasErrorRepresentativeAddrStreet] = useState(false);
  const [hasErrorRepresentativeAddrStreetNumber, setHasErrorRepresentativeAddrStreetNumber] = useState(false);
  const [hasErrorRepresentativeAddrCity, setHasErrorRepresentativeAddrCity] = useState(false);
  const [hasErrorRepresentativeAddrCountry, setHasErrorRepresentativeAddrCountry] = useState(false);
  const [hasErrorRepresentativePesel, setHasErrorRepresentativePesel] = useState(false);
  const [hasErrorRepresentativeIdType, setHasErrorRepresentativeIdType] = useState(false);
  const [hasErrorRepresentativeIdNumber, setHasErrorRepresentativeIdNumber] = useState(false);
  const [hasErrorRepresentativePhone, setHasErrorRepresentativePhone] = useState(false);
  const [hasErrorRepresentativeEmail, setHasErrorRepresentativeEmail] = useState(false);
  const [hasErrorSectionPerson, setHasErrorSectionPerson] = useState(false);

  // View state
  const [hideSendToClientButton, setHideSendToClientButton] = useState(false);

  // Coowners data
  const [coowners, setCoowners] = useState([]);

  // Signature
  const [isSignatureAgentVisible, setIsSignatureAgentVisible] = useState(false);
  const signatureAgent = useRef(-1);
  const [checkCounterAgent, setCheckCounterAgent] = useState(0);

  // Part II
  const setFieldsDefault = async () => {
    let agent = await UserApi.GetById(session.getUserId());
    let branch = await BranchApi.GetById(agent.branchId);

    if (branch && branch.addrCity) {
      setContractCity(branch.addrCity);
    }
  };

  const refreshCoowners = async () => {
    const object = await IntermediationContractPersonApi.GetByDocId(objectId);

    let data = [];
    object.forEach((e) => {
      data.push({ id: e.id, myRef: createRef() });
    });
    setCoowners(data);
  };

  const setFields = async (objId) => {
    try {
      const object = await IntermediationContractApi.GetById(objId);

      setNumber(object.number);
      setVariant(object.variant);
      if (object.variant) {
        setVariantCombo(dict.IntermediationContractVariantCombo.filter((f) => f.code === object.variant)[0]);
      }
      setContractDurationMonth(object.contractDurationMonth);
      setContractDate(dayjs(object.contractDate));
      setContractCity(object.contractCity);
      setClientName(object.clientName);
      setClientSurname(object.clientSurname);
      setClientAddrStreet(object.clientAddrStreet);
      setClientAddrStreetNumber(object.clientAddrStreetNumber);
      setClientAddrLocaleNumber(object.clientAddrLocaleNumber);
      setClientAddrPostcode(object.clientAddrPostcode);
      setClientAddrCity(object.clientAddrCity);
      setClientAddrCountry(object.clientAddrCountry);
      setClientPesel(object.clientPesel);
      setClientIdType(object.clientIdType);
      if (object.clientIdType) {
        setClientIdTypeCombo(dict.IdentityDocumentType.filter((f) => f.code === object.clientIdType)[0]);
      }
      setClientIdNumber(object.clientIdNumber);
      setClientPhone(object.clientPhone);
      setClientEmail(object.clientEmail);
      setPropertyIsSeparateOwnership(object.propertyIsSeparateOwnership);
      setPropertyIsLand(object.propertyIsLand);
      setpropertyIsCooperativeOwnership(object.propertyIsCooperativeOwnership);
      setPropertyIsOthers(object.propertyIsOthers);
      setPropertyIsOthersText(object.propertyIsOthersText);
      setPropertyArea(object.propertyArea);
      setPropertyAddrStreet(object.propertyAddrStreet);
      setPropertyAddrStreetNumber(object.propertyAddrStreetNumber);
      setPropertyAddrLocaleNumber(object.propertyAddrLocaleNumber);
      setPropertyAddrPostcode(object.propertyAddrPostcode);
      setPropertyAddrCity(object.propertyAddrCity);
      setPropertyAddrCountry(object.propertyAddrCountry);
      setLandNumber(object.landNumber);
      setMortgageRegister(object.mortgageRegister);
      setOfferPrice(object.offerPrice);
      setOfferPriceCurrency(object.offerPriceCurrency);
      if (object.offerPriceCurrency) {
        setOfferPriceCurrencyCombo(dict.Currency.filter((f) => f.code === object.offerPriceCurrency)[0]);
      }
      setObligationShowPurchOffer(object.obligationShowPurchOffer);
      setObligationPresentation(object.obligationPresentation);
      setObligationPhotos(object.obligationPhotos);
      setObligationCollectingDocs(object.obligationCollectingDocs);
      setObligationDescrAndPubl(object.obligationDescrAndPubl);
      setObligationLegalVerif(object.obligationLegalVerif);
      setObligationAdMother(object.obligationAdMother);
      setObligationAdMotherAndOthers(object.obligationAdMotherAndOthers);
      setObligationDescrUpgrade(object.obligationDescrUpgrade);
      setObligationCombTranContact(object.obligationCombTranContact);
      setObligationProtocols(object.obligationProtocols);
      setObligationTransPrepHelp(object.obligationTransPrepHelp);
      setObligationOthers(object.obligationOthers);
      setObligationOthersText(object.obligationOthersText);
      setAgentProvisionType(object.agentProvisionType ? object.agentProvisionType : dict.AgentProvisionType.percent);
      setAgentProvisionPercentNet(object.agentProvisionPercentNet);
      setAgentProvisionAmountNet(object.agentProvisionAmountNet);
      setClientInvoiceEmail(object.clientInvoiceEmail);
      setStatus(object.status);
      setSessionKey(object.sessionKey);

      setClientType(object.clientType);
      setCompanyName(object.companyName);
      setCompanyNIP(object.companyNIP);
      setCompanyKRS(object.companyKRS);
      setPersonName(object.personName);
      setPersonSurname(object.personSurname);
      setPersonAddrStreet(object.personAddrStreet);
      setPersonAddrStreetNumber(object.personAddrStreetNumber);
      setPersonAddrLocaleNumber(object.personAddrLocaleNumber);
      setPersonAddrPostcode(object.personAddrPostcode);
      setPersonAddrCity(object.personAddrCity);
      setPersonAddrCountry(object.personAddrCountry);
      setPersonPesel(object.personPesel);
      setPersonIdType(object.personIdType);
      setPersonIdNumber(object.personIdNumber);
      setPersonPhone(object.personPhone);
      setPersonEmail(object.personEmail);

      const signatureAgentBlob = await IntermediationContractApi.GetSignatureAgentBlobById(objId);

      if (!(signatureAgentBlob || signatureAgentBlob.size)) {
        //let blob = new Blob([signatureAgentBlob], { type: "image/png" });
        signatureAgent.current = await convertBlobToBase64(signatureAgentBlob);
        setIsSignatureAgentVisible(true);
      }

      // let response = await IntermediationContractApi.GetSignatureAgentBlobById(
      //   objId
      // );
      // let blob = new Blob([response], { type: "image/png" });
      // let link = document.createElement("a");

      // link.download = "testttt.jpg";
      // link.href = URL.createObjectURL(blob);
      // link.click();

      // URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error: ", error);
    }

    try {
      await refreshCoowners();
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const setFieldsModePreview = async (objId) => {
    try {
      const object = await IntermediationContractApi.GetById(objId);

      // dedykowana logika
      setClientEmail(object.clientEmail);
      if (object.clientAcceptedAt) {
        setClientAcceptedAt(dayjs(object.clientAcceptedAt));
      }
      if (object.agentSentAt) {
        setAgentSentAt(dayjs(object.agentSentAt));
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (mode === dict.FormMode.edit) {
      setFields(objectId);
      isAfterFirstRender.current = true;
    } else if (mode === dict.FormMode.preview) {
      setFields(objectId);
      setFieldsModePreview(objectId);
    } else if (mode === dict.FormMode.add) {
      setFieldsDefault();
      isAfterFirstRender.current = true;
    }

    // eslint-disable-next-line
  }, []);

  // Part III
  useEffect(() => {
    let runAutosave = false;

    if (mode === dict.FormMode.edit) {
      runAutosave = true;
    } else if (mode === dict.FormMode.preview) {
    } else if (mode === dict.FormMode.add) {
      runAutosave = true;
    }

    if (runAutosave && isAfterFirstRender.current) {
      autosave();
    }

    if (mode !== dict.FormMode.preview) {
      setHasErrorVariant(false);
      setHasErrorContractDurationMonth(false);
      setHasErrorContractDate(false);
      setHasErrorContractCity(false);
      setHasErrorClientName(false);
      setHasErrorClientSurname(false);
      //setHasErrorClientPhone(false);
      setHasErrorClientAddrCountry(false);
      setHasErrorClientPesel(false);
      setHasErrorClientIdType(false);
      setHasErrorClientIdNumber(false);
      setHasErrorClientAddrStreet(false);
      setHasErrorClientAddrStreetNumber(false);
      setHasErrorClientAddrCity(false);
      setHasErrorPropertyArea(false);
      setHasErrorPropertyAddrStreet(false);
      setHasErrorPropertyAddrStreetNumber(false);
      setHasErrorPropertyAddrCity(false);
      setHasErrorOfferPrice(false);
      setHasErrorAgentProvisionPercentNet(false);
      setHasErrorAgentProvisionAmountNet(false);
      setHasErrorPropertyIsOthersText(false);
      setHasErrorObligationOthersText(false);
      setHasErrorPropertyIsSection(false);
      setHasErrorSectionClient(false);

      setHasErrorCompanyName(false);
      setHasErrorCompanyNIP(false);
      setHasErrorCompanyKRS(false);
      setHasErrorRepresentativeName(false);
      setHasErrorRepresentativeSurname(false);
      setHasErrorRepresentativeAddrStreet(false);
      setHasErrorRepresentativeAddrStreetNumber(false);
      setHasErrorRepresentativeAddrCity(false);
      setHasErrorRepresentativeAddrCountry(false);
      setHasErrorRepresentativePesel(false);
      setHasErrorRepresentativeIdType(false);
      setHasErrorRepresentativeIdNumber(false);
      //setHasErrorRepresentativePhone(false);
      //setHasErrorRepresentativeEmail(false);
      setHasErrorSectionPerson(false);
    }
    // eslint-disable-next-line
  }, [
    number,
    variant,
    contractDurationMonth,
    contractDate,
    contractCity,
    clientName,
    clientSurname,
    clientAddrStreet,
    clientAddrStreetNumber,
    clientAddrLocaleNumber,
    clientAddrPostcode,
    clientAddrCity,
    clientAddrCountry,
    clientPhone,
    clientPesel,
    clientIdType,
    clientIdNumber,
    clientEmail,
    propertyIsSeparateOwnership,
    propertyIsLand,
    propertyIsCooperativeOwnership,
    propertyIsOthers,
    propertyIsOthersText,

    propertyArea,
    propertyAddrStreet,
    propertyAddrStreetNumber,
    propertyAddrLocaleNumber,
    propertyAddrPostcode,
    propertyAddrCity,
    propertyAddrCountry,
    landNumber,
    mortgageRegister,
    offerPrice,
    offerPriceCurrency,

    obligationShowPurchOffer,
    obligationPresentation,
    obligationPhotos,
    obligationCollectingDocs,
    obligationDescrAndPubl,
    obligationLegalVerif,
    obligationAdMother,
    obligationAdMotherAndOthers,
    obligationDescrUpgrade,
    obligationCombTranContact,
    obligationProtocols,
    obligationTransPrepHelp,
    obligationOthers,
    obligationOthersText,
    agentProvisionType,
    agentProvisionPercentNet,
    agentProvisionPercentNet,
    clientInvoiceEmail,
    //status,
    clientType,
    companyName,
    companyNIP,
    companyKRS,
    personName,
    personSurname,
    personAddrStreet,
    personAddrStreetNumber,
    personAddrLocaleNumber,
    personAddrPostcode,
    personAddrCity,
    personAddrCountry,
    personPesel,
    personIdType,
    personIdNumber,
    personPhone,
    personEmail,
  ]);

  const autosave = async () => {
    if (autoSaveLastDate.current === 0) {
      autoSaveLastDate.current = Date.now();
    }

    let currentTimestamp = Date.now();
    let lastSaveTimestamp = autoSaveLastDate.current;

    let interval = currentTimestamp - lastSaveTimestamp;
    //console.log("bbbbbbbbbbb", currentTimestamp, lastSaveTimestamp, interval);
    if (interval <= 30000) {
      //console.log("Mniej niż 15 sekund", interval);
      //console.log("kuku");
      return 0;
    }

    if (objectId) {
      // tryb edycji
      // const intermediationContract =
      //   await IntermediationContract.GetMonitorById(objectId);

      if (status === dict.DocStatus.accepted) {
        return 0;
      } else {
        //console.log("autosave RUN - editMode ", new Date().toUTCString());
        autoSaveLastDate.current = Date.now();
        handleSaveClick();
      }
    } else {
      // tryb dodawania
      //console.log("autosave RUN - addMode", new Date().toUTCString());
      autoSaveLastDate.current = Date.now();
      handleSaveClick();
    }
  };

  // Part IV
  const validateFormButtonSave = () => {
    if (clientType === dict.ClientType.person && !clientName && !clientSurname && !clientPhone && !clientIdNumber) {
      setHasErrorSectionClient(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (clientType === dict.ClientType.personWithRepresentative && !personName && !personSurname && !personPhone && !personIdNumber) {
      setHasErrorSectionPerson(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (clientType === dict.ClientType.businessIndividual && !companyName && !companyNIP && !clientName && !clientSurname && !clientPhone) {
      setHasErrorSectionClient(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (
      clientType === dict.ClientType.businessIndividualWithRepresentative &&
      !companyName &&
      !companyNIP &&
      !personName &&
      !personSurname &&
      !personPhone
    ) {
      setHasErrorSectionPerson(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (
      clientType === dict.ClientType.company &&
      !companyName &&
      !companyNIP &&
      !companyKRS &&
      !clientName &&
      !clientSurname &&
      !clientPhone
    ) {
      setHasErrorSectionClient(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    } else if (
      clientType === dict.ClientType.companyWithRepresentative &&
      !companyName &&
      !companyNIP &&
      !companyKRS &&
      !personName &&
      !personSurname &&
      !personPhone
    ) {
      setHasErrorSectionPerson(true);
      props.onNotificationShow(
        "Wymagane jest uzupełnienie minimum jednego pola obowiązkowego, identyfikującego klienta.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (
      clientType === dict.ClientType.person ||
      clientType === dict.ClientType.businessIndividual ||
      clientType === dict.ClientType.businessIndividual
    ) {
      if (!phoneValidator(clientPhone)) {
        props.onNotificationShow("Numer telefonu klienta posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
        return -1;
      }
    }

    if (
      clientType === dict.ClientType.personWithRepresentative ||
      clientType === dict.ClientType.businessIndividualWithRepresentative ||
      clientType === dict.ClientType.businessIndividualWithRepresentative
    ) {
      if (!phoneValidator(personPhone)) {
        props.onNotificationShow("Numer telefonu klienta posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
        return -1;
      }

      if (!phoneValidator(clientPhone)) {
        props.onNotificationShow("Numer telefonu pełnomocnika posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
        return -1;
      }
    }
  };

  const validateClientPerson = (
    clientName,
    clientSurname,
    clientPhone,
    clientAddrCountry,
    clientPesel,
    clientIdType,
    clientIdNumber,
    clientAddrStreet,
    clientAddrStreetNumber,
    clientAddrCity
  ) => {
    let stakeholder = "klienta";

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrCountry) {
      setHasErrorClientAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
      setHasErrorClientPesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(clientIdType && clientIdNumber)) {
      if (!clientIdType) {
        setHasErrorClientIdType(true);
      }

      if (!clientIdNumber) {
        setHasErrorClientIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateRepresentative = () => {
    let stakeholder = "pełnomocnika";

    if (!clientName) {
      setHasErrorRepresentativeName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorRepresentativeSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorRepresentativePhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrCountry) {
      setHasErrorRepresentativeAddrCountry(true);
      props.onNotificationShow("Kraj obywatelstwa " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }
    if (clientAddrCountry.toUpperCase().trim() === "POLSKA" && !clientPesel) {
      setHasErrorRepresentativePesel(true);
      props.onNotificationShow("Pesel " + stakeholder + " jest wymagany. Uzupełnij dane.", dict.NotificationType.error);
      return -1;
    }

    if (!(clientIdType && clientIdNumber)) {
      if (!clientIdType) {
        setHasErrorRepresentativeIdType(true);
      }
      if (!clientIdNumber) {
        setHasErrorRepresentativeIdNumber(true);
      }
      props.onNotificationShow(
        "Rodzaj i numer dokumentu identyfikacji " + stakeholder + " są wymagane. Uzupełnij dane.",
        dict.NotificationType.error
      );
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorRepresentativeAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorRepresentativeAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorRepresentativeAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateClientBusinessIndividual = (
    companyName,
    companyNIP,
    clientName,
    clientSurname,
    clientPhone,
    clientAddrStreet,
    clientAddrStreetNumber,
    clientAddrCity
  ) => {
    let stakeholder = "klienta";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP) {
      setHasErrorCompanyNIP(true);
      props.onNotificationShow("NIP firmy jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + stakeholder + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateCompanyFormFieldsClientErrorClient = () => {
    let stakeholder = "reprezentanta";
    let company = "firmy";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP && !companyKRS) {
      setHasErrorCompanyNIP(true);
      setHasErrorCompanyKRS(true);
      props.onNotificationShow("NIP firmy lub KRS jest wymagany. Uzupełnij minimum jedno pole.", dict.NotificationType.error);
      return -1;
    }

    if (!clientAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!clientAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }

    if (!clientName) {
      setHasErrorClientName(true);
      props.onNotificationShow("Imię " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientSurname) {
      setHasErrorClientSurname(true);
      props.onNotificationShow("Nazwisko " + stakeholder + " jest wymagane. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!clientPhone) {
      setHasErrorClientPhone(true);
      props.onNotificationShow("Telefon " + stakeholder + " jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }
    if (!phoneValidator(clientPhone)) {
      props.onNotificationShow("Numer telefonu " + stakeholder + " posiada niepoprawny format. Wymagana poprawa.", dict.NotificationType.error);
      return -1;
    }
  };

  const validateCompanyFormFieldsPersonErrorClient = () => {
    let company = "firmy";

    if (!companyName) {
      setHasErrorCompanyName(true);
      props.onNotificationShow("Nazwa firmy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!companyNIP && !companyKRS) {
      setHasErrorCompanyNIP(true);
      setHasErrorCompanyKRS(true);
      props.onNotificationShow("NIP firmy lub KRS jest wymagany. Uzupełnij minimum jedno pole.", dict.NotificationType.error);
      return -1;
    }

    if (!personAddrStreet) {
      setHasErrorClientAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!personAddrStreetNumber) {
      setHasErrorClientAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
    if (!personAddrCity) {
      setHasErrorClientAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe " + company + ".", dict.NotificationType.error);
      return -1;
    }
  };

  const validateForm = async () => {
    if (!contractDate) {
      setHasErrorContractDate(true);
      props.onNotificationShow("Data zawarcia umowy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!contractCity) {
      setHasErrorContractCity(true);
      props.onNotificationShow("Miejscowość zawarcia umowy jest wymagana. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (!contractDurationMonth) {
      setHasErrorContractDurationMonth(true);
      props.onNotificationShow("Czas trwania umowy jest wymagany. Uzupełnij pole.", dict.NotificationType.error);
      return -1;
    }

    if (clientType === dict.ClientType.person) {
      // prettier-ignore
      let res = validateClientPerson(
        clientName,clientSurname,clientPhone,clientAddrCountry,clientPesel,clientIdType,clientIdNumber,clientAddrStreet,clientAddrStreetNumber,clientAddrCity
      );
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.personWithRepresentative) {
      // prettier-ignore
      let res = validateClientPerson(
        personName,personSurname,personPhone,personAddrCountry,personPesel,personIdType,personIdNumber,personAddrStreet,personAddrStreetNumber,personAddrCity
      );
      if (res === -1) return -1;
      res = validateRepresentative();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.businessIndividual) {
      // prettier-ignore
      let res = validateClientBusinessIndividual(
        companyName,companyNIP,clientName,clientSurname,clientPhone,clientAddrStreet,clientAddrStreetNumber,clientAddrCity
      );
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.businessIndividualWithRepresentative) {
      // prettier-ignore
      let res = validateClientBusinessIndividual(
        companyName,companyNIP,personName,personSurname,personPhone,personAddrStreet,personAddrStreetNumber,personAddrCity
      );
      if (res === -1) return -1;
      res = validateRepresentative();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.company) {
      let res = validateCompanyFormFieldsClientErrorClient();
      if (res === -1) return -1;
    } else if (clientType === dict.ClientType.companyWithRepresentative) {
      let res = validateCompanyFormFieldsPersonErrorClient();
      if (res === -1) return -1;
      res = validateRepresentative();
      if (res === -1) return -1;
    }

    for (let i = 0; i < coowners.length; i++) {
      if (coowners[i].myRef) {
        let result = 0;
        result = await coowners[i].myRef.current.validateCoowner();
        if (result === -1) {
          return -1;
        }
      }
    }

    if (!propertyIsSeparateOwnership && !propertyIsLand && !propertyIsCooperativeOwnership && !propertyIsOthers) {
      setHasErrorPropertyIsSection(true);
      props.onNotificationShow("Nieruchomość stanowi - wymagane zaznaczenie minimum jednej opcji. ", dict.NotificationType.error);
      return -1;
    }

    if (!propertyAddrStreet) {
      setHasErrorPropertyAddrStreet(true);
      props.onNotificationShow("Ulica jest wymagana. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
      return -1;
    }
    if (!propertyAddrStreetNumber) {
      setHasErrorPropertyAddrStreetNumber(true);
      props.onNotificationShow("Numer budynku jest wymagany. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
      return -1;
    }
    if (!propertyAddrCity) {
      setHasErrorPropertyAddrCity(true);
      props.onNotificationShow("Miejscowość jest wymagana. Uzupełnij dane adresowe nieruchomości.", dict.NotificationType.error);
      return -1;
    }

    if (
      !propertyArea ||
      propertyArea === "" ||
      // eslint-disable-next-line
      propertyArea == dict.DefaultNumber
    ) {
      setHasErrorPropertyArea(true);
      props.onNotificationShow("Powierzchnia jest wymagana. Uzupełnij dane nieruchomości.", dict.NotificationType.error);
      return -1;
    }

    if (
      !offerPrice ||
      offerPrice === "" ||
      // eslint-disable-next-line
      offerPrice == dict.DefaultNumber
    ) {
      setHasErrorOfferPrice(true);
      props.onNotificationShow("Cena oferowana jest wymagana. Uzupełnij dane nieruchomości.", dict.NotificationType.error);
      return -1;
    }

    if (
      agentProvisionType === dict.AgentProvisionType.percent &&
      (!agentProvisionPercentNet ||
        agentProvisionPercentNet === "" ||
        // eslint-disable-next-line
        agentProvisionPercentNet == dict.DefaultNumber)
    ) {
      setHasErrorAgentProvisionPercentNet(true);
      props.onNotificationShow("Wynagrodzenie procentowe Agenta jest wymagane. Uzupełnij dane nieruchomości.", dict.NotificationType.error);
      return -1;
    }

    if (
      agentProvisionType === dict.AgentProvisionType.amount &&
      (!agentProvisionAmountNet ||
        agentProvisionAmountNet === "" ||
        // eslint-disable-next-line
        agentProvisionAmountNet == dict.DefaultNumber)
    ) {
      setHasErrorAgentProvisionAmountNet(true);
      props.onNotificationShow("Wynagrodzenie kwotowe Agenta jest wymagane. Uzupełnij dane nieruchomości.", dict.NotificationType.error);
      return -1;
    }

    if (propertyIsOthers && !propertyIsOthersText) {
      setHasErrorPropertyIsOthersText(true);
      props.onNotificationShow("Nieruchomość stanowi - wybrano inne. Wymagane dodanie opisu tego wyboru.", dict.NotificationType.error);
      return -1;
    }

    if (obligationOthers && !obligationOthersText) {
      setHasErrorObligationOthersText(true);
      props.onNotificationShow("Zobowiązania Agenta - wybrano inne. Wymagane dodanie opisu tego wyboru.", dict.NotificationType.error);
      return -1;
    }
  };

  const validateFormSend = () => {
    if (signatureAgent.current === -1) {
      //setHasErrorContractDate(true);
      props.onNotificationShow("Podpis Agenta jest wymagany.", dict.NotificationType.error);
      return -1;
    }

    if (checkCounterAgent < 800) {
      //setHasErrorContractDate(true);
      props.onNotificationShow("Podpis Agenta jest uzupełniony niepoprawnie i wymaga poprawy.", dict.NotificationType.error);
      return -1;
    }
  };

  // Part V
  const getFields = (formMode) => {
    let data = {
      number: number,
      variant: variant,
      contractDurationMonth: parseInt(contractDurationMonth),
      contractDate: contractDate,
      contractCity: contractCity,
      clientName: clientName,
      clientSurname: clientSurname,
      clientAddrStreet: clientAddrStreet,
      clientAddrStreetNumber: clientAddrStreetNumber,
      clientAddrLocaleNumber: clientAddrLocaleNumber,
      clientAddrPostcode: clientAddrPostcode,
      clientAddrCity: clientAddrCity,
      clientAddrCountry: clientAddrCountry,
      clientPesel: clientPesel,
      clientIdType: clientIdType,
      clientIdNumber: clientIdNumber,
      clientPhone: clientPhone,
      clientEmail: clientEmail,
      propertyIsSeparateOwnership: propertyIsSeparateOwnership,
      propertyIsLand: propertyIsLand,
      propertyIsCooperativeOwnership: propertyIsCooperativeOwnership,
      propertyIsOthers: propertyIsOthers,
      propertyIsOthersText: propertyIsOthersText,
      propertyArea: propertyArea,
      propertyAddrStreet: propertyAddrStreet,
      propertyAddrStreetNumber: propertyAddrStreetNumber,
      propertyAddrLocaleNumber: propertyAddrLocaleNumber,
      propertyAddrPostcode: propertyAddrPostcode,
      propertyAddrCity: propertyAddrCity,
      propertyAddrCountry: propertyAddrCountry,
      landNumber: landNumber,
      mortgageRegister: mortgageRegister,
      offerPrice: offerPrice,
      offerPriceCurrency: offerPriceCurrency,
      obligationShowPurchOffer: obligationShowPurchOffer,
      obligationPresentation: obligationPresentation,
      obligationPhotos: obligationPhotos,
      obligationCollectingDocs: obligationCollectingDocs,
      obligationDescrAndPubl: obligationDescrAndPubl,
      obligationLegalVerif: obligationLegalVerif,
      obligationAdMother: obligationAdMother,
      obligationAdMotherAndOthers: obligationAdMotherAndOthers,
      obligationDescrUpgrade: obligationDescrUpgrade,
      obligationCombTranContact: obligationCombTranContact,
      obligationProtocols: obligationProtocols,
      obligationTransPrepHelp: obligationTransPrepHelp,
      obligationOthers: obligationOthers,
      obligationOthersText: obligationOthersText,
      agentProvisionType: agentProvisionType,
      agentProvisionPercentNet: agentProvisionPercentNet,
      agentProvisionAmountNet: agentProvisionAmountNet,
      clientInvoiceEmail: clientInvoiceEmail,
      status: status,
      sessionKey: sessionKey,
      clientType: clientType,
      companyName: companyName,
      companyNIP: companyNIP,
      companyKRS: companyKRS,
      personName: personName,
      personSurname: personSurname,
      personAddrStreet: personAddrStreet,
      personAddrStreetNumber: personAddrStreetNumber,
      personAddrLocaleNumber: personAddrLocaleNumber,
      personAddrPostcode: personAddrPostcode,
      personAddrCity: personAddrCity,
      personAddrCountry: personAddrCountry,
      personPesel: personPesel,
      personIdType: personIdType,
      personIdNumber: personIdNumber,
      personPhone: personPhone,
      personEmail: personEmail,
    };

    if (formMode === dict.FormMode.add) {
      data = {
        ...data,
        status: dict.DocStatus.new,
      };
    } else if (formMode === dict.FormMode.edit) {
      data = {
        ...data,
        signatureAgent: signatureAgent.current,
      };
    }

    return data;
  };

  const saveModeAdd = async () => {
    let objId = await IntermediationContractApi.Create(getFields(dict.FormMode.add));
    return objId;
  };

  const saveModeEdit = async () => {
    await IntermediationContractApi.Update(objectId, getFields(dict.FormMode.edit));

    for (let i = 0; i < coowners.length; i++) {
      if (coowners[i].myRef) {
        let result = 0;
        result = await coowners[i].myRef.current.saveCoowner();
        if (result === -1) {
          return -1;
        }
      }
    }

    return objectId;
  };

  // Part VI
  const handleSaveClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormButtonSave();
      if (result === -1) {
        return -1;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        let p = await IntermediationContractApi.GetStatusById(objectId);
        if (p.status === dict.DocStatus.accepted) {
          throw Error("Nie można aktualizować - dane zostały zatwierdzone przez klienta.");
        }

        objId = await saveModeEdit();
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd: ".concat(error.message), dict.NotificationType.error);
    }
  };

  const handleCancelClick = () => {
    if (props.onAfterCancel) {
      props.onAfterCancel(mode);
    }
  };

  const handleBackClick = () => {
    if (props.onAfterBack) {
      props.onAfterBack(mode);
    }
  };

  const handleSendClick = async () => {
    let objId = null;
    try {
      setIsSignatureAgentVisible(true);
      setHideSendToClientButton(true);
      setStatus(dict.DocStatus.sended);

      let result = 0;
      result = await validateForm();
      if (result === -1) {
        setHideSendToClientButton(false);
        setIsSignatureAgentVisible(false);
        setStatus(dict.DocStatus.new);
        return;
      }
      result = validateFormSend();
      if (result === -1) {
        setHideSendToClientButton(false);
        setStatus(dict.DocStatus.new);
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.preview) {
        objId = objectId;
      }

      await IntermediationContractApi.Update(objId, {
        agentId: session.getUserId(),
        agentSentAt: dayjs(new Date().toUTCString()),
        status: dict.DocStatus.sended,
      });

      let activeLink = await ActiveLinkAPI.Create({
        pin: phoneAsPin(clientPhone),
        objCategory: dict.ActiveLinkCategory.intermediationContract, // todo
        objId: objId,
        objStage: 0,
        isActive: true,
      });

      let link = "".concat(process.env.REACT_APP_SYSTEM_URL, "c-intco/", activeLink.guid);

      let agent = await UserApi.GetById(session.getUserId());
      //throw new Error("Test");
      let sms = {
        to: clientPhone,
        msg: "Potwierdź umowę pośrednictwa. Kliknij: ".concat(link, " ", agent.name, " ", agent.surname, ", tel. ", agent.phone),
      };

      //console.log(sms);
      let smsValidator = await SMSApi.Send(sms);

      if (smsValidator?.errorMsg) {
        throw new Error({
          message: "SMS - (" + smsValidator?.errorCode + ") " + smsValidator?.errorMsg,
        });
      }

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }

      props.onNotificationShow("Dokument został wysłany na urządzenie klienta.", dict.NotificationType.success);
    } catch (error) {
      props.onNotificationShow("Błąd wysyłki: ".concat(error.message), dict.NotificationType.error);
      setHideSendToClientButton(false);
      setStatus(dict.DocStatus.new);
      return;
    }
  };

  const handleShareClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = await validateForm();
      if (result === -1) {
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        let doc = await IntermediationContractApi.GetById(objectId);

        if (doc.status === dict.DocStatus.accepted) {
          throw Error("Dokument został już podpisany");
        }
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.preview) {
        objId = objectId;
      }

      await IntermediationContractApi.Update(objId, {
        agentId: session.getUserId(),
      });

      navigate(RoutePath.IntermediationContractCientForm, {
        state: {
          id: objId,
          mode: dict.DocFormViewMode.agent,
        },
      });

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd wysyłki: ".concat(error.message), dict.NotificationType.error);
      return;
    }
  };

  const handleInspectionClick = async () => {
    let objId = null;
    try {
      let result = 0;
      result = validateFormButtonSave();
      if (result === -1) {
        return;
      }

      if (mode === dict.FormMode.add) {
        objId = await saveModeAdd();
      } else if (mode === dict.FormMode.edit) {
        let doc = await IntermediationContractApi.GetById(objectId);

        if (doc.status === dict.DocStatus.accepted) {
          throw Error("Dokument został już podpisany");
        }
        objId = await saveModeEdit();
      } else if (mode === dict.FormMode.preview) {
        objId = objectId;
      }

      await IntermediationContractApi.Update(objId, {
        agentId: session.getUserId(),
      });

      navigate(RoutePath.IntermediationContractCientForm, {
        state: {
          id: objId,
          mode: dict.DocFormViewMode.agent,
          isInspection: true,
        },
      });

      if (props.onAfterSave) {
        props.onAfterSave(mode, objId);
      }
    } catch (error) {
      props.onNotificationShow("Błąd wysyłki: ".concat(error.message), dict.NotificationType.error);
      return;
    }
  };

  const handleDownloadClick = async () => {
    let link = document.createElement("a");

    link.download = setFileName(
      dict.DocTypes.intermediationContract,
      "pdf",
      dayjs(contractDate).format("YYYY-MM-DDTHH:mm"),
      propertyAddrCity,
      propertyAddrStreet,
      propertyAddrStreetNumber,
      propertyAddrLocaleNumber
    );

    let response = await IntermediationContractApi.GetBlobById(props.id);
    let blob = new Blob([response], { type: "application/pdf" });

    link.href = URL.createObjectURL(blob);
    link.click();

    URL.revokeObjectURL(link.href);
  };

  // Part VII
  const renderPreviewInfo = () => {
    if (mode !== dict.FormMode.preview) return;

    return (
      <Stack>
        <Stack sx={{ pt: 2, fontSize: 13 }}>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Status: "}</b> {dict.DocStatusGetName(status)}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Data wysłania: "}</b>{" "}
            {!agentSentAt || agentSentAt.valueOf() === dict.DefaultDate.valueOf() ? null : dayjs(agentSentAt).format("YYYY-MM-DD HH:mm")}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Data akceptacji: "}</b>{" "}
            {!clientAcceptedAt || clientAcceptedAt.valueOf() === dict.DefaultDate.valueOf()
              ? null
              : dayjs(clientAcceptedAt).format("YYYY-MM-DD HH:mm")}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Email klienta: "}</b> {clientEmail}
          </Typography>
          <Typography variant="span" sx={{ pb: 1 }}>
            <b> {"Email faktura: "}</b> {clientInvoiceEmail}
          </Typography>
        </Stack>
        <Stack width={"150px"}>
          {clientEmail && (
            <Button variant="text" onClick={handleDownloadClick}>
              Pobierz dokument
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<SaveOutlinedIcon />} onClick={handleSaveClick}>
            {(mode === dict.FormMode.add && !hideSendToClientButton) || (mode === dict.FormMode.edit && !hideSendToClientButton)
              ? "Zapisz"
              : "Aktualizuj"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? false : true) && (
          <Button variant="contained" size="small" startIcon={<CancelOutlinedIcon />} onClick={handleCancelClick}>
            {"Wyjdź"}
          </Button>
        )}
        {(mode === dict.FormMode.preview ? true : false) && (
          <Button variant="contained" size="small" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackClick}>
            {"Wstecz"}
          </Button>
        )}
        {status !== dict.DocStatus.accepted && status === dict.DocStatus.new && !isModePreview && !hideSendToClientButton && (
          <Button variant="contained" size="small" startIcon={<SendToMobileOutlinedIcon />} onClick={handleSendClick}>
            {"Wyślij"}
          </Button>
        )}
        {status !== dict.DocStatus.accepted && (status === dict.DocStatus.new || status === dict.DocStatus.sended) && !isModePreview && (
          <Button variant="contained" size="small" startIcon={<BorderColorOutlined />} onClick={handleShareClick}>
            {"Podpisz"}
          </Button>
        )}
        {status !== dict.DocStatus.accepted && (status === dict.DocStatus.new || status === dict.DocStatus.sended) && !isModePreview && (
          <Button variant="contained" size="small" startIcon={<SearchOutlinedIcon />} onClick={handleInspectionClick}>
            {"Podgląd"}
          </Button>
        )}
        {/* <Stack>
    <PDFDownloadLink
      document={
        <Printing
          clientName={clientName}
          clientSurname={clientSurname}
          clientPesel={clientPesel}
          clientIdType={clientIdType}
          clientIdNumber={clientIdNumber}
          coviewerName={coviewerName}
          coviewerSurname={coviewerSurname}
          coviewerPesel={coviewerPesel}
          coviewerIdType={coviewerIdType}
          coviewerIdNumber={coviewerIdNumber}
          agentName={"Imię Agenta"}
          agentSurname={"Nazwisko Agenta"}
          consentPrivacy={consentPrivacy}
          consentMediation={consentMediation}
          consentTrade={consentTrade}
          consentMarketing={consentMarketing}
          presentationDate={dayjs(new Date())
            .format("YYYY-MM-DD HH:mm")
            .toString()}
          clientSignature={clientName + " " + clientSurname}
          visitDate={dayjs(visitDate).format("YYYY-MM-DD")}
          visitHour={dayjs(visitDate).format("HH:mm")}
          visitAddress={"".concat(
            addrStreet ? "ul. ".concat(addrStreet) : "",
            " ",
            addrStreetNumber,
            addrLocaleNumber ? "/".concat(addrLocaleNumber) : "",
            "\n",
            addrPostcode,
            " ",
            addrCity
          )}
        />
      }
      fileName="somename.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now!"
      }
    </PDFDownloadLink>
  </Stack> */}
      </Stack>
    );
  };

  const [coownersData, setCoownersData] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);

  const getCoownersData = async () => {
    if (objectId && mode === dict.DocFormViewMode.agent) {
      const object = await IntermediationContractPersonApi.GetByDocId(objectId, sessionKey);
      setCoownersData(object);
    } else if (objectId && mode === dict.DocFormViewMode.client) {
      const object = await IntermediationContractPersonApi.GetByDocIdUnauthorized(objectId, sessionKey);
      setCoownersData(object);
    }
  };

  const getAttachmentsData = async () => {
    if (mode === dict.DocFormViewMode.client) {
      const object = await IntermediationContractBinaryApi.GetIdsByDocIdSessionKey(objectId, null, sessionKey);
      setAttachmentsData(object);
    } else {
      const object = await IntermediationContractBinaryApi.GetIdsByDocId(objectId, null);
      // setAttachmentsData([
      //   { id: 19, label: "Kolorki", mimeType: "image/png" },
      //   { id: 20, label: "Lewek", mimeType: "image/png" },
      // ]);
      setAttachmentsData(object);
    }
  };

  const prepareExternalPrintingData = () => {
    getCoownersData();
    getAttachmentsData();
  };

  const renderPrintingPreview = () => {
    if (!debug) return;

    prepareExternalPrintingData();

    return (
      <PDFViewer width={"100%"} height={1000} showToolbar={true}>
        <IntermediationContractPDF
          id={objectId}
          mode={dict.DocFormViewMode.agent}
          sessionKey={sessionKey}
          contractDate={dayjs(contractDate).format("YYYY-MM-DD")}
          contractHour={dayjs(contractDate).format("HH:mm")}
          contractCity={contractCity}
          clientName={clientName}
          clientSurname={clientSurname}
          clientAddrStreet={clientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          clientAddrPostcode={clientAddrPostcode}
          clientAddrCity={clientAddrCity}
          clientAddrCountry={clientAddrCountry}
          clientEmail={clientEmail}
          clientPhone={clientPhone}
          clientPesel={clientPesel}
          clientIdType={clientIdType}
          clientIdNumber={clientIdNumber}
          propertyIsSeparateOwnership={propertyIsSeparateOwnership}
          propertyIsLand={propertyIsLand}
          propertyIsCooperativeOwnership={propertyIsCooperativeOwnership}
          propertyIsOthers={propertyIsOthers}
          propertyIsOthersText={propertyIsOthersText}
          propertyArea={propertyArea}
          propertyAddrStreet={propertyAddrStreet}
          propertyAddrStreetNumber={propertyAddrStreetNumber}
          propertyAddrLocaleNumber={propertyAddrLocaleNumber}
          propertyAddrPostcode={propertyAddrPostcode}
          propertyAddrCity={propertyAddrCity}
          propertyAddrCountry={propertyAddrCountry}
          landNumber={landNumber}
          mortgageRegister={mortgageRegister}
          offerPrice={offerPrice}
          offerPriceCurrency={offerPriceCurrency}
          obligationShowPurchOffer={obligationShowPurchOffer}
          obligationPresentation={obligationPresentation}
          obligationPhotos={obligationPhotos}
          obligationCollectingDocs={obligationCollectingDocs}
          obligationDescrAndPubl={obligationDescrAndPubl}
          obligationLegalVerif={obligationLegalVerif}
          obligationAdMother={obligationAdMother}
          obligationAdMotherAndOthers={obligationAdMotherAndOthers}
          obligationDescrUpgrade={obligationDescrUpgrade}
          obligationCombTranContact={obligationCombTranContact}
          obligationProtocols={obligationProtocols}
          obligationTransPrepHelp={obligationTransPrepHelp}
          obligationOthers={obligationOthers}
          obligationOthersText={obligationOthersText}
          agentProvisionType={agentProvisionType}
          agentProvisionPercentNet={agentProvisionPercentNet}
          agentProvisionAmountNet={agentProvisionAmountNet}
          clientInvoiceEmail={clientInvoiceEmail}
          agentPostTerminationWork={true}
          agentName={"AImię"}
          agentSurname={"ANazwisko"}
          agentPhone={"123456789"}
          agentEmail={"example@example.com"}
          branchName={"Oddział Warszawa"}
          // branchAddressLine1={"".concat(
          //   branchAddrStreet ? "ul. ".concat(branchAddrStreet) : "",
          //   " ",
          //   branchAddrStreetNumber,
          //   branchAddrLocaleNumber ? "/".concat(branchAddrLocaleNumber) : ""
          // )}
          // branchAddressLine2={"".concat(
          //   branchAddrPostcode,
          //   " ",
          //   branchAddrCity
          // )}
          // branchEmail={branchEmail}
          // branchPhone={branchPhone}
          // signature={-1}
          variant={variant}
          contractDurationMonth={contractDurationMonth}
          clientType={clientType}
          personName={personName}
          personSurname={personSurname}
          personAddrStreet={personAddrStreet}
          personAddrStreetNumber={personAddrStreetNumber}
          personAddrLocaleNumber={personAddrLocaleNumber}
          personAddrPostcode={personAddrPostcode}
          personAddrCity={personAddrCity}
          personAddrCountry={personAddrCountry}
          personEmail={personEmail}
          personPhone={personPhone}
          personPesel={personPesel}
          personIdType={personIdType}
          personIdNumber={personIdNumber}
          companyName={companyName}
          companyNIP={companyNIP}
          companyKRS={companyKRS}
          coownersData={coownersData}
          attachmentsData={attachmentsData}
        />
      </PDFViewer>
    );
  };

  const handleOnTouchEndAgent = (counter, value) => {
    setCheckCounterAgent(counter);
    signatureAgent.current = value;
  };

  const renderAgentSignature = () => {
    if (!isSignatureAgentVisible) return;

    if (status !== dict.DocStatus.sended) {
      return (
        <FormGroup
          sx={{
            bgcolor: "background.paper",
            boxShadow: 1,
            borderRadius: 2,
            p: 2,
            maxWidth: 1200,
            mb: 2,
          }}
        >
          <Grid container spacing={1} style={{ marginTop: "-25px" }}>
            <Grid item xs={12} sm={12} md={12}>
              <DrawingCanvas
                id={"signatureAgentBeforeSend"}
                title="Podpis Agenta"
                onTouchEnd={(a, b, c) => handleOnTouchEndAgent(a, b, c)}
                marginTop={"0px"}
                scale={isSm ? 3 : 2}
              />
            </Grid>
          </Grid>
        </FormGroup>
      );
    }

    return (
      <FormGroup
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          maxWidth: 1200,
          mb: 2,
        }}
      >
        {renderSignature(signatureAgent.current)}
      </FormGroup>
    );
  };

  const renderSignature = (value) => {
    if (value && value !== -1) {
      return (
        // <Image
        //   source={value}
        //   type={"image/png"}
        //   style={{
        //     padding: "0",
        //     margin: "0",
        //     width: "180",
        //     height: "70",
        //   }}
        //   // width="360px"
        //   // height="140px"
        // />
        <div style={{ marginTop: "-15px" }}>
          <h4>Podgląd podpisu Agenta</h4>
          <img src={value} type={"image/png"} alt="Podpis Agenta" width="180px" height="70px" />
        </div>
      );
    }
    return <div></div>;
  };

  const handleAddCoowner = async () => {
    if (mode !== dict.FormMode.edit) {
      let result = await handleSaveClick();

      if (result === -1) {
        return -1;
      }

      props.onNotificationShow("Dodawanie współwłaścicieli wymaga wcześniejszego zapisu umowy.", dict.NotificationType.error);
      return -1;
    }

    // eslint-disable-next-line
    let objId = await IntermediationContractPersonApi.Create({
      docId: objectId,
    });

    refreshCoowners();
  };

  const handleDeleteCoowner = async (id) => {
    refreshCoowners();
  };

  const handleClientTypeChoosen = (value) => {
    setClientType(value);
  };

  const renderRepresentative = () => {
    return (
      <ClientPerson
        isRepresentative={true}
        isModePreview={isModePreview}
        hasErrorSectionClient={hasErrorSectionClient}
        clientType={clientType}
        onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
        clientName={clientName}
        onChangeClientName={(value) => setClientName(value)}
        hasErrorClientName={hasErrorRepresentativeName}
        clientSurname={clientSurname}
        onChangeClientSurname={(value) => setClientSurname(value)}
        hasErrorClientSurname={hasErrorRepresentativeSurname}
        clientPhone={clientPhone}
        onChangeClientPhone={(value) => {
          setClientPhone(value);
          phoneValidator(value) ? setHasErrorRepresentativePhone(false) : setHasErrorRepresentativePhone(true);
        }}
        hasErrorClientPhone={hasErrorRepresentativePhone}
        clientEmail={clientEmail}
        onChangeClientEmail={(value) => {
          setClientEmail(value);
          emailValidator(value) ? setHasErrorRepresentativeEmail(false) : setHasErrorRepresentativeEmail(true);
        }}
        hasErrorClientEmail={hasErrorRepresentativeEmail}
        clientAddrCountry={clientAddrCountry}
        onChangeClientAddrCountry={(value) => setClientAddrCountry(value)}
        hasErrorClientAddrCountry={hasErrorRepresentativeAddrCountry}
        clientPesel={clientPesel}
        onChangeClientPesel={(value) => setClientPesel(value)}
        hasErrorClientPesel={hasErrorRepresentativePesel}
        clientIdTypeCombo={clientIdTypeCombo}
        hasErrorClientIdType={hasErrorRepresentativeIdType}
        onChangeClientIdType={(value) => setClientIdType(value)}
        onChangeClientIdTypeCombo={(value) => setClientIdTypeCombo(value)}
        clientIdNumber={clientIdNumber}
        onChangeClientIdNumber={(value) => setClientIdNumber(value)}
        hasErrorClientIdNumber={hasErrorRepresentativeIdNumber}
        clientAddrStreet={clientAddrStreet}
        onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
        hasErrorClientAddrStreet={hasErrorRepresentativeAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
        hasErrorClientAddrStreetNumber={hasErrorRepresentativeAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
        clientAddrPostcode={clientAddrPostcode}
        onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
        clientAddrCity={clientAddrCity}
        onChangeClientAddrCity={(value) => setClientAddrCity(value)}
        hasErrorClientAddrCity={hasErrorRepresentativeAddrCity}
      />
    );
  };

  const renderClient = () => {
    if (clientType === dict.ClientType.businessIndividual) {
      return (
        <ClientBusinessIndividual
          isModePreview={isModePreview}
          hasErrorSectionClient={hasErrorSectionClient}
          clientType={clientType}
          onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
          companyName={companyName}
          onChangeCompanyName={(value) => setCompanyName(value)}
          hasErrorCompanyName={hasErrorCompanyName}
          companyNIP={companyNIP}
          onChangeCompanyNIP={(value) => setCompanyNIP(value)}
          hasErrorCompanyNIP={hasErrorCompanyNIP}
          clientName={clientName}
          onChangeClientName={(value) => setClientName(value)}
          hasErrorClientName={hasErrorClientName}
          clientSurname={clientSurname}
          onChangeClientSurname={(value) => setClientSurname(value)}
          hasErrorClientSurname={hasErrorClientSurname}
          clientPhone={clientPhone}
          onChangeClientPhone={(value) => {
            setClientPhone(value);
            phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
          }}
          hasErrorClientPhone={hasErrorClientPhone}
          clientEmail={clientEmail}
          onChangeClientEmail={(value) => {
            setClientEmail(value);
            emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
          }}
          hasErrorClientEmail={hasErrorClientEmail}
          clientAddrStreet={clientAddrStreet}
          onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
          hasErrorClientAddrStreet={hasErrorClientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
          hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
          clientAddrPostcode={clientAddrPostcode}
          onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
          clientAddrCity={clientAddrCity}
          onChangeClientAddrCity={(value) => setClientAddrCity(value)}
          hasErrorClientAddrCity={hasErrorClientAddrCity}
        />
      );
    } else if (clientType === dict.ClientType.businessIndividualWithRepresentative) {
      return (
        <Stack>
          <ClientBusinessIndividual
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionPerson}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            companyName={companyName}
            onChangeCompanyName={(value) => setCompanyName(value)}
            hasErrorCompanyName={hasErrorCompanyName}
            companyNIP={companyNIP}
            onChangeCompanyNIP={(value) => setCompanyNIP(value)}
            hasErrorCompanyNIP={hasErrorCompanyNIP}
            clientName={personName}
            onChangeClientName={(value) => setPersonName(value)}
            hasErrorClientName={hasErrorClientName}
            clientSurname={personSurname}
            onChangeClientSurname={(value) => setPersonSurname(value)}
            hasErrorClientSurname={hasErrorClientSurname}
            clientPhone={personPhone}
            onChangeClientPhone={(value) => {
              setPersonPhone(value);
              phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
            }}
            hasErrorClientPhone={hasErrorClientPhone}
            clientEmail={personEmail}
            onChangeClientEmail={(value) => {
              setPersonEmail(value);
              emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
            }}
            hasErrorClientEmail={hasErrorClientEmail}
            clientAddrStreet={personAddrStreet}
            onChangeClientAddrStreet={(value) => setPersonAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={personAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPersonAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={personAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPersonAddrLocaleNumber(value)}
            clientAddrPostcode={personAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPersonAddrPostcode(value)}
            clientAddrCity={personAddrCity}
            onChangeClientAddrCity={(value) => setPersonAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } else if (clientType === dict.ClientType.company) {
      return (
        <ClientCompany
          isRepresentative={false}
          isModePreview={isModePreview}
          hasErrorSectionClient={hasErrorSectionClient}
          clientType={clientType}
          onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
          companyName={companyName}
          onChangeCompanyName={(value) => setCompanyName(value)}
          hasErrorCompanyName={hasErrorCompanyName}
          companyNIP={companyNIP}
          onChangeCompanyNIP={(value) => setCompanyNIP(value)}
          hasErrorCompanyNIP={hasErrorCompanyNIP}
          companyKRS={companyKRS}
          onChangeCompanyKRS={(value) => setCompanyKRS(value)}
          hasErrorCompanyKRS={hasErrorCompanyKRS}
          clientAddrStreet={clientAddrStreet}
          onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
          hasErrorClientAddrStreet={hasErrorClientAddrStreet}
          clientAddrStreetNumber={clientAddrStreetNumber}
          onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
          hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
          clientAddrLocaleNumber={clientAddrLocaleNumber}
          onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
          clientAddrPostcode={clientAddrPostcode}
          onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
          clientAddrCity={clientAddrCity}
          onChangeClientAddrCity={(value) => setClientAddrCity(value)}
          hasErrorClientAddrCity={hasErrorClientAddrCity}
          clientName={clientName}
          onChangeClientName={(value) => setClientName(value)}
          hasErrorClientName={hasErrorClientName}
          clientSurname={clientSurname}
          onChangeClientSurname={(value) => setClientSurname(value)}
          hasErrorClientSurname={hasErrorClientSurname}
          clientPhone={clientPhone}
          onChangeClientPhone={(value) => {
            setClientPhone(value);
            phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
          }}
          hasErrorClientPhone={hasErrorClientPhone}
          clientEmail={clientEmail}
          onChangeClientEmail={(value) => {
            setClientEmail(value);
            emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
          }}
          hasErrorClientEmail={hasErrorClientEmail}
        />
      );
    } else if (clientType === dict.ClientType.companyWithRepresentative) {
      return (
        <Stack>
          <ClientCompany
            isRepresentative={true}
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionPerson}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            companyName={companyName}
            onChangeCompanyName={(value) => setCompanyName(value)}
            hasErrorCompanyName={hasErrorCompanyName}
            companyNIP={companyNIP}
            onChangeCompanyNIP={(value) => setCompanyNIP(value)}
            hasErrorCompanyNIP={hasErrorCompanyNIP}
            companyKRS={companyKRS}
            onChangeCompanyKRS={(value) => setCompanyKRS(value)}
            hasErrorCompanyKRS={hasErrorCompanyKRS}
            clientAddrStreet={personAddrStreet}
            onChangeClientAddrStreet={(value) => setPersonAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={personAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPersonAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={personAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPersonAddrLocaleNumber(value)}
            clientAddrPostcode={personAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPersonAddrPostcode(value)}
            clientAddrCity={personAddrCity}
            onChangeClientAddrCity={(value) => setPersonAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } else if (clientType === dict.ClientType.personWithRepresentative) {
      return (
        <Stack>
          <ClientPerson
            isModePreview={isModePreview}
            hasErrorSectionClient={hasErrorSectionPerson}
            clientType={clientType}
            onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
            clientName={personName}
            onChangeClientName={(value) => setPersonName(value)}
            hasErrorClientName={hasErrorClientName}
            clientSurname={personSurname}
            onChangeClientSurname={(value) => setPersonSurname(value)}
            hasErrorClientSurname={hasErrorClientSurname}
            clientPhone={personPhone}
            onChangeClientPhone={(value) => {
              setPersonPhone(value);
              phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
            }}
            hasErrorClientPhone={hasErrorClientPhone}
            clientEmail={personEmail}
            onChangeClientEmail={(value) => {
              setPersonEmail(value);
              emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
            }}
            hasErrorClientEmail={hasErrorClientEmail}
            clientAddrCountry={personAddrCountry}
            onChangeClientAddrCountry={(value) => setPersonAddrCountry(value)}
            hasErrorClientAddrCountry={hasErrorClientAddrCountry}
            clientPesel={personPesel}
            onChangeClientPesel={(value) => setPersonPesel(value)}
            hasErrorClientPesel={hasErrorClientPesel}
            clientIdTypeCombo={personIdTypeCombo}
            hasErrorClientIdType={hasErrorClientIdType}
            onChangeClientIdType={(value) => setPersonIdType(value)}
            onChangeClientIdTypeCombo={(value) => setPersonIdTypeCombo(value)}
            clientIdNumber={personIdNumber}
            onChangeClientIdNumber={(value) => setPersonIdNumber(value)}
            hasErrorClientIdNumber={hasErrorClientIdNumber}
            clientAddrStreet={personAddrStreet}
            onChangeClientAddrStreet={(value) => setPersonAddrStreet(value)}
            hasErrorClientAddrStreet={hasErrorClientAddrStreet}
            clientAddrStreetNumber={personAddrStreetNumber}
            onChangeClientAddrStreetNumber={(value) => setPersonAddrStreetNumber(value)}
            hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
            clientAddrLocaleNumber={personAddrLocaleNumber}
            onChangeClientAddrLocaleNumber={(value) => setPersonAddrLocaleNumber(value)}
            clientAddrPostcode={personAddrPostcode}
            onChangeClientAddrPostcode={(value) => setPersonAddrPostcode(value)}
            clientAddrCity={personAddrCity}
            onChangeClientAddrCity={(value) => setPersonAddrCity(value)}
            hasErrorClientAddrCity={hasErrorClientAddrCity}
          />
          {renderRepresentative()}
        </Stack>
      );
    } //else if (clientType === dict.ClientType.person) {

    return (
      <ClientPerson
        isModePreview={isModePreview}
        hasErrorSectionClient={hasErrorSectionClient}
        clientType={clientType}
        onClientTypeChoosen={(a) => handleClientTypeChoosen(a)}
        clientName={clientName}
        onChangeClientName={(value) => setClientName(value)}
        hasErrorClientName={hasErrorClientName}
        clientSurname={clientSurname}
        onChangeClientSurname={(value) => setClientSurname(value)}
        hasErrorClientSurname={hasErrorClientSurname}
        clientPhone={clientPhone}
        onChangeClientPhone={(value) => {
          setClientPhone(value);
          phoneValidator(value) ? setHasErrorClientPhone(false) : setHasErrorClientPhone(true);
        }}
        hasErrorClientPhone={hasErrorClientPhone}
        clientEmail={clientEmail}
        onChangeClientEmail={(value) => {
          setClientEmail(value);
          emailValidator(value) ? setHasErrorClientEmail(false) : setHasErrorClientEmail(true);
        }}
        hasErrorClientEmail={hasErrorClientEmail}
        clientAddrCountry={clientAddrCountry}
        onChangeClientAddrCountry={(value) => setClientAddrCountry(value)}
        hasErrorClientAddrCountry={hasErrorClientAddrCountry}
        clientPesel={clientPesel}
        onChangeClientPesel={(value) => setClientPesel(value)}
        hasErrorClientPesel={hasErrorClientPesel}
        clientIdTypeCombo={clientIdTypeCombo}
        hasErrorClientIdType={hasErrorClientIdType}
        onChangeClientIdType={(value) => setClientIdType(value)}
        onChangeClientIdTypeCombo={(value) => setClientIdTypeCombo(value)}
        clientIdNumber={clientIdNumber}
        onChangeClientIdNumber={(value) => setClientIdNumber(value)}
        hasErrorClientIdNumber={hasErrorClientIdNumber}
        clientAddrStreet={clientAddrStreet}
        onChangeClientAddrStreet={(value) => setClientAddrStreet(value)}
        hasErrorClientAddrStreet={hasErrorClientAddrStreet}
        clientAddrStreetNumber={clientAddrStreetNumber}
        onChangeClientAddrStreetNumber={(value) => setClientAddrStreetNumber(value)}
        hasErrorClientAddrStreetNumber={hasErrorClientAddrStreetNumber}
        clientAddrLocaleNumber={clientAddrLocaleNumber}
        onChangeClientAddrLocaleNumber={(value) => setClientAddrLocaleNumber(value)}
        clientAddrPostcode={clientAddrPostcode}
        onChangeClientAddrPostcode={(value) => setClientAddrPostcode(value)}
        clientAddrCity={clientAddrCity}
        onChangeClientAddrCity={(value) => setClientAddrCity(value)}
        hasErrorClientAddrCity={hasErrorClientAddrCity}
      />
    );
  };

  return (
    <div>
      <Stack>{renderPrintingPreview()}</Stack>
      <FormGroup
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          maxWidth: 1200,
          mb: 2,
        }}
      >
        <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 0, pb: 2 }}>
          {"Informacje"}
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            id="variant"
            options={dict.IntermediationContractVariantCombo}
            sx={{ width: "100%", pb: 2 }}
            renderInput={(params) => <TextField {...params} label="Wariant umowy" size="small" required={true} error={hasErrorVariant} />}
            value={variantCombo}
            onChange={(e, value) => {
              setVariant(value?.code);
              setVariantCombo(dict.IntermediationContractVariantCombo.filter((f) => f.code === value?.code)[0]);
            }}
            disabled={isModePreview}
            disableClearable={true}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={"Data zawarcia umowy *"}
              id={"contractDate"}
              value={contractDate?.isSame(dict.DefaultDate.toUTCString()) ? null : contractDate}
              format="YYYY-MM-DD HH:mm"
              onChange={(e) => setContractDate(e)}
              disabled={isModePreview}
              slotProps={{
                textField: {
                  size: "small",
                  //helperText: "Format YYYY-MM-DD HH:mm",
                },
              }}
              sx={{ pb: 2, width: "200px" }}
              error={hasErrorContractDate}
            />
          </LocalizationProvider>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Miejscowość zawarcia umowy"}
            id={"contractCity"}
            value={contractCity}
            onChange={(e) => {
              setContractCity(e.target.value);
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            error={hasErrorContractCity}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            label={"Czas trwania umowy (miesiące)"}
            id={"contractDurationMonth"}
            value={returnFormattedValue(contractDurationMonth)}
            onChange={(e) => {
              setContractDurationMonth(parseInt(e.target.value));
            }}
            disabled={isModePreview}
            sx={{ pb: 2, width: "100%" }}
            required={true}
            type={"number"}
            error={hasErrorContractDurationMonth}
            pattern={"[0-9]*"}

            // InputProps={{
            //   inputProps: {
            //     pattern: "[0-9]",
            //   },
            // }}
          />
        </Stack>
        {renderPreviewInfo()}
        {renderClient()}

        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {"Współwłaścicielstwo"}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              size="medium"
              startIcon={<PersonAddAlt1OutlinedIcon />}
              disabled={mode !== dict.FormMode.edit ? true : false}
              onClick={handleAddCoowner}
              style={{ marginTop: "5px", marginBottom: "25px" }}
            >
              {"Dodaj właściciela"}
            </Button>
          </Stack>
          {coowners.map((item) => (
            <Coowner
              key={"".concat("coowner", item.id)}
              id={item.id}
              mode={mode}
              onDelete={(a) => handleDeleteCoowner(a)}
              ref={item.myRef}
              onNotificationShow={
                (a, b) => {
                  a = "Współwłaściciel - " + a;
                  props.onNotificationShow(a, b);
                }
                //
              }
              docStatus={status}
            />
          ))}
        </Stack>

        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }} style={{ color: hasErrorPropertyIsSection ? "red" : "" }}>
            {"Nieruchomość stanowi"}
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"odrębny lokal mieszkalny / użytkowy (prawo własności)"}</Typography>}
              sx={{
                mr: 0,
              }}
              checked={propertyIsSeparateOwnership}
              onChange={(e) => setPropertyIsSeparateOwnership(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"nieruchomość gruntową"}</Typography>}
              sx={{
                mr: 0,
              }}
              checked={propertyIsLand}
              onChange={(e) => setPropertyIsLand(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"spółdzielcze własnościowe prawo do lokalu"}</Typography>}
              sx={{
                mr: 0,
              }}
              checked={propertyIsCooperativeOwnership}
              onChange={(e) => setpropertyIsCooperativeOwnership(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"inne"}</Typography>}
              sx={{
                mr: 0,
              }}
              checked={propertyIsOthers}
              onChange={(e) => setPropertyIsOthers(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Inne"}
              id={"propertyIsOthersText"}
              value={propertyIsOthersText}
              onChange={(e) => setPropertyIsOthersText(e.target.value)}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={false}
              error={hasErrorPropertyIsOthersText}
            />
          </Stack>
        </Stack>
        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {"Adres nieruchomości"}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Ulica"}
              id={"propertyAddrStreet"}
              value={propertyAddrStreet}
              onChange={(e) => setPropertyAddrStreet(e.target.value)}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={true}
              error={hasErrorPropertyAddrStreet}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Numer budynku"}
              id={"propertyAddrStreetNumber"}
              value={propertyAddrStreetNumber}
              onChange={(e) => setPropertyAddrStreetNumber(e.target.value)}
              disabled={isModePreview}
              sx={{ pb: 2, width: "50%" }}
              required={true}
              error={hasErrorPropertyAddrStreetNumber}
            />
            <TextField
              size="small"
              label={"Numer lokalu"}
              id={"propertyAddrLocaleNumber"}
              value={propertyAddrLocaleNumber}
              onChange={(e) => setPropertyAddrLocaleNumber(e.target.value)}
              disabled={isModePreview}
              sx={{ pb: 2, width: "50%" }}
              required={false}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Kod pocztowy"}
              id={"propertyAddrPostcode"}
              value={propertyAddrPostcode}
              onChange={(e) => setPropertyAddrPostcode(e.target.value)}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={false}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Miejscowość"}
              id={"propertyAddrCity"}
              value={propertyAddrCity}
              onChange={(e) => setPropertyAddrCity(e.target.value)}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={true}
              error={hasErrorPropertyAddrCity}
            />
          </Stack>
        </Stack>
        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {"Dane nieruchomości"}
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Powierzchnia"}
              id={"propertyArea"}
              value={returnFormattedValue(propertyArea)}
              onChange={(e) => {
                setPropertyArea(e.target.value);
              }}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={true}
              type={"number"}
              error={hasErrorPropertyArea}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Numer działki"}
              id={"landNumber"}
              value={landNumber}
              onChange={(e) => {
                setLandNumber(e.target.value);
              }}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={false}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Numer księgi wieczystej"}
              id={"mortgageRegister"}
              value={mortgageRegister}
              onChange={(e) => {
                setMortgageRegister(e.target.value);
              }}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={false}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Cena oferowana"}
              id={"offerPrice"}
              value={returnFormattedValue(offerPrice)}
              onChange={(e) => {
                setOfferPrice(e.target.value);
              }}
              disabled={isModePreview}
              sx={{ pb: 2, width: "70%" }}
              required={true}
              type={"number"}
              error={hasErrorOfferPrice}
            />
            <Autocomplete
              disablePortal
              id="offerPriceCurrency"
              options={dict.Currency}
              sx={{ width: "30%", pb: 2 }}
              renderInput={(params) => <TextField {...params} label="Waluta" size="small" required={false} />}
              value={offerPriceCurrencyCombo}
              onChange={(e, value) => {
                setOfferPriceCurrency(value?.code);
                setOfferPriceCurrencyCombo(dict.Currency.filter((f) => f.code === value?.code)[0]);
              }}
              disabled={isModePreview}
              disableClearable={true}
            />
          </Stack>
          <Stack>
            <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 0 }}>
              {"Wynagrodzenie Agenta"}
            </Stack>
            <FormControl>
              {/* <FormLabel id="agent-provision-group">{"Metoda wynagrodzenia Agenta"}</FormLabel> */}
              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={agentProvisionType}>
                <FormControlLabel
                  value={dict.AgentProvisionType.percent}
                  control={<Radio />}
                  label="procentowe"
                  onChange={(e) => setAgentProvisionType(parseInt(e.target.value))}
                />
                <FormControlLabel
                  value={dict.AgentProvisionType.amount}
                  control={<Radio />}
                  label="kwotowe"
                  onChange={(e) => setAgentProvisionType(parseInt(e.target.value))}
                />
              </RadioGroup>
            </FormControl>
            {agentProvisionType === dict.AgentProvisionType.percent && (
              <Stack direction="row" spacing={2} sx={{ pt: 1 }}>
                <TextField
                  size="small"
                  label={"Procent netto wynagrodzenia"}
                  id={"agentProvisionPercentNet"}
                  value={returnFormattedValue(agentProvisionPercentNet)}
                  onChange={(e) => {
                    setAgentProvisionPercentNet(e.target.value);
                  }}
                  disabled={isModePreview}
                  sx={{ pb: 2, width: "100%" }}
                  required={true}
                  type={"number"}
                  error={hasErrorAgentProvisionPercentNet}
                />
              </Stack>
            )}
            {agentProvisionType === dict.AgentProvisionType.amount && (
              <Stack direction="row" spacing={2} sx={{ pt: 1 }}>
                <TextField
                  size="small"
                  label={"Kwota netto wynagrodzenia"}
                  id={"agentProvisionAmountNet"}
                  value={returnFormattedValue(agentProvisionAmountNet)}
                  onChange={(e) => {
                    setAgentProvisionAmountNet(e.target.value);
                  }}
                  disabled={isModePreview}
                  sx={{ pb: 2, width: "100%" }}
                  required={true}
                  type={"number"}
                  error={hasErrorAgentProvisionAmountNet}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack>
          <Stack direction="row" spacing={2} sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {"Zobowiązania Agenta"}
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"przedstawianie Klientowi ofert kupna Nieruchomości"}</Typography>}
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationShowPurchOffer}
              onChange={(e) => setObligationShowPurchOffer(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography variant="body1">{"dokonywanie w uzgodnionych terminach prezentacji Nieruchomości potencjalnym nabywcom"}</Typography>
              }
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationPresentation}
              onChange={(e) => setObligationPresentation(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"sporządzenie dokumentacji zdjęciowej"}</Typography>}
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationPhotos}
              onChange={(e) => setObligationPhotos(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"skompletowanie dokumentacji na podstawie przedłożonych przez Klienta dokumentów"}</Typography>}
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationCollectingDocs}
              onChange={(e) => setObligationCollectingDocs(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography variant="body1">
                  {"sporządzenie oferty i opisu Nieruchomości oraz rejestracji oferty w bazie danych Pośrednika"}
                </Typography>
              }
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationDescrAndPubl}
              onChange={(e) => setObligationDescrAndPubl(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"dokonanie wstępnej weryfikacji stanu prawnego Nieruchomości"}</Typography>}
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationLegalVerif}
              onChange={(e) => setObligationLegalVerif(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          {/* <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography variant="body1">
                  {"umieszczenie reklamy na stronie internetowej Pośrednika"}
                </Typography>
              }
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationAdMother}
              onChange={(e) => setObligationAdMother(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack> */}
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography variant="body1">
                  {"umieszczenie reklamy na stronie internetowej Pośrednika oraz na innych portalach internetowych"}
                </Typography>
              }
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationAdMotherAndOthers}
              onChange={(e) => setObligationAdMotherAndOthers(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"bieżąca aktualizacja danych dotyczących Nieruchomości"}</Typography>}
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationDescrUpgrade}
              onChange={(e) => setObligationDescrUpgrade(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={
                <Typography variant="body1">
                  {
                    "skontaktowanie Klienta i potencjalnego nabywcy Nieruchomości w celu uzgodnienia przyszłych warunków transakcji łączącej strony, udziału w negocjacjach"
                  }
                </Typography>
              }
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationCombTranContact}
              onChange={(e) => setObligationCombTranContact(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"przygotowanie protokołu uzgodnień lub protokołu zdawczo-odbiorczego"}</Typography>}
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationProtocols}
              onChange={(e) => setObligationProtocols(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"zapewnienie obsługi związanej z przygotowaniem transakcji"}</Typography>}
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationTransPrepHelp}
              onChange={(e) => setObligationTransPrepHelp(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body1">{"inne"}</Typography>}
              sx={{
                mr: 0,
                pb: 1,
              }}
              checked={obligationOthers}
              onChange={(e) => setObligationOthers(e.target.checked)}
              disabled={isModePreview}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              label={"Inne"}
              id={"obligationOthersText"}
              value={obligationOthersText}
              onChange={(e) => setObligationOthersText(e.target.value)}
              disabled={isModePreview}
              sx={{ pb: 2, width: "100%" }}
              required={false}
              error={hasErrorObligationOthersText}
            />
          </Stack>
        </Stack>
        <IntermediationContractBinary
          docId={objectId}
          mode={mode}
          onNotificationShow={
            (a, b) => {
              a = "Załączniki - " + a;
              props.onNotificationShow(a, b);
            }
            //
          }
        />
      </FormGroup>
      {renderAgentSignature()}
      <IntermediationContractMonitor id={objectId} mode={mode} />
      {renderButtons()}
    </div>
  );
};

export default IntermediationContractForm;
