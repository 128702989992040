import { Text, View } from "@react-pdf/renderer";

const DocumentForm = (props) => {
  const styles = {
    page: {
      fontFamily: "Roboto",
      padding: "0%",
      marginLeft: "15px",
      fontSize: "9px",
      marginRight: "20px",
      marginTop: "-60px",
    },
    title: {
      marginTop: "20px",
      marginBottom: "40px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "12px",
    },
    newParagraph: {
      paddingBottom: "4px",
    },
  };

  return (
    <View break style={styles.page}>
      <View style={styles.title}>
        <Text>
          Załącznik nr 1 - Pouczenie o odstąpieniu od umowy zawartej poza lokalem przedsiębiorstwa (zgodnie z ustawą z dnia 30 maja 2014r. o prawach
          konsumenta)
        </Text>
      </View>

      <View>
        <View style={styles.newParagraph}>
          <Text>- Mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania przyczyny.</Text>
        </View>
        <View style={styles.newParagraph}>
          <Text>- Termin do odstąpienia od umowy wygasa po upływie 14 (czternastu) dni od dnia zawarcia umowy.</Text>
        </View>
        <View style={styles.newParagraph}>
          <Text>
            - Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować HOME Sp. z o.o. z siedzibą w Szczecinie o swojej decyzji o
            odstąpieniu od umowy w drodze jednoznacznego oświadczenia w formie pisma wysłanego pocztą na następujący adres: ul. Zielonogórska 31, lok.
            1.1, 71-084 Szczecin.
          </Text>
        </View>
        <View style={styles.newParagraph}>
          <Text>- Mogą Państwo skorzystać z wzoru formularza odstąpienia od umowy, jednak nie jest to obowiązkowe.</Text>
        </View>
        <View style={styles.newParagraph}>
          <Text>
            - Aby zachować termin do odstąpienia od umowy, wystarczy aby wysłali Państwo informację dotyczącą wykonania przysługującego Państwu prawa
            odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.
          </Text>
        </View>
        <View style={styles.newParagraph}>
          <Text>
            - W przypadku odstąpienia od niniejszej umowy zwracamy Państwu wszystkie otrzymane od Państwa płatności, w tym koszty dostarczenia rzeczy
            (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Państwa sposobu dostarczenia innego niż najtańszy zwykły sposób
            dostarczenia oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o
            Państwa decyzji o wykonaniu prawa odstąpienia od niniejszej umowy.
          </Text>
        </View>
        <View style={styles.newParagraph}>
          <Text>
            - Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba
            że wyraźnie zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat w związku z tym zwrotem.
          </Text>
        </View>
        <View style={styles.newParagraph}>
          <Text>
            - Jeżeli zażądali Państwo rozpoczęcia świadczenia usług przed upływem terminu do odstąpienia od umowy, zapłacą Państwo HOME kwotę
            proporcjonalną do zakresu świadczeń spełnionych do chwili, w której poinformowali nas Państwo o odstąpieniu od niniejszej umowy.
          </Text>
        </View>
      </View>
    </View>
  );
};

export default DocumentForm;
