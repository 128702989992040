import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import * as dict from "../config/Dictionary";

// Register font
Font.register({
  family: "Roboto",
  fonts: [
    { src: "/assets/Fonts/Roboto/Roboto-Regular.ttf" },
    { src: "/assets/Fonts/Roboto/Roboto-Bold.ttf" },
    {
      src: "/assets/Fonts/Roboto/Roboto-Italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "/assets/Fonts/Roboto/Roboto-BoldItalic.ttf",
      fontStyle: "italic",
      fontWeight: "bold",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "3%",
    marginLeft: "15px",
    fontSize: "11px",
  },
  parameter: {
    fontWeight: "bold",
    color: "#163058",
  },
  title: {
    marginTop: "20px",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "12px",
  },
  person: {
    flexDirection: "row",
    marginBottom: "10px",
  },
  descr: {
    marginBottom: "7px",
    fontSize: "9px",
    marginRight: "20px",
  },
  descrBold: {
    fontWeight: "bold",
  },
  square: {
    width: 10, // Adjust width and height as needed
    height: 10,
    borderWidth: 0, // Set border width to 0 for no border
    border: "1px solid black",
  },
});

const stylesTable = StyleSheet.create({
  table: {
    display: "table",
    width: "90%",
    marginTop: "30px",
  },
  rowHeader: {
    flexDirection: "row",
  },
  row: {
    flexDirection: "row",
    height: "50px",
    fontWeight: "bold",
    color: "#163058",
  },
  cell: {
    display: "table-cell",
    margin: 0,
    padding: 5,
    width: "20%",
    whiteSpace: "pre-line",
    border: "1px solid #000",
    marginLeft: "-1px",
    marginTop: "-1px",
  },
  column1: {
    width: "15%",
  },
  column2: {
    width: "11%",
  },
  column3: {
    width: "34%",
  },
  column4: {
    width: "20%",
  },
});

const Printing = (props) => {
  const clientName = props.clientName;
  const clientSurname = props.clientSurname;
  const clientPesel = props.clientPesel;
  const clientIdType = props.clientIdType;
  const clientIdNumber = props.clientIdNumber;
  const coviewerName = props.coviewerName;
  const coviewerSurname = props.coviewerSurname;
  const coviewerPesel = props.coviewerPesel;
  const coviewerIdType = props.coviewerIdType;
  const coviewerIdNumber = props.coviewerIdNumber;
  const agentName = props.agentName;
  const agentSurname = props.agentSurname;

  const consentPrivacy = props.consentPrivacy;
  const consentMediation = props.consentMediation;
  const consentTrade = props.consentTrade;
  const consentMarketing = props.consentMarketing;

  const presentationDate = props.presentationDate;
  const clientSignature = props.clientSignature;

  const branchName = props.branchName;
  const branchAddressLine1 = props.branchAddressLine1;
  const branchAddressLine2 = props.branchAddressLine2;
  const branchEmail = props.branchEmail;
  const branchPhone = props.branchPhone;

  const tableRows = [
    {
      date: props.visitDate,
      hour: props.visitHour,
      address: props.visitAddress,
      client: props.clientName + " " + props.clientSurname,
      signature: props.clientSignature,
    },
    { date: "", hour: "", address: "", client: "", signature: "" },
    { date: "", hour: "", address: "", client: "", signature: "" },
    { date: "", hour: "", address: "", client: "", signature: "" },
  ];

  const renderTable = () => (
    <View style={stylesTable.table}>
      <View style={stylesTable.rowHeader}>
        <View style={[stylesTable.cell, stylesTable.column1]}>
          <Text>Data</Text>
        </View>
        <View style={[stylesTable.cell, stylesTable.column2]}>
          <Text>Godzina</Text>
        </View>
        <View style={[stylesTable.cell, stylesTable.column3]}>
          <Text>Adres nieruchomości</Text>
        </View>
        <View style={[stylesTable.cell, stylesTable.column4]}>
          <Text>Imię i nazwisko Klienta</Text>
        </View>
        <View style={[stylesTable.cell]}>
          <Text>Podpis Klienta</Text>
        </View>
      </View>

      {tableRows.map((obj, index) => (
        <View style={stylesTable.row} key={"".concat("tabRows", index)}>
          <View style={[stylesTable.cell, stylesTable.column1]}>
            <Text>{obj.date}</Text>
          </View>
          <View style={[stylesTable.cell, stylesTable.column2]}>
            <Text>{obj.hour}</Text>
          </View>
          <View style={[stylesTable.cell, stylesTable.column3]}>
            <Text>{obj.address}</Text>
          </View>
          <View style={[stylesTable.cell, stylesTable.column4]}>
            <Text>{obj.client}</Text>
          </View>
          <View style={stylesTable.cell}>
            <Text>{obj.signature}</Text>
          </View>
        </View>
      ))}
    </View>
  );

  const renderImagesShape = () => {
    let data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    return data.map((item, index) => (
      <View key={"".concat("graphics", index)}>
        <Image
          style={{
            width: "25px",
            height: "25px",
            position: "absolute",
            marginTop: "".concat(30 * index * 2.3, "px"),
            marginLeft: "-17px",
          }}
          src={"/assets/Graphics/client/docShapeA.png"}
        />
        <Image
          style={{
            width: "25px",
            height: "25px",
            position: "absolute",
            marginTop: "".concat(33 + 30 * index * 2.3, "px"),
            marginLeft: "-17px",
          }}
          src={"/assets/Graphics/client/docShapeB.png"}
        />
      </View>
    ));
  };

  const renderClientIdentificationDoc = () => {
    if (clientPesel !== "") {
      return (
        <Text>
          {"Pesel: "} <Text style={styles.parameter}> {clientPesel}</Text>
        </Text>
      );
    }
    return (
      <Text>
        {dict.IdentityDocumentType.filter((f) => f.code === clientIdType)[0].label}
        {":"}
        <Text style={styles.parameter}> {clientIdNumber}</Text>
      </Text>
    );
  };

  const renderCoviewerIdentificationDoc = () => {
    if (coviewerPesel !== "") {
      return (
        <Text>
          {"Pesel: "} <Text style={styles.parameter}> {coviewerPesel}</Text>
        </Text>
      );
    }

    return (
      <Text>
        {dict.IdentityDocumentType.filter((f) => f.code === coviewerIdType)[0].label}
        {":"}
        <Text style={styles.parameter}> {coviewerIdNumber}</Text>
      </Text>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={{ left: 13, width: "25px", position: "absolute" }} src={"/assets/Graphics/client/docLogoTop.png"} fixed />
        <View style={{ left: 13, marginTop: "80px" }} fixed>
          {renderImagesShape()}
        </View>
        <View
          style={{
            left: 40,
            bottom: 20,
            position: "absolute",
            color: "#163058",
            fontSize: "10px",
          }}
          fixed
        >
          <Text style={{ fontWeight: "bold", marginBottom: "3px" }}>{branchName}</Text>
          <Text style={{ marginBottom: "3px" }}>
            {branchAddressLine1} {branchAddressLine2}
          </Text>
          <Text style={{ marginBottom: "3px" }}>NIP: 8522665760</Text>
        </View>
        <View
          style={{
            left: 380,
            bottom: 20,
            position: "absolute",
            color: "#163058",
            fontSize: "10px",
          }}
          fixed
        >
          <Text style={{ fontWeight: "bold", marginBottom: "3px" }}>{branchEmail}</Text>
          <Text style={{ marginBottom: "3px" }}>{branchPhone}</Text>
          <Text style={{ marginBottom: "3px" }}>www.home-estate.pl</Text>
        </View>
        <Image
          style={{
            bottom: 35,
            left: 530,
            width: "16px",
            height: "18px",
            position: "absolute",
          }}
          src={"/assets/Graphics/client/docLogoBottom.png"}
          fixed
        />
        <View style={{ marginTop: "-50px" }}>
          <View style={styles.title}>
            <Text>{"PROTOKÓŁ Z PREZENTACJI NIERUCHOMOŚCI"}</Text>
          </View>
          <View style={styles.person}>
            <View style={{ width: "65%" }}>
              <Text>
                {"Imię i nazwisko Klienta: "}
                <Text style={styles.parameter}> {clientName + " " + clientSurname}</Text>
              </Text>
            </View>
            <View style={{ width: "35%" }}>{renderClientIdentificationDoc()}</View>
          </View>
          <View style={styles.person}>
            <View style={{ width: "65%" }}>
              <Text>
                {"Imię i nazwisko Osoby współoglądającej: "}
                <Text style={styles.parameter}> {coviewerName + " " + coviewerSurname}</Text>
              </Text>
            </View>
            <View style={{ width: "35%" }}>{renderCoviewerIdentificationDoc()}</View>
          </View>
          <View style={styles.person}>
            <View>
              <Text>
                {"Imię i nazwisko Pośrednika: "}
                <Text style={styles.parameter}> {agentName + " " + agentSurname}</Text>
              </Text>
            </View>
          </View>
          {renderTable()}
          <View style={{ marginRight: "20px" }}>
            <Text style={[styles.descr, { marginTop: "20px" }]}>
              Administratorem Pani/Pana danych osobowych jest
              <Text style={styles.descrBold}> HOME Spółka z ograniczoną odpowiedzialnością </Text>z siedzibą w Szczecinie adres: ul. Zielonogórska 31,
              lok. 1/1, 71-084 (poniżej jako „<Text style={styles.descrBold}>Administrator</Text>”).
            </Text>
            <Text style={styles.descr}>
              Z Administratorem może Pani/Pan skontaktować się pisemnie, pod adresem podanym powyżej lub pod adresem email rodo@home- estate.pl.
              Administrator będzie przetwarzać Pani/Pana dane osobowe w następujących celach:
            </Text>
            <View style={([styles.descr], { flexDirection: "row" })}>
              <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>a)</Text>
              <Text style={[styles.descr, { width: "90%" }]}>
                w celu podjęcia działań przed zawarciem umowy pośrednictwa lub w przypadku jej zawarcia, w celu jej wykonania - na podstawie art. 6
                ust. 1 lit. b) RODO;{" "}
              </Text>
            </View>
            <View style={([styles.descr], { flexDirection: "row" })}>
              <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>b)</Text>
              <Text style={[styles.descr, { width: "90%" }]}>
                w celu wypełnienia obowiązków ciążących na Administratorze, w szczególności wynikających z przepisów podatkowych i rachunkowych - na
                podstawie art. 6 ust. 1 lit. c) RODO;
              </Text>
            </View>
            <View style={([styles.descr], { flexDirection: "row" })}>
              <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>c)</Text>
              <Text style={[styles.descr, { width: "90%" }]}>
                w celu prowadzenia postępowania windykacyjnego, ustalenia, dochodzenia i obrony przed potencjalnymi roszczeniami na drodze
                sądowej/pozasądowej - na podstawie art. 6 ust. 1 lit. f) RODO;
              </Text>
            </View>
            <View style={([styles.descr], { flexDirection: "row" })}>
              <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>d)</Text>
              <Text style={[styles.descr, { width: "90%" }]}>
                w celu marketingu bezpośredniego produktów lub usług Administratora za pośrednictwem środków komunikacji elektronicznej i
                telefonicznej – podstawą prawną przetwarzania danych będzie Pani/Pana zgoda na przekazywanie informacji handlowej za pośrednictwem
                środków komunikacji elektronicznej i telefonicznej (art. 6 ust. 1 lit. a) RODO). Jeżeli wyrazi Pani/Pan zgodę na otrzymywanie od nas
                informacji marketingowych, informacje takie dostarczą Pani/Panu również nasi zaufani Partnerzy, z którymi stale współpracujemy. W
                każdej chwili mogą Państwo wycofać udzieloną zgodę.
              </Text>
            </View>
            <View>
              <Text style={[styles.descr]}>
                Pani/Pana dane mogą zostaną przez nas udostępnione osobom, które z upoważnienia Administratora lub podmiotu przetwarzającego mogą
                przetwarzać dane osobowe (pracownicy/współpracownicy), podmiotom przetwarzającym - dostawcom usług np. pocztowych, kurierskich,
                marketingowych, lT, a także franczyzobiorcom i potencjalnym kontrahentom uczestniczącym w wykonaniu przedmiotu Umowy. W każdej chwili
                może Pani/Pan zwrócić się do nas z wnioskiem o udzielenie informacji o osobach, którym udostępniliśmy Pani/Pana dane.
              </Text>
            </View>
            <View style={{ marginTop: "60px" }}>
              <Text style={[styles.descr]}>
                {"\n"}
                Jeżeli wyrazi Pani/Pan zgodę na otrzymywanie od nas informacji handlowych, dane kontaktowe przekażemy również naszym zaufanym
                Partnerom z którymi stale współpracujemy, w celu przedstawienia Pani/Panu informacji o ich usługach lub ofercie. Pani/Pana dane
                osobowe nie będą przekazywane do odbiorców znajdujących się w państwach poza Europejskim Obszarem Gospodarczym.
              </Text>
              <Text style={[styles.descr]}>
                W przypadku udzielenia zgody na przesyłanie informacji handlowych drogą elektroniczną lub na kontakt telefoniczny w celu
                przedstawienia oferty, Pani/Pana dane będą przez nas przetwarzane do czasu wycofania udzielonej zgody. W zakresie, w jakim Pani/Pana
                dane będą przetwarzane w celu wykonania i zapewnienia najwyższej jakości naszych usług lub w celu marketingu bezpośredniego naszych
                towarów lub usług, będziemy je przetwarzali do czasu zgłoszenia sprzeciwu wobec przetwarzania danych osobowych w powyższych celach. W
                zakresie, w jaki Pani/Pana dane będą przetwarzane w celu wykonania umowy pośrednictwa lub w celu obrony, dochodzenia, ustalenia
                roszczeń - będziemy je przetwarzali do upływu okresu przedawnienia roszczeń lub zakończenia postępowania windykacyjnego.
              </Text>
            </View>
            <View style={{}}>
              <Text style={[styles.descr]}>Przysługują Pani/Panu następujące prawa związane z przetwarzaniem danych osobowych:</Text>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>a)</Text>
                <Text style={[styles.descr, { width: "90%" }]}>
                  prawo wycofania zgody na przetwarzania danych - przy czym cofnięcie zgody nie ma wpływu na zgodność z prawem przetwarzania w okresie
                  przed jej wycofaniem,
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>b)</Text>
                <Text style={[styles.descr, { width: "90%" }]}>
                  prawo dostępu do danych osobowych – uzyskania informacji o przetwarzaniu danych osobowych oraz kopii danych,
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>c)</Text>
                <Text style={[styles.descr, { width: "90%" }]}>
                  prawo żądania sprostowania danych osobowych - ich poprawienia, uzupełnienia lub aktualizacji,
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>d)</Text>
                <Text style={[styles.descr, { width: "90%" }]}>prawo żądania usunięcia danych osobowych,</Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>e)</Text>
                <Text style={[styles.descr, { width: "90%" }]}>prawo żądania ograniczenia przetwarzania danych osobowych,</Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>f)</Text>
                <Text style={[styles.descr, { width: "90%" }]}>
                  prawo wyrażenia sprzeciwu wobec przetwarzania danych ze względu na Pani/Pana szczególną sytuację – w przypadkach, kiedy przetwarzamy
                  dane w zakresie wynikającym z prawnie uzasadnionego interesu Administratora (w szczególności, dotyczy to sprzeciwu wobec
                  przetwarzania Pani/Pana danych na potrzeby marketingu bezpośredniego oraz w celu zapewnienia najwyższej jakości usług),
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.descr, { marginLeft: "14px", width: "1%" }]}>g)</Text>
                <Text style={[styles.descr, { width: "90%" }]}>
                  prawo żądania przeniesienia danych osobowych, tj. przekazania danych osobowych przez Administratora w ustrukturyzowanym, powszechnie
                  używanym formacie nadającym się do odczytu maszynowego.
                </Text>
              </View>
              <View>
                <Text style={[styles.descr]}>
                  W celu realizacji powyższych uprawnień, prosimy o kontakt e-mailowy rodo@home-estate.pl lub pisemny na adres Administratora.
                </Text>
                <Text style={[styles.descr]}>
                  Przysługuje Pani/Panu także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych tj. Prezesa
                  Urzędu Ochrony Danych Osobowych.
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row", marginTop: "7px" })}>
                <Text style={[styles.square, { marginLeft: "14px", marginRight: "3px" }]}></Text>
                <Text style={{ color: "#163058", left: -10.5, top: -2 }}>{consentPrivacy ? "x" : "  "}</Text>
                <Text style={[styles.descr, { width: "90%", fontStyle: "italic", fontSize: "8px" }]}>
                  <Text style={{ fontWeight: "bold" }}>Wyrażam zgodę</Text> na przetwarzanie moich danych przez HOME Sp. z o.o. w zakresie niezbędnym
                  do oferowania produktów i usług w związku z wykonywanym przez HOME Sp. z o.o. pośrednictwem nieruchomościowym.
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.square, { marginLeft: "14px", marginRight: "3px" }]}></Text>
                <Text style={{ color: "#163058", left: -10.5, top: -2 }}>{consentMediation ? "x" : "  "}</Text>
                <Text style={[styles.descr, { width: "90%", fontStyle: "italic", fontSize: "8px" }]}>
                  <Text style={{ fontWeight: "bold" }}>Oświadczam</Text>, że umowa pośrednictwa zostanie podpisana w ciągu dwóch dni od prezentacji
                  lub w późniejszym terminie, ale przed kolejną prezentacją nieruchomości.
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.square, { marginLeft: "14px", marginRight: "3px" }]}></Text>
                <Text style={{ color: "#163058", left: -10.5, top: -2 }}>{consentTrade ? "x" : "  "}</Text>
                <Text style={[styles.descr, { width: "90%", fontStyle: "italic", fontSize: "8px" }]}>
                  <Text style={{ fontWeight: "bold" }}>Wyrażam zgodę</Text> na przesyłanie mi przez HOME Sp. z o.o. informacji handlowej zgodnie z
                  art. 10 ust. 2 ustawy z dnia 18.07.2002r. o świadczeniu usług drogą elektroniczną.
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row" })}>
                <Text style={[styles.square, { marginLeft: "14px", marginRight: "3px" }]}></Text>
                <Text style={{ color: "#163058", left: -10.5, top: -2 }}>{consentMarketing ? "x" : "  "}</Text>
                <Text style={[styles.descr, { width: "90%", fontStyle: "italic", fontSize: "8px" }]}>
                  <Text style={{ fontWeight: "bold" }}>Wyrażam zgodę</Text> na przesyłanie mi przez HOME Sp. z o.o. informacji marketingowych za
                  pomocą urządzeń telekomunikacyjnych zgodnie z art. 172 ustawy z dnia 16.07.2004r. Prawo telekomunikacyjne.
                </Text>
              </View>
              <View style={([styles.descr], { flexDirection: "row", marginTop: "30px" })}>
                <Text
                  style={[
                    styles.descr,
                    {
                      width: "50%",
                      fontSize: "10px",
                      textAlign: "center",
                    },
                  ]}
                >
                  Data
                  <Text style={[styles.parameter]}>
                    {"\n\n"}
                    {presentationDate}
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.descr,
                    {
                      width: "50%",
                      fontSize: "10px",
                      textAlign: "center",
                    },
                  ]}
                >
                  Podpis
                  <Text style={[styles.parameter]}>
                    {"\n\n"}
                    {clientSignature}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Printing;
