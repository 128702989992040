import axios from "axios";
import * as api from "../../config/ApiConfig";
import * as session from "../../components/SessionStorage/SessionStorage";
import * as Token from "../../api/Token";

export const Create = async (branch) => {
  try {
    const response = await axios({
      method: "post",
      url: api.url_Branch_Create,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        createdBy: session.getUserId(),
        code: branch.code,
        name: branch.name,
        addrStreet: branch.addrStreet,
        addrStreetNumber: branch.addrStreetNumber,
        addrLocaleNumber: branch.addrLocaleNumber,
        addrPostcode: branch.addrPostcode,
        addrCity: branch.addrCity,
        addrCountry: branch.addrCountry,
        email: branch.email,
        phone: branch.phone,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_Branch_Create, error);
  }
};

export const GetById = async (objectId) => {
  try {
    const response = await axios.get(api.url_Branch_GetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_Branch_GetById, error);
  }
};

export const GetAllFiltering = async (filter, retry) => {
  try {
    const response = await axios.get(api.url_Branch_GetAllFiltering, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: !filter
        ? {}
        : {
            qUserId: session.getUserId(),
            code: filter.code,
            name: filter.name,
          },
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 403 && !retry) {
      await Token.isTokenValid();
      return await GetAllFiltering(filter, true);
    } else if (error.response.status === 403) {
      throw new Error("403");
    }
    console.error("Error: ", error);
    throw new Error("Error, ".concat(api.url_Branch_GetAllFiltering, error.message));
  }
};

export const Update = async (objectId, branch) => {
  try {
    let bodyData = {
      updatedBy: session.getUserId(),
      code: branch.code,
      name: branch.name,
      addrStreet: branch.addrStreet,
      addrStreetNumber: branch.addrStreetNumber,
      addrLocaleNumber: branch.addrLocaleNumber,
      addrPostcode: branch.addrPostcode,
      addrCity: branch.addrCity,
      addrCountry: branch.addrCountry,
      email: branch.email,
      phone: branch.phone,
    };

    const response = await axios.post(api.url_Branch_Update, bodyData, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_Branch_Update, error);
  }
};
