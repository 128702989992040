import { useState, useEffect } from "react";
import Paperbase from "../../layout/Admin/Paperbase";
import { useNavigate } from "react-router-dom";
import * as Token from "../../api/Token";
import * as RoutePath from "../../config/NavigationRoute/RoutePath";
import * as dict from "../../config/Dictionary";
import PresentationProtocolForm from "../../business/PresentationProtocol/PresentationProtocolForm";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationHelper";

function PresentationProtocol(props) {
  const { t } = useTranslation();

  // eslint-disable-next-line
  const [notification, setNotification] = useState({
    text: "",
    severity: "",
  });

  const handleNotificationShow = (text, severity) => {
    setNotification({
      text: text,
      severity: severity,
    });
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [objId, setObjId] = useState(-1);
  const [title, setTitle] = useState("Nowy protokół prezentacji");

  const checkToken = async () => {
    const isTokenValid = await Token.isTokenValid();

    if (!isTokenValid) {
      navigate(RoutePath.Login);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    //setCurrentTabIndex(newValue);
  };

  const displayOperationPanel = () => {
    if (currentTabIndex === 0) {
      return <div></div>;
    } else if (currentTabIndex === 1) {
      return <div></div>;
    }
  };

  const handleObjectAfterSave = (mode, objId) => {
    setObjId(objId);
    setTitle("Protokół prezentacji [".concat(objId, "]"));
    setNotification({
      text: "Zapisano protokół prezentacji".concat(" [", objId, "]."),
      severity: "success",
    });
  };

  const handleObjectAfterCancel = (mode) => {
    navigate(RoutePath.Main);
  };

  const displayContentTab0 = () => {
    if (objId !== -1) {
      return (
        <PresentationProtocolForm
          id={objId}
          mode={dict.FormMode.edit}
          onNotificationShow={(a, b) => handleNotificationShow(a, b)}
          onAfterSave={(a, b) => handleObjectAfterSave(a, b)}
          onAfterCancel={(a) => handleObjectAfterCancel(a)}
        />
      );
    }
    return (
      <PresentationProtocolForm
        id={null}
        mode={dict.FormMode.add}
        onNotificationShow={(a, b) => handleNotificationShow(a, b)}
        onAfterSave={(a, b) => handleObjectAfterSave(a, b)}
        onAfterCancel={(a) => handleObjectAfterCancel(a)}
      />
    );
  };

  if (loading) {
    return null;
  }

  return (
    <Paperbase
      title={t(formatTranslation("menu", dict.PageCodes.presentationProtocol))}
      subtitle={"Protokół prezentacji"}
      activepositioncode={dict.PageCodes.presentationProtocol}
      tabActiveIndex={currentTabIndex}
      onTabChange={(a, b) => handleTabChange(a, b)}
      operationPanel={displayOperationPanel()}
      //tab0Label={"Proces diagnostyczny"}
      tab0Title={title}
      tab0Content={displayContentTab0()}
      //   tab1Label={"Proces terapeutyczny"}
      //   tab1Title={"Ustawienia dotyczące zadania egzaminacyjnego"}
      //   tab1Content={displayContentTab1()}
      notification={notification}
    />
  );
}

export default PresentationProtocol;
