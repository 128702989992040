//import * as dict from "./../config/Dictionary";

exports.emailValidator = (email) => {
  if (!email) return true;
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

exports.phoneValidator = (phone) => {
  if (!phone) return true;
  const regex = /^\d{9}$|^[+]{1}\d{11,}$/;
  return regex.test(phone);
};

exports.phoneNumberFormat = (phone) => {
  if (!phone) {
    return phone;
  }
  // Usunięcie spacji i znaków specjalnych
  phone = phone.replace(/[^0-9+]/g, "");

  // Sprawdzenie, czy numer zaczyna się od "+"
  if (phone.startsWith("+")) {
    // Usunięcie "+48"
    let country = phone.substring(0, 3) + " ";
    phone = phone.slice(3);

    // Wstawienie spacji po kodzie kraju (+48)
    phone = phone.replace(/^/, country);

    // Wstawienie spacji co trzy cyfry
    phone = phone.replace(/(\d{3})/g, "$1 ");

    return phone;
  } else {
    // Wstawienie spacji co trzy cyfry
    phone = phone.replace(/(\d{3})/g, "$1 ");
    return phone;
  }
};

exports.phoneAsPin = (phone) => {
  if (phone.substring(0, 3) === "+48" && phone.length === 12) {
    return phone.substring(3);
  }
  if (phone.charAt(0) === "+") {
    return phone.substring(1);
  }

  return phone;
};

exports.validateEmail = (email) => {
  if (!email) return true;
  // Regular expression sprawdza poprawność formatu adresu e-mail
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

exports.returnFormattedValue = (value) => {
  if (!value) return "";
  return value === -1 ? "" : value.toString();
};

exports.formatAddressLine1 = (addrStreet, addrStreetNumber, addrStreetLocaleNumber) => {
  return "".concat(
    addrStreet ? "".concat(addrStreet) : "",
    " ",
    addrStreetNumber,
    addrStreetLocaleNumber ? ", lokal nr ".concat(addrStreetLocaleNumber) : ""
  );
};

exports.formatAddressLine2 = (addrPostcode, addrCity) => {
  return "".concat(addrPostcode, " ", addrCity);
};

exports.netToGrossRecalculate = (value, currency) => {
  if (!value) return "";

  let vatPercent = 23;
  let netValue = Number(value);

  if (typeof netValue !== "number" || typeof vatPercent !== "number") {
    throw new Error("Należy podać wartości liczbowe dla kwoty netto i stawki VAT.");
  }
  if (vatPercent < 0 || vatPercent > 100) {
    throw new Error("Stawka VAT musi być wartością procentową z przedziału 0-100.");
  }

  const vatValue = netValue * (vatPercent / 100);
  const grossValue = netValue + vatValue;

  let result = grossValue.toFixed(2).toString();

  return result;
};

exports.formatNumberByCurrency = (value, currency) => {
  if (!value) return "";

  let result = value.toString();

  if (currency === "PLN") {
    result = result.replace(".", ",");
  }

  return result;
};

exports.formatPrice = (price, currency, options) => {
  //let price = 1234567890;
  // Domyślne opcje

  price = parseFloat(price);

  let defaultOptions = {
    thousandSeparator: " ",
    decimalCount: 2,
    decimalSeparator: ",",
    currencySignBefore: false,
    currencySignAfter: true,
  };

  if (currency !== "PLN") {
    defaultOptions = {
      thousandSeparator: " ",
      decimalCount: 2,
      decimalSeparator: ".",
      currencySignBefore: true,
      currencySignAfter: false,
    };
  }

  // Połącz domyślne opcje z podanymi opcjami
  options = Object.assign({}, defaultOptions, options);

  // Konwersja kwoty na łańcuch znaków
  let priceAsText = options.decimalCount > 0 ? price?.toFixed(options.decimalCount) : "".concat(price, ".");

  // Dodanie separatora tysięcy
  if (options.thousandSeparator) {
    const regex = /\d(?=(\d{3})+\.)/g;
    priceAsText = priceAsText.replace(regex, `$&${options.thousandSeparator}`);
  }

  // Dodanie symbolu waluty
  let currencyValue = currency ? `${currency} ` : ` `;

  const priceFormatted = priceAsText.replace(".", options.decimalSeparator);

  if (options.currencySignAfter) {
    currencyValue = currency === "PLN" ? "zł" : currencyValue;
    return `${priceFormatted} ${currencyValue}`;
  }

  return `${currencyValue}${priceFormatted}`;
};

exports.setFileName = (type, ext, date, addrCity, addrStreet, addrNumber, addrLocale) => {
  let name = "";
  let prefix = "";

  // Umowa Pośrednictwa Home [adres nieruchomości] [data zawarcia]

  if (type === "presentationProtocol") {
    prefix = "ProtokółPrezentacji";
    name = prefix.concat(
      "-" + date.toLocaleString().substring(0, 16).replace("T", "g").replace("_", "-"),
      "-" + addrCity,
      "-" + addrStreet,
      "-" + addrNumber,
      addrLocale ? "m" + addrLocale : "",
      ext ? "." + ext : ".pdf"
    );
  } else if (type === "intermediationContract") {
    prefix = "UmowaPośrednictwa";
    name = prefix.concat(
      "-" + date.toLocaleString().substring(0, 10),
      "-" + addrCity,
      "-" + addrStreet,
      "-" + addrNumber,
      addrLocale ? "m" + addrLocale : "",
      ext ? "." + ext : ".pdf"
    );
  } else {
    throw new Error("Niepoprawny typ dokumentu - niezbędne do ustalenia nazwy pliku");
  }

  //UmowaPośrednictwa-2024-06-06-Warszawa-Prezentacyjna-56.pdf
  return name;
};

exports.base64ToBlob = (base64Data) => {
  if (!base64Data || base64Data === -1) return null;

  const base64WithoutHeader = base64Data.split(",")[1];
  const byteString = window.atob(base64WithoutHeader); // Dekodowanie Base64 do ciągu bajtów
  const byteChars = new Uint8Array(byteString.length); // Tworzenie tablicy bajtów
  for (let i = 0; i < byteString.length; i++) {
    byteChars[i] = byteString.charCodeAt(i); // Konwersja każdego znaku na bajt
  }
  const byteArray = new Blob([byteChars], { type: "image/png" }); // Tworzenie blobu z tablicy bajtów
  //console.log(byteArray);
  return byteArray;
};

exports.convertBlobToBase64 = async (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target.error) {
        reject(event.target.error);
        return;
      }
      resolve(event.target.result);
    };
    reader.readAsDataURL(blob);
  });
};

exports.translateMonthNumber = (value) => {
  const odmianyCyfr = [
    { 1: "jednego" },
    { 2: "dwóch" },
    { 3: "trzech" },
    { 4: "czterech" },
    { 5: "pięciu" },
    { 6: "sześciu" },
    { 7: "siedmiu" },
    { 8: "ośmiu" },
    { 9: "dziewięciu" },
    { 10: "dziesięciu" },
    { 11: "jedenastu" },
    { 12: "dwunastu" },
    { 13: "trzynastu" },
    { 14: "czternastu" },
    { 15: "piętnastu" },
    { 16: "szesnastu" },
    { 17: "siedemnastu" },
    { 18: "osiemnastu" },
    { 19: "dziewiętnastu" },
    { 20: "dwudziestu" },
    { 21: "dwudziestu jeden" },
    { 22: "dwudziestu dwóch" },
    { 23: "dwudziestu trzech" },
    { 24: "dwudziestu czterech" },
    { 25: "dwudziestu pięciu" },
    { 26: "dwudziestu sześciu" },
    { 27: "dwudziestu siedmiu" },
    { 28: "dwudziestu ośmiu" },
    { 29: "dwudziestu dziewięciu" },
    { 30: "trzydziestu" },
    { 31: "trzydziestu jeden" },
    { 32: "trzydziestu dwóch" },
    { 33: "trzydziestu trzech" },
    { 34: "trzydziestu czterech" },
    { 35: "trzydziestu pięciu" },
    { 36: "trzydziestu sześciu" },
    { 37: "trzydziestu siedmiu" },
    { 38: "trzydziestu ośmiu" },
    { 39: "trzydziestu dziewięciu" },
    { 40: "czterdziestu" },
    { 41: "czterdziestu jeden" },
    { 42: "czterdziestu dwóch" },
    { 43: "czterdziestu trzech" },
    { 44: "czterdziestu czterech" },
    { 45: "czterdziestu pięciu" },
    { 46: "czterdziestu sześciu" },
    { 47: "czterdziestu siedmiu" },
    { 48: "czterdziestu ośmiu" },
    { 49: "czterdziestu dziewięciu" },
    { 50: "pięćdziesięciu" },
    { 51: "pięćdziesięciu jeden" },
    { 52: "pięćdziesięciu dwóch" },
    { 53: "pięćdziesięciu trzech" },
    { 54: "pięćdziesięciu czterech" },
    { 55: "pięćdziesięciu pięciu" },
    { 56: "pięćdziesięciu sześciu" },
    { 57: "pięćdziesięciu siedmiu" },
    { 58: "pięćdziesięciu ośmiu" },
    { 59: "pięćdziesięciu dziewięciu" },
    { 60: "sześćdziesięciu" },
    { 61: "sześćdziesięciu jeden" },
    { 62: "sześćdziesięciu dwóch" },
    { 63: "sześćdziesięciu trzech" },
    { 64: "sześćdziesięciu czterech" },
    { 65: "sześćdziesięciu pięciu" },
    { 66: "sześćdziesięciu sześciu" },
    { 67: "sześćdziesięciu siedmiu" },
    { 68: "sześćdziesięciu ośmiu" },
    { 69: "sześćdziesięciu dziewięciu" },
    { 70: "siedemdziesięciu" },
    { 71: "siedemdziesięciu jeden" },
    { 72: "siedemdziesięciu dwóch" },
    { 73: "siedemdziesięciu trzech" },
    { 74: "siedemdziesięciu czterech" },
    { 75: "siedemdziesięciu pięciu" },
    { 76: "siedemdziesięciu sześciu" },
    { 77: "siedemdziesięciu siedmiu" },
    { 78: "siedemdziesięciu ośmiu" },
    { 79: "siedemdziesięciu dziewięciu" },
    { 80: "osiemdziesięciu" },
    { 81: "osiemdziesięciu jeden" },
    { 82: "osiemdziesięciu dwóch" },
    { 83: "osiemdziesięciu trzech" },
    { 84: "osiemdziesięciu czterech" },
    { 85: "osiemdziesięciu pięciu" },
    { 86: "osiemdziesięciu sześciu" },
    { 87: "osiemdziesięciu siedmiu" },
    { 88: "osiemdziesięciu ośmiu" },
    { 89: "osiemdziesięciu dziewięciu" },
    { 90: "dziewięćdziesięciu" },
    { 91: "dziewięćdziesięciu jeden" },
    { 92: "dziewięćdziesięciu dwóch" },
    { 93: "dziewięćdziesięciu trzech" },
    { 94: "dziewięćdziesięciu czterech" },
    { 95: "dziewięćdziesięciu pięciu" },
    { 96: "dziewięćdziesięciu sześciu" },
    { 97: "dziewięćdziesięciu siedmiu" },
    { 98: "dziewięćdziesięciu ośmiu" },
    { 99: "dziewięćdziesięciu dziewięciu" },
    { 100: "stu" },
  ];

  // Wyszukanie obiektu z daną liczbą
  const obiektOdmiany = odmianyCyfr.find((e) => e[value]);

  // Sprawdzenie, czy obiekt został znaleziony
  if (!obiektOdmiany) {
    return "";
  }

  // Zwrócenie odmiany
  return obiektOdmiany[value];
};
