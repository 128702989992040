import { Routes, Route } from "react-router-dom";
import NoPage from "../../pages/NoPage/NoPage";
import HomePage from "../../pages/HomePage/HomePage";
import Login from "../../pages/Login/Login";
import Contact from "../../pages/Contact/Contact";
import UserPanel from "../../pages/User/UserPanel";
import * as RoutePath from "./RoutePath";
import PresentationProtocolAdd from "../../pages/PresentationProtocol/PresentationProtocolAdd";
import PresentationProtocolClient from "../../pages/PresentationProtocol/ClientLogin";
import PresentationProtocolPanel from "../../pages/PresentationProtocol/PresentationProtocolPanel";
import BranchPanel from "../../pages/Branch/BranchPanel";
import DrawingCanvas from "../../components/DrawingCanvas/DrawingCanvas";
import IntermediationContractAdd from "../../pages/IntermediationContract/IntermediationContractAdd";
import IntermediationContractClientLogin from "../../pages/IntermediationContract/ClientLogin";
import IntermediationContractPanel from "../../pages/IntermediationContract/IntermediationContractPanel";
import IntermediationContractClientForm from "../../pages/IntermediationContract/ClientForm";
import DocumentSignedScreen from "../../pages/IntermediationContract/DocumentSignedScreen";
import TemplateDocumentAdd from "../../pages/TemplateDocument/TemplateDocumentAdd";
import TemplateDocumentHistory from "../../pages/TemplateDocument/TemplateDocumentHistory";
import TemplateDocumentClientLogin from "./../../pages/TemplateDocument/Client/TemplateDocumentClientLogin";
import TemplateDocumentClientDecision from "./../../pages/TemplateDocument/Client/TemplateDocumentClientDecision";
import TemplateDocumentClientFinish from "./../../pages/TemplateDocument/Client/TemplateDocumentClientFinish";
import ContractPurchaseAdd from "../../pages/ContractPurchase/ContractPurchaseAdd";
import ContractPurchaseHistory from "../../pages/ContractPurchase/ContractPurchaseHistory";
import ContractPurchaseClientLogin from "./../../pages/ContractPurchase/Client/ContractPurchaseClientLogin";
import ContractPurchaseClientDecision from "./../../pages/ContractPurchase/Client/ContractPurchaseClientDecision";
import ContractPurchaseClientFinish from "./../../pages/ContractPurchase/Client/ContractPurchaseClientFinish";
import ContractLeaseAdd from "../../pages/ContractLease/ContractLeaseAdd";
import ContractLeaseHistory from "../../pages/ContractLease/ContractLeaseHistory";
import ContractLeaseClientLogin from "./../../pages/ContractLease/Client/ContractLeaseClientLogin";
import ContractLeaseClientDecision from "./../../pages/ContractLease/Client/ContractLeaseClientDecision";
import ContractLeaseClientFinish from "./../../pages/ContractLease/Client/ContractLeaseClientFinish";

const NavigationRoute = (props) => {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        // display: "flex",
      }}
    >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path={RoutePath.Login} element={<Login />} />
        <Route path={RoutePath.Contact} element={<Contact />} />
        <Route path={RoutePath.PresentationProtocolHistory} element={<PresentationProtocolPanel />} />
        <Route path={RoutePath.PresentationProtocolAgent} element={<PresentationProtocolAdd />} />
        <Route path={RoutePath.PresentationProtocolClient} element={<PresentationProtocolClient />} />
        <Route path={RoutePath.Users} element={<UserPanel />} />
        <Route path={RoutePath.Branches} element={<BranchPanel />} />
        <Route path={RoutePath.DrawingCanvas} element={<DrawingCanvas />} />

        <Route path={RoutePath.IntermediationContractHistory} element={<IntermediationContractPanel />} />
        <Route path={RoutePath.IntermediationContractAgent} element={<IntermediationContractAdd />} />
        <Route path={RoutePath.IntermediationContractClientLogin} element={<IntermediationContractClientLogin />} />
        <Route path={RoutePath.IntermediationContractCientForm} element={<IntermediationContractClientForm />} />
        <Route path={RoutePath.IntermediationContractFinish} element={<DocumentSignedScreen />} />

        <Route path={RoutePath.TemplateDocumentAdd} element={<TemplateDocumentAdd />} />
        <Route path={RoutePath.TemplateDocumentHistory} element={<TemplateDocumentHistory />} />
        <Route path={RoutePath.TemplateDocumentClientLogin} element={<TemplateDocumentClientLogin />} />
        <Route path={RoutePath.TemplateDocumentClientDecision} element={<TemplateDocumentClientDecision />} />
        <Route path={RoutePath.TemplateDocumentClientFinish} element={<TemplateDocumentClientFinish />} />

        <Route path={RoutePath.ContractPurchaseAdd} element={<ContractPurchaseAdd />} />
        <Route path={RoutePath.ContractPurchaseHistory} element={<ContractPurchaseHistory />} />
        <Route path={RoutePath.ContractPurchaseClientLogin} element={<ContractPurchaseClientLogin />} />
        <Route path={RoutePath.ContractPurchaseClientDecision} element={<ContractPurchaseClientDecision />} />
        <Route path={RoutePath.ContractPurchaseClientFinish} element={<ContractPurchaseClientFinish />} />

        <Route path={RoutePath.ContractLeaseAdd} element={<ContractLeaseAdd />} />
        <Route path={RoutePath.ContractLeaseHistory} element={<ContractLeaseHistory />} />
        <Route path={RoutePath.ContractLeaseClientLogin} element={<ContractLeaseClientLogin />} />
        <Route path={RoutePath.ContractLeaseClientDecision} element={<ContractLeaseClientDecision />} />
        <Route path={RoutePath.ContractLeaseClientFinish} element={<ContractLeaseClientFinish />} />

        <Route path="*" element={<NoPage />} />
      </Routes>
    </div>
  );
};

export default NavigationRoute;
