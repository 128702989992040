import { useState, useEffect } from "react";
import Paperbase from "../../layout/Admin/Paperbase";
import UserList from "../../business/User/UserList";
import UserForm from "../../business/User/UserForm";
import * as dict from "../../config/Dictionary";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationHelper";

const UserPanel = (props) => {
  const { t } = useTranslation();
  const currentTabIndex = 0;

  const [notification, setNotification] = useState({
    text: "",
    severity: "",
  });

  const modes = {
    objectChoose: "objectChoose",
    objectAdd: "objectAdd",
    objectAdded: "objectAdded",
    objectEdit: "objectEdit",
    objectEdited: "objectEdited",
    objectDeleted: "objectDeleted",
  };

  const handleTabChange = () => {};

  useEffect(() => {}, []);

  const handleNotificationShow = (text, severity) => {
    setNotification({
      text: text,
      severity: severity,
    });
  };

  const [objectId, setObjectId] = useState(-1);

  const titles = {
    list: "Konta użytkowników",
    edit: "Edycja danych użytkownika",
    add: "Nowe konto użytkownika",
    preview: "Konto użytkownika",
  };

  const [title, setTitle] = useState(titles.list);
  const [objectFilters, setObjectFilters] = useState({});
  const [activeMode, setActiveMode] = useState(modes.objectChoose);

  const handleObjectPreview = (recordId, filters) => {
    setObjectFilters(filters);
    setActiveMode(modes.objectPreview);
    setTitle(titles.preview.concat(" [", recordId, "]"));
  };

  const handleObjectEdit = (recordId, filters) => {
    setObjectFilters(filters);
    setActiveMode(modes.objectEdit);
    setTitle(titles.edit.concat(" [", recordId, "]"));
  };

  const handleObjectFiltered = (filters) => {
    setObjectFilters(filters);
  };

  const handleObjectSelected = (a) => {
    setNotification(null);
    let val = a[0];
    setObjectId(val ? val : -1);
  };
  const handleObjectDelete = (recordId) => {
    setActiveMode(modes.objectChoose);
    setTitle(titles.list);
  };

  const handleObjectAfterSave = (mode, recordId) => {
    // setObjectId(-1);
    // setActiveMode(modes.objectChoose);
    // setTitle(titles.list);

    if (mode === dict.FormMode.edit) {
    } else if (mode === dict.FormMode.add) {
      setObjectId(recordId);
      setActiveMode(modes.objectEdit);
      setTitle(titles.edit.concat(" [", recordId, "]"));
    }
  };

  const handleObjectAfterBack = (mode) => {
    setObjectId(-1);
    setActiveMode(modes.objectChoose);
    setTitle(titles.list);
  };

  const handleObjectAfterCancel = (mode) => {
    setActiveMode(modes.objectChoose);
    setTitle(titles.list);
  };

  // eslint-disable-next-line
  const operAddObject = () => {
    setActiveMode(modes.objectAdd);
    setTitle(titles.add);
  };

  const displayContentTab0 = () => {
    if (activeMode === modes.objectEdit) {
      return (
        <div>
          <UserForm
            id={objectId}
            mode={dict.FormMode.edit}
            onNotificationShow={(a, b) => handleNotificationShow(a, b)}
            onAfterSave={(a, b) => handleObjectAfterSave(a, b)}
            onAfterCancel={(a) => handleObjectAfterCancel(a)}
          />
        </div>
      );
    } else if (activeMode === modes.objectAdd) {
      return (
        <div>
          <UserForm
            id={null}
            mode={dict.FormMode.add}
            onNotificationShow={(a, b) => handleNotificationShow(a, b)}
            onAfterSave={(a, b) => handleObjectAfterSave(a, b)}
            onAfterCancel={(a) => handleObjectAfterCancel(a)}
          />
        </div>
      );
    } else if (activeMode === modes.objectPreview) {
      return (
        <div>
          <UserForm id={objectId} mode={dict.FormMode.preview} onAfterBack={(a) => handleObjectAfterBack(a)} />
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <Stack direction="row" spacing={2}>
              {/* <Button variant="outlined">Wyszukaj</Button> */}
              <Button variant="outlined" onClick={operAddObject} startIcon={<TaskOutlinedIcon />}>
                {"Dodaj konto użytkownika"}
              </Button>
            </Stack>
          </div>
          <UserList
            onRecordSelected={(a) => handleObjectSelected(a)}
            onObjectPreview={(a, filters) => handleObjectPreview(a, filters)}
            onObjectEdit={(a, filters) => handleObjectEdit(a, filters)}
            onObjectDelete={(a) => handleObjectDelete(a)}
            onObjectFiltered={(filters) => handleObjectFiltered(filters)}
            filters={objectFilters}
            onNotificationShow={(a, b) => handleNotificationShow(a, b)}
          />
        </div>
      );
    }
  };

  return (
    <Paperbase
      title={t(formatTranslation("menu", dict.PageCodes.users))}
      subtitle={"Lista kont dostępowych"}
      activepositioncode={dict.PageCodes.users}
      tabActiveIndex={currentTabIndex}
      onTabChange={(a, b) => handleTabChange(a, b)}
      //operationPanel = { displayOperationPanel() }
      tab0Label={""}
      tab0Title={title}
      tab0Content={displayContentTab0()}
      tab1Label={null}
      tab1Title={null}
      tab1Content={null}
      notification={notification}
    />
  );
};

export default UserPanel;
