import axios from "axios";
import * as api from "../../../config/ApiConfig";
import * as session from "../../../components/SessionStorage/SessionStorage";
import * as Token from "./../../../api/Token";

export const Create = async (object) => {
  try {
    const response = await axios({
      method: "post",
      url: api.url_TemplateDocumentBinary_Create,
      headers: {
        "Content-Type": "multipart/form-data",
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: {
        data: object.data,
        fileName: object.fileName,
        mimeType: object.mimeType,
        label: object.label,
        docId: object.docId,
      },
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_TemplateDocumentBinary_Create, error);
  }
};

export const Update = async (objectId, object) => {
  try {
    let bodyData = {
      updatedBy: session.getUserId(),
      label: object.label,
    };

    const response = await axios({
      method: "post",
      url: api.url_TemplateDocumentBinary_Update,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        id: objectId,
      },
      data: bodyData,
    });

    return response.data.id;
  } catch (error) {
    console.log(error);
    console.error("API method error: ", api.url_TemplateDocumentBinary_Update, error);
  }
};

export const Delete = async (objectId) => {
  try {
    const response = await axios({
      method: "delete",
      url: api.url_TemplateDocumentBinary_Delete,
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      data: [objectId],
    });

    return response.data.id;
  } catch (error) {
    console.error("API method error: ", api.url_TemplateDocumentBinary_Delete, error);
  }
};

export const GetFileById = async (objectId) => {
  try {
    const response = await axios({
      method: "get",
      url: api.url_TemplateDocumentBinary_GetFileById,
      params: { id: objectId },
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_TemplateDocumentBinary_GetFileById, error);
  }
};

export const GetFileByIdSessionKey = async (objectId, sessionKey, docId) => {
  try {
    const response = await axios({
      method: "get",
      url: api.url_TemplateDocumentBinary_GetFileByIdSessionKey,
      params: { id: objectId, sessionKey: sessionKey, docId: docId },
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_TemplateDocumentBinary_GetFileByIdSessionKey, error);
  }
};

export const GetById = async (objectId) => {
  try {
    const response = await axios.get(api.url_TemplateDocumentBinary_GetById, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: { id: objectId },
    });

    // const object = new TemplateDocument();

    // object.id = response.data.id;
    // object.createdBy = response.data.createdBy;
    // object.updatedBy = response.data.updatedBy;
    // object.sessionKey = response.data.sessionKey;

    return response.data;
  } catch (error) {
    console.error("API method error: ", api.url_TemplateDocumentBinary_GetById, error);
  }
};

export const GetIdsByDocId = async (docId, retry) => {
  try {
    const response = await axios.get(api.url_TemplateDocumentBinary_GetIdsByDocId, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        qUserId: session.getUserId(),
        id: docId,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 403 && !retry) {
      await Token.isTokenValid();
      return await GetIdsByDocId(docId, true);
    } else if (error.response.status === 403) {
      throw new Error("403");
    }
    console.log("error", error);
    throw new Error("Error, ".concat(api.url_TemplateDocumentBinary_GetIdsByDocId, error.message));
  }
};

export const GetIdsByDocIdSessionKey = async (docId, retry, sessionKey) => {
  try {
    const response = await axios.get(api.url_TemplateDocumentBinary_GetIdsByDocIdSessionKey, {
      headers: {
        login: session.getLogin(),
        token: session.getToken(),
      },
      params: {
        qUserId: session.getUserId(),
        id: docId,
        sessionKey: sessionKey,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response.status === 403 && !retry) {
      await Token.isTokenValid();
      return await GetIdsByDocId(docId, true);
    } else if (error.response.status === 403) {
      throw new Error("403");
    }
    console.log("error", error);
    throw new Error("Error, ".concat(api.url_TemplateDocumentBinary_GetIdsByDocIdSessionKey, error.message));
  }
};
