import "./App.css";
import NavigationRoute from "./config/NavigationRoute/NavigationRoute";
import { useEffect } from "react";
import * as Token from "./api/Token";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "./config/NavigationRoute/RoutePath";

function App() {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const checkToken = async () => {
    const isTokenValid = await Token.isTokenValid("global");

    if (!isTokenValid) {
      navigate(RoutePath.Login);
    }
  };

  useEffect(() => {
    // const interval = setInterval(() => {
    //   const currentPath = window.location.pathname;
    //   if (currentPath !== "/login") {
    //     checkToken();
    //   }
    // }, 7000);
    // return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <NavigationRoute />
    </div>
  );
}

export default App;
