import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "./../../config/NavigationRoute/RoutePath";
import * as UserApi from "../../business/User/UserApi";
import { SHA256 } from "crypto-js";

import * as session from "./../../components/SessionStorage/SessionStorage";

function Login() {
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const refLogin = useRef(null);
  const refPassword = useRef(null);
  const [focusedElement, setFocusedElement] = useState("login");

  const handleFocus = (e) => {
    setFocusedElement(e.target.name);
  };

  const handleBlur = () => {
    setFocusedElement(null);
  };

  useEffect(() => {
    document.body.style.backgroundImage = 'url("/assets/Graphics/client/background.jpg")';

    document.body.style.backgroundSize = "100% 100%";
    document.body.style.minHeight = "100vh";

    session.setLogin("");
    session.setToken("");
    session.setRefreshToken("");
    session.setUserId(-1);

    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundSize = "";
      document.body.style.minHeight = "";
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [focusedElement, login, password]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && focusedElement === "password") {
      handleClick();
    }
  };

  const handleSetLogin = (event) => {
    setMessage("");
    setLogin(event.target.value);
  };

  const handleSetPassword = (event) => {
    setMessage("");
    setPassword(event.target.value);
  };

  const handleClick = async () => {
    try {
      if (!login || !password) {
        setMessage("Uzupełnij login i hasło.");
        return -1;
      }
      const salt = await UserApi.GetSalt(login);
      const hashedPassword = SHA256(password.concat(salt)).toString();

      const result = await UserApi.Login(login, hashedPassword);

      if (result.errorMsg !== "") {
        setMessage("Niepoprawny login lub hasło.");
        return -1;
      }

      setMessage("");

      session.setLogin(login);
      session.setToken(result.token);
      session.setRefreshToken(result.refreshToken);
      session.setUserId(result.userId);

      navigate(RoutePath.Main, {});
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const renderForm = (
    <>
      <Stack spacing={3}>
        <TextField
          name="login"
          label="Login"
          value={login}
          onChange={handleSetLogin}
          ref={refLogin}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoFocus
        />

        <TextField
          name="password"
          label="Hasło"
          value={password}
          onChange={handleSetPassword}
          ref={refPassword}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3 }}>
        <Link variant="subtitle2" underline="hover">
          Zapomniałeś hasło?
        </Link>
      </Stack>
      <div
        style={{
          color: "red",
          fontWeight: "bold",
          minHeight: "25px",
          marginBottom: "5px",
        }}
      >
        {message}
      </div>
      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        onClick={handleClick}
        style={{ background: "#009be5", color: "#ffffff" }}
      >
        Kontynuuj
      </Button>
    </>
  );

  return (
    <Box component="main" sx={{ flex: 1, py: 6, px: 4 }}>
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4">Witamy</Typography>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Wprowadź login i hasło, aby rozpocząć
            </Typography>
          </Divider>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
}

export default Login;
